/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import _ from 'underscore';
import memoize from 'memoize-one';
import {
	TableHeaderColumn as TableHeaderColumnBootstrap,
	BootstrapTable,
} from 'react-bootstrap-table';
import { ButtonGroup, Button } from 'react-bootstrap';
import MainFunctions from 'mainLibs/MainFunctions';

import tableFooter from 'components/service/statsTableFooter';

//TableHeaderColumn из react-bootstrap-table перерендеривается
// даже при тех же props и state, поэтому передаем через оболочку PureComponent
class TableHeaderColumn extends React.PureComponent {
	render() {
		return <TableHeaderColumnBootstrap {...this.props} />;
	}
}

// Изспользуем мемоизацию, чтобы не парсить данные при каждом рендеринге
const parseDaily = memoize((stats) => {
	if (!Object.keys(stats).length) return [];
	let daily = [];

	for (const currentDate in stats) {
		stats[currentDate] = { actions: [], uniqClicks: 0, allClicks: 0, ...stats[currentDate] };

		// let statusApprove = (((currentActionsSorted.Approved || []).length || 0) / ((currentActionsSorted.Declined || []).length || 0)) * 100;

		const currentActions = stats[currentDate].actions || [];
		const currentActionsSorted = _.groupBy(
			currentActions,
			(action) => ((_.last(action.statusesFlow || []) || {}).status || {}).name,
		);
		const validApprove =
			(((currentActionsSorted.Approved || []).length || 0) /
				((currentActionsSorted.Approved || []).length +
					(currentActionsSorted.Declined || []).length +
					(currentActionsSorted.Pending || []).length)) *
			100;
		const statusApprove =
			(((currentActionsSorted.Approved || []).length || 0) /
				((currentActionsSorted.Approved || []).length +
					(currentActionsSorted.Declined || []).length +
					(currentActionsSorted.Pending || []).length +
					(currentActionsSorted.Trash || []).length)) *
			100;

		const numUniqClicks = stats[currentDate].uniqClicks || 0;
		let CR =
			(((currentActionsSorted.Approved || []).length +
				(currentActionsSorted.Declined || []).length +
				(currentActionsSorted.Pending || []).length) /
				numUniqClicks) *
			100;
		let epc =
			_.reduce(
				currentActionsSorted.Approved,
				(memo, action) => memo + action.fixedPayout,
				0,
			) / numUniqClicks;

		const approvedMoney = _.reduce(
			currentActionsSorted.Approved || [],
			(memo, action) => memo + action.fixedPayout,
			0,
		);
		const holdMoney = _.reduce(
			currentActionsSorted.Pending || [],
			(memo, action) => memo + action.fixedPayout,
			0,
		);
		const declinedMoney = _.reduce(
			currentActionsSorted.Declined || [],
			(memo, action) => memo + action.fixedPayout,
			0,
		);
		const allMoney = approvedMoney + holdMoney + declinedMoney;
		daily.push({
			date: currentDate,
			uniqClicks: MainFunctions.formatNumber(numUniqClicks, 0),
			allClicks: MainFunctions.formatNumber(stats[currentDate].allClicks, 0),
			CR: MainFunctions.formatNumber(isNaN(CR) || !isFinite(CR) ? 0 : CR, 2) + ' %',
			epc: MainFunctions.formatNumber(isNaN(epc) ? 0 : epc, 2) + ' р.',

			// approve: (actionsParsed.A || []).length,
			allActions: MainFunctions.formatNumber(
				(currentActionsSorted.Approved || []).length +
					(currentActionsSorted.Pending || []).length +
					(currentActionsSorted.Declined || []).length,
				0,
			),
			approvedActions: MainFunctions.formatNumber(
				(currentActionsSorted.Approved || []).length,
				0,
			),
			holdActions: MainFunctions.formatNumber((currentActionsSorted.Pending || []).length, 0),
			declinedActions: MainFunctions.formatNumber(
				(currentActionsSorted.Declined || []).length,
				0,
			),
			trashActions: MainFunctions.formatNumber((currentActionsSorted.Trash || []).length, 0),

			allMoney: MainFunctions.formatNumber(allMoney, 0) + ' р',
			approvedMoney: MainFunctions.formatNumber(approvedMoney, 0) + ' р',
			holdMoney: MainFunctions.formatNumber(holdMoney, 0) + ' р',
			declinedMoney: MainFunctions.formatNumber(declinedMoney, 0) + ' р',

			validApprove:
				MainFunctions.formatNumber((currentActionsSorted.Approved || []).length, 2) +
				' (' +
				MainFunctions.formatNumber(isNaN(validApprove) ? 0 : validApprove, 2) +
				'%)',
			statusApprove:
				MainFunctions.formatNumber((currentActionsSorted.Approved || []).length, 2) +
				' (' +
				MainFunctions.formatNumber(isNaN(statusApprove) ? 0 : statusApprove, 2) +
				'%)',
		});
	}
	return daily;
});

export default class TableBlock extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			tableConfig: [
				{
					label: 'Траффик',
					columns: [
						{
							label: 'Клики',
							dataField: 'allClicks',
						},
						{
							label: 'Уники',
							dataField: 'uniqClicks',
						},
					],
					collapse: false,
				},
				{
					label: 'Коэффициенты',
					columns: [
						{
							label: 'CR',
							dataField: 'CR',
						},
						{
							label: 'EPC',
							dataField: 'epc',
						},
					],
					collapse: false,
				},
				{
					label: 'Конверсии',
					columns: [
						{
							label: 'Апрув',
							label2: '(valid)',
							dataField: 'validApprove',
						},
						{
							label: 'Апрув',
							label2: '(status)',
							dataField: 'statusApprove',
						},
						{
							label: 'Всего',
							dataField: 'allActions',
						},
						{
							label: 'Одобрено',
							dataField: 'approvedActions',
						},
						{
							label: 'Холд',
							dataField: 'holdActions',
						},
						{
							label: 'Отклонено',
							dataField: 'declinedActions',
						},
						{
							label: 'Трэш',
							dataField: 'trashActions',
						},
					],
					collapse: false,
				},
				{
					label: 'Финансы',
					columns: [
						{
							label: 'Всего',
							dataField: 'allMoney',
						},
						{
							label: 'Апрув',
							dataField: 'approvedMoney',
						},
						{
							label: 'Холд',
							dataField: 'holdMoney',
						},
						{
							label: 'Отклонено',
							dataField: 'declinedMoney',
						},
					],
					collapse: false,
				},
			],
		};
		this.tdStyle = { whiteSpace: 'normal' };
	}

	getNoDataText = memoize((loading, text) => {
		if (loading) {
			return (
				<div className="pageOverlay">
					<i className="fa fa-spinner css--animate" />
				</div>
			);
		} else return text;
	});

	collapseField = (field, collapsed) => {
		let currentTableConfig = this.state.tableConfig.slice();
		currentTableConfig[field].collapse = collapsed;
		this.setState({
			tableConfig: currentTableConfig,
		});
	};

	render() {
		const daily = this.props.daily.loading ? [] : parseDaily(this.props.daily.data);
		const noDataText = this.getNoDataText(this.props.daily.loading, this.props.t('Нет данных'));
		const { t } = this.props;
		const footerData = tableFooter(daily);
		return (
			<div className="box-body" style={{ padding: '0px' }}>
				<BootstrapTable
					data={daily}
					options={{ noDataText }}
					pagination
					bordered={false}
					hover
					striped
					footer
					footerData={footerData}
				>
					<TableHeaderColumn row="0" colSpan="1">
						<ButtonGroup>
							{this.state.tableConfig.map(
								(config, key) =>
									config.collapse && (
										<Button
											bsStyle="success"
											key={_.uniqueId()}
											bsSize="xsmall"
											style={{ minWidth: '80px' }}
											onClick={() => this.collapseField(key, false)}
										>
											{config.label} <i className={'fa fa-plus'} />
										</Button>
									),
							)}
						</ButtonGroup>
					</TableHeaderColumn>

					<TableHeaderColumn
						row="1"
						dataAlign="center"
						dataField="date"
						tdStyle={{ ...this.tdStyle, wordBreak: 'break-all' }}
						colSpan="1"
					>
						{t('Дата')}
					</TableHeaderColumn>

					<TableHeaderColumn row="1" dataField="date" isKey hidden>
						{t('Id')}
					</TableHeaderColumn>

					{this.state.tableConfig.map((config, key) => {
						if (!config.collapse) {
							const header = (
								<TableHeaderColumn
									key={'header_daily_stats' + config.label + key}
									row="0"
									dataAlign="center"
									colSpan={config.columns.length}
								>
									<a
										href="javascript:void(0)"
										onClick={() => this.collapseField(key, true)}
									>
										<small>
											{t(config.label)} <i className={'fa fa-minus'} />
										</small>
									</a>
								</TableHeaderColumn>
							);
							const children = config.columns.map((column, key) => (
								<TableHeaderColumn
									key={'column_daily_stats' + column.label + key}
									row="1"
									dataAlign="center"
									dataField={column.dataField}
									tdStyle={this.tdStyle}
									colSpan="1"
								>
									{t(column.label)}
									{column.label2 && <br />}
									{column.label2 && t(column.label2)}
								</TableHeaderColumn>
							));
							children.push(header);
							return children;
						}
						return null;
					})}
				</BootstrapTable>
			</div>
		);
	}
}
