import React from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import Select from 'react-select';
import { translate } from 'react-i18next';
import { ControlLabel, FormGroup, FormControl, Col, Row } from 'react-bootstrap';

import MainFunctions from 'mainLibs/MainFunctions';

class DateForm extends React.Component {
	static propTypes = {
		label: PropTypes.string,
		startValue: PropTypes.string,
		endValue: PropTypes.string,
		selectedPeriod: PropTypes.string,
		changeInfo: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);
		this.period = [
			{
				value: 0,
				label: 'Сегодня',
			},
			{
				value: 6,
				label: '7 дней',
			},
			{
				value: 29,
				label: '30 дней',
			},
			{
				value: 365,
				label: 'Год',
			},
		];
	}

	shouldComponentUpdate(nextProps, nextState) {
		return (
			this.props.startValue !== nextProps.startValue ||
			this.props.endValue !== nextProps.endValue ||
			this.props.selectedPeriod !== nextProps.selectedPeriod
		);
	}

	getData = (period) => {
		const today = new Date();
		let weekDay = today.getDay() - 1; // т.к. воскресенье - это 0, понедельник - 1
		if (weekDay < 0) weekDay = 6;
		let dateStart, dateEnd;
		switch (period) {
			case 'yesterday':
				dateStart = new Date(
					today.getFullYear(),
					today.getMonth(),
					today.getDate() - 1,
					0,
					0,
					0,
					0,
				);
				dateEnd = new Date(
					today.getFullYear(),
					today.getMonth(),
					today.getDate() - 1,
					23,
					59,
					59,
					999,
				);
				break;
			case 'today':
				dateStart = new Date(
					today.getFullYear(),
					today.getMonth(),
					today.getDate(),
					0,
					0,
					0,
					0,
				);
				dateEnd = new Date(
					today.getFullYear(),
					today.getMonth(),
					today.getDate(),
					23,
					59,
					59,
					999,
				);
				break;
			case 'last week':
				dateStart = new Date(
					today.getFullYear(),
					today.getMonth(),
					today.getDate() - weekDay - 7,
					0,
					0,
					0,
					0,
				);
				dateEnd = new Date(
					today.getFullYear(),
					today.getMonth(),
					today.getDate() - weekDay + 6 - 7,
					23,
					59,
					59,
					999,
				);
				break;
			case 'current week':
				dateStart = new Date(
					today.getFullYear(),
					today.getMonth(),
					today.getDate() - weekDay,
					0,
					0,
					0,
					0,
				);
				dateEnd = new Date(
					today.getFullYear(),
					today.getMonth(),
					today.getDate() - weekDay + 6,
					23,
					59,
					59,
					999,
				);
				break;
			case 'week':
				dateStart = new Date(
					today.getFullYear(),
					today.getMonth(),
					today.getDate() - 6,
					0,
					0,
					0,
					0,
				);
				dateEnd = new Date(
					today.getFullYear(),
					today.getMonth(),
					today.getDate(),
					23,
					59,
					59,
					999,
				);
				break;
			case 'current month':
				dateStart = new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, 0, 0);
				dateEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0, 0, 0, 0, 0);
				break;
			case 'last month':
				dateStart = new Date(today.getFullYear(), today.getMonth() - 1, 1, 0, 0, 0, 0);
				dateEnd = new Date(today.getFullYear(), today.getMonth(), 0, 0, 0, 0, 0);
				break;
			case 'month':
				dateStart = new Date(
					today.getFullYear(),
					today.getMonth() - 1,
					today.getDate(),
					0,
					0,
					0,
					0,
				);
				dateEnd = new Date(
					today.getFullYear(),
					today.getMonth(),
					today.getDate(),
					23,
					59,
					59,
					999,
				);
				break;
			case 'period':
				dateStart = new Date(this.props.startValue);
				dateEnd = new Date(this.props.endValue);
				break;
			default:
				dateStart = new Date(this.props.startValue);
				dateEnd = new Date(this.props.endValue);
				break;
		}

		return {
			start: MainFunctions.formatDateAsMySQL(dateStart),
			end: MainFunctions.formatDateAsMySQL(dateEnd),
			selectedPeriod: period,
		};
	};

	componentDidMount() {
		this.props.changeInfo(this.getData(this.props.selectedPeriod));
	}

	onСhangeStartClick = (val) => {
		const value = val.target ? val.target.value : val;
		this.props.changeInfo({ start: value, end: this.props.endValue, selectedPeriod: 'period' });
	};

	onСhangeEndClick = (val) => {
		const value = val.target ? val.target.value : val.value;
		this.props.changeInfo({
			start: this.props.startValue,
			end: value,
			selectedPeriod: 'period',
		});
	};

	onСhangePeriodClick = (val) => {
		const days = val.value;
		this.props.changeInfo(this.getData(days));
	};

	getPeriodOptions = memoize((t) => {
		const period = [
			{
				value: 'today',
				label: t('Сегодня'),
			},
			{
				value: 'yesterday',
				label: t('Вчера'),
			},
			{
				value: 'current week',
				label: t('Текущая неделя'),
			},
			{
				value: 'week',
				label: t('7 дней'),
			},
			{
				value: 'last week',
				label: t('Прошлая неделя'),
			},
			{
				value: 'current month',
				label: t('Текущий месяц'),
			},
			{
				value: 'month',
				label: t('30 дней'),
			},
			{
				value: 'last month',
				label: t('Прошлый месяц'),
			},
			{
				value: 'period',
				label: t('Период'),
			},
		];
		return period;
	});

	render() {
		const { selectedPeriod, startValue, endValue, label } = this.props;
		const period = this.getPeriodOptions(this.props.t);
		return (
			<FormGroup>
				<ControlLabel>{label}</ControlLabel>

				<Row>
					<Col xs={12} sm={12} md={4}>
						<Select
							value={selectedPeriod}
							options={period}
							autoBlur={true}
							clearable={false}
							onChange={this.onСhangePeriodClick}
						/>
					</Col>

					<Col xs={6} sm={6} md={4}>
						<FormControl
							type="date"
							value={startValue}
							onChange={this.onСhangeStartClick}
						/>
					</Col>

					<Col xs={6} sm={6} md={4}>
						<FormControl
							type="date"
							value={endValue}
							onChange={this.onСhangeEndClick}
						/>
					</Col>
				</Row>
			</FormGroup>
		);
	}
}

export default translate('DateForm')(DateForm);
