import React from 'react';

import InfoBlock from './InfoBlock';
import EditBlock from './EditBlock';

export default class LandingsInfoBlock extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			boxCollapse: false,
		};
	}

	componentDidMount() {
		this.props.getUsersLandings({ offerId: this.props.offerId });
	}

	toggleBox = (e) => {
		e.preventDefault();
		this.setState((prevState) => {
			return {
				boxCollapse: !prevState.boxCollapse,
			};
		});
	};

	render() {
		const {
			t,
			edited,
			landings,
			usersLandings,
			offerId,
			deleteLanding,
			addLanding,
			alertShow,
		} = this.props;
		return (
			<div className={'box box-primary' + (this.state.boxCollapse ? ' collapsed-box' : '')}>
				<div className="box-header with-border">
					<h3 className="box-title">{t('Лендинги')}</h3>
					<div className="box-tools pull-right">
						<button className="btn btn-box-tool" onClick={this.toggleBox}>
							<i
								className={
									'fa ' + (this.state.boxCollapse ? 'fa-plus' : 'fa-minus')
								}
							/>
						</button>
					</div>
				</div>

				<div className="box-body">
					{usersLandings.loading ? (
						<div className="pageOverlay">
							<i className="fa fa-spinner css--animate" />
						</div>
					) : null}

					<InfoBlock
						t={t}
						edited={edited}
						landings={landings}
						usersLandings={usersLandings.data}
						offerId={offerId}
						deleteLanding={deleteLanding}
						hideLanding={this.props.updateLandings}
					/>

					{edited && (
						<EditBlock
							alertShow={alertShow}
							t={t}
							offerId={offerId}
							addLanding={addLanding}
						/>
					)}
				</div>
			</div>
		);
	}
}
