/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'underscore';
import MainFunctions from 'mainLibs/MainFunctions';
import FormatedOfferInfo from 'components/service/FormatedOfferInfo';

import { Row, Col, Tab, Nav, NavItem, Button } from 'react-bootstrap';

export default class OfferAccessContent extends React.PureComponent {
	formatUser = (user) => {
		user = user || {};
		const to = user.isAffiliate ? 'affiliates/' : 'merchants/';
		return <Link to={`/users/${to + user._id}`}>{user.username}</Link>;
	};

	renderOwnerInfo = (owner, index) => {
		owner = owner || {};
		return (
			<dl className="dl-horizontal" key={`owner_${owner._id}_${index}`}>
				<dt>{this.props.t('Рекламодатель')}</dt>
				<dd>{this.formatUser(owner.ownerId)}</dd>
				<dt>{this.props.t('Процент траффика')}</dt>
				<dd>{owner.percentage}</dd>
				<dt>{this.props.t('Наценка рекламодателя')}</dt>
				<dd>{owner.merchantTopUp}</dd>
				<dt>{this.props.t('ID оффера в CRM')}</dt>
				<dd>{owner.ownerOfferId}</dd>
				<dt>{this.props.t('Сокр.название оффера в CRM')}</dt>
				<dd>{owner.ownerOfferName}</dd>
				<dt>{this.props.t('Полное название оффера в CRM')}</dt>
				<dd>{owner.ownerOfferNameFull}</dd>
			</dl>
		);
	};

	renderGeoInfo = (geo) => (
		<React.Fragment>
			<dl className="dl-horizontal">
				<dt>{this.props.t('Валюта на лендинге')}</dt>
				<dd>{`${geo.currency.name} (${geo.currency.code})`}</dd>
				<dt>{this.props.t('Старая цена товара')}</dt>
				<dd>{geo.oldLandingPrice}</dd>
				<dt>{this.props.t('Цена товара')}</dt>
				<dd>{geo.landingPrice}</dd>
				<dt>{this.props.t('Выплата')}</dt>
				<dd>{geo.payout.value}</dd>
			</dl>

			<div className="hr-text hr-text-center m-t-1">
				<strong>{this.props.t('Рекламодатели')}</strong>
			</div>

			{geo.owners.map(this.renderOwnerInfo)}
		</React.Fragment>
	);

	renderOfferInfo = (offer) => (
		<React.Fragment>
			<div className="hr-text hr-text-center m-t-1">
				<strong> {this.props.t('Товар')} </strong>
				<br />
				<strong>
					<FormatedOfferInfo onlyOffer {...offer} />
				</strong>
			</div>

			<dl className="dl-horizontal">
				<dt>{this.props.t('Колл-центр')}</dt>
				<dd>{offer.callCenter}</dd>
				<dt>{this.props.t('ЦА')}</dt>
				<dd>{offer.auditory}</dd>
				<dt>{this.props.t('Траффик')}</dt>
				<dd>{offer.traffic}</dd>
				<dt>{this.props.t('Описание товара')}</dt>
				<dd>{offer.description}</dd>
				<dt>{this.props.t('Тип действия')}</dt>
				<dd>
					{offer.actionType
						? offer.actionType.name + ' (' + offer.actionType.description + ')'
						: ''}
				</dd>
				<dt>{this.props.t('Категории товара')}</dt>
				<dd>
					{Array.isArray(offer.category)
						? _.pluck(offer.category, 'name').join(', ')
						: (offer.category || {}).name}
				</dd>
				<dt>{this.props.t('NNCPA колл-центр')}</dt>
				<dd>
					{offer.nncpaCallCenter ? (
						<i className={'fa fa-lg fa-check-circle'} style={{ color: '#99c262' }} />
					) : (
						<i className={'fa fa-lg fa-times-circle'} style={{ color: '#ff3232' }} />
					)}
				</dd>
				<dt>{this.props.t('Публичный оффер')}</dt>
				<dd>
					{offer.publicOffer ? (
						<i className={'fa fa-lg fa-check-circle'} style={{ color: '#99c262' }} />
					) : (
						<i className={'fa fa-lg fa-times-circle'} style={{ color: '#ff3232' }} />
					)}
				</dd>
				<dt>{this.props.t('White-оффер')}</dt>
				<dd>
					{offer.white ? (
						<i className={'fa fa-lg fa-check-circle'} style={{ color: '#99c262' }} />
					) : (
						<i className={'fa fa-lg fa-times-circle'} style={{ color: '#ff3232' }} />
					)}
				</dd>
			</dl>

			<Tab.Container id="tab-offer-geos" defaultActiveKey={0}>
				<Row className="clearfix">
					<Col xs={3}>
						<Nav stacked>
							{offer.geos.map((geo, i) => {
								geo = geo || {};
								geo.geoRef = geo.geoRef || {};
								return (
									<NavItem
										eventKey={i}
										key={'navGeo_' + offer._id + '_' + geo.geoRef._id + '_' + i}
									>
										{geo.geoRef.name}
									</NavItem>
								);
							})}
						</Nav>
					</Col>

					<Col xs={9}>
						<Tab.Content animation>
							{offer.geos.map((geo, i) => {
								geo = geo || {};
								geo.geoRef = geo.geoRef || {};
								geo.currency = geo.currency || {};
								geo.payout = geo.payout || {};
								if (!Array.isArray(geo.owners)) geo.owners = [];
								return <Tab.Pane eventKey={i}>{this.renderGeoInfo(geo)}</Tab.Pane>;
							})}
						</Tab.Content>
					</Col>
				</Row>
			</Tab.Container>
		</React.Fragment>
	);

	renderUserInfo = (user) => (
		<React.Fragment>
			<div className="hr-text hr-text-center m-t-1">
				<strong> {this.props.t('Пользователь')} </strong>
				<br />
				<strong>{this.formatUser(user)}</strong>
			</div>

			<dl className="dl-horizontal">
				<dt>{this.props.t('Имя')}</dt>
				<dd>{user.name}</dd>
				<dt>{this.props.t('Фамилия')}</dt>
				<dd>{user.lastName}</dd>
				<dt>{this.props.t('Зарегистрирован(а)')}</dt>
				<dd>{MainFunctions.dateFormat(user.registered)}</dd>
				<dt>{this.props.t('Был(а) в сети')}</dt>
				<dd>{MainFunctions.dateFormat(user.lastActivity)}</dd>
				<dt>{this.props.t('Язык')}</dt>
				<dd>{user.language.name}</dd>
				<dt>{this.props.t('Телефон')}</dt>
				<dd>{user.phone}</dd>
				<dt>{this.props.t('Email')}</dt>
				<dd>
					<a href="javascript:void(0)">{user.email}</a>
				</dd>
				<dt>{this.props.t('Skype')}</dt>
				<dd>
					<a href="javascript:void(0)">{user.skype}</a>
				</dd>
			</dl>
		</React.Fragment>
	);

	renderDescriptionInfo = (description) => (
		<React.Fragment>
			<div className="hr-text hr-text-center m-t-1">
				<strong> {this.props.t('Описание запроса')} </strong>
			</div>
			<p>{description}</p>
			<p>
				<Button bsStyle={'success'} onClick={this.props.resolveAccess}>
					{this.props.t('Разрешить доступ')}
				</Button>{' '}
				<Button bsStyle={'danger'} onClick={this.props.rejectAccess}>
					{this.props.t('Запретить доступ')}
				</Button>
			</p>
		</React.Fragment>
	);

	render() {
		const { user, offer, description } = this.props;
		return (
			<Row>
				<Col xs={12} md={7}>
					{this.renderOfferInfo(offer)}
				</Col>

				<Col xs={12} md={5}>
					{this.renderUserInfo(user)}
					{this.renderDescriptionInfo(description)}
				</Col>
			</Row>
		);
	}
}
