export const GET_ACTIONS_TYPES = 'GET_ACTIONS_TYPES';
export const GET_ACTIONS_TYPES_REQUEST = 'GET_ACTIONS_TYPES_REQUEST';
export const GET_ACTIONS_TYPES_SUCCESS = 'GET_ACTIONS_TYPES_SUCCESS';
export const GET_ACTIONS_TYPES_FAIL = 'GET_ACTIONS_TYPES_FAIL';

export const GET_BROWSER_PAGE_TYPES = 'GET_BROWSER_PAGE_TYPES';
export const GET_BROWSER_PAGE_TYPES_REQUEST = 'GET_BROWSER_PAGE_TYPES_REQUEST';
export const GET_BROWSER_PAGE_TYPES_SUCCESS = 'GET_BROWSER_PAGE_TYPES_SUCCESS';
export const GET_BROWSER_PAGE_TYPES_FAIL = 'GET_BROWSER_PAGE_TYPES_FAIL';

export const GET_CRMS = 'GET_CRMS';
export const GET_CRMS_REQUEST = 'GET_CRMS_REQUEST';
export const GET_CRMS_SUCCESS = 'GET_CRMS_SUCCESS';
export const GET_CRMS_FAIL = 'GET_CRMS_FAIL';

export const GET_AFFILIATES = 'GET_AFFILIATES';
export const GET_AFFILIATES_REQUEST = 'GET_AFFILIATES_REQUEST';
export const GET_AFFILIATES_SUCCESS = 'GET_AFFILIATES_SUCCESS';
export const GET_AFFILIATES_FAIL = 'GET_AFFILIATES_FAIL';

export const GET_EMAILS = 'GET_EMAILS';
export const GET_EMAILS_REQUEST = 'GET_EMAILS_REQUEST';
export const GET_EMAILS_SUCCESS = 'GET_EMAILS_SUCCESS';
export const GET_EMAILS_FAIL = 'GET_EMAILS_FAIL';

export const GET_ALL_AFFILIATES = 'GET_ALL_AFFILIATES';
export const GET_ALL_AFFILIATES_REQUEST = 'GET_ALL_AFFILIATES_REQUEST';
export const GET_ALL_AFFILIATES_SUCCESS = 'GET_ALL_AFFILIATES_SUCCESS';
export const GET_ALL_AFFILIATES_FAIL = 'GET_ALL_AFFILIATES_FAIL';

export const GET_GEOS = 'GET_GEOS';
export const GET_GEOS_REQUEST = 'GET_GEOS_REQUEST';
export const GET_GEOS_SUCCESS = 'GET_GEOS_SUCCESS';
export const GET_GEOS_FAIL = 'GET_GEOS_FAIL';

export const GET_MERCHANTS = 'GET_MERCHANTS';
export const GET_MERCHANTS_REQUEST = 'GET_MERCHANTS_REQUEST';
export const GET_MERCHANTS_SUCCESS = 'GET_MERCHANTS_SUCCESS';
export const GET_MERCHANTS_FAIL = 'GET_MERCHANTS_FAIL';

export const GET_CURRENCIES = 'GET_CURRENCIES';
export const GET_CURRENCIES_REQUEST = 'GET_CURRENCIES_REQUEST';
export const GET_CURRENCIES_SUCCESS = 'GET_CURRENCIES_SUCCESS';
export const GET_CURRENCIES_FAIL = 'GET_CURRENCIES_FAIL';

export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_USER_INFO_REQUEST = 'GET_USER_INFO_REQUEST';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_FAIL = 'GET_USER_INFO_FAIL';
export const LOCAL_USER_UPDATE = 'LOCAL_USER_UPDATE';

export const UPDATE_USER_OFFERS = 'UPDATE_USER_OFFERS';
export const UPDATE_USER_OFFERS_REQUEST = 'UPDATE_USER_OFFERS_REQUEST';
export const UPDATE_USER_OFFERS_SUCCESS = 'UPDATE_USER_OFFERS_SUCCESS';
export const UPDATE_USER_OFFERS_FAIL = 'UPDATE_USER_OFFERS_FAIL';

// ========= TICKETS CONSTANTS BEGIN =========
export const GET_TICKETS_FILTERS = 'GET_TICKETS_FILTERS';
export const GET_TICKETS_FILTERS_REQUEST = 'GET_TICKETS_FILTERS_REQUEST';
export const GET_TICKETS_FILTERS_SUCCESS = 'GET_TICKETS_FILTERS_SUCCESS';
export const GET_TICKETS_FILTERS_FAIL = 'GET_TICKETS_FILTERS_FAIL';

export const GET_ALL_TICKETS = 'GET_ALL_TICKETS';
export const GET_ALL_TICKETS_REQUEST = 'GET_ALL_TICKETS_REQUEST';
export const GET_ALL_TICKETS_SUCCESS = 'GET_ALL_TICKETS_SUCCESS';
export const GET_ALL_TICKETS_FAIL = 'GET_ALL_TICKETS_FAIL';

export const CREATE_TICKET = 'CREATE_TICKET';
export const CREATE_TICKET_REQUEST = 'CREATE_TICKET_REQUEST';
export const CREATE_TICKET_SUCCESS = 'CREATE_TICKET_SUCCESS';
export const CREATE_TICKET_FAIL = 'CREATE_TICKET_FAIL';

export const POST_MESSAGE_TO_TICKET = 'POST_MESSAGE_TO_TICKET';
export const POST_MESSAGE_TO_TICKET_REQUEST = 'POST_MESSAGE_TO_TICKET_REQUEST';
export const POST_MESSAGE_TO_TICKET_SUCCESS = 'POST_MESSAGE_TO_TICKET_SUCCESS';
export const POST_MESSAGE_TO_TICKET_FAIL = 'POST_MESSAGE_TO_TICKET_FAIL';

export const CLOSE_TICKET = 'CLOSE_TICKET';
export const CLOSE_TICKET_REQUEST = 'CLOSE_TICKET_REQUEST';
export const CLOSE_TICKET_SUCCESS = 'CLOSE_TICKET_SUCCESS';
export const CLOSE_TICKET_FAIL = 'CLOSE_TICKET_FAIL';

export const READ_TICKET_MESSAGE = 'READ_TICKET_MESSAGE';
export const READ_TICKET_MESSAGE_REQUEST = 'READ_TICKET_MESSAGE_REQUEST';
export const READ_TICKET_MESSAGE_SUCCESS = 'READ_TICKET_MESSAGE_SUCCESS';
export const READ_TICKET_MESSAGE_FAIL = 'READ_TICKET_MESSAGE_FAIL';

export const GET_NEW_TICKETS = 'GET_NEW_TICKETS';
export const GET_NEW_TICKETS_REQUEST = 'GET_NEW_TICKETS_REQUEST';
export const GET_NEW_TICKETS_SUCCESS = 'GET_NEW_TICKETS_SUCCESS';
export const GET_NEW_TICKETS_FAIL = 'GET_NEW_TICKETS_FAIL';

export const GET_TICKET = 'GET_TICKET';
export const GET_TICKET_REQUEST = 'GET_TICKET_REQUEST';
export const GET_TICKET_SUCCESS = 'GET_TICKET_SUCCESS';
export const GET_TICKET_FAIL = 'GET_TICKET_FAIL';
export const HIDE_TICKET = 'HIDE_TICKET';

// ========= TICKETS CONSTANTS END =========

// ========= PAYMENTS CONSTANTS END =========
export const GET_PAYMENTS = 'GET_PAYMENTS';
export const GET_PAYMENTS_REQUEST = 'GET_PAYMENTS_REQUEST';
export const GET_PAYMENTS_SUCCESS = 'GET_PAYMENTS_SUCCESS';
export const GET_PAYMENTS_FAIL = 'GET_PAYMENTS_FAIL';

export const GET_PRE_PAYMENTS = 'GET_PRE_PAYMENTS';
export const GET_PRE_PAYMENTS_REQUEST = 'GET_PRE_PAYMENTS_REQUEST';
export const GET_PRE_PAYMENTS_SUCCESS = 'GET_PRE_PAYMENTS_SUCCESS';
export const GET_PRE_PAYMENTS_FAIL = 'GET_PRE_PAYMENTS_FAIL';

export const GET_PAYMENT = 'GET_PAYMENT';
export const GET_PAYMENT_REQUEST = 'GET_PAYMENT_REQUEST';
export const GET_PAYMENT_SUCCESS = 'GET_PAYMENT_SUCCESS';
export const GET_PAYMENT_FAIL = 'GET_PAYMENT_FAIL';

export const UPDATE_PAYMENTS = 'UPDATE_PAYMENTS';
export const UPDATE_PAYMENTS_REQUEST = 'UPDATE_PAYMENTS_REQUEST';
export const UPDATE_PAYMENTS_SUCCESS = 'UPDATE_PAYMENTS_SUCCESS';
export const UPDATE_PAYMENTS_FAIL = 'UPDATE_PAYMENTS_FAIL';

export const GET_PAYMENT_STATUSES = 'GET_PAYMENT_STATUSES';
export const GET_PAYMENT_STATUSES_REQUEST = 'GET_PAYMENT_STATUSES_REQUEST';
export const GET_PAYMENT_STATUSES_SUCCESS = 'GET_PAYMENT_STATUSES_SUCCESS';
export const GET_PAYMENT_STATUSES_FAIL = 'GET_PAYMENT_STATUSES_FAIL';

export const GET_PAYMENT_SYSTEMS = 'GET_PAYMENT_SYSTEMS';
export const GET_PAYMENT_SYSTEMS_REQUEST = 'GET_PAYMENT_SYSTEMS_REQUEST';
export const GET_PAYMENT_SYSTEMS_SUCCESS = 'GET_PAYMENT_SYSTEMS_SUCCESS';
export const GET_PAYMENT_SYSTEMS_FAIL = 'GET_PAYMENT_SYSTEMS_FAIL';

export const PAYMENTS_ADD = 'PAYMENTS_ADD';
export const PAYMENTS_ADD_REQUEST = 'PAYMENTS_ADD_REQUEST';
export const PAYMENTS_ADD_SUCCESS = 'PAYMENTS_ADD_SUCCESS';
export const PAYMENTS_ADD_FAIL = 'PAYMENTS_ADD_FAIL';
export const HIDE_PAYMENT = 'HIDE_PAYMENT';
// ========= PAYMENTS CONSTANTS END =========

// ========= OFFERS CONSTANTS BEGIN =========
export const GET_OFFER = 'GET_OFFER';
export const GET_OFFER_REQUEST = 'GET_OFFER_REQUEST';
export const GET_OFFER_SUCCESS = 'GET_OFFER_SUCCESS';
export const GET_OFFER_FAIL = 'GET_OFFER_FAIL';

export const GET_OFFERS = 'GET_OFFERS';
export const GET_OFFERS_REQUEST = 'GET_OFFERS_REQUEST';
export const GET_OFFERS_SUCCESS = 'GET_OFFERS_SUCCESS';
export const GET_OFFERS_FAIL = 'GET_OFFERS_FAIL';

export const CREATE_OFFER = 'CREATE_OFFER';
export const CREATE_OFFER_REQUEST = 'CREATE_OFFER_REQUEST';
export const CREATE_OFFER_SUCCESS = 'CREATE_OFFER_SUCCESS';
export const CREATE_OFFER_FAIL = 'CREATE_OFFER_FAIL';

export const GET_OFFER_CATEGORY = 'GET_OFFER_CATEGORY';
export const GET_OFFER_CATEGORY_REQUEST = 'GET_OFFER_CATEGORY_REQUEST';
export const GET_OFFER_CATEGORY_SUCCESS = 'GET_OFFER_CATEGORY_SUCCESS';
export const GET_OFFER_CATEGORY_FAIL = 'GET_OFFER_CATEGORY_FAIL';

export const UPDATE_OFFER_INFO = 'UPDATE_OFFER_INFO';
export const UPDATE_OFFER_INFO_REQUEST = 'UPDATE_OFFER_INFO_REQUEST';
export const UPDATE_OFFER_INFO_SUCCESS = 'UPDATE_OFFER_INFO_SUCCESS';
export const UPDATE_OFFER_INFO_FAIL = 'UPDATE_OFFER_INFO_FAIL';

export const ADD_OFFER_GEO = 'ADD_OFFER_GEO';
export const ADD_OFFER_GEO_REQUEST = 'ADD_OFFER_GEO_REQUEST';
export const ADD_OFFER_GEO_SUCCESS = 'ADD_OFFER_GEO_SUCCESS';
export const ADD_OFFER_GEO_FAIL = 'ADD_OFFER_GEO_FAIL';

export const UPDATE_OFFER_GEO = 'UPDATE_OFFER_GEO';
export const UPDATE_OFFER_GEO_REQUEST = 'UPDATE_OFFER_GEO_REQUEST';
export const UPDATE_OFFER_GEO_SUCCESS = 'UPDATE_OFFER_GEO_SUCCESS';
export const UPDATE_OFFER_GEO_FAIL = 'UPDATE_OFFER_GEO_FAIL';

export const DELETE_OFFER_GEO = 'DELETE_OFFER_GEO';
export const DELETE_OFFER_GEO_REQUEST = 'DELETE_OFFER_GEO_REQUEST';
export const DELETE_OFFER_GEO_SUCCESS = 'DELETE_OFFER_GEO_SUCCESS';
export const DELETE_OFFER_GEO_FAIL = 'DELETE_OFFER_GEO_FAIL';

export const ADD_OFFER_LANDING = 'ADD_OFFER_LANDING';
export const ADD_OFFER_LANDING_REQUEST = 'ADD_OFFER_LANDING_REQUEST';
export const ADD_OFFER_LANDING_SUCCESS = 'ADD_OFFER_LANDING_SUCCESS';
export const ADD_OFFER_LANDING_FAIL = 'ADD_OFFER_LANDING_FAIL';

export const DELETE_OFFER_LANDING = 'DELETE_OFFER_LANDING';
export const DELETE_OFFER_LANDING_REQUEST = 'DELETE_OFFER_LANDING_REQUEST';
export const DELETE_OFFER_LANDING_SUCCESS = 'DELETE_OFFER_LANDING_SUCCESS';
export const DELETE_OFFER_LANDING_FAIL = 'DELETE_OFFER_LANDING_FAIL';

export const ADD_OFFER_PRELANDING = 'ADD_OFFER_PRELANDING';
export const ADD_OFFER_PRELANDING_REQUEST = 'ADD_OFFER_PRELANDING_REQUEST';
export const ADD_OFFER_PRELANDING_SUCCESS = 'ADD_OFFER_PRELANDING_SUCCESS';
export const ADD_OFFER_PRELANDING_FAIL = 'ADD_OFFER_PRELANDING_FAIL';

export const DELETE_OFFER_PRELANDING = 'DELETE_OFFER_PRELANDING';
export const DELETE_OFFER_PRELANDING_REQUEST = 'DELETE_OFFER_PRELANDING_REQUEST';
export const DELETE_OFFER_PRELANDING_SUCCESS = 'DELETE_OFFER_PRELANDING_SUCCESS';
export const DELETE_OFFER_PRELANDING_FAIL = 'DELETE_OFFER_PRELANDING_FAIL';

export const UPDATE_OFFER_ACCESS = 'UPDATE_OFFER_ACCESS';
export const UPDATE_OFFER_ACCESS_REQUEST = 'UPDATE_OFFER_ACCESS_REQUEST';
export const UPDATE_OFFER_ACCESS_SUCCESS = 'UPDATE_OFFER_ACCESS_SUCCESS';
export const UPDATE_OFFER_ACCESS_FAIL = 'UPDATE_OFFER_ACCESS_FAIL';

export const GET_OFFER_ACCESS = 'GET_OFFER_ACCESS';
export const GET_OFFER_ACCESS_REQUEST = 'GET_OFFER_ACCESS_REQUEST';
export const GET_OFFER_ACCESS_SUCCESS = 'GET_OFFER_ACCESS_SUCCESS';
export const GET_OFFER_ACCESS_FAIL = 'GET_OFFER_ACCESS_FAIL';

export const GET_ONE_OFFER_ACCESS = 'GET_ONE_OFFER_ACCESS';
export const GET_ONE_OFFER_ACCESS_REQUEST = 'GET_ONE_OFFER_ACCESS_REQUEST';
export const GET_ONE_OFFER_ACCESS_SUCCESS = 'GET_ONE_OFFER_ACCESS_SUCCESS';
export const GET_ONE_OFFER_ACCESS_FAIL = 'GET_ONE_OFFER_ACCESS_FAIL';
export const HIDE_OFFER_ACCESS = 'HIDE_OFFER_ACCESS';

export const CHANGE_OFFER_ACCESS = 'CHANGE_OFFER_ACCESS';
export const CHANGE_OFFER_ACCESS_REQUEST = 'CHANGE_OFFER_ACCESS_REQUEST';
export const CHANGE_OFFER_ACCESS_SUCCESS = 'CHANGE_OFFER_ACCESS_SUCCESS';
export const CHANGE_OFFER_ACCESS_FAIL = 'CHANGE_OFFER_ACCESS_FAIL';

export const CHANGE_OFFER_STATUS = 'CHANGE_OFFER_STATUS';
export const CHANGE_OFFER_STATUS_REQUEST = 'CHANGE_OFFER_STATUS_REQUEST';
export const CHANGE_OFFER_STATUS_SUCCESS = 'CHANGE_OFFER_STATUS_SUCCESS';
export const CHANGE_OFFER_STATUS_FAIL = 'CHANGE_OFFER_STATUS_FAIL';

export const GET_OFFERS_NAME = 'GET_OFFERS_NAME';
export const GET_OFFERS_NAME_REQUEST = 'GET_OFFERS_NAME_REQUEST';
export const GET_OFFERS_NAME_SUCCESS = 'GET_OFFERS_NAME_SUCCESS';
export const GET_OFFERS_NAME_FAIL = 'GET_OFFERS_NAME_FAIL';

export const DUPLICATE_OFFERS = 'DUPLICATE_OFFERS';
export const DUPLICATE_OFFERS_REQUEST = 'DUPLICATE_OFFERS_REQUEST';
export const DUPLICATE_OFFERS_SUCCESS = 'DUPLICATE_OFFERS_SUCCESS';
export const DUPLICATE_OFFERS_FAIL = 'DUPLICATE_OFFERS_FAIL';

export const GET_PWA_OPTIONS = 'GET_PWA_OPTIONS';
export const GET_PWA_OPTIONS_REQUEST = 'GET_PWA_OPTIONS_REQUEST';
export const GET_PWA_OPTIONS_SUCCESS = 'GET_PWA_OPTIONS_SUCCESS';
export const GET_PWA_OPTIONS_FAIL = 'GET_PWA_OPTIONS_FAIL';

export const ADD_PWA_OPTIONS = 'ADD_PWA_OPTIONS';
export const ADD_PWA_OPTIONS_REQUEST = 'ADD_PWA_OPTIONS_REQUEST';
export const ADD_PWA_OPTIONS_SUCCESS = 'ADD_PWA_OPTIONS_SUCCESS';
export const ADD_PWA_OPTIONS_FAIL = 'ADD_PWA_OPTIONS_FAIL';

export const DELETE_PWA_OPTIONS = 'DELETE_PWA_OPTIONS';
export const DELETE_PWA_OPTIONS_REQUEST = 'DELETE_PWA_OPTIONS_REQUEST';
export const DELETE_PWA_OPTIONS_SUCCESS = 'DELETE_PWA_OPTIONS_SUCCESS';
export const DELETE_PWA_OPTIONS_FAIL = 'DELETE_PWA_OPTIONS_FAIL';
// ========= OFFERS CONSTANTS END =========

export const UPDATE_USERS_LANDINGS = 'UPDATE_USERS_LANDINGS';
export const UPDATE_USERS_LANDINGS_REQUEST = 'UPDATE_USERS_LANDINGS_REQUEST';
export const UPDATE_USERS_LANDINGS_SUCCESS = 'UPDATE_USERS_LANDINGS_SUCCESS';
export const UPDATE_USERS_LANDINGS_FAIL = 'UPDATE_USERS_LANDINGS_FAIL';

export const GET_USERS_LANDINGS = 'GET_USERS_LANDINGS';
export const GET_USERS_LANDINGS_REQUEST = 'GET_USERS_LANDINGS_REQUEST';
export const GET_USERS_LANDINGS_SUCCESS = 'GET_USERS_LANDINGS_SUCCESS';
export const GET_USERS_LANDINGS_FAIL = 'GET_USERS_LANDINGS_FAIL';

export const GET_PARTNERS = 'GET_PARTNERS';
export const GET_PARTNERS_REQUEST = 'GET_PARTNERS_REQUEST';
export const GET_PARTNERS_SUCCESS = 'GET_PARTNERS_SUCCESS';
export const GET_PARTNERS_FAIL = 'GET_PARTNERS_FAIL';

export const LANDINGS = 'LANDINGS';
export const LANDINGS_REQUEST = 'LANDINGS_REQUEST';
export const LANDINGS_SUCCESS = 'LANDINGS_SUCCESS';
export const LANDINGS_FAIL = 'LANDINGS_FAIL';

export const GET_ACTIONS = 'GET_ACTIONS';
export const GET_ACTIONS_REQUEST = 'GET_ACTIONS_REQUEST';
export const GET_ACTIONS_SUCCESS = 'GET_ACTIONS_SUCCESS';
export const GET_ACTIONS_FAIL = 'GET_ACTIONS_FAIL';

export const UPDATE_ACTIONS = 'UPDATE_ACTIONS';
export const UPDATE_ACTIONS_REQUEST = 'UPDATE_ACTIONS_REQUEST';
export const UPDATE_ACTIONS_SUCCESS = 'UPDATE_ACTIONS_SUCCESS';
export const UPDATE_ACTIONS_FAIL = 'UPDATE_ACTIONS_FAIL';

export const GET_ALL_ACTIONS = 'GET_ALL_ACTIONS';
export const GET_ALL_ACTIONS_REQUEST = 'GET_ALL_ACTIONS_REQUEST';
export const GET_ALL_ACTIONS_SUCCESS = 'GET_ALL_ACTIONS_SUCCESS';
export const GET_ALL_ACTIONS_FAIL = 'GET_ALL_ACTIONS_FAIL';

export const GREATE_ACTIONS = 'GREATE_ACTIONS';
export const GREATE_ACTIONS_REQUEST = 'GREATE_ACTIONS_REQUEST';
export const GREATE_ACTIONS_SUCCESS = 'GREATE_ACTIONS_SUCCESS';
export const GREATE_ACTIONS_FAIL = 'GREATE_ACTIONS_FAIL';

export const GET_STATUSES = 'GET_STATUSES';
export const GET_STATUSES_REQUEST = 'GET_STATUSES_REQUEST';
export const GET_STATUSES_SUCCESS = 'GET_STATUSES_SUCCESS';
export const GET_STATUSES_FAIL = 'GET_STATUSES_FAIL';

export const GET_STATS_OVERALL = 'GET_STATS_OVERALL';
export const GET_STATS_OVERALL_REQUEST = 'GET_STATS_OVERALL_REQUEST';
export const GET_STATS_OVERALL_SUCCESS = 'GET_STATS_OVERALL_SUCCESS';
export const GET_STATS_OVERALL_FAIL = 'GET_STATS_OVERALL_FAIL';

export const GET_STATS_DAILY = 'GET_STATS_DAILY';
export const GET_STATS_DAILY_REQUEST = 'GET_STATS_DAILY_REQUEST';
export const GET_STATS_DAILY_SUCCESS = 'GET_STATS_DAILY_SUCCESS';
export const GET_STATS_DAILY_FAIL = 'GET_STATS_DAILY_FAIL';

export const GET_STATS_REQUESTS = 'GET_STATS_REQUESTS';
export const GET_STATS_REQUESTS_REQUEST = 'GET_STATS_REQUESTS_REQUEST';
export const GET_STATS_REQUESTS_SUCCESS = 'GET_STATS_REQUESTS_SUCCESS';
export const GET_STATS_REQUESTS_FAIL = 'GET_STATS_REQUESTS_FAIL';

export const GET_STATS_LANDINGS = 'GET_STATS_LANDINGS';
export const GET_STATS_LANDINGS_REQUEST = 'GET_STATS_LANDINGS_REQUEST';
export const GET_STATS_LANDINGS_SUCCESS = 'GET_STATS_LANDINGS_SUCCESS';
export const GET_STATS_LANDINGS_FAIL = 'GET_STATS_LANDINGS_FAIL';

export const GET_STATS_PRELANDINGS = 'GET_STATS_PRELANDINGS';
export const GET_STATS_PRELANDINGS_REQUEST = 'GET_STATS_PRELANDINGS_REQUEST';
export const GET_STATS_PRELANDINGS_SUCCESS = 'GET_STATS_PRELANDINGS_SUCCESS';
export const GET_STATS_PRELANDINGS_FAIL = 'GET_STATS_PRELANDINGS_FAIL';

// ========= AFFILIATE STATS CONSTANTS BEGIN =========
export const GET_STATS_AFFILIATE_DAILY = 'GET_STATS_AFFILIATE_DAILY';
export const GET_STATS_AFFILIATE_DAILY_REQUEST = 'GET_STATS_AFFILIATE_DAILY_REQUEST';
export const GET_STATS_AFFILIATE_DAILY_SUCCESS = 'GET_STATS_AFFILIATE_DAILY_SUCCESS';
export const GET_STATS_AFFILIATE_DAILY_FAIL = 'GET_STATS_AFFILIATE_DAILY_FAIL';

export const GET_STATS_AFFILIATE_LANDINGS = 'GET_STATS_AFFILIATE_LANDINGS';
export const GET_STATS_AFFILIATE_LANDINGS_REQUEST = 'GET_STATS_AFFILIATE_LANDINGS_REQUEST';
export const GET_STATS_AFFILIATE_LANDINGS_SUCCESS = 'GET_STATS_AFFILIATE_LANDINGS_SUCCESS';
export const GET_STATS_AFFILIATE_LANDINGS_FAIL = 'GET_STATS_AFFILIATE_LANDINGS_FAIL';

export const GET_STATS_AFFILIATE_PRELANDINGS = 'GET_STATS_AFFILIATE_PRELANDINGS';
export const GET_STATS_AFFILIATE_PRELANDINGS_REQUEST = 'GET_STATS_AFFILIATE_PRELANDINGS_REQUEST';
export const GET_STATS_AFFILIATE_PRELANDINGS_SUCCESS = 'GET_STATS_AFFILIATE_PRELANDINGS_SUCCESS';
export const GET_STATS_AFFILIATE_PRELANDINGS_FAIL = 'GET_STATS_AFFILIATE_PRELANDINGS_FAIL';

export const GET_STATS_AFFILIATE_SOURCES = 'GET_STATS_AFFILIATE_SOURCES';
export const GET_STATS_AFFILIATE_SOURCES_REQUEST = 'GET_STATS_AFFILIATE_SOURCES_REQUEST';
export const GET_STATS_AFFILIATE_SOURCES_SUCCESS = 'GET_STATS_AFFILIATE_SOURCES_SUCCESS';
export const GET_STATS_AFFILIATE_SOURCES_FAIL = 'GET_STATS_AFFILIATE_SOURCES_FAIL';

export const GET_STATS_AFFILIATE_CAMPAIGNS = 'GET_STATS_AFFILIATE_CAMPAIGNS';
export const GET_STATS_AFFILIATE_CAMPAIGNS_REQUEST = 'GET_STATS_AFFILIATE_CAMPAIGNS_REQUEST';
export const GET_STATS_AFFILIATE_CAMPAIGNS_SUCCESS = 'GET_STATS_AFFILIATE_CAMPAIGNS_SUCCESS';
export const GET_STATS_AFFILIATE_CAMPAIGNS_FAIL = 'GET_STATS_AFFILIATE_CAMPAIGNS_FAIL';

export const GET_STATS_AFFILIATE_OFFERS = 'GET_STATS_AFFILIATE_OFFERS';
export const GET_STATS_AFFILIATE_OFFERS_REQUEST = 'GET_STATS_AFFILIATE_OFFERS_REQUEST';
export const GET_STATS_AFFILIATE_OFFERS_SUCCESS = 'GET_STATS_AFFILIATE_OFFERS_SUCCESS';
export const GET_STATS_AFFILIATE_OFFERS_FAIL = 'GET_STATS_AFFILIATE_OFFERS_FAIL';

export const GET_STATS_AFFILIATE_GEOS = 'GET_STATS_AFFILIATE_GEOS';
export const GET_STATS_AFFILIATE_GEOS_REQUEST = 'GET_STATS_AFFILIATE_GEOS_REQUEST';
export const GET_STATS_AFFILIATE_GEOS_SUCCESS = 'GET_STATS_AFFILIATE_GEOS_SUCCESS';
export const GET_STATS_AFFILIATE_GEOS_FAIL = 'GET_STATS_AFFILIATE_GEOS_FAIL';

export const GET_STATS_AFFILIATE_REQUESTS = 'GET_STATS_AFFILIATE_REQUESTS';
export const GET_STATS_AFFILIATE_REQUESTS_REQUEST = 'GET_STATS_AFFILIATE_REQUESTS_REQUEST';
export const GET_STATS_AFFILIATE_REQUESTS_SUCCESS = 'GET_STATS_AFFILIATE_REQUESTS_SUCCESS';
export const GET_STATS_AFFILIATE_REQUESTS_FAIL = 'GET_STATS_AFFILIATE_REQUESTS_FAIL';

export const GET_USER_PAYMENTS = 'GET_USER_PAYMENTS';
export const GET_USER_PAYMENTS_REQUEST = 'GET_USER_PAYMENTS_REQUEST';
export const GET_USER_PAYMENTS_SUCCESS = 'GET_USER_PAYMENTS_SUCCESS';
export const GET_USER_PAYMENTS_FAIL = 'GET_USER_PAYMENTS_FAIL';

export const GET_STATS_AFFILIATE_FILTERS = 'GET_STATS_AFFILIATE_FILTERS';
export const GET_STATS_AFFILIATE_FILTERS_REQUEST = 'GET_STATS_AFFILIATE_FILTERS_REQUEST';
export const GET_STATS_AFFILIATE_FILTERS_SUCCESS = 'GET_STATS_AFFILIATE_FILTERS_SUCCESS';
export const GET_STATS_AFFILIATE_FILTERS_FAIL = 'GET_STATS_AFFILIATE_FILTERS_FAIL';

export const GET_AFFILIATE_TRACKLINKS = 'GET_AFFILIATE_TRACKLINKS';
export const GET_AFFILIATE_TRACKLINKS_REQUEST = 'GET_AFFILIATE_TRACKLINKS_REQUEST';
export const GET_AFFILIATE_TRACKLINKS_SUCCESS = 'GET_AFFILIATE_TRACKLINKS_SUCCESS';
export const GET_AFFILIATE_TRACKLINKS_FAIL = 'GET_AFFILIATE_TRACKLINKS_FAIL';

export const UPDATE_USER_PAYMENT_SETTINGS = 'UPDATE_USER_PAYMENT_SETTINGS';
export const UPDATE_USER_PAYMENT_SETTINGS_REQUEST = 'UPDATE_USER_PAYMENT_SETTINGS_REQUEST';
export const UPDATE_USER_PAYMENT_SETTINGS_SUCCESS = 'UPDATE_USER_PAYMENT_SETTINGS_SUCCESS';
export const UPDATE_USER_PAYMENT_SETTINGS_FAIL = 'UPDATE_USER_PAYMENT_SETTINGS_FAIL';

export const DELETE_USER_PAYMENT_SETTINGS = 'DELETE_USER_PAYMENT_SETTINGS';
export const DELETE_USER_PAYMENT_SETTINGS_REQUEST = 'DELETE_USER_PAYMENT_SETTINGS_REQUEST';
export const DELETE_USER_PAYMENT_SETTINGS_SUCCESS = 'DELETE_USER_PAYMENT_SETTINGS_SUCCESS';
export const DELETE_USER_PAYMENT_SETTINGS_FAIL = 'DELETE_USER_PAYMENT_SETTINGS_FAIL';
// ========= AFFILIATE STATS CONSTANTS END =========

// ========= MERCHANT STATS CONSTANTS BEGIN =========
export const GET_STATS_MERCHANT_OVERALL = 'GET_STATS_MERCHANT_OVERALL';
export const GET_STATS_MERCHANT_OVERALL_REQUEST = 'GET_STATS_MERCHANT_OVERALL_REQUEST';
export const GET_STATS_MERCHANT_OVERALL_SUCCESS = 'GET_STATS_MERCHANT_OVERALL_SUCCESS';
export const GET_STATS_MERCHANT_OVERALL_FAIL = 'GET_STATS_MERCHANT_OVERALL_FAIL';

export const GET_STATS_MERCHANT_DAILY = 'GET_STATS_MERCHANT_DAILY';
export const GET_STATS_MERCHANT_DAILY_REQUEST = 'GET_STATS_MERCHANT_DAILY_REQUEST';
export const GET_STATS_MERCHANT_DAILY_SUCCESS = 'GET_STATS_MERCHANT_DAILY_SUCCESS';
export const GET_STATS_MERCHANT_DAILY_FAIL = 'GET_STATS_MERCHANT_DAILY_FAIL';

export const GET_STATS_MERCHANT_REQUESTS = 'GET_STATS_MERCHANT_REQUESTS';
export const GET_STATS_MERCHANT_REQUESTS_REQUEST = 'GET_STATS_MERCHANT_REQUESTS_REQUEST';
export const GET_STATS_MERCHANT_REQUESTS_SUCCESS = 'GET_STATS_MERCHANT_REQUESTS_SUCCESS';
export const GET_STATS_MERCHANT_REQUESTS_FAIL = 'GET_STATS_MERCHANT_REQUESTS_FAIL';

export const GET_STATS_MERCHANT_FILTERS = 'GET_STATS_MERCHANT_FILTERS';
export const GET_STATS_MERCHANT_FILTERS_REQUEST = 'GET_STATS_MERCHANT_FILTERS_REQUEST';
export const GET_STATS_MERCHANT_FILTERS_SUCCESS = 'GET_STATS_MERCHANT_FILTERS_SUCCESS';
export const GET_STATS_MERCHANT_FILTERS_FAIL = 'GET_STATS_MERCHANT_FILTERS_FAIL';
// ========= MERCHANT STATS CONSTANTS END =========

// ========= REQUEST STATS CONSTANTS BEGIN =========
export const GET_OFFERS_FOR_REQUEST = 'GET_OFFERS_FOR_REQUEST';
export const GET_OFFERS_FOR_REQUEST_REQUEST = 'GET_OFFERS_FOR_REQUEST_REQUEST';
export const GET_OFFERS_FOR_REQUEST_SUCCESS = 'GET_OFFERS_FOR_REQUEST_SUCCESS';
export const GET_OFFERS_FOR_REQUEST_FAIL = 'GET_OFFERS_FOR_REQUEST_FAIL';

export const GET_MERCHANTS_FOR_REQUEST = 'GET_MERCHANTS_FOR_REQUEST';
export const GET_MERCHANTS_FOR_REQUEST_REQUEST = 'GET_MERCHANTS_FOR_REQUEST_REQUEST';
export const GET_MERCHANTS_FOR_REQUEST_SUCCESS = 'GET_MERCHANTS_FOR_REQUEST_SUCCESS';
export const GET_MERCHANTS_FOR_REQUEST_FAIL = 'GET_MERCHANTS_FOR_REQUEST_FAIL';

export const GET_TRACKLINKS_FOR_REQUEST = 'GET_TRACKLINKS_FOR_REQUEST';
export const GET_TRACKLINKS_FOR_REQUEST_REQUEST = 'GET_TRACKLINKS_FOR_REQUEST_REQUEST';
export const GET_TRACKLINKS_FOR_REQUEST_SUCCESS = 'GET_TRACKLINKS_FOR_REQUEST_SUCCESS';
export const GET_TRACKLINKS_FOR_REQUEST_FAIL = 'GET_TRACKLINKS_FOR_REQUEST_FAIL';

export const GET_IPS_FOR_REQUEST = 'GET_IPS_FOR_REQUEST';
export const GET_IPS_FOR_REQUEST_REQUEST = 'GET_IPS_FOR_REQUEST_REQUEST';
export const GET_IPS_FOR_REQUEST_SUCCESS = 'GET_IPS_FOR_REQUEST_SUCCESS';
export const GET_IPS_FOR_REQUEST_FAIL = 'GET_IPS_FOR_REQUEST_FAIL';

export const GET_CITYS_FOR_REQUEST = 'GET_CITYS_FOR_REQUEST';
export const GET_CITYS_FOR_REQUEST_REQUEST = 'GET_CITYS_FOR_REQUEST_REQUEST';
export const GET_CITYS_FOR_REQUEST_SUCCESS = 'GET_CITYS_FOR_REQUEST_SUCCESS';
export const GET_CITYS_FOR_REQUEST_FAIL = 'GET_CITYS_FOR_REQUEST_FAIL';

export const GET_AFFILIATES_FOR_REQUEST = 'GET_AFFILIATES_FOR_REQUEST';
export const GET_AFFILIATES_FOR_REQUEST_REQUEST = 'GET_AFFILIATES_FOR_REQUEST_REQUEST';
export const GET_AFFILIATES_FOR_REQUEST_SUCCESS = 'GET_AFFILIATES_FOR_REQUEST_SUCCESS';
export const GET_AFFILIATES_FOR_REQUEST_FAIL = 'GET_AFFILIATES_FOR_REQUEST_FAIL';

export const SET_USER_ACCESS = 'SET_USER_ACCESS';
export const SET_USER_ACCESS_REQUEST = 'SET_USER_ACCESS_REQUEST';
export const SET_USER_ACCESS_SUCCESS = 'SET_USER_ACCESS_SUCCESS';
export const SET_USER_ACCESS_FAIL = 'SET_USER_ACCESS_FAIL';
// ========= MERCHANT STATS CONSTANTS END =========
