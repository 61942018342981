import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translate } from 'react-i18next';

import { getMerchants } from 'store/actions/apiRequestActions/users';

import ContentBody from './ContentBody';
import ContentHeader from './ContentHeader';

class Merchants extends React.Component {
	render() {
		const { t, merchants, getMerchants } = this.props;
		const loading = merchants.loading;
		return (
			<div className="content-wrapper">
				<ContentHeader label={t('Рекламодатели')} />

				<section className="content offers">
					{loading ? (
						<div className="pageOverlay">
							<i className="fa fa-spinner css--animate" />
						</div>
					) : null}

					<ContentBody
						merchants={merchants.data || []}
						getMerchants={getMerchants}
						t={t}
					/>
				</section>
			</div>
		);
	}
}

//Получаем modalStore из stora и записываем в props компонента
const mapStateToProps = ({ apiRequestStore }) => ({
	merchants: apiRequestStore.users.merchants,
});

//Пробрасываем actions в props компонента
const mapActionCreators = {
	getMerchants,
};

Merchants = translate('Merchants')(Merchants);
export default withRouter(connect(mapStateToProps, mapActionCreators)(Merchants));
