import React from 'react';
import { connect } from 'react-redux';
import memoize from 'memoize-one';
import { Tabs, Tab } from 'react-bootstrap';
import MainFunctions from 'mainLibs/MainFunctions';
import _ from 'underscore';

import SourcesStatsTable from './SourcesStatsTable';

const parseSourcesStats = memoize((stats, sourceProp) => {
	if (!Object.keys(stats).length) return [];
	let sourceTable = [];

	// let sources = _.uniq(_.map(stats.trackLinks, (link) => {
	//     return link.markers[sourceProp];
	// }));

	// let actionsGrouped = _.groupBy(stats.actions, (action) => {
	//     return _.find(stats.trackLinks, (trackLink) => {
	//         return trackLink._id.toString() === action.trackLink.toString();
	//     }).markers[sourceProp];
	// });
	// let visitsGrouped = _.groupBy(stats.visits, (visit) => {
	//     return _.find(stats.trackLinks, (trackLink) => {
	//         return trackLink._id.toString() === visit.trackLink.toString();
	//     }).markers[sourceProp];
	// });

	let sources = _.uniq(
		_.filter(stats.visits, (visit) => {
			return visit.markers && visit.markers[sourceProp];
		}).map((visit) => {
			return visit.markers[sourceProp];
		}),
	);

	let visitsGrouped = _.groupBy(
		stats.visits.filter((visit) => {
			return visit.markers && visit.markers[sourceProp];
		}),
		(visit) => {
			return visit.markers[sourceProp];
		},
	);

	let actionsGrouped = _.groupBy(
		stats.actions.filter((action) => {
			return action.markers && action.markers[sourceProp];
		}),
		(action) => {
			return action.markers[sourceProp];
		},
	);

	for (var i = 0; i < sources.length; i++) {
		let currentSource = sources[i];
		let actionsParsed = _.groupBy(actionsGrouped[currentSource] || [], (action) => {
			let actionStatus = _.last(_.where(action.statusesFlow, { affiliateVisible: true }))
				.status.name;
			if (actionStatus === 'Approved') return 'A';
			else if (actionStatus === 'Declined') return 'D';
			else if (actionStatus === 'Trash') return 'T';
			else if (actionStatus === 'Pending') return 'P';
		});

		let validApprove =
			(((actionsParsed.A || []).length || 0) /
				((actionsParsed.A || []).length +
					(actionsParsed.D || []).length +
					(actionsParsed.P || []).length)) *
			100;
		// let statusApprove = (((actionsParsed.A || []).length || 0) / ((actionsParsed.D || []).length || 0)) * 100;
		let statusApprove =
			(((actionsParsed.A || []).length || 0) /
				((actionsParsed.A || []).length +
					(actionsParsed.D || []).length +
					(actionsParsed.P || []).length +
					(actionsParsed.T || []).length)) *
			100;

		//получаем уникальные клики (от одного посетителя)
		let uniqClicks = _.groupBy(visitsGrouped[currentSource] || [], (visit) => {
			return visit.techInfo.ip;
		});
		let CR =
			(((actionsParsed.A || []).length +
				(actionsParsed.D || []).length +
				(actionsParsed.P || []).length) /
				((Object.keys(uniqClicks) || []).length || 0)) *
			100;
		let epc =
			_.reduce(actionsParsed.A || [], (memo, action) => memo + action.fixedPayout, 0) /
			((Object.keys(uniqClicks) || []).length || 0);

		let approvedMoney = _.reduce(
			actionsParsed.A || [],
			(memo, action) => memo + action.fixedPayout,
			0,
		);
		let holdMoney = _.reduce(
			actionsParsed.P || [],
			(memo, action) => memo + action.fixedPayout,
			0,
		);
		let declinedMoney = _.reduce(
			actionsParsed.D || [],
			(memo, action) => memo + action.fixedPayout,
			0,
		);

		let allMoney = approvedMoney + holdMoney + declinedMoney;

		sourceTable.push({
			source: currentSource,
			id: currentSource + i,
			uniqClicks: MainFunctions.formatNumber(Object.keys(uniqClicks).length, 0),
			allClicks: MainFunctions.formatNumber(
				(visitsGrouped[currentSource] || []).length || 0,
				0,
			),
			CR: MainFunctions.formatNumber(isNaN(CR) || !isFinite(CR) ? 0 : CR, 2) + ' %',
			epc: MainFunctions.formatNumber(isNaN(epc) ? 0 : epc, 2) + ' р.',

			allActions: MainFunctions.formatNumber(
				(actionsParsed.A || []).length +
					(actionsParsed.P || []).length +
					(actionsParsed.D || []).length,
				0,
			),
			approvedActions: MainFunctions.formatNumber((actionsParsed.A || []).length, 0),
			holdActions: MainFunctions.formatNumber((actionsParsed.P || []).length, 0),
			declinedActions: MainFunctions.formatNumber((actionsParsed.D || []).length, 0),
			trashActions: MainFunctions.formatNumber((actionsParsed.T || []).length, 0),

			allMoney: MainFunctions.formatNumber(allMoney, 0) + ' р',
			approvedMoney: MainFunctions.formatNumber(approvedMoney, 0) + ' р',
			holdMoney: MainFunctions.formatNumber(holdMoney, 0) + ' р',
			declinedMoney: MainFunctions.formatNumber(declinedMoney, 0) + ' р',
			validApprove:
				MainFunctions.formatNumber((actionsParsed.A || []).length, 2) +
				' (' +
				MainFunctions.formatNumber(isNaN(validApprove) ? 0 : validApprove, 2) +
				' %)',
			statusApprove:
				MainFunctions.formatNumber((actionsParsed.A || []).length, 2) +
				' (' +
				MainFunctions.formatNumber(isNaN(statusApprove) ? 0 : statusApprove, 2) +
				' %)',
		});
	}
	return _.sortBy(sourceTable, 'allActions').reverse();
});

class SourcesStats extends React.Component {
	getNoDataText = memoize((loading, text) => {
		if (loading) {
			return (
				<div className="pageOverlay">
					<i className="fa fa-spinner css--animate" />
				</div>
			);
		} else return text;
	});
	render() {
		const { t, statsSourcesStore } = this.props;
		let sourceTableS = parseSourcesStats(statsSourcesStore.data, 'S');
		let sourceTableW = parseSourcesStats(statsSourcesStore.data, 'W');
		let sourceTableT = parseSourcesStats(statsSourcesStore.data, 'T');
		let sourceTableP = parseSourcesStats(statsSourcesStore.data, 'P');
		const noDataText = this.getNoDataText(
			statsSourcesStore.loading,
			this.props.t('Нет данных'),
		);
		return (
			<React.Fragment>
				{statsSourcesStore.loading && (
					<div className="pageOverlay">
						<i className="fa fa-spinner css--animate" />
					</div>
				)}
				<Tabs defaultActiveKey={1} id="uncontrolled-tab-example" animation={false}>
					<Tab eventKey={1} title={t('Метка S')}>
						<SourcesStatsTable
							t={t}
							sourceTable={sourceTableS}
							noDataText={noDataText}
						/>
					</Tab>
					<Tab eventKey={2} title={t('Метка W')}>
						<SourcesStatsTable
							t={t}
							sourceTable={sourceTableW}
							noDataText={noDataText}
						/>
					</Tab>
					<Tab eventKey={3} title={t('Метка T')}>
						<SourcesStatsTable
							t={t}
							sourceTable={sourceTableT}
							noDataText={noDataText}
						/>
					</Tab>
					<Tab eventKey={4} title={t('Метка P')}>
						<SourcesStatsTable
							t={t}
							sourceTable={sourceTableP}
							noDataText={noDataText}
						/>
					</Tab>
				</Tabs>
			</React.Fragment>
		);
	}
}

const mapStateToProps = ({ apiRequestStore }) => ({
	statsSourcesStore: apiRequestStore.affiliateStats.sources,
});

export default connect(mapStateToProps, null)(SourcesStats);
