import React from 'react';
import { connect } from 'react-redux';
import { Alert as BootstrapAlert } from 'react-bootstrap';
import { alertActions } from '../../store/actions';

class Alert extends React.Component {
	componentDidMount() {
		if (this.props.open) {
			this.alertTM = setTimeout(() => {
				this.props.alertHide();
			}, 7000);
		}
	}

	componentDidUpdate() {
		if (this.props.open) {
			this.alertTM = setTimeout(() => {
				this.props.alertHide();
			}, 7000);
		}
	}

	componentWillUnmount() {
		clearTimeout(this.alertTM);
	}

	handleAlertDismiss = () => {
		this.props.alertHide();
		clearTimeout(this.alertTM);
	};

	render() {
		if (this.props.open) {
			return (
				<BootstrapAlert
					bsStyle={this.props.type}
					className="main-alert"
					onDismiss={this.handleAlertDismiss}
				>
					{this.props.title && <h4>{this.props.title}</h4>}
					{this.props.message
						? this.props.message.map(function (elem, i) {
								return <p key={i}>{elem}</p>;
						  })
						: null}
				</BootstrapAlert>
			);
		} else return null;
	}
}

const mapStateToProps = ({ alertStore }) => alertStore;

const mapActionCreators = {
	...alertActions,
};

export default connect(mapStateToProps, mapActionCreators)(Alert);
