import React from 'react';
import _ from 'underscore';
import memoize from 'memoize-one';

import { Col } from 'react-bootstrap';

import MainFunctions from 'mainLibs/MainFunctions';

import UserInfo from './UserInfo';
import UserDetail from './UserDetail';

export default class ContentBody extends React.PureComponent {
	componentDidMount() {
		this.props.getUserInfo(this.props.userId);
	}

	parseUserInfo = memoize((userInfo) => {
		const user = _.isObject(userInfo) ? userInfo : {};
		const info = {
			id: user._id,
			username: user.username || '',
			name: user.name || '',
			lastName: user.lastName || '',
			registered: MainFunctions.dateFormat(user.registered || ''),
			lastActivity: MainFunctions.dateFormat(user.lastActivity || ''),
			avatar: user.avatar || '',
			language: user.language ? user.language.name : '',
			phone: user.phone || '',
			skype: user.skype || '',
			email: user.username || '',
			ownerOffers: user.ownerOffers || [],
			access: user.access || {},
			isAffiliate: user.isAffiliate,
		};
		return info;
	});

	parsePayments = memoize((paymentsData) => {
		const payments = _.isArray(paymentsData)
			? _.sortBy(paymentsData, (payment) => -Date.parse(payment.date))
			: [];

		function getCurrency(currency) {
			const correctCurrency = _.isObject(currency) ? currency : {};
			return correctCurrency.name + ` (${correctCurrency.code})`;
		}

		function getPaymentSystem(paymentSystem) {
			const correctPaymentSystem = _.isObject(paymentSystem) ? paymentSystem : {};
			return correctPaymentSystem.description + ` (${correctPaymentSystem.name})`;
		}

		function parsePayment(payments) {
			const correctPayments = _.isObject(payments) ? payments : {};
			return {
				_id: correctPayments._id || '',
				date: MainFunctions.dateFormat(correctPayments.date),
				amount: correctPayments.amount || 0,
				currency: getCurrency(correctPayments.currency),
				paymentSystem: getPaymentSystem(correctPayments.paymentSystem),
				statusesFlow: correctPayments.statusesFlow || [],
			};
		}

		return payments.map(parsePayment);
	});

	render() {
		const {
			t,
			userInfo,
			push,
			adminInfo,
			paymentsData,
			getAllPayments,
			getCurrencies,
			currencies,
			getAllPaymentSystems,
			paymentSystems,
			addPayment,
			setAccess,
			username,
		} = this.props;

		const info = this.parseUserInfo(userInfo);
		const payments = this.parsePayments(paymentsData);
		return (
			<div className="box box-primary">
				<div className="box-body">
					<Col sm={12}>
						<UserInfo
							t={t}
							overallInfo={info}
							setAccess={setAccess}
							username={username}
						/>
					</Col>
				</div>

				<div className="box-body" style={{ padding: '15px' }}>
					<UserDetail
						t={t}
						push={push}
						detailInfo={info}
						adminInfo={adminInfo}
						userDetail={{}}
						payments={payments}
						getAllPayments={getAllPayments}
						getCurrencies={getCurrencies}
						currencies={currencies}
						getAllPaymentSystems={getAllPaymentSystems}
						paymentSystems={paymentSystems}
						addPayment={addPayment}
					/>
				</div>
			</div>
		);
	}
}
