import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, ControlLabel, FormGroup, Image } from 'react-bootstrap';

export default class LogoForm extends React.Component {
	static propTypes = {
		logo: PropTypes.string,
		placeholder: PropTypes.string.isRequired,
		changeInfo: PropTypes.func.isRequired,
	};

	shouldComponentUpdate(nextProps, nextState) {
		return (
			this.props.logo !== nextProps.logo || this.props.defaultLogo !== nextProps.defaultLogo
		);
	}

	onСhangeInfoClick = (event) => {
		this.props.changeInfo(event);
	};

	render() {
		const { logo, defaultLogo, label, placeholder } = this.props;
		const currentLogo = logo ? logo : defaultLogo;
		return (
			<FormGroup>
				<ControlLabel>{label}</ControlLabel>

				<FormControl
					type="file"
					placeholder={placeholder}
					onChange={this.onСhangeInfoClick}
				/>

				{currentLogo && (
					<div className="offerImage">
						<Image src={currentLogo} thumbnail />
					</div>
				)}
			</FormGroup>
		);
	}
}
