import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import { Col, ControlLabel, FormGroup } from 'react-bootstrap';

export default class SelectForm extends React.Component {
	static propTypes = {
		value: PropTypes.string,
		defaultValue: PropTypes.string,
		changeInfo: PropTypes.func.isRequired,
		name: PropTypes.string.isRequired,
		options: PropTypes.array,
	};

	shouldComponentUpdate(nextProps, nextState) {
		return (
			this.props.value !== nextProps.value ||
			this.props.defaultValue !== nextProps.defaultValue ||
			this.props.options !== nextProps.options
		);
	}
	onСhangeOfferInfoClick = (event) => {
		this.props.changeInfo(event, this.props.name);
	};
	render() {
		const { value, options, label, defaultValue } = this.props;
		const currentValue = value ? value : defaultValue || '';
		return (
			<FormGroup>
				<Col componentClass={ControlLabel} sm={2}>
					{label}
				</Col>
				<Col sm={10}>
					<ReactSelect
						value={currentValue}
						options={options}
						autoBlur={true}
						clearable={false}
						onChange={this.onСhangeOfferInfoClick}
					/>
				</Col>
			</FormGroup>
		);
	}
}
