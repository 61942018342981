import React from 'react';
import { connect } from 'react-redux';
import { Navbar, NavItem, NavDropdown, MenuItem, Nav } from 'react-bootstrap';
import { translate } from 'react-i18next';
import i18n from 'dictionary/i18n';
import classes from './Header.module.css';

import { push } from 'connected-react-router';
import { logOut } from 'store/actions/apiRequestActions/auth';

class Header extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			collapse: true,
			activeKey: window.location.pathname.substr(window.location.pathname.lastIndexOf('/')),
		};
	}

	handleClick = (path) => {
		if (path)
			this.setState(
				{
					activeKey: path,
					collapse: true,
				},
				() => this.props.push(path),
			);
	};

	collapse = () => this.setState((prevState) => ({ collapse: !prevState.collapse }));

	logout = () => {
		this.props.logOut();
		window.location.href = '/';
	};

	handleSelect(eventKey) {
		i18n.changeLanguage(eventKey);
	}

	toAffiliate = () => {
		if (process.env.NODE_ENV === 'development') window.location.href = '//localhost:3001';
		else window.location.href = '/affiliate/';
	};

	render() {
		const { t, access } = this.props;
		return (
			<Navbar
				className="main-header navbar-custom-menu"
				fluid
				staticTop
				expanded={!this.state.collapse}
				onToggle={this.collapse}
			>
				<Navbar.Header>
					<Navbar.Brand>
						<a href="/" className="logo">
							<span className="logo-mini">
								<b>DW</b>
							</span>
							<span className="logo-lg">
								<b>DZHAWEB</b>
								{/* <img src="/images/logos/main.png"/> */}
							</span>
						</a>
					</Navbar.Brand>

					<Navbar.Toggle />
				</Navbar.Header>

				<Navbar.Collapse>
					<Nav>
						<NavDropdown
							className={classes.languageBox}
							title="Language"
							id="nav-dropdown"
						>
							<MenuItem eventKey="ru" onClick={() => this.handleSelect('ru')}>
								{t('Русский')}
							</MenuItem>
							<MenuItem eventKey="en" onClick={() => this.handleSelect('en')}>
								{t('Английский')}
							</MenuItem>
						</NavDropdown>
					</Nav>

					<Nav
						className="topMenu"
						activeKey={this.state.activeKey}
						onSelect={this.handleClick}
					>
						{access.adminOnly && (
							<NavItem className="clickable" eventKey="/campaigns">
								<i className="fa fa-fire" /> <span>{t('Офферы')}</span>
							</NavItem>
						)}
						{access.adminOnly && (
							<NavItem className="clickable" eventKey="/users/affiliates">
								<i className="fa fa-laptop" /> <span>{t('Веб-мастеры')}</span>
							</NavItem>
						)}
						{access.adminOnly && (
							<NavItem className="clickable" eventKey="/users/merchants">
								<i className="fa fa-suitcase" /> <span>{t('Рекламодатели')}</span>
							</NavItem>
						)}
						<NavItem className="clickable" eventKey="/">
							<i className="fa fa-user" /> <span>{t('Профиль')}</span>
						</NavItem>
						{(access.adminOnly || access.white) && (
							<NavItem className="clickable" eventKey="/stats/daily">
								<i className="fa fa-calendar" />{' '}
								<span>{t('Ежедневная статистика')}</span>
							</NavItem>
						)}
						{(access.adminOnly || access.white) && (
							<NavItem className="clickable" eventKey="/stats/requests">
								<i className="fa fa-truck" />{' '}
								<span>{t('Статистика по заявкам')}</span>
							</NavItem>
						)}
						{(access.adminOnly || access.support) && (
							<NavItem className="clickable" eventKey="/support/tickets">
								<i className="fa fa-question" /> <span>{t('Тикеты')}</span>
							</NavItem>
						)}
						{(access.adminOnly || access.support) && (
							<NavItem className="clickable" eventKey="/support/requests">
								<i className="fa fa-expeditedssl" />{' '}
								<span>{t('Запросы на доступ')}</span>
							</NavItem>
						)}
						{(access.adminOnly || access.support) && (
							<NavItem className="clickable" eventKey="/support/finance">
								<i className="fa fa-expeditedssl" /> <span>{t('Финансы')}</span>
							</NavItem>
						)}
						<NavItem className="clickable" eventKey="/settings">
							<i className="fa fa-cog" /> <span>{t('Настройки')}</span>
						</NavItem>
						{(access.adminOnly || access.white) && (
							<NavItem className="clickable" onClick={this.toAffiliate}>
								<i className="fa fa-sign-out" />{' '}
								<span>{t('Перейте в affiliate')}</span>
							</NavItem>
						)}
						<NavItem className="clickable" onClick={this.logout}>
							<i className="fa fa-sign-out" /> <span>{t('Выйти')}</span>
						</NavItem>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		);
	}
}

const mapStateToProps = ({ apiRequestStore }) => ({
	access: (apiRequestStore.auth.user || {}).access || {},
});

const mapActionCreators = {
	logOut,
	push,
};

Header = translate('SideBar')(Header);
export default connect(mapStateToProps, mapActionCreators)(Header);
