import React from 'react';
import ReactDOM from 'react-dom';
import MainFunctions from 'mainLibs/MainFunctions';

import {
	Row,
	Col,
	InputGroup,
	FormControl,
	FormGroup,
	Button,
	OverlayTrigger,
	Tooltip,
} from 'react-bootstrap';

import { Colors } from 'consts';
import ListContainer from '../ListContainer';

import classes from '../../DeepAffiliates.module.css';
class TicketsContainer extends React.PureComponent {
	constructor(props) {
		super(props);
		this.inputNewTicket = null;
	}

	createTicket = () => {
		if (this.inputNewTicket) {
			if (this.props.userId) {
				const newTicket = ReactDOM.findDOMNode(this.inputNewTicket).value;
				this.props.createTicket({
					userId: this.props.userId,
					topic: newTicket,
				});
			}
		}
	};

	formatTopic = (value) => {
		let newMessage = 0;
		for (var i = 0, length1 = value.messages.length; i < length1; i++) {
			if (!value.messages[i].readByBoth && value.messages[i].sentByUser) newMessage++;
		}
		return (
			<Row>
				<Col xs={8} className="text-left">
					<div className={classes.chatMessageFooter}>
						<span>{value.topic}</span>
					</div>
				</Col>

				<Col xs={4} className="text-right">
					{newMessage !== 0 && (
						<OverlayTrigger
							ref="trigger"
							placement="top"
							overlay={
								<Tooltip id="tooltipName" className="nameBreak">
									{newMessage + ' ' + this.props.t('Новых сообщений')}
								</Tooltip>
							}
						>
							<i className={'fa fa-envelope'} style={{ color: Colors.brandInfo }} />
						</OverlayTrigger>
					)}
				</Col>

				<Col xs={12}>
					<small>{MainFunctions.dateFormat(value.created)}</small>
				</Col>
			</Row>
		);
	};

	render() {
		const { t, tickets, setTicket } = this.props;
		return (
			<React.Fragment>
				<FormGroup>
					<InputGroup>
						<FormControl type="text" inputRef={(ref) => (this.inputNewTicket = ref)} />

						<InputGroup.Addon style={{ padding: '0' }}>
							<Button style={{ minWidth: '0' }} onClick={this.createTicket}>
								<i
									className={'fa fa-plus'}
									style={{ color: Colors.brandSuccess }}
								/>
							</Button>
						</InputGroup.Addon>
					</InputGroup>
				</FormGroup>

				<ListContainer
					noDataText={t('Обращений нет')}
					sizePerPage={5}
					data={tickets}
					dataFormat={this.formatTopic}
					onSelect={setTicket}
				/>
			</React.Fragment>
		);
	}
}

export default TicketsContainer;
