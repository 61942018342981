import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import { getStatuses } from 'store/actions/apiRequestActions/statuses';
import { getStatsOverall } from 'store/actions/apiRequestActions/stats';
import { getAllOffers } from 'store/actions/apiRequestActions/offers';
import { getAllGeos } from 'store/actions/apiRequestActions/geos';
import { getAffiliatesUsed } from 'store/actions/apiRequestActions/affiliates';

import { setFilter, clearFilter } from 'store/actions/supportActions';

import ContentHeader from './ContentHeader';
import Content from './Content';

class Profile extends React.Component {
	render() {
		return (
			<div className="content-wrapper">
				<ContentHeader t={this.props.t} />

				<Content
					access={this.props.access}
					t={this.props.t}
					getStatuses={this.props.getStatuses}
					getStatsOverall={this.props.getStatsOverall}
					statuses={this.props.statuses}
					stats={this.props.overall}
					merchants={this.props.merchants.data}
					merchantId={this.props.merchantId}
					offers={this.props.offers}
					affiliates={this.props.affiliates}
					geos={this.props.geos}
					getAllOffers={this.props.getAllOffers}
					getAffiliatesUsed={this.props.getAffiliatesUsed}
					getAllGeos={this.props.getAllGeos}
					filter={this.props.filter}
					setFilter={this.props.setFilter}
					clearFilter={this.props.clearFilter}
				/>
			</div>
		);
	}
}

//Получаем modalStore из stora и записываем в props компонента
const mapStateToProps = ({ apiRequestStore, supportedStore }) => ({
	access: (apiRequestStore.auth.user || {}).access || {},
	statuses: apiRequestStore.statuses,
	overall: apiRequestStore.stats.overall,
	merchants: apiRequestStore.users.merchants,
	merchantId: supportedStore.merchantId.value,

	daily: apiRequestStore.stats.daily,
	offers: apiRequestStore.offers.allOffers,
	geos: apiRequestStore.geos,
	affiliates: apiRequestStore.affiliates,
	filter: supportedStore.filters.profile,
});

//Пробрасываем actions в props компонента
const mapActionCreators = {
	getStatuses,
	getStatsOverall,
	getAllOffers,
	getAffiliatesUsed,
	getAllGeos,
	setFilter,
	clearFilter,
};

Profile = translate('Profile')(Profile);
export default connect(mapStateToProps, mapActionCreators)(Profile);
