import React from 'react';
import _ from 'underscore';
import memoize from 'memoize-one';
import { FormGroup, ControlLabel, Button, FormControl, Form } from 'react-bootstrap';

import SelectForm from './SelectForm';
import DateForm from 'components/service/DateForm';

export default class BoxBody extends React.PureComponent {
	setFilter = (data) => this.props.setFilter('tickets', data);
	clearFilter = () => this.props.clearFilter('tickets');

	componentDidMount() {
		this.loadTickets();
		this.props.getFiltersData();
	}

	loadTickets = () => {
		function getDataFromArray(arrayData) {
			let result = [];
			for (let i = 0; i < arrayData.length; i++) {
				if (arrayData[i].value === 'all') {
					result = [];
					break;
				} else {
					result.push(arrayData[i].value);
				}
			}
			return result;
		}

		const { tickets, categories, users, ...otherFilter } = this.props.filter;
		const data = {
			tickets: _.compact(tickets.replace(/(\s+)|(,)/g, ',').split(',')),
			categories: getDataFromArray(categories),
			users: getDataFromArray(users),
			...otherFilter,
		};

		this.props.getAllTickets(data);
	};

	changeFilter = (val, name) => {
		var filter = { ...this.props.filter };
		filter[name] = val.target ? val.target.value : val;
		this.setFilter(filter);
	};

	changeFilterId = (event) => {
		var filter = { ...this.props.filter };
		filter.tickets = event ? (event.target || {}).value : event;
		this.setFilter(filter);
	};

	changeFilterPeriod = ({ start, end, selectedPeriod }) => {
		var filter = { ...this.props.filter };
		filter.start = start;
		filter.end = end;
		filter.selectedPeriod = selectedPeriod;
		this.setFilter(filter);
	};

	search = (e) => {
		e.preventDefault();
		e.stopPropagation();
		this.loadTickets();
	};

	getReactSelectOptions = memoize((allSelectOptions) => {
		const usersSelectOptions = [];
		const categoriesSelectOptions = [];

		const users = Array.isArray(allSelectOptions.users) ? allSelectOptions.users : [];
		const categories = Array.isArray(allSelectOptions.categories)
			? allSelectOptions.categories
			: [];
		for (let i = 0; i < users.length; i++) {
			usersSelectOptions.push({
				value: users[i]._id,
				label: users[i].username + ' (' + users[i]._id + ')',
			});
		}
		for (let i = 0; i < categories.length; i++) {
			categoriesSelectOptions.push({
				value: categories[i]._id,
				label: this.props.t(categories[i].name),
			});
		}

		categoriesSelectOptions.push({
			value: 'all',
			label: this.props.t('Без категории'),
		});

		return { usersSelectOptions, categoriesSelectOptions };
	});

	render() {
		const { t, filterData } = this.props;
		const { usersSelectOptions, categoriesSelectOptions } =
			this.getReactSelectOptions(filterData);
		return (
			<div className="box-body">
				<Form onSubmit={this.search} className="semiInline">
					<FormGroup>
						<ControlLabel>{t('ID тикетов')}</ControlLabel>
						<FormControl
							type="text"
							value={this.props.filter.tickets}
							onChange={this.changeFilterId}
						/>
					</FormGroup>
					<DateForm
						label={t('Дата')}
						startValue={this.props.filter.start}
						endValue={this.props.filter.end}
						selectedPeriod={this.props.filter.selectedPeriod}
						changeInfo={this.changeFilterPeriod}
					/>
					<SelectForm
						label={t('Пользователи')}
						value={this.props.filter.users}
						defaultValue={t('Все пользователи')}
						changeInfo={this.changeFilter}
						name={'users'}
						options={usersSelectOptions}
					/>
					<SelectForm
						label={t('Категория тикета')}
						value={this.props.filter.categories}
						defaultValue={t('Все категории')}
						changeInfo={this.changeFilter}
						name={'categories'}
						options={categoriesSelectOptions}
					/>
					<Button type="submit" bsStyle="info">
						{t('Отобразить')}
					</Button>{' '}
					<Button bsStyle="danger" onClick={this.clearFilter}>
						{t('Сбросить')}
					</Button>
				</Form>
			</div>
		);
	}
}
