import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translate } from 'react-i18next';

import {
	getAllOffers,
	getCategories,
	offersStatusUpdate,
	getOffersName,
	duplicateOffers,
} from 'store/actions/apiRequestActions/offers';

import { setFilter, clearFilter } from 'store/actions/supportActions';

import ContentHeader from './ContentHeader';
import ContentOffers from './ContentOffers';
import SearchBlock from './SearchBlock';

class CampaignsMerchant extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			getFilter: () => {},
		};
	}

	componentDidMount() {
		this.setState({
			getFilter: this.refs.searchBlock.getFilter,
		});
	}

	render() {
		const loading = this.props.campaigns.loading || this.props.categories.loading;
		return (
			<div className="content-wrapper">
				<ContentHeader
					access={this.props.access}
					history={this.props.history}
					t={this.props.t}
				/>
				<section className="content offers">
					{loading ? (
						<div className="pageOverlay">
							<i className="fa fa-spinner css--animate" />
						</div>
					) : null}

					<SearchBlock
						ref="searchBlock"
						access={this.props.access}
						t={this.props.t}
						merchantId={this.props.merchantId}
						getAllOffers={this.props.getAllOffers}
						categories={this.props.categories.data}
						getCategories={this.props.getCategories}
						offersName={this.props.offersName.data}
						getOffersName={this.props.getOffersName}
						filter={this.props.filter}
						setFilter={this.props.setFilter}
						clearFilter={this.props.clearFilter}
					/>

					<ContentOffers
						getFilter={this.state.getFilter}
						campaigns={this.props.campaigns.data}
						history={this.props.history}
						t={this.props.t}
						offersStatusUpdate={this.props.offersStatusUpdate}
						duplicateOffers={this.props.duplicateOffers}
					/>
				</section>
			</div>
		);
	}
}

//Получаем modalStore из stora и записываем в props компонента
const mapStateToProps = ({ apiRequestStore, supportedStore }) => ({
	access: (apiRequestStore.auth.user || {}).access || {},
	campaigns: apiRequestStore.offers.allOffers,
	categories: apiRequestStore.offers.categories,
	offersName: apiRequestStore.offers.offersName,
	filter: supportedStore.filters.offers,
});

//Пробрасываем actions в props компонента
const mapActionCreators = {
	getAllOffers,
	getCategories,
	offersStatusUpdate,
	getOffersName,
	setFilter,
	clearFilter,
	duplicateOffers,
};
CampaignsMerchant = translate('Campaigns')(CampaignsMerchant);
export default withRouter(connect(mapStateToProps, mapActionCreators)(CampaignsMerchant));
