// ========= USERS CONSTANTS BEGIN =========
export const PASSWORD_LOGIN = 'PASSWORD_LOGIN';
export const PASSWORD_LOGIN_REQUEST = 'PASSWORD_LOGIN_REQUEST';
export const PASSWORD_LOGIN_SUCCESS = 'PASSWORD_LOGIN_SUCCESS';
export const PASSWORD_LOGIN_FAIL = 'PASSWORD_LOGIN_FAIL';

export const REFRESH_LOGIN = 'REFRESH_LOGIN';
export const ACCESS_TOKEN_INVALID = 'ACCESS_TOKEN_INVALID';
export const REFRESH_LOGIN_REQUEST = 'REFRESH_LOGIN_REQUEST';
export const REFRESH_LOGIN_SUCCESS = 'REFRESH_LOGIN_SUCCESS';
export const REFRESH_LOGIN_FAIL = 'REFRESH_LOGIN_FAIL';

export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_FAIL = 'USER_LOGOUT_FAIL';

export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_REQUEST = 'SIGNUP_USER_REQUEST';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNUP_USER_FAIL = 'SIGNUP_USER_FAIL';

export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const UPDATE_USER_INFO_REQUEST = 'UPDATE_USER_INFO_REQUEST';
export const UPDATE_USER_INFO_SUCCESS = 'UPDATE_USER_INFO_SUCCESS';
export const UPDATE_USER_INFO_FAIL = 'UPDATE_USER_INFO_FAIL';

export const UPDATE_SETTINGS_USER = 'UPDATE_SETTINGS_USER';
export const UPDATE_SETTINGS_USER_REQUEST = 'UPDATE_SETTINGS_USER_REQUEST';
export const UPDATE_SETTINGS_USER_SUCCESS = 'UPDATE_SETTINGS_USER_SUCCESS';
export const UPDATE_SETTINGS_USER_FAIL = 'UPDATE_SETTINGS_USER_FAIL';
// ========= USERS CONSTANTS END =========
