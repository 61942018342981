import _ from 'underscore';
import MainFunctions from 'mainLibs/MainFunctions';

export default function tableFooter(data) {
	return [
		[
			{
				label: 'Всего',
				columnIndex: 0,
			},
			{
				columnIndex: 2,
				align: 'center',
				formatter: (data) => {
					let total = 0;
					_.each(data, (dataItem) => {
						total += parseInt(dataItem.allClicks.replace(' ', ''), 10);
					});
					return MainFunctions.formatNumber(total, 0);
				},
			},
			{
				columnIndex: 3,
				align: 'center',
				formatter: (data) => {
					let total = 0;
					_.each(data, (dataItem) => {
						total += parseInt(dataItem.uniqClicks.replace(' ', ''), 10);
					});
					return MainFunctions.formatNumber(total, 0);
				},
			},
			{
				columnIndex: 8,
				align: 'center',
				formatter: (data) => {
					let total = 0;
					_.each(data, (dataItem) => {
						total += parseInt(dataItem.allActions.replace(' ', ''), 10);
					});
					return MainFunctions.formatNumber(total, 0);
				},
			},
			{
				columnIndex: 9,
				align: 'center',
				formatter: (data) => {
					let total = 0;
					_.each(data, (dataItem) => {
						total += parseInt(dataItem.approvedActions.replace(' ', ''), 10);
					});
					return MainFunctions.formatNumber(total, 0);
				},
			},
			{
				columnIndex: 10,
				align: 'center',
				formatter: (data) => {
					let total = 0;
					_.each(data, (dataItem) => {
						total += parseInt(dataItem.holdActions.replace(' ', ''), 10);
					});
					return MainFunctions.formatNumber(total, 0);
				},
			},
			{
				columnIndex: 11,
				align: 'center',
				formatter: (data) => {
					let total = 0;
					_.each(data, (dataItem) => {
						total += parseInt(dataItem.declinedActions.replace(' ', ''), 10);
					});
					return MainFunctions.formatNumber(total, 0);
				},
			},
			{
				columnIndex: 12,
				align: 'center',
				formatter: (data) => {
					let total = 0;
					_.each(data, (dataItem) => {
						total += parseInt(dataItem.trashActions.replace(' ', ''), 10);
					});
					return MainFunctions.formatNumber(total, 0);
				},
			},
			{
				columnIndex: 13,
				align: 'center',
				formatter: (data) => {
					let total = 0;
					_.each(data, (dataItem) => {
						total += parseInt(dataItem.allMoney.replace(' р', '').replace(' ', ''), 10);
					});
					return MainFunctions.formatNumber(total, 0) + ' р';
				},
			},
			{
				columnIndex: 14,
				align: 'center',
				formatter: (data) => {
					let total = 0;
					_.each(data, (dataItem) => {
						total += parseInt(
							dataItem.approvedMoney.replace(' р', '').replace(' ', ''),
							10,
						);
					});
					return MainFunctions.formatNumber(total, 0) + ' р';
				},
			},
			{
				columnIndex: 15,
				align: 'center',
				formatter: (data) => {
					let total = 0;
					_.each(data, (dataItem) => {
						total += parseInt(
							dataItem.holdMoney.replace(' р', '').replace(' ', ''),
							10,
						);
					});
					return MainFunctions.formatNumber(total, 0) + ' р';
				},
			},
			{
				columnIndex: 16,
				align: 'center',
				formatter: (data) => {
					let total = 0;
					_.each(data, (dataItem) => {
						total += parseInt(
							dataItem.declinedMoney.replace(' р', '').replace(' ', ''),
							10,
						);
					});
					return MainFunctions.formatNumber(total, 0) + ' р';
				},
			},
		],
	];
}
