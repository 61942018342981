import { supportTypes } from '../../constants';
import MainFunctions from 'mainLibs/MainFunctions';
import { combineReducers } from 'redux';

const today = new Date();
const selectedPeriod = 'week';
const dateStart = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6, 0, 0, 0, 0);
const dateEnd = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59, 999);

const initialOffers = {
	merchantId: 'all',
	categories: [],
	name: '',
	active: true,
	noActive: false,
	whiteActive: false,
	whiteNoActive: false,
	collapsed: true,
};

const initialRequestStats = {
	merchantId: 'all',
	id: '',
	affiliates: [],
	rstatus: [],
	geos: [],
	offers: [],
	start: MainFunctions.formatDateAsMySQL(dateStart),
	end: MainFunctions.formatDateAsMySQL(dateEnd),
	selectedPeriod: selectedPeriod,
	active: true,
	noActive: false,
	whiteActive: false,
	whiteNoActive: false,
	collapsed: true,
};

const initialLandingsStats = {
	start: MainFunctions.formatDateAsMySQL(dateStart),
	end: MainFunctions.formatDateAsMySQL(dateEnd),
	selectedPeriod: selectedPeriod,
	collapsed: true,
	expanded: false,
};

const initialDailyStats = {
	merchantId: 'all',
	affiliates: [],
	geos: [],
	offers: [],
	start: MainFunctions.formatDateAsMySQL(dateStart),
	end: MainFunctions.formatDateAsMySQL(dateEnd),
	selectedPeriod: selectedPeriod,
	active: true,
	noActive: false,
	whiteActive: false,
	whiteNoActive: false,
	collapsed: true,
};

const initialAffiliateStats = {
	geos: null,
	offers: null,
	trackLinks: null,
	landings: null,
	middlePages: null,
	markers: {
		S: null,
		W: null,
		T: null,
		P: null,
	},
	start: MainFunctions.formatDateAsMySQL(dateStart),
	end: MainFunctions.formatDateAsMySQL(dateEnd),
	selectedPeriod: selectedPeriod,
	collapsed: true,
	expanded: false,
};

const initialMerchantStats = {
	geos: null,
	offers: null,
	trackLinks: null,
	landings: null,
	middlePages: null,
	markers: {
		S: null,
		W: null,
		T: null,
		P: null,
	},
	start: MainFunctions.formatDateAsMySQL(dateStart),
	end: MainFunctions.formatDateAsMySQL(dateEnd),
	selectedPeriod: selectedPeriod,
	collapsed: true,
	expanded: false,
};

const initialTickets = {
	tickets: '',
	categories: [],
	users: [],
	start: MainFunctions.formatDateAsMySQL(dateStart),
	end: MainFunctions.formatDateAsMySQL(dateEnd),
	selectedPeriod: selectedPeriod,
	collapsed: true,
};

const initialPayments = {
	email: '',
	start: MainFunctions.formatDateAsMySQL(dateStart),
	end: MainFunctions.formatDateAsMySQL(dateEnd),
	selectedPeriod: selectedPeriod,
	collapsed: true,
};

function prePayments(state = initialPayments, action) {
	switch (action.type) {
		case supportTypes.SET_FILTER_PRE_PAYMENTS:
			return { ...state, ...action.data };
		case supportTypes.CLEAR_FILTER:
		case supportTypes.CLEAR_FILTER_PRE_PAYMENTS:
			return initialOffers;
		default:
			return state;
	}
}

function payments(state = initialPayments, action) {
	switch (action.type) {
		case supportTypes.SET_FILTER_PAYMENTS:
			return { ...state, ...action.data };
		case supportTypes.CLEAR_FILTER:
		case supportTypes.CLEAR_FILTER_PAYMENTS:
			return initialOffers;
		default:
			return state;
	}
}

function offers(state = initialOffers, action) {
	switch (action.type) {
		case supportTypes.SET_FILTER_OFFERS:
			return { ...state, ...action.data };
		case supportTypes.CLEAR_FILTER:
		case supportTypes.CLEAR_FILTER_OFFERS:
			return initialOffers;
		default:
			return state;
	}
}

function requestStats(state = initialRequestStats, action) {
	switch (action.type) {
		case supportTypes.SET_FILTER_REQUEST_STATS:
			return { ...state, ...action.data };
		case supportTypes.CLEAR_FILTER:
		case supportTypes.CLEAR_FILTER_REQUEST_STATS:
			return initialRequestStats;
		default:
			return state;
	}
}

function dailyStats(state = initialDailyStats, action) {
	switch (action.type) {
		case supportTypes.SET_FILTER_DAILY_STATS:
			return { ...state, ...action.data };
		case supportTypes.CLEAR_FILTER:
		case supportTypes.CLEAR_FILTER_DAILY_STATS:
			return initialDailyStats;
		default:
			return state;
	}
}

function affiliateStats(state = initialAffiliateStats, action) {
	switch (action.type) {
		case supportTypes.SET_FILTER_AFFILIATE_STATS:
			return { ...state, ...action.data };
		case supportTypes.CLEAR_FILTER:
		case supportTypes.CLEAR_FILTER_AFFILIATE_STATS:
			return initialAffiliateStats;
		default:
			return state;
	}
}

function merchantStats(state = initialMerchantStats, action) {
	switch (action.type) {
		case supportTypes.SET_FILTER_MERCHANT_STATS:
			return { ...state, ...action.data };
		case supportTypes.CLEAR_FILTER:
		case supportTypes.CLEAR_FILTER_MERCHANT_STATS:
			return initialMerchantStats;
		default:
			return state;
	}
}

function tickets(state = initialTickets, action) {
	switch (action.type) {
		case supportTypes.SET_FILTER_TICKETS:
			return { ...state, ...action.data };
		case supportTypes.CLEAR_FILTER:
		case supportTypes.CLEAR_FILTER_TICKETS:
			return initialTickets;
		default:
			return state;
	}
}

function profile(state = initialDailyStats, action) {
	switch (action.type) {
		case supportTypes.SET_FILTER_PROFILE:
			return { ...state, ...action.data };
		case supportTypes.CLEAR_FILTER:
		case supportTypes.CLEAR_FILTER_PROFILE:
			return initialDailyStats;
		default:
			return state;
	}
}

function landingStats(state = initialLandingsStats, action) {
	switch (action.type) {
		case supportTypes.SET_FILTER_LANDINGS_STATS:
			return { ...state, ...action.data };
		case supportTypes.CLEAR_FILTER:
		case supportTypes.CLEAR_FILTER_LANDINGS_STATS:
			return initialDailyStats;
		default:
			return state;
	}
}

const filters = {
	offers,
	tickets,
	merchantStats,
	affiliateStats,
	dailyStats,
	requestStats,
	profile,
	prePayments,
	payments,
	landingStats,
};

export default combineReducers(filters);
