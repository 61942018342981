export const SET_MERCHANT_ID = 'SET_MERCHANT_ID';

export const SET_FILTER = 'SET_FILTER';
export const SET_FILTER_OFFERS = 'SET_FILTER_OFFERS';
export const SET_FILTER_REQUEST_STATS = 'SET_FILTER_REQUEST_STATS';
export const SET_FILTER_DAILY_STATS = 'SET_FILTER_DAILY_STATS';
export const SET_FILTER_AFFILIATE_STATS = 'SET_FILTER_AFFILIATE_STATS';
export const SET_FILTER_MERCHANT_STATS = 'SET_FILTER_MERCHANT_STATS';
export const SET_FILTER_TICKETS = 'SET_FILTER_TICKETS';
export const SET_FILTER_PROFILE = 'SET_FILTER_PROFILE';
export const SET_FILTER_PAYMENTS = 'SET_FILTER_PAYMENTS';
export const SET_FILTER_PRE_PAYMENTS = 'SET_FILTER_PRE_PAYMENTS';
export const SET_FILTER_LANDINGS_STATS = 'SET_FILTER_LANDINGS_STATS';

export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_FILTER_OFFERS = 'CLEAR_FILTER_OFFERS';
export const CLEAR_FILTER_REQUEST_STATS = 'CLEAR_FILTER_REQUEST_STATS';
export const CLEAR_FILTER_DAILY_STATS = 'CLEAR_FILTER_DAILY_STATS';
export const CLEAR_FILTER_AFFILIATE_STATS = 'CLEAR_FILTER_AFFILIATE_STATS';
export const CLEAR_FILTER_MERCHANT_STATS = 'CLEAR_FILTER_MERCHANT_STATS';
export const CLEAR_FILTER_TICKETS = 'CLEAR_FILTER_TICKETS';
export const CLEAR_FILTER_PROFILE = 'CLEAR_FILTER_PROFILE';
export const CLEAR_FILTER_PAYMENTS = 'CLEAR_FILTER_PAYMENTS';
export const CLEAR_FILTER_PRE_PAYMENTS = 'CLEAR_FILTER_PRE_PAYMENTS';
export const CLEAR_FILTER_LANDINGS_STATS = 'CLEAR_FILTER_LANDINGS_STATS';
