import { adminPanelRequestTypes } from '../../constants';
import { combineReducers } from 'redux';

const initialDaily = {
	loading: false,
	loaded: false,
	errors: null,
	period: {
		start: null,
		end: null,
	},
	data: {},
};

const initialRequests = {
	loading: false,
	loaded: false,
	errors: null,
	data: [],
};

const initialOverall = {
	loading: false,
	loaded: false,
	errors: null,
	data: [],
};

const initialStatsFilters = {
	loading: false,
	loaded: false,
	errors: null,
	data: {
		geos: [],
		trackLinks: [],
		offers: [],
		landings: [],
		middlePages: [],
		markers: {},
		rStatuses: [],
	},
};

function statsFiltersData(state = initialStatsFilters, { type, data, errors }) {
	switch (type) {
		case adminPanelRequestTypes.GET_STATS_MERCHANT_FILTERS_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_STATS_MERCHANT_FILTERS_SUCCESS:
			return { ...state, loading: false, loaded: true, errors: null, data: data };
		case adminPanelRequestTypes.GET_STATS_MERCHANT_FILTERS_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		default:
			return state;
	}
}

function daily(state = initialDaily, { type, period, data, errors }) {
	switch (type) {
		case adminPanelRequestTypes.GET_STATS_MERCHANT_DAILY_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_STATS_MERCHANT_DAILY_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				errors: null,
				data: data,
				period: period,
			};
		case adminPanelRequestTypes.GET_STATS_MERCHANT_DAILY_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		default:
			return state;
	}
}

function requests(state = initialRequests, { type, period, data, errors }) {
	switch (type) {
		case adminPanelRequestTypes.GET_STATS_MERCHANT_REQUESTS_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_STATS_MERCHANT_REQUESTS_SUCCESS:
			return { ...state, loading: false, loaded: true, errors: null, data: data };
		case adminPanelRequestTypes.GET_STATS_MERCHANT_REQUESTS_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		default:
			return state;
	}
}

function overall(state = initialOverall, { type, period, data, errors }) {
	switch (type) {
		case adminPanelRequestTypes.GET_STATS_MERCHANT_OVERALL_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_STATS_MERCHANT_OVERALL_SUCCESS:
			return { ...state, loading: false, loaded: true, errors: null, data: data };
		case adminPanelRequestTypes.GET_STATS_MERCHANT_OVERALL_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		default:
			return state;
	}
}

const stats = {
	daily,
	overall,
	requests,
	statsFiltersData,
};

export default combineReducers(stats);
