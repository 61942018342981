import React from 'react';
import { Row, Col, Tab, Nav, NavItem, NavDropdown, MenuItem } from 'react-bootstrap';

import { Stats } from './StatsPages';
import Statistics from './StatsPages';
import UserDetailOffers from './UserDetailOffers';
import UserDetailInfo from './UserDetailInfo';
import UserDetailPayments from './UserDetailPayments';
import UserDetailChat from './UserDetailChat';
import UserDetailSignIn from './UserDetailSignIn';

export default class UserDetail extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			activeStatsComponent: UserDetailInfo,
			nameComponent: 'UserDetailInfo',
			activeKey: 'UserDetailInfo',
		};
	}

	switchStats = (nameComponent) =>
		this.setActiveStatsComponent('affiliate-profile-stats', nameComponent);
	switchPayments = (nameComponent) => this.switchTab('affiliate-profile-payments', nameComponent);

	setActiveStatsComponent(activeKey, nameComponent) {
		this.setState({
			activeKey: activeKey,
			nameComponent: nameComponent,
			activeStatsComponent: Statistics[nameComponent],
		});
	}

	switchTab = (activeKey, nameComponent) => {
		this.setState({
			activeKey: activeKey,
			nameComponent: nameComponent || activeKey,
		});
	};

	render() {
		const {
			t,
			userId,
			push,
			detailInfo,
			tickets,
			createTicket,
			readMessage,
			prePayments,
			payments,
			getAllPayments,
			updatePaymentStatus,
			paymentStatuses,
			getAllPaymentStatuses,
			getAllPaymentSystems,
			paymentSystems,
			getCurrencies,
			currencies,
			updatePaymentSetings,
			deletePaymentSetings,
			alertShow,
			updateUserOffers,
		} = this.props;
		let ActiveStatsComponent = this.state.activeStatsComponent;
		if (!ActiveStatsComponent) ActiveStatsComponent = () => '';
		const height = `${window.innerHeight}px`;
		return (
			<Tab.Container
				activeKey={this.state.activeKey}
				id="affiliate-profile-tabs"
				onSelect={() => {}}
			>
				<Row className="clearfix">
					<Col sm={12}>
						<Nav bsStyle="tabs">
							<NavItem
								eventKey={'UserDetailInfo'}
								onClick={() => this.switchTab('UserDetailInfo')}
							>
								{t('О пользователе')}
							</NavItem>

							<NavItem
								eventKey={'UserDetailOffers'}
								onClick={() => this.switchTab('UserDetailOffers')}
							>
								{t('Офферы')}
							</NavItem>

							<NavDropdown
								eventKey={'affiliate-profile-payments'}
								title={t('Выплаты')}
							>
								<MenuItem
									active={this.state.nameComponent === 'PrePaymentsRequests'}
									onClick={() => this.switchPayments('PrePaymentsRequests')}
								>
									{t('Запросы на досрочную выплату')}
								</MenuItem>

								<MenuItem
									active={this.state.nameComponent === 'PaymentsRequests'}
									onClick={() => this.switchPayments('PaymentsRequests')}
								>
									{t('Запросы на выплаты')}
								</MenuItem>
							</NavDropdown>

							<NavItem
								eventKey={'UserDetailChat'}
								onClick={() => this.switchTab('UserDetailChat')}
							>
								{t('Чат')}
							</NavItem>

							<NavDropdown
								eventKey={'affiliate-profile-stats'}
								title={t('Статистика')}
							>
								<MenuItem
									active={this.state.nameComponent === 'DailyStats'}
									onClick={() => this.switchStats('DailyStats')}
								>
									{t('Ежедневная')}
								</MenuItem>

								<MenuItem
									active={this.state.nameComponent === 'RequestsStats'}
									onClick={() => this.switchStats('RequestsStats')}
								>
									{t('По заявкам')}
								</MenuItem>

								<MenuItem
									active={this.state.nameComponent === 'CampaignsStats'}
									onClick={() => this.switchStats('CampaignsStats')}
								>
									{t('По потокам')}
								</MenuItem>

								<MenuItem
									active={this.state.nameComponent === 'OffersStats'}
									onClick={() => this.switchStats('OffersStats')}
								>
									{t('По офферам')}
								</MenuItem>

								<MenuItem
									active={this.state.nameComponent === 'LandingsStats'}
									onClick={() => this.switchStats('LandingsStats')}
								>
									{t('По лендингам')}
								</MenuItem>

								<MenuItem
									active={this.state.nameComponent === 'PreLandingsStats'}
									onClick={() => this.switchStats('PreLandingsStats')}
								>
									{t('По прелендингам')}
								</MenuItem>

								<MenuItem
									active={this.state.nameComponent === 'SourcesStats'}
									onClick={() => this.switchStats('SourcesStats')}
								>
									{t('По меткам')}
								</MenuItem>

								<MenuItem
									active={this.state.nameComponent === 'GeosStats'}
									onClick={() => this.switchStats('GeosStats')}
								>
									{t('По гео')}
								</MenuItem>
							</NavDropdown>
							<NavItem
								eventKey={'UserDetailSignIn'}
								onClick={() => this.switchTab('UserDetailSignIn')}
							>
								{t('Статистика входов')}
							</NavItem>
						</Nav>
					</Col>

					<Col sm={12}>
						<Tab.Content animation={true}>
							<Tab.Pane eventKey={'UserDetailInfo'}>
								<UserDetailInfo
									t={t}
									push={push}
									active={this.state.nameComponent === 'UserDetailInfo'}
									height={height}
									detailInfo={detailInfo || {}}
									getAllPaymentSystems={getAllPaymentSystems}
									paymentSystems={paymentSystems || []}
									getCurrencies={getCurrencies}
									currencies={currencies || []}
									updatePaymentSetings={updatePaymentSetings}
									deletePaymentSetings={deletePaymentSetings}
									alertShow={alertShow}
								/>
							</Tab.Pane>

							<Tab.Pane eventKey={'UserDetailOffers'}>
								<UserDetailOffers
									t={t}
									active={this.state.nameComponent === 'UserDetailOffers'}
									height={height}
									offers={detailInfo.offers || []}
									userId={userId}
									updateUserOffers={updateUserOffers}
								/>
							</Tab.Pane>

							<Tab.Pane eventKey={'affiliate-profile-payments'}>
								<UserDetailPayments
									t={t}
									height={height}
									active={this.state.activeKey === 'affiliate-profile-payments'}
									data={
										this.state.nameComponent === 'PrePaymentsRequests'
											? prePayments
											: payments
									}
									pre={this.state.nameComponent === 'PrePaymentsRequests'}
									userId={userId}
									getAllPayments={getAllPayments}
									updatePaymentStatus={updatePaymentStatus}
									paymentStatuses={paymentStatuses || []}
									getAllPaymentStatuses={getAllPaymentStatuses}
								/>
							</Tab.Pane>

							<Tab.Pane eventKey={'UserDetailChat'}>
								<UserDetailChat
									t={t}
									active={this.state.nameComponent === 'UserDetailChat'}
									height={height}
									tickets={tickets}
									userInfo={detailInfo}
									createTicket={createTicket}
									readMessage={readMessage}
								/>
							</Tab.Pane>

							<Tab.Pane eventKey={'UserDetailSignIn'}>
								<UserDetailSignIn t={t} data={detailInfo.ips.slice().reverse()} />
							</Tab.Pane>

							<Tab.Pane eventKey={'affiliate-profile-stats'}>
								<Stats
									active={this.state.activeKey === 'affiliate-profile-stats'}
									height={height}
									t={this.props.t}
									userId={userId}
									statsName={this.state.nameComponent}
								>
									<ActiveStatsComponent t={this.props.t} />
								</Stats>
							</Tab.Pane>
						</Tab.Content>
					</Col>
				</Row>
			</Tab.Container>
		);
	}
}
