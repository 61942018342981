import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, ControlLabel, FormGroup } from 'react-bootstrap';

export default class InfoForm extends React.Component {
	static propTypes = {
		placeholder: PropTypes.string.isRequired,
		changeInfo: PropTypes.func.isRequired,
		name: PropTypes.string,
		type: PropTypes.string,
	};

	onСhangeInfoClick = (event) => {
		this.props.changeInfo(event, this.props.name);
	};

	shouldComponentUpdate(nextProps, nextState) {
		return (
			this.props.value !== nextProps.value ||
			this.props.defaultValue !== nextProps.defaultValue
		);
	}

	render() {
		const { value, label, placeholder, defaultValue, type } = this.props;
		const currentValue = value ? value : defaultValue || '';
		return (
			<FormGroup>
				<ControlLabel>{label}</ControlLabel>
				<FormControl
					type={type || 'text'}
					placeholder={placeholder}
					value={currentValue}
					onChange={this.onСhangeInfoClick}
				/>
			</FormGroup>
		);
	}
}
