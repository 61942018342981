/* eslint-disable no-useless-concat */
import { adminPanelRequestTypes, adminPanelApi } from '../../constants';

// //github.com/axios/axios
export function getAffiliatesUsed(params) {
	return {
		//тип определяющий обрабатывающий редюсер
		type: adminPanelRequestTypes.GET_AFFILIATES,
		//объект request служит для конфигурации запроса axios
		request: {
			method: 'get',
			url: adminPanelApi.API_URL_AFFILIATES_USED,
			params: params,
		},
	};
}

export function getAffiliateTrackLinks(userId) {
	return {
		type: adminPanelRequestTypes.GET_AFFILIATE_TRACKLINKS,
		request: {
			method: 'get',
			url: adminPanelApi.API_URL_AFFILIATES + `/${userId}` + '/trackLinks',
		},
	};
}
