import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import ContentHeader from './ContentHeader';
import EditBlock from './EditBlock';

import { request, geos } from 'store/actions/apiRequestActions';
import { addRequest } from 'store/actions/apiRequestActions/actions';

class Request extends React.Component {
	render() {
		const { merchants, affiliates, trackLinks, citys, ip, geos, offers, ...otherProps } =
			this.props;
		const loading =
			merchants.loading ||
			affiliates.loading ||
			trackLinks.loading ||
			citys.loading ||
			geos.loading ||
			offers.loading ||
			ip.loading;
		const isWhite = !this.props.access.adminOnly && this.props.access.white;
		return (
			<div className="content-wrapper">
				<ContentHeader t={this.props.t} />

				<section className="content offers">
					{loading ? (
						<div className="pageOverlay">
							<i className="fa fa-spinner css--animate" />
						</div>
					) : null}

					<EditBlock
						merchants={merchants.data}
						affiliates={affiliates.data}
						trackLinks={trackLinks.data}
						offers={offers.data}
						geos={geos.data}
						citys={citys || {}}
						ip={ip.data || []}
						isWhite={isWhite}
						userId={this.props.userId}
						{...otherProps}
					/>
				</section>
			</div>
		);
	}
}

//Получаем modalStore из stora и записываем в props компонента
const mapStateToProps = ({ apiRequestStore }) => ({
	offers: apiRequestStore.request.offers,
	geos: apiRequestStore.geos,
	merchants: apiRequestStore.request.merchants,
	affiliates: apiRequestStore.request.affiliates,
	citys: apiRequestStore.request.citys,
	ip: apiRequestStore.request.ip,
	trackLinks: apiRequestStore.request.trackLinks,
	userId: (apiRequestStore.auth.user || {})._id,
	access: (apiRequestStore.auth.user || {}).access || {},
});

//Пробрасываем actions в props компонента
const mapActionCreators = {
	addRequest,
	getAllGeos: geos.getAllGeos,
	...request,
};

Request = translate('Request')(Request);
export default connect(mapStateToProps, mapActionCreators)(Request);
