import React from 'react';
import _ from 'underscore';
import { FormControl, FormGroup } from 'react-bootstrap';

export default class InputForm extends React.Component {
	onСhangeClick = (event) => {
		const value = event.target.value;
		this.props.changeInfo(value, this.props.name);
	};

	shouldComponentUpdate(nextProps, nextState) {
		return (
			this.props.value !== nextProps.value ||
			this.props.defaultValue !== nextProps.defaultValue
		);
	}

	render() {
		const { value, placeholder, defaultValue, type } = this.props;
		const currentValue = _.isNull(value) || _.isUndefined(value) ? defaultValue || 0 : value;
		return (
			<FormGroup validationState={value ? 'warning' : null} style={{ marginBottom: '10px' }}>
				<FormControl
					placeholder={placeholder}
					value={currentValue}
					onChange={this.onСhangeClick}
					type={type}
				/>
			</FormGroup>
		);
	}
}
