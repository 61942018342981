import React from 'react';
import memoize from 'memoize-one';
import { Form, FormGroup, ControlLabel, Col } from 'react-bootstrap';

import { InfoForm, SelectForm } from '../supportedForm';

export default class GeoDetail extends React.PureComponent {
	getReactSelectOptionsFromCurrencies = memoize((currencies) =>
		currencies.map((currency) => ({
			value: currency._id,
			label: currency.name,
		})),
	);

	getReactSelectOptionsFromGeos = memoize((allGeos, allCurrencies) => {
		const geos = Array.isArray(allGeos) ? allGeos : [];
		const currencies = Array.isArray(allCurrencies) ? allCurrencies : [];
		if (!geos.length || !currencies.length) return [];
		const geosSelectOptions = [];

		for (let i = 0; i < geos.length; i++) {
			let currencyCode = 'USD';
			// switch (geos[i].code.toLowerCase()) {
			// 	case 'ru':
			// 		currencyCode = 'RUB';
			// 		break;
			// 	case 'ua':
			// 		currencyCode = 'UAH';
			// 		break;
			// 	case 'kz':
			// 		currencyCode = 'KZT';
			// 		break;
			// 	case 'by':
			// 		currencyCode = 'BYN';
			// 		break;
			// 	case 'md':
			// 		currencyCode = 'MDL';
			// 		break;
			// 	case 'kg':
			// 		currencyCode = 'KGS';
			// 		break;
			// 	case 'uz':
			// 		currencyCode = 'UZS';
			// 		break;
			// 	case 'am':
			// 		currencyCode = 'AMD';
			// 		break;
			// 	case 'ge':
			// 		currencyCode = 'GEL';
			// 		break;
			// 	default:
			// 		currencyCode = 'USD';
			// 		break;
			// }
			const index = currencies.findIndex(
				(t) => (t.code || '').toUpperCase() === currencyCode,
			);
			geosSelectOptions.push({
				value: geos[i]._id,
				label: geos[i].name + ' (' + geos[i].code + ')',
				currency: index !== -1 ? currencies[index]._id : null,
			});
		}
		return geosSelectOptions;
	});

	render() {
		const { t, newGeo, geos, currencies, changedGeo, changeGeoInfo, edited } = this.props;
		let geosSelectOptions;
		if (newGeo) geosSelectOptions = this.getReactSelectOptionsFromGeos(geos, currencies);

		return (
			<Form horizontal onSubmit={(e) => e.preventDefault()}>
				{newGeo ? (
					<SelectForm
						value={changedGeo.geoRef}
						label={t('Гео')}
						changeInfo={changeGeoInfo}
						name={'geoRef'}
						options={geosSelectOptions}
					/>
				) : (
					<FormGroup>
						<Col componentClass={ControlLabel} sm={2}>
							{t('Гео')}
						</Col>
						<Col sm={10}>
							<h4>
								{this.props.geoRef.name}
								<img
									alt=""
									className="miniIcon"
									src={`/images/countries/${this.props.geoRef.code.toLowerCase()}.png`}
								/>
							</h4>
						</Col>
					</FormGroup>
				)}
				{/* <SelectForm
					value={changedGeo.currency}
					defaultValue={this.props.currency._id}
					label={t('Валюта на лендинге')}
					changeInfo={changeGeoInfo}
					name={'currency'}
					options={currenciesSelectOptions}
					disabled={!edited}
				/> */}

				{/* <InfoForm
                    value = {changedGeo.oldLandingPrice}
                    defaultValue = {this.props.oldLandingPrice}
                    label = {t('Старая цена товара')}
                    placeholder = {t('Старая цена товара')}
                    changeInfo = {changeGeoInfo}
                    name = {'oldLandingPrice'}
                    type = 'number'
                    disabled = {!edited}
                />

                <InfoForm
                    value = {changedGeo.landingPrice}
                    defaultValue = {this.props.landingPrice}
                    label = {t('Цена товара')}
                    placeholder = {t('Цена товара')}
                    changeInfo = {changeGeoInfo}
                    name = {'landingPrice'}
                    type = 'number'
                    disabled = {!edited}
                /> */}

				<InfoForm
					value={changedGeo.payout}
					defaultValue={this.props.payout.value}
					label={t('Выплата')}
					placeholder={t('Выплата')}
					changeInfo={changeGeoInfo}
					name={'payout'}
					type="number"
					disabled={!edited}
				/>
			</Form>
		);
	}
}
