import React from 'react';
// https://github.com/igorprado/react-notification-system
import NotifySystem from 'react-notification-system';
import { translate } from 'react-i18next';
// const notification = {
//   // uid: 'once-please', // you can specify your own uid if required
//   title: 'Hey, it\'s good to see you!',
//   message: 'Now you can see how easy it is to use notifications in React!',
//   position: 'tr',
//   autoDismiss: 0,
//   action: {
//     label: 'Click me!!',
//     callback: () => alert('clicked!')
//   }
// };
const defaultNotify = {
	error: () => {},
	show: () => {},
	system: () => {},
	success: () => {},
	warning: () => {},
	info: () => {},
	hide: () => {},
	removeAll: () => {},
	edit: () => {},
};

export default function initNotification(nameSpace = 'Notifications', DEFAULT_MESSAGE = {}) {
	const NotificationSystem = {};
	NotificationSystem.__proto__ = defaultNotify;
	class Notifications extends React.Component {
		componentDidMount() {
			NotificationSystem.__proto__ = this;
		}

		system() {
			return this.notify;
		}

		show(notification) {
			const { title, message, action, ...newNotification } = notification;
			newNotification.title = this.props.t ? this.props.t(title) : title;
			newNotification.message = this.props.t ? this.props.t(message) : message;
			if (action)
				newNotification.action = {
					label: this.props.t ? this.props.t(action.label) : action.label,
					callback: action.callback,
				};
			this.system().addNotification({
				position: 'tr',
				autoDismiss: 3,
				level: 'success',
				uid: Date.now(),
				...notification,
				onRemove: () => {
					notification.onRemove && notification.onRemove();
				},
			});
		}

		error(notification = DEFAULT_MESSAGE.error, clear = false) {
			this.show({ ...notification, level: 'error' });
			if (clear) this.removeAll();
		}

		success(notification = DEFAULT_MESSAGE.success, clear = false) {
			this.show({ ...notification, level: 'success' });
			if (clear) this.removeAll();
		}

		warning(notification = DEFAULT_MESSAGE.warning, clear = false) {
			this.show({ ...notification, level: 'warning' });
			if (clear) this.removeAll();
		}

		info(notification = DEFAULT_MESSAGE.info, clear = false) {
			this.show({ ...notification, level: 'info' });
			if (clear) this.removeAll();
		}

		hide(notification) {
			this.system().removeNotification(notification);
		}

		removeAll() {
			this.system().clearNotifications();
		}

		edit(notification, newProperties) {
			this.system().editNotification(notification, newProperties);
		}

		shouldComponentUpdate(nextProps) {
			return this.props !== nextProps;
		}

		getSystemRef = (ref) => (this.notify = ref);

		render() {
			return <NotifySystem ref={this.getSystemRef} {...this.props} />;
		}
	}
	NotificationSystem.MountedSystemComponent = translate(nameSpace)(Notifications);
	return NotificationSystem;
}
