import React from 'react';

import { Tabs, Tab } from 'react-bootstrap';

import LandingsList from './LandingsList';

export default class InfoBlock extends React.PureComponent {
	parse(landing) {
		return {
			_id: landing._id,
			url: landing.url || '',
			name: landing.name || '',
			screenshot: landing.screenshot || '',
			stats: { epc: 0, cr: 0, ...landing.stats },
		};
	}

	parseSystemLandings = (landing, i) => {
		if (landing) {
			return {
				key: landing._id || i,
				copy: this.parse(landing),
				original: null,
			};
		} else {
			return undefined;
		}
	};

	parseUsersLandings = (landing, i) => {
		if (landing.copy && landing.original && landing.affiliate) {
			return {
				key: landing._id || i,
				affiliate: landing.affiliate,
				disabled: landing.disabled,
				copy: this.parse(landing.copy),
				original: this.parse(landing.original),
			};
		} else return undefined;
	};

	render() {
		const { t, landings, usersLandings } = this.props;
		return (
			<Tabs defaultActiveKey={'system-landings'} id="tab-geos" animation={true}>
				<Tab eventKey={'system-landings'} title={t('Системные лендинги')}>
					<LandingsList
						t={t}
						landings={landings}
						offerId={this.props.offerId}
						deleteLanding={this.props.deleteLanding}
						edited={this.props.edited}
						parse={this.parseSystemLandings}
					/>
				</Tab>

				<Tab
					eventKey={'user-landings'}
					title={t('Пользовательские лендинги')}
					disabled={!usersLandings.length}
				>
					<LandingsList
						t={t}
						landings={usersLandings}
						parse={this.parseUsersLandings}
						offerId={this.props.offerId}
						hideLanding={this.props.hideLanding}
						edited={this.props.edited}
						searchField={'affiliate'}
					/>
				</Tab>
			</Tabs>
		);
	}
}
