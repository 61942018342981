import { adminPanelRequestTypes } from '../../constants';

const initialState = {
	loading: false,
	loaded: false,
	errors: null,
	data: [],
};

export default function crms(state = initialState, { type, data, errors }) {
	switch (type) {
		case adminPanelRequestTypes.GET_CRMS_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_CRMS_FAIL:
			return {
				...initialState,
				loading: false,
				loaded: true,
				errors: errors ? errors : null,
			};
		case adminPanelRequestTypes.GET_CRMS_SUCCESS:
			return { ...initialState, loading: false, loaded: true, errors: null, data: data };
		default:
			return state;
	}
}
