import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Button, Thumbnail } from 'react-bootstrap';

export default class CampaignItem extends React.PureComponent {
	setActive = () => {
		this.props.setActive(this.props.index, this.props.offer, !this.props.active);
	};

	render() {
		const { t, active, hidden } = this.props;
		if (hidden) return null;
		else
			return (
				<Col lg={3} md={3} sm={4} xs={6} className={'offer-item'}>
					<div className={'detail ' + (active ? 'active' : '')} onClick={this.setActive}>
						<Thumbnail src={this.props.offer.logo} alt={''} />
						<div className="caption">
							<h4>{this.props.offer.name}</h4>

							<Link to={`/campaigns/${this.props.offer._id}`}>
								<Button block bsStyle="danger">
									{t('Подробнее')}
								</Button>
							</Link>
						</div>
					</div>
				</Col>
			);

		//            <div >
		//                 <Col xs={12} className="offerItemOuter">

		//                     <Col lg={3} md={4} sm={4} xs={12} className="offerItemLeft">
		//                         <div className="offerImage">
		//                             <img src={this.props.logo}/>
		//                         </div>
		//                         <h3>
		//                             {this.props.name}
		//                         </h3>
		//                     </Col>

		//                     <Col lg={9} md={8} sm={8} xs={12}>

		//                         <Col sm={12} xs={12} className="offerDescriptionRow">
		//                             <Col xs={4}>{t('Колл-центр')}:</Col>
		//                             <Col xs={8}>{this.props.callCenter}</Col>
		//                         </Col>

		//                         <Col sm={12} xs={12} className="offerDescriptionRow">
		//                             <Col xs={4}>{t('ЦА')}:</Col>
		//                             <Col xs={8}>{this.props.auditory}</Col>
		//                         </Col>

		//                         <Col sm={12} xs={12} className="offerDescriptionRow">
		//                             <Col xs={4}>{t('Траффик')}:</Col>
		//                             <Col xs={8}>{this.props.traffic}</Col>
		//                         </Col>

		//                         <Col sm={12} xs={12} className="offerDescriptionRow geoHolder">
		//                         </Col>
		//                     </Col>

		//                     <Button
		//                         bsStyle="danger"
		//                         onClick={() => this.handleClick("/campaigns/" + this.props._id)}
		//                     >
		//                         {t('Подробнее')}
		//                     </Button>

		//                 </Col>
		//              </div>
	}
}
