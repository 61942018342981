import { combineReducers } from 'redux';
import merchantId from './merchantId';
import filters from './filters';

const supportedStore = {
	merchantId,
	filters,
};

export default combineReducers(supportedStore);

/* Немного про store в Redux
store - один и создается при createStore. 
createStore, принимает в качестве параметра один reducer
получается такая структура store:
store = {
    reducer
}
когда делаем getState, Redux возвращает этот reducer
если нужно передать несколько reducers, делаем combaineReducers
combineReducers создает новый reducer, содержащий переданные ему reducers
это похоже на вложенные объекты. То есть combineReducer "разбивает" store
примерно так
reducer = combainReducer({
    reducer_1,
    reducer_2,
    .........,
    reducer_n
})
store = {
    reducer : {
        reducer_1,
        reduser_2,
        .........,
        reducer_3
    }
}
combainReduser возвращает reducer и его можно применят множество раз
Пример:
reducer_2 = combainReducer({
    reducer_2_1,
    reducer_2_2,
    .........,
    reducer_2_n
})
store будет выглядеть так
store = {
    reducer : {
        reducer_1,
        reduser_2 : {
            reducer_2_1,
            reducer_2_2,
            .........,
            reducer_2_n
        }
        .........,
        reducer_3
    }
}
*/
