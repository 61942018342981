import React from 'react';
import uuidv4 from 'uuid/v4';
import { TableHeaderColumn, BootstrapTable } from 'react-bootstrap-table';

import { Row, Col, Button, Thumbnail, Modal } from 'react-bootstrap';

import InputForm from './InputForm';
import LandingsList from './LandingsList';

export default class OfferItem extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			payouts: null,
			shave: null,
			hold: null,
			warning: [],
			callbackWarning: null,
			show: false,
			showLandings: false,
		};
		this.tdStyle = { whiteSpace: 'normal' };
	}

	onСhangeShave = (value) => this.setState({ shave: value });

	onСhangeHold = (value) => this.setState({ hold: value });

	onСhangePayout = (value, index) => {
		let payouts = this.state.payouts
			? this.state.payouts.slice()
			: this.props.data.payouts.slice();
		payouts[index] = { ...payouts[index], value };
		this.setState({ payouts });
	};

	hideModal = () => this.setState({ show: false, warning: [], callbackWarning: null });

	save = () => {
		const data = {
			offerId: this.props.data.offer._id,
		};
		const warning = [];
		if (this.state.shave) data.offerShave = this.state.shave;
		if (this.state.hold) data.offerHold = this.state.hold;
		if (this.state.payouts) {
			data.offerPayouts = [];
			for (let i = 0; i < this.state.payouts.length; i++) {
				if (this.state.payouts[i].value > this.state.payouts[i].defaultValue) {
					warning.push({
						key: uuidv4(),
						geoName: this.state.payouts[i].geo.name,
						value: this.state.payouts[i].value,
						defaultValue: this.state.payouts[i].defaultValue,
					});
				}
				data.offerPayouts.push({
					geo: this.state.payouts[i].geo,
					value: this.state.payouts[i].value,
				});
			}
		}
		if (warning.length) {
			this.setState({
				show: true,
				warning,
				callbackWarning: () => this.props.updateUserOffers(data, this.reset),
			});
		} else {
			this.props.updateUserOffers(data, this.reset);
		}
	};

	reset = () => {
		this.setState({
			payouts: null,
			hold: null,
			shave: null,
			show: false,
			warning: [],
			callbackWarning: null,
		});
	};

	checkChanged() {
		if (this.state.payouts || this.state.hold || this.state.shave) {
			return true;
		} else {
			return false;
		}
	}

	showLandings = () => this.setState({ showLandings: true });

	closeLandings = () => this.setState({ showLandings: false });

	render() {
		const { t, data } = this.props;
		const payouts = this.state.payouts ? this.state.payouts : data.payouts;
		const changed = this.checkChanged();
		if (data.hidden) return null;
		else
			return (
				<Col xs={12} md={6}>
					<Row className={'user-offer-item ' + (data.isUser ? 'active-item' : '')}>
						<Col xs={4} className="text-center">
							<Thumbnail src={data.offer.logo} />
							<b> {data.offer.name} </b>
							<br />
							<Button
								block
								bsStyle="danger"
								disabled={!data.landings.length}
								onClick={this.showLandings}
							>
								{t('Лендинги')}
							</Button>
						</Col>

						<Col xs={8}>
							<Row>
								<Col xs={4}>
									<p>
										<b> {t('Шейф')} </b>
									</p>
									<InputForm
										placeholder={t('Шейф')}
										value={this.state.shave}
										defaultValue={data.shave}
										changeInfo={this.onСhangeShave}
										type={'number'}
									/>
									<p>
										<b> {t('Холд')} </b>
									</p>
									<InputForm
										placeholder={t('Холд')}
										value={this.state.hold}
										defaultValue={data.hold}
										changeInfo={this.onСhangeHold}
										type={'number'}
									/>
								</Col>

								<Col xs={8} className="payout">
									<p className="text-center">
										<b> {t('Выплата')} </b>
									</p>
									{payouts.map((payout, index) => (
										<Row key={`user-offer-payout${index}`}>
											<Col xs={5}>
												{payout.geo.name}
												<br />
												<small> {`(${payout.defaultValue})`} </small>
											</Col>
											<Col xs={7}>
												<InputForm
													value={
														payout.value === data.payouts[index].value
															? null
															: payout.value
													}
													defaultValue={data.payouts[index].value}
													changeInfo={(value) =>
														this.onСhangePayout(value, index)
													}
													type="number"
												/>
											</Col>
										</Row>
									))}
								</Col>
							</Row>
							<div>
								<Button bsStyle="danger" onClick={this.save} disabled={!changed}>
									{t('Сохранить')}
								</Button>{' '}
								<Button bsStyle="danger" onClick={this.reset} disabled={!changed}>
									{t('Сбросить')}
								</Button>
								{
									// <Button bsStyle='danger' className='pull-right'>
									//     {t('Развернуть')}
									// </Button>
								}
							</div>
						</Col>
					</Row>

					<Modal bsSize="lg" show={this.state.showLandings} onHide={this.closeLandings}>
						<Modal.Header closeButton>
							<Modal.Title id="offer-landings-modal">{t('Лендинги')}</Modal.Title>
						</Modal.Header>
						<Modal.Body className="landings-list">
							<LandingsList t={t} data={data.landings} />
						</Modal.Body>
					</Modal>

					<Modal show={this.state.show} onHide={this.hideModal}>
						<Modal.Header closeButton>
							<Modal.Title id="contained-modal-title-sm">
								{t('Предупреждение')}
							</Modal.Title>
						</Modal.Header>
						<Modal.Body></Modal.Body>

						<BootstrapTable data={this.state.warning} striped={true}>
							<TableHeaderColumn dataField="key" isKey={true} hidden />

							<TableHeaderColumn
								dataField="geoName"
								dataAlign="center"
								tdStyle={this.tdStyle}
							>
								{t('Гео')}
							</TableHeaderColumn>

							<TableHeaderColumn
								dataField="defaultValue"
								dataAlign="center"
								tdStyle={this.tdStyle}
							>
								{t('Значение по умолчанию')}
							</TableHeaderColumn>

							<TableHeaderColumn
								dataField="value"
								dataAlign="center"
								tdStyle={this.tdStyle}
							>
								{t('Заданное значение')}
							</TableHeaderColumn>
						</BootstrapTable>
						<Modal.Footer>
							<Button bsStyle={'success'} onClick={this.state.callbackWarning}>
								{t('Подтвердить')}
							</Button>
							<Button bsStyle={'danger'} onClick={this.hideModal}>
								{t('Отмена')}
							</Button>
						</Modal.Footer>
					</Modal>
				</Col>
			);
	}
}
