import { adminPanelRequestTypes, adminPanelApi } from '../../constants';

// //github.com/axios/axios
export function getCurrencies() {
	return {
		//тип определяющий обрабатывающий редюсер
		type: adminPanelRequestTypes.GET_CURRENCIES,
		//объект request служит для конфигурации запроса axios
		request: {
			method: 'get',
			url: adminPanelApi.API_URL_CURRENCIES,
		},
	};
}
