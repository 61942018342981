import React from 'react';
import PropTypes from 'prop-types';
import { Col, ControlLabel, FormGroup, FormControl } from 'react-bootstrap';

export default class TextForm extends React.Component {
	static propTypes = {
		value: PropTypes.string,
		changeInfo: PropTypes.func.isRequired,
		name: PropTypes.string.isRequired,
	};

	currentValue = null;

	shouldComponentUpdate(nextProps, nextState) {
		return (
			this.props.value !== nextProps.value ||
			this.props.defaultValue !== nextProps.defaultValue ||
			this.props.disabled !== nextProps.disabled ||
			this.props.placeholder !== nextProps.placeholder
		);
	}

	checkValue() {
		if (this.currentValue !== this.props.defaultValue) {
			this.currentValue = this.props.defaultValue;
			this.props.changeInfo(this.props.defaultValue, this.props.name);
		}
	}

	componentDidMount() {
		this.checkValue();
	}

	componentDidUpdate() {
		this.checkValue();
	}

	onChangeText = (event) => {
		const value = event.target.value;
		this.props.changeInfo(value, this.props.name);
	};

	render() {
		const { value, label, placeholder, disabled, validator, type } = this.props;
		return (
			<FormGroup
				validationState={(validator ? validator(value) : value) ? 'success' : 'error'}
			>
				<Col componentClass={ControlLabel} sm={2}>
					{label}
				</Col>
				<Col sm={10}>
					<FormControl
						type={type || 'textarea'}
						placeholder={placeholder}
						value={value || ''}
						onChange={this.onChangeText}
						readOnly={disabled ? 'readonly' : false}
					/>
				</Col>
			</FormGroup>
		);
	}
}
