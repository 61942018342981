import { adminPanelRequestTypes, adminPanelApi } from '../../constants';

export function getStatsOverall(data) {
	return {
		type: adminPanelRequestTypes.GET_STATS_OVERALL,
		request: {
			method: 'post',
			url: adminPanelApi.API_URL_STATS_OVERALL,
			data: data,
		},
	};
}

export function getStatsDaily(data) {
	return {
		type: adminPanelRequestTypes.GET_STATS_DAILY,
		request: {
			method: 'post',
			url: adminPanelApi.API_URL_STATS_DAILY,
			data: data,
		},
		period: {
			start: data.start,
			end: data.end,
		},
	};
}

export function getStasLandings(data) {
	return {
		type: adminPanelRequestTypes.GET_STATS_LANDINGS,
		request: {
			method: 'post',
			url: adminPanelApi.API_URL_STATS_LANDINGS,
			data: data,
		},
		period: {
			start: data.start,
			end: data.end,
		},
	};
}

export function getStatsRequests(data) {
	return {
		type: adminPanelRequestTypes.GET_STATS_REQUESTS,
		request: {
			method: 'post',
			url: adminPanelApi.API_URL_STATS_REQUESTS,
			data: data,
		},
	};
}

export function getStatsLandings(params) {
	return {
		type: adminPanelRequestTypes.GET_STATS_LANDINGS,
		request: {
			method: 'get',
			url: adminPanelApi.API_URL_STATS_LANDINGS,
			params: params,
		},
	};
}

export function getStatsPreLandings(params) {
	return {
		type: adminPanelRequestTypes.GET_STATS_PRELANDINGS,
		request: {
			method: 'get',
			url: adminPanelApi.API_URL_STATS_PRELANDINGS,
			params: params,
		},
	};
}
