import React from 'react';
import SearchBlock from './SearchBlock';
import { Row } from 'react-bootstrap';
export default class Stats extends React.Component {
	render() {
		return (
			<Row>
				<SearchBlock t={this.props.t} statsName={this.props.statsName} />
				{this.props.children}
			</Row>
		);
	}
}
