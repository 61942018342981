import { alertTypes } from '../constants';

export function alertShow(options) {
	return {
		type: alertTypes.ALERT_SHOW,
		payload: {
			title: options.title,
			message: options.message,
			type: options.type,
		},
	};
}

export function alertHide() {
	return {
		type: alertTypes.ALERT_HIDE,
	};
}
