import { adminPanelRequestTypes, adminPanelApi } from '../../constants';

export function hideTicket(ticketId) {
	return {
		type: adminPanelRequestTypes.HIDE_TICKET,
		ticketId: ticketId,
	};
}

export function getTicket(ticketId) {
	return {
		type: adminPanelRequestTypes.GET_TICKET,
		request: {
			method: 'get',
			url: adminPanelApi.API_URL_TICKET + `/${ticketId}`,
		},
	};
}

export function getAllTickets(params) {
	return {
		type: adminPanelRequestTypes.GET_ALL_TICKETS,
		request: {
			method: 'get',
			url: adminPanelApi.API_URL_TICKETS,
			params: params,
		},
	};
}

export function getNewTickets() {
	return {
		type: adminPanelRequestTypes.GET_NEW_TICKETS,
		request: {
			method: 'get',
			url: adminPanelApi.API_URL_TICKETS,
			params: {
				new: true,
			},
		},
	};
}

export function getFiltersData() {
	return {
		type: adminPanelRequestTypes.GET_TICKETS_FILTERS,
		request: {
			method: 'get',
			url: adminPanelApi.API_URL_TICKETS_FILTER,
		},
	};
}

export function createTicket(data) {
	return {
		type: adminPanelRequestTypes.CREATE_TICKET,
		request: {
			method: 'post',
			url: adminPanelApi.API_URL_TICKETS,
			data: data,
		},
	};
}

export function postMessageToTicket(ticketId, userId, data, callback) {
	return {
		type: adminPanelRequestTypes.POST_MESSAGE_TO_TICKET,
		request: {
			method: 'post',
			url: adminPanelApi.API_URL_TICKET + `/${ticketId}`,
			data: data,
		},
		callback,
		ticketId,
		userId,
	};
}

export function closeTicket(ticketId) {
	return {
		type: adminPanelRequestTypes.CLOSE_TICKET,
		request: {
			method: 'delete',
			url: adminPanelApi.API_URL_TICKET + `/${ticketId}`,
		},
	};
}

export function readMessage(ticketId) {
	return {
		type: adminPanelRequestTypes.READ_TICKET_MESSAGE,
		request: {
			method: 'put',
			url: adminPanelApi.API_URL_TICKET + `/${ticketId}`,
		},
	};
}
