import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import ContentHeader from './ContentHeader';
import ContentOffers from './ContentOffers';

import { getStatsPreLandings } from 'store/actions/apiRequestActions/stats';

class PreLandingStatsMerchant extends React.Component {
	static defaultProps = {
		preLandingsSelect: [],
	};
	componentDidMount() {
		// Не найдено этих роутингов на сервере
		// ServerConnection.sendRequest('offers/list/min/', 'GET', null, [], (answer)=> {
		//     if (!answer.status) AlertActions.show(answer.error, "danger");
		//     else {
		//         var offers = [];
		//         if (_.every(answer.data, (campaign)=> {
		//                 if (_.every(campaign.locals, (localOffer)=> {
		//                         var refactored = {label: "", value: ""};
		//                         refactored.label = campaign.name + "(" + localOffer.countrycode + ")";
		//                         refactored.value = localOffer.id;
		//                         offers.push(refactored);
		//                         return true;
		//                     })) {
		//                     return true;
		//                 }
		//             })) {
		//             self.setState({offers});
		//         }
		//     }
		// });
		// ServerConnection.sendRequest('affiliates/used/', 'GET', null, [], (answer)=> {
		//     if (!answer.status) AlertActions.show(answer.error, "danger");
		//     else {
		//         self.setState({
		//             affiliates: _.map(answer.data, function (elem) {
		//                 var refactored = {label: "", value: ""};
		//                 refactored.label = elem.email ? (elem.email + "(" + elem.id + ")") : elem.id;
		//                 refactored.value = elem.id;
		//                 return refactored;
		//             }) || []
		//         });
		//     }
		// });
		// ServerConnection.sendRequest('preLandings/owned/', 'GET', null, [], (answer)=> {
		//     if (!answer.status) AlertActions.show(answer.error, "danger");
		//     else {
		//         self.setState({
		//             preLandingsSelect: _.map(answer.data, function (elem) {
		//                 var refactored = {label: "", value: ""};
		//                 refactored.label = elem.url;
		//                 refactored.value = elem.id;
		//                 return refactored;
		//             }) || []
		//         });
		//     }
		// });
	}

	render() {
		return (
			<div className="content-wrapper">
				<ContentHeader t={this.props.t} />
				<ContentOffers
					t={this.props.t}
					preLandings={this.props.preLandings}
					getStatsPreLandings={this.props.getStatsPreLandings}
					preLandingsSelect={this.props.preLandingsSelect}
				/>
			</div>
		);
	}
}

//Получаем modalStore из stora и записываем в props компонента
const mapStateToProps = ({ apiRequestStore }) => ({
	preLandings: apiRequestStore.stats.preLandings,
});

//Пробрасываем actions в props компонента
const mapActionCreators = {
	getStatsPreLandings,
};

PreLandingStatsMerchant = translate('PreLandingStats')(PreLandingStatsMerchant);
export default connect(mapStateToProps, mapActionCreators)(PreLandingStatsMerchant);
