import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translate } from 'react-i18next';
import { push } from 'connected-react-router';
import { getUserInfo, setAccess } from 'store/actions/apiRequestActions/users';

import {
	getAllPayments,
	getAllPaymentSystems,
	addPayment,
} from 'store/actions/apiRequestActions/payments';

import { getCurrencies } from 'store/actions/apiRequestActions/currencies';

import ContentBody from './ContentBody';
import ContentHeader from './ContentHeader';

class DeepMerchants extends React.Component {
	getUserId = () => {
		const url = window.location.pathname;
		return url.substr(url.lastIndexOf('/') + 1);
	};

	render() {
		const {
			t,
			userInfo,
			getUserInfo,
			push,
			adminInfo,
			payments,
			getAllPayments,
			currencies,
			getCurrencies,
			getAllPaymentSystems,
			paymentSystems,
			addPayment,
			username,
		} = this.props;

		const loading =
			userInfo.loading || payments.loading || currencies.loading || paymentSystems.loading;

		return (
			<div className="content-wrapper">
				<ContentHeader label={t('Рекламодатель') + ' ' + userInfo.data.name} />

				<section className="content offers">
					{loading ? <div className="pageOverlay">css--animatefa-spin"/></div> : null}

					<ContentBody
						userId={this.getUserId()}
						userInfo={userInfo.data || {}}
						getUserInfo={getUserInfo}
						paymentsData={payments.data}
						adminInfo={adminInfo}
						currencies={currencies.data}
						getCurrencies={getCurrencies}
						getAllPayments={getAllPayments}
						getAllPaymentSystems={getAllPaymentSystems}
						paymentSystems={paymentSystems.data}
						addPayment={addPayment}
						push={push}
						username={username}
						setAccess={this.props.setAccess}
						t={t}
					/>
				</section>
			</div>
		);
	}
}

//Получаем modalStore из stora и записываем в props компонента
const mapStateToProps = ({ apiRequestStore }) => ({
	currencies: apiRequestStore.currencies,
	userInfo: apiRequestStore.users.userInfo,
	adminInfo: apiRequestStore.auth.user,
	payments: apiRequestStore.payments.allPayments,
	paymentSystems: apiRequestStore.payments.paymentSystems,
	username: (apiRequestStore.auth.user || {}).username || '',
});

//Пробрасываем actions в props компонента
const mapActionCreators = {
	getUserInfo,
	getAllPayments,
	push,
	getCurrencies,
	getAllPaymentSystems,
	addPayment,
	setAccess,
};

DeepMerchants = translate('DeepMerchants')(DeepMerchants);
export default withRouter(connect(mapStateToProps, mapActionCreators)(DeepMerchants));
