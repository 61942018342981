import React from 'react';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { TableHeaderColumn, BootstrapTable } from 'react-bootstrap-table';

import { Colors } from 'consts';

export default class BoxBody extends React.PureComponent {
	constructor(props) {
		super(props);
		this.tdStyle = { whiteSpace: 'normal' };
	}

	formatLink = (cell) => {
		return <Link to={'/users/affiliates/' + cell}>{cell}</Link>;
	};

	formatStatus = (cell) => {
		const newTickets = (
			<OverlayTrigger
				ref="trigger"
				placement="top"
				overlay={
					<Tooltip id="tooltipName" className="nameBreak">
						{cell.tickets + ' ' + this.props.t('Новых сообщений')}
					</Tooltip>
				}
			>
				<i className={'fa fa-envelope'} style={{ color: Colors.brandInfo }} />
			</OverlayTrigger>
		);

		const newPayments = (
			<OverlayTrigger
				ref="trigger"
				placement="top"
				overlay={
					<Tooltip id="tooltipName" className="nameBreak">
						{cell.payments + ' ' + this.props.t('Запросов на выплаты')}
					</Tooltip>
				}
			>
				<i className={'fa fa-money'} style={{ color: Colors.brandSuccess }} />
			</OverlayTrigger>
		);

		if (cell.tickets !== 0) {
			if (cell.payments !== 0)
				return (
					<React.Fragment>
						{newTickets} {newPayments}
					</React.Fragment>
				);
			else return newTickets;
		} else if (cell.payments !== 0) {
			return newPayments;
		} else return null;
	};

	render() {
		const { t, data } = this.props;
		const numUsers = 15;
		const pagination = data.length > numUsers;
		return (
			<BootstrapTable
				data={data}
				striped={true}
				options={{ sizePerPage: numUsers }}
				hover={true}
				pagination={pagination}
				search
				multiColumnSearch
				searchPlaceholder={t('ID/Email веб-мастера')}
			>
				<TableHeaderColumn
					dataField="status"
					width="50"
					dataAlign="center"
					tdStyle={this.tdStyle}
					dataFormat={this.formatStatus}
				/>

				<TableHeaderColumn
					dataField="id"
					isKey={true}
					dataAlign="center"
					tdStyle={this.tdStyle}
					dataFormat={this.formatLink}
				>
					{t('ID')}
					<br />
					{t('веб-мастера')}
				</TableHeaderColumn>

				<TableHeaderColumn dataField="email" tdStyle={this.tdStyle} dataAlign="center">
					{t('Email')}
				</TableHeaderColumn>
				{
					// <TableHeaderColumn
					//     dataField="name"
					//     tdStyle={this.tdStyle}
					//     dataAlign="center"
					// >
					//     {t('Имя')}
					// </TableHeaderColumn>
					// <TableHeaderColumn
					//     dataField="lastName"
					//     tdStyle={this.tdStyle}
					//     dataAlign="center"
					// >
					//     {t('Фамилия')}
					// </TableHeaderColumn>
				}
				<TableHeaderColumn
					dataField="paid"
					dataAlign="center"
					tdStyle={this.tdStyle}
					searchable={false}
				>
					{t('Выплачено')}
				</TableHeaderColumn>

				<TableHeaderColumn
					dataField="notPaid"
					dataAlign="center"
					tdStyle={this.tdStyle}
					searchable={false}
				>
					{t('Ожидает')}
					<br />
					{t('выплаты')}
				</TableHeaderColumn>

				<TableHeaderColumn
					dataField="clicks"
					dataAlign="center"
					tdStyle={this.tdStyle}
					searchable={false}
				>
					{t('Клики')}
				</TableHeaderColumn>

				<TableHeaderColumn
					dataField="requests"
					dataAlign="center"
					tdStyle={this.tdStyle}
					searchable={false}
				>
					{t('Заявки')}
				</TableHeaderColumn>
			</BootstrapTable>
		);
	}
}
