import { adminPanelRequestTypes, adminPanelApi } from '../../constants';

// //github.com/axios/axios
export function getAllGeos() {
	return {
		//тип определяющий обрабатывающий редюсер
		type: adminPanelRequestTypes.GET_GEOS,
		//объект request служит для конфигурации запроса axios
		request: {
			method: 'get',
			url: adminPanelApi.API_URL_GEOS,
		},
	};
}

// //github.com/axios/axios
export function getGeosByTrackLink(id) {
	return {
		//тип определяющий обрабатывающий редюсер
		type: adminPanelRequestTypes.GET_GEOS,
		//объект request служит для конфигурации запроса axios
		request: {
			method: 'get',
			url: adminPanelApi.API_URL_GEOS + `/${id}`,
		},
	};
}
