import React from 'react';
import { Link } from 'react-router-dom';
import ReactDOM from 'react-dom';
import _ from 'underscore';
import memoize from 'memoize-one';
import uuidv4 from 'uuid/v4';

import {
	Row,
	Col,
	Tooltip,
	OverlayTrigger,
	Tab,
	Nav,
	NavItem,
	ListGroup,
	ListGroupItem,
	Well,
} from 'react-bootstrap';

import Chat from 'components/service/Chat';
import { DimensionsSize } from 'components/service/Dimensions';

import { Colors } from 'consts';
import classes from './Notification.module.css';

class TicketDetail extends React.PureComponent {
	formatUser = (user) => {
		user = _.isObject(user) ? user : {};
		const to = user.isAffiliate ? 'affiliates/' : 'merchants/';
		return (
			<Link to={`/users/${to + user._id}`}>{this.props.t('От') + ': ' + user.username}</Link>
		);
	};

	renderHeader = memoize((ticket) => {
		return (
			<Row>
				<Col xs={8} className="text-left">
					{ticket.topic}
					<br />
					{this.formatUser(ticket.user)}
				</Col>

				<OverlayTrigger
					ref="trigger"
					placement="left"
					overlay={
						<Tooltip id={`closed-${ticket.ticketId || uuidv4()}`} className="nameBreak">
							{this.props.t('Свернуть тикет')}
						</Tooltip>
					}
				>
					<Col xs={4} className={classes.pointer} onClick={this.props.collapseTicket}>
						<i className="fa fa-lg fa-chevron-up" />
					</Col>
				</OverlayTrigger>
			</Row>
		);
	});

	style = { padding: '0px' };

	render() {
		const { ticket, className, height } = this.props;
		return (
			<div className={className}>
				{ticket && (
					<Chat
						small
						header={this.renderHeader(ticket)}
						style={this.style}
						height={height}
						userInfo={ticket.user}
						openTicket={ticket}
						ticketId={ticket._id}
						userId={ticket.user._id}
					/>
				)}
			</div>
		);
	}
}

class Tickets extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			expandTicket: null,
		};
	}

	renderNotifyIcon(icon, text) {
		return (
			<OverlayTrigger
				ref="trigger"
				placement="left"
				overlay={
					<Tooltip id={uuidv4()} className="nameBreak">
						{text}
					</Tooltip>
				}
			>
				{icon}
			</OverlayTrigger>
		);
	}

	renderClosedButton = (ticketId) => {
		const icon = <i className={'fa fa-lg fa-remove'} style={{ color: Colors.brandDanger }} />;
		const text = this.props.t('Убрать уведомление');
		return (
			<button className={classes['close-button']} onClick={() => this.closeTicket(ticketId)}>
				{this.renderNotifyIcon(icon, text)}
			</button>
		);
	};

	closeTicket = (ticketId) => {
		if (ticketId) this.props.closeTicket(ticketId);
	};

	renderTicketDescription = (ticket) => {
		if (ticket.messages.length > 0) {
			const icon = <i className={'fa fa-envelope'} style={{ color: Colors.brandInfo }} />;
			const text = ticket.messages.length + ' ' + this.props.t('Новых сообщений');
			return (
				<React.Fragment>
					<Col xs={10}>{ticket.topic}</Col>
					<Col xs={2}>{this.renderNotifyIcon(icon, text)}</Col>
				</React.Fragment>
			);
		} else return <Col xs={12}>{ticket.topic}</Col>;
	};

	renderTicket = (ticket, index) => {
		return (
			<ListGroupItem key={ticket._id + index}>
				<Row>
					<Col
						xs={10}
						onClick={() => this.props.expandTicket(ticket)}
						style={{ padding: '10px 0px 10px 15px' }}
					>
						{this.renderTicketDescription(ticket)}
					</Col>
					<Col xs={2} style={{ padding: '10px 15px 10px 0px' }}>
						{this.renderClosedButton(ticket._id)}
					</Col>
				</Row>
			</ListGroupItem>
		);
	};

	render() {
		const { height, tickets } = this.props;
		return (
			<ListGroup className={classes['list-container']} style={{ maxHeight: height }}>
				{tickets.map(this.renderTicket)}
			</ListGroup>
		);
	}
}

const currentTicket = {
	openTicket: null,
	closedTicket: true,
};

const SIZE = {
	bodySize: {},
	headerSize: {},
};
class TicketsContainer extends React.PureComponent {
	constructor(props, context) {
		super(props, context);
		this.state = {
			openTicket: currentTicket.openTicket,
			closedTicket: currentTicket.closedTicket,
			headerSize: SIZE.headerSize,
			bodySize: SIZE.bodySize,
			activeKey: 'overall',
		};
	}

	handleSelect = (activeKey) => {
		this.setState({ activeKey });
	};

	setSize = () => {
		const size = this.getSize();
		if (
			!_.isEqual(this.state.bodySize, size.bodySize) ||
			!_.isEqual(this.state.headerSize, size.headerSize)
		) {
			this.setState(size);
		}
	};

	getSize() {
		if (this.body) SIZE.bodySize = DimensionsSize(ReactDOM.findDOMNode(this.body));
		if (this.header) SIZE.headerSize = DimensionsSize(ReactDOM.findDOMNode(this.header));
		return {
			bodySize: SIZE.bodySize,
			headerSize: SIZE.headerSize,
		};
	}

	componentDidMount() {
		this.setSize();
	}

	componentDidUpdate() {
		this.setSize();
	}

	getHeaderRef = (header) => (this.header = header);
	getBodyRef = (body) => (this.body = body);

	expandTicket = (ticket) => {
		currentTicket.openTicket = (ticket || {})._id;
		currentTicket.closedTicket = false;
		this.setState({ ...currentTicket });
	};

	collapseTicket = () => {
		currentTicket.openTicket = null;
		currentTicket.closedTicket = true;
		this.setState({ ...currentTicket });
	};

	getCurrentTicket = memoize((allTicket, ticketId) => {
		let ticket = null;
		const index = allTicket.findIndex((t) => t._id === ticketId);
		if (index !== -1) ticket = allTicket[index];
		return ticket;
	});

	render() {
		const { t, allTicket, tickets, closeTicket } = this.props;
		const containerHeight = window.innerHeight * 0.6;
		const maxBodyHeight = containerHeight - (this.state.headerSize.height || 0);
		const style = {
			maxHeight: `${containerHeight}px`,
			height: (this.state.headerSize.height || 0) + (this.state.bodySize.height || 0),
		};
		if (!this.state.closedTicket) style.height = style.maxHeight;

		const openTicket = this.getCurrentTicket(allTicket, this.state.openTicket);
		return (
			<div className={classes['tickets-container']} style={style}>
				<TicketDetail
					height={containerHeight}
					className={this.state.closedTicket ? classes['collapsed'] : classes['expanded']}
					ticket={openTicket}
					collapseTicket={this.collapseTicket}
					t={t}
				/>

				<div
					className={this.state.closedTicket ? classes['expanded'] : classes['collapsed']}
				>
					<Tab.Container
						id="tickets-notification-tabs"
						activeKey={this.state.activeKey}
						onSelect={this.handleSelect}
					>
						<React.Fragment>
							<div ref={this.getHeaderRef}>
								<Nav bsStyle="pills" justified className={classes.navigation}>
									<NavItem eventKey="overall">
										<div
											className={
												tickets.overall.length
													? classes['tab-title-animated']
													: ''
											}
										>
											{t('Общие')}
										</div>
									</NavItem>

									<NavItem eventKey="finance">
										<div
											className={
												tickets.finance.length
													? classes['tab-title-animated']
													: ''
											}
										>
											{t('Финансовые')}
										</div>
									</NavItem>

									<NavItem eventKey="tech">
										<div
											className={
												tickets.tech.length
													? classes['tab-title-animated']
													: ''
											}
										>
											{t('Технические')}
										</div>
									</NavItem>
								</Nav>
							</div>
							<div ref={this.getBodyRef}>
								<Tab.Content animation={false}>
									<Tab.Pane eventKey="overall">
										{tickets.overall.length ? (
											<Tickets
												tickets={tickets.overall}
												height={maxBodyHeight}
												expandTicket={this.expandTicket}
												closeTicket={closeTicket}
												t={t}
											/>
										) : (
											<Well bsSize="small" style={{ marginBottom: '0px' }}>
												{this.props.t('Новых тикетов нет')}
											</Well>
										)}
									</Tab.Pane>

									<Tab.Pane eventKey="finance">
										{tickets.finance.length ? (
											<Tickets
												tickets={tickets.finance}
												height={maxBodyHeight}
												expandTicket={this.expandTicket}
												closeTicket={closeTicket}
												t={t}
											/>
										) : (
											<Well bsSize="small" style={{ marginBottom: '0px' }}>
												{this.props.t('Новых тикетов нет')}
											</Well>
										)}
									</Tab.Pane>

									<Tab.Pane eventKey="tech">
										{tickets.tech.length ? (
											<Tickets
												tickets={tickets.tech}
												height={maxBodyHeight}
												expandTicket={this.expandTicket}
												closeTicket={closeTicket}
												t={t}
											/>
										) : (
											<Well bsSize="small" style={{ marginBottom: '0px' }}>
												{this.props.t('Новых тикетов нет')}
											</Well>
										)}
									</Tab.Pane>
								</Tab.Content>
							</div>
						</React.Fragment>
					</Tab.Container>
				</div>
			</div>
		);
	}
}

export default TicketsContainer;
