import React from 'react';
import _ from 'underscore';
import uuidv4 from 'uuid/v4';
import { ListGroup, ListGroupItem, Pager } from 'react-bootstrap';

export default class ListContainer extends React.Component {
	static defaultProps = {
		sizePerPage: 5,
		data: [],
		noDataText: 'Нет данных',
		dataFormat: (value) => value,
	};

	constructor(props) {
		super(props);
		this.state = {
			numItems: 0,
		};
		this.result = [];
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextProps.data !== this.props.data) {
			this.result = [];
			return true;
		}
		return nextProps.noDataText !== this.props.noDataText;
		// if(nextProps.sizePerPage !== this.props.sizePerPage) return true
		// if(nextProps.onSelect !== this.props.onSelect) return true
		// if(nextProps.dataFormat !== this.props.dataFormat) return true
	}

	renderListItem = (numItems) => {
		let start = numItems;
		start = start < 0 ? 0 : start;
		let end = start + this.props.sizePerPage;
		end = end > this.props.data.length ? this.props.data.length : end;
		let result = [];
		for (let i = start; i < end; i++) {
			if (!this.result[i]) {
				const options = {};
				if (_.isFunction(this.props.onSelect))
					options.onClick = () => this.props.onSelect(this.props.data[i]);
				this.result[i] = (
					<ListGroupItem key={uuidv4()} {...options}>
						{this.props.dataFormat(this.props.data[i])}
					</ListGroupItem>
				);
			}
			result[i] = this.result[i];
		}
		if (!result.length)
			return <ListGroupItem key={uuidv4()}> {this.props.noDataText} </ListGroupItem>;
		return result;
	};
	nextPage = () => {
		const numItems =
			this.state.numItems >= this.props.data.length
				? this.props.data.length
				: this.state.numItems + this.props.sizePerPage;
		return this.setState(() => ({ numItems }));
	};

	prevPage = () =>
		this.setState((prevState) => ({
			numItems: prevState.numItems <= 0 ? 0 : prevState.numItems - this.props.sizePerPage,
		}));

	render() {
		const pagination = this.props.data.length > this.props.sizePerPage;
		const disableNext = this.state.numItems >= this.props.data.length - this.props.sizePerPage;
		const disablePrev = this.state.numItems <= 0;
		return (
			<React.Fragment>
				<ListGroup style={{ marginBottom: '0' }}>
					{this.renderListItem(this.state.numItems)}
				</ListGroup>

				{pagination && (
					<Pager>
						<Pager.Item previous disabled={disablePrev} onClick={this.prevPage}>
							{this.props.next || <React.Fragment> &larr; </React.Fragment>}
						</Pager.Item>
						<Pager.Item next disabled={disableNext} onClick={this.nextPage}>
							{this.props.prev || <React.Fragment> &rarr; </React.Fragment>}
						</Pager.Item>
					</Pager>
				)}
			</React.Fragment>
		);
	}
}
