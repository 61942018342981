import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(LanguageDetector).init({
	// we init with resources
	resources: {
		en: require('./en.json'),
		ru: require('./ru.json'),
	},
	fallbackLng: 'ru',
	debug: process.env.NODE_ENV === 'development' ? true : false,

	// have a common namespace used around the full app
	ns: [
		'Affiliates',
		'Campaigns',
		'Daily',
		'DeepAffiliates',
		'DeepCampaign',
		'DeepRequest',
		'LandingStats',
		'Partners',
		'PreLandingStats',
		'Profile',
		'RequestStats',
		'Settings',
		'Header',
		'SideBar',
	],

	defaultNS: 'Profile',

	keySeparator: false, // we use content as keys

	interpolation: {
		escapeValue: false, // not needed for react!!
		formatSeparator: ',',
	},

	react: {
		wait: true,
	},
});

export default i18n;
