import React from 'react';
import uuidv4 from 'uuid/v4';
import _ from 'underscore';
import { Link } from 'react-router-dom';

export default class FormatedOfferInfo extends React.PureComponent {
	render() {
		const _id = this.props._id || '';
		const name = this.props.name || '';
		const geos = this.props.geos || [];
		const offerImg = [];
		if (!this.props.onlyOffer) {
			if (_.isObject(this.props.geo)) {
				offerImg.push(this.props.geo.code.toLowerCase() + '.png');
			} else {
				for (let i = 0; i < geos.length; i++) {
					if (_.isObject(geos[i].geoRef)) {
						offerImg.push(geos[i].geoRef.code.toLowerCase() + '.png');
					}
				}
			}
		}
		return (
			<Link to={'/campaigns/' + _id}>
				{name}
				{offerImg.map((img) => (
					<img
						alt={img}
						key={uuidv4()}
						className="miniIcon"
						src={`/images/countries/${img}`}
					/>
				))}
			</Link>
		);
	}
}
