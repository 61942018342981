import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { getStatsRequests } from 'store/actions/apiRequestActions/stats';
import { updateStatusMany } from 'store/actions/apiRequestActions/actions';
import { getAllOffers } from 'store/actions/apiRequestActions/offers';
import { getAllGeos } from 'store/actions/apiRequestActions/geos';
import { getAffiliatesUsed } from 'store/actions/apiRequestActions/affiliates';
import { getStatuses } from 'store/actions/apiRequestActions/statuses';

import { setFilter, clearFilter } from 'store/actions/supportActions';

import ContentHeader from './ContentHeader';
import FilterBlock from './FilterBlock';
import StatsBlock from './StatsBlock';
import RequestsBlock from './RequestsBlock';

class RequestsStats extends React.Component {
	componentDidMount() {
		this.props.getAllGeos();
		this.props.getStatuses();
	}

	render() {
		const { offers, affiliates, statuses, geos, access } = this.props;
		return (
			<div className="content-wrapper">
				<ContentHeader history={this.props.history} t={this.props.t} />

				<section className="content offers">
					{/*loading ? (
                        <div className="pageOverlay">
                            <i className="fa fa-spinner css--animate"/>
                        </div>
                    ) : null*/}

					<FilterBlock
						access={access}
						t={this.props.t}
						offers={offers.data}
						affiliates={affiliates.data}
						geos={geos.data}
						statuses={statuses.data}
						filter={this.props.filter}
						setFilter={this.props.setFilter}
						clearFilter={this.props.clearFilter}
						getAllOffers={this.props.getAllOffers}
						getStatsRequests={this.props.getStatsRequests}
						getAffiliatesUsed={this.props.getAffiliatesUsed}
					/>

					<div className="box" style={{ borderTop: 'none' }}>
						{this.props.requests.loading ? (
							<div className="pageOverlay">
								<i className="fa fa-spinner css--animate" />
							</div>
						) : null}

						<StatsBlock t={this.props.t} requests={this.props.requests.data} />

						<RequestsBlock
							t={this.props.t}
							loading={this.props.requests.loading}
							requests={this.props.requests.data}
							updateStatusMany={this.props.updateStatusMany}
							statuses={statuses.data}
						/>
					</div>
				</section>
			</div>
		);
	}
}

const mapStateToProps = ({ apiRequestStore, supportedStore }) => ({
	access: (apiRequestStore.auth.user || {}).access || {},
	requests: apiRequestStore.stats.requests,
	statuses: apiRequestStore.statuses,
	offers: apiRequestStore.offers.allOffers,
	geos: apiRequestStore.geos,
	affiliates: apiRequestStore.affiliates,
	filter: supportedStore.filters.requestStats,
});

const mapActionsToProps = {
	getStatsRequests,
	getAllOffers,
	getAffiliatesUsed,
	getStatuses,
	getAllGeos,
	setFilter,
	clearFilter,
	updateStatusMany,
};

RequestsStats = translate('RequestStats')(RequestsStats);
export default withRouter(connect(mapStateToProps, mapActionsToProps)(RequestsStats));
