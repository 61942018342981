import React from 'react';
import memoize from 'memoize-one';
import { Tabs, Tab, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { Colors } from 'consts';
import TableInfo from './TableInfo';

export default class ContentBlock extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {};
	}

	parseTickets = memoize((allTickets) => {
		const tickets = Array.isArray(allTickets) ? allTickets : [];

		const activeTicketsHead = [];
		const activeTicketsTail = [];
		const closedTickets = [];
		let numActiveTickets = 0;
		let numNewTickets = 0;
		let numMessages = 0;
		for (let i = tickets.length - 1; i >= 0; i--) {
			const category = tickets[i].category || {};
			tickets[i].category = category.name || this.props.t('Без категории');
			if (tickets[i].closed) {
				closedTickets.push(tickets[i]);
			} else {
				let newMessages = 0;
				let newTicket = true;
				const messages = Array.isArray(tickets[i].messages) ? tickets[i].messages : [];
				for (let j = 0; j < messages.length; j++) {
					if (messages[j]) {
						if (!messages[j].readByBoth && messages[j].sentByUser) {
							newMessages++;
						} else newTicket = false;
						if (!messages[j].sentByUser) newTicket = false;
					}
				}
				tickets[i].status = {
					newMessages,
					newTicket,
				};
				if (messages.length) activeTicketsHead.push(tickets[i]);
				else activeTicketsTail.push(tickets[i]);
				if (newTicket) numNewTickets++;
				else numActiveTickets++;
				numMessages += newMessages;
			}
		}

		return {
			activeTickets: activeTicketsHead.concat(activeTicketsTail),
			count: { active: numActiveTickets, new: numNewTickets, messages: numMessages },
			closedTickets,
		};
	});

	render() {
		const { t, tickets, closeTicket } = this.props;
		const { activeTickets, count, closedTickets } = this.parseTickets(tickets);
		return (
			<div
				className="box tabs tabs-primary"
				style={{ borderTop: 'none', minHeight: `${window.innerHeight - 45}px` }}
			>
				<Tabs defaultActiveKey={'tab-tickets-active'} id="tab-tickets" animation={true}>
					<Tab
						eventKey={'tab-tickets-active'}
						title={
							<h4 className={'custom-tab-title'}>
								<div>{t('Активные тикеты')}</div>

								<OverlayTrigger
									placement="top"
									overlay={
										<Tooltip id="tooltipName" className="nameBreak">
											{`${t('Новых тикетов')}: ${count.new}`}
										</Tooltip>
									}
								>
									<strong>
										<i
											className={'fa fa-lg fa-comments'}
											style={{ color: Colors.brandPrimary }}
										/>
										{` : ${count.new}`}
									</strong>
								</OverlayTrigger>
								{'  '}
								<OverlayTrigger
									placement="top"
									overlay={
										<Tooltip id="tooltipName" className="nameBreak">
											{`${t('В процессе')}: ${count.active}`}
										</Tooltip>
									}
								>
									<strong>
										<i
											className={'fa fa-lg fa-gears'}
											style={{ color: Colors.brandWarning }}
										/>
										{` : ${count.active}`}
									</strong>
								</OverlayTrigger>
								{'  '}
								<OverlayTrigger
									placement="top"
									overlay={
										<Tooltip id="tooltipName" className="nameBreak">
											{`${t('Новых сообщений')}: ${count.messages}`}
										</Tooltip>
									}
								>
									<strong>
										<i
											className={'fa fa-lg fa-envelope'}
											style={{ color: Colors.brandInfo }}
										/>
										{` : ${count.messages}`}
									</strong>
								</OverlayTrigger>
							</h4>
						}
					>
						<TableInfo
							t={t}
							data={activeTickets}
							count={count}
							closeTicket={closeTicket}
							active
						/>
					</Tab>
					<Tab
						eventKey={'tab-tickets-archive'}
						title={
							<div className={'custom-tab-title'}>
								<h4>{'Архив тикетов'}</h4>
							</div>
						}
					>
						<TableInfo t={t} data={closedTickets} closeTicket={closeTicket} archive />
					</Tab>
				</Tabs>
			</div>
		);
	}
}
