import React from 'react';
import { Col } from 'react-bootstrap';

export default class ContentHeader extends React.PureComponent {
	render() {
		return (
			<section className="content-header" style={{ height: '50px' }}>
				<Col xs={12}>
					<h1>
						<i className="fa fa-fire" />
						{this.props.name}
					</h1>
				</Col>
			</section>
		);
	}
}
