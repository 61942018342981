import React from 'react';
import memoize from 'memoize-one';
import ReactDOM from 'react-dom';

import {
	Media,
	Image,
	Row,
	Col,
	FormGroup,
	FormControl,
	InputGroup,
	Button,
	Alert,
} from 'react-bootstrap';

export default class UserInfo extends React.PureComponent {
	constructor(props) {
		super(props);
		this.select = null;
	}

	getAccessInfo = memoize((user) => {
		const access = user.access;
		const result = [];
		for (const props in access) {
			if (access[props]) {
				switch (props) {
					case 'support':
						result.push({
							value: 'support',
							label: 'Поддержка',
						});
						break;
					case 'adminOnly':
						result.push({
							value: 'adminOnly',
							label: 'Админ',
						});
						break;
					case 'white':
						result.push({
							value: 'white',
							label: 'White-пользователь',
						});
						break;
					default:
						// statements_def
						break;
				}
			}
		}
		if (!user.isAffiliate && typeof user.isAffiliate === 'boolean') {
			result.push({
				value: 'merchant',
				label: 'Рекламодатель',
			});
		}
		return result;
	});

	getAccessSettings = memoize((user) => {
		const access = user.access;
		let result = [
			{
				value: 'support',
				label: 'Поддержка',
			},
			{
				value: 'merchant',
				label: 'Рекламодатель',
			},
			{
				value: 'adminOnly',
				label: 'Админ',
			},
			{
				value: 'white',
				label: 'White-пользователь',
			},
		];
		if (access.white)
			result = [
				{
					value: 'support',
					label: 'Поддержка',
				},
				{
					value: 'merchant',
					label: 'Рекламодатель',
				},
				{
					value: 'adminOnly',
					label: 'Админ',
				},
			];
		if (access.support)
			result = [
				{
					value: 'white',
					label: 'White-пользователь',
				},
				{
					value: 'merchant',
					label: 'Рекламодатель',
				},
				{
					value: 'adminOnly',
					label: 'Админ',
				},
			];
		if (!user.isAffiliate && typeof user.isAffiliate === 'boolean')
			result = [
				{
					value: 'white',
					label: 'White-пользователь',
				},
				{
					value: 'support',
					label: 'Поддержка',
				},
				{
					value: 'adminOnly',
					label: 'Админ',
				},
			];
		if (access.adminOnly) result = [];
		return result;
	});

	removeAccess = (accessName) => {
		const data = {
			userId: this.props.overallInfo.id,
			access: {
				type: accessName,
				enabled: false,
			},
		};
		this.props.setAccess(data);
	};

	addAccess = () => {
		const accessName = (ReactDOM.findDOMNode(this.select) || {}).value;
		if (accessName) {
			const data = {
				userId: this.props.overallInfo.id,
				access: {
					type: accessName,
					enabled: true,
				},
			};
			this.props.setAccess(data);
		}
	};

	render() {
		const { t, overallInfo } = this.props;
		const accessInfo = this.getAccessInfo(overallInfo);
		const accessSettings = this.getAccessSettings(overallInfo);
		const isAdmin = ((overallInfo || {}).access || {}).master;
		//const isAdmin = true;
		return (
			<Row>
				<Col lg={4} md={5} sm={12} xs={12}>
					<Media style={{ display: 'table-cell' }}>
						<Media.Left align="middle" style={{ minWidth: '140px' }}>
							<Image height={140} src={overallInfo.avatar} alt="" circle />
						</Media.Left>
					</Media>
					<Media.Body>
						<h4> {overallInfo.username} </h4>

						<h4> {overallInfo.lastName + ' ' + overallInfo.name} </h4>

						<p> {t('Зарегистрирован(а)') + ': ' + overallInfo.registered} </p>

						<p> {t('Был(а) в сети') + ': ' + overallInfo.lastActivity} </p>
					</Media.Body>
				</Col>
				<Col lg={3} md={3} sm={4} xs={12}>
					{isAdmin && (
						<React.Fragment>
							{!!accessSettings.length && (
								<FormGroup validationState="error">
									<InputGroup>
										<FormControl
											componentClass="select"
											placeholder="select"
											inputRef={(ref) => (this.select = ref)}
										>
											{accessSettings.map((options, i) => (
												<option
													key={`options_${i}-${options.value}`}
													value={options.value}
												>
													{' '}
													{t(options.label)}{' '}
												</option>
											))}
										</FormControl>
										<InputGroup.Button>
											<Button
												bsStyle="danger"
												onClick={this.addAccess}
												disabled={!accessSettings.length}
											>
												<i className="fa fa-lg fa-check" />
											</Button>
										</InputGroup.Button>
									</InputGroup>
								</FormGroup>
							)}
							{accessInfo.map((accessName, i) => (
								<Alert
									bsStyle="info"
									key={`accessName_${i}-${accessName.value}`}
									className="access-info"
									onDismiss={() => this.removeAccess(accessName.value)}
								>
									{accessName.label}
								</Alert>
							))}
						</React.Fragment>
					)}
				</Col>
			</Row>
		);
	}
}
