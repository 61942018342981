import { adminPanelRequestTypes, socketTypes } from '../../constants';
import _ from 'underscore';

import { combineReducers } from 'redux';

import { push } from 'connected-react-router';
import notify from 'notifications';

import { getAllOffers, getOneOfferAccess } from 'store/actions/apiRequestActions/offers';

const MESSAGE = {
	success: {
		title: 'Успешно!',
		message: 'Данные обновлены!',
		position: 'tr',
	},
	successDuble: {
		title: 'Успешно!',
		message: 'Офферы успешно продублированы!',
		position: 'tr',
	},
	warning: {
		title: 'Что-то пошло не так!',
		message: 'Не все офферы удалось обновить!',
		position: 'tr',
	},
};
const initialOffers = {
	loading: false,
	loaded: false,
	errors: null,
	data: [],
};

const initialOffer = {
	loading: false,
	loaded: false,
	errors: null,
	data: {},
};
const initialCreateOffer = {
	loading: false,
	loaded: false,
	errors: null,
};

const initialCategories = {
	loading: false,
	loaded: false,
	errors: null,
	data: [],
};

function allOffers(
	state = initialOffers,
	{ type, data, errors, asyncDispatch, offersStatus, callback },
) {
	switch (type) {
		case adminPanelRequestTypes.GET_OFFERS_REQUEST:
		case adminPanelRequestTypes.CHANGE_OFFER_STATUS_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_OFFERS_FAIL:
		case adminPanelRequestTypes.CHANGE_OFFER_STATUS_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		case adminPanelRequestTypes.GET_OFFERS_SUCCESS:
			callback && callback();
			return { ...state, loading: false, loaded: true, errors: null, data: data };
		case adminPanelRequestTypes.DUPLICATE_OFFERS_SUCCESS:
			callback && callback();
			if (((data || {}).failure || []).length) {
				notify.warning(MESSAGE.warning);
			} else {
				notify.success(MESSAGE.successDuble);
			}
			return {
				...state,
				loading: false,
				loaded: true,
				errors: null,
				data: data ? data.success : state.data,
			};
		case adminPanelRequestTypes.CHANGE_OFFER_STATUS_SUCCESS:
			notify.success(MESSAGE.success);
			callback && asyncDispatch(getAllOffers(callback()));
			const offers = Array.isArray(state.data) ? state.data.slice() : [];
			for (let i = 0; i < offers.length; i++) {
				offersStatus.offers.findIndex((t) => t._id === offers[i]._id);
				if (_.isBoolean(offersStatus.active))
					offers[i] = { ...offers[i], disabled: !offersStatus.active };
				if (_.isBoolean(offersStatus.white)) {
					const access = { ...offers[i].access };
					access.white = offersStatus.white;
					offers[i] = { ...offers[i], access: access };
				}
			}
			return { ...state, loading: false, loaded: true, errors: null, data: offers };
		default:
			return state;
	}
}

function offer(
	state = initialOffer,
	{ type, data, errors, geoId, landingId, preLandingId, access, callback, asyncDispatch },
) {
	let index = -1;
	switch (type) {
		case adminPanelRequestTypes.GET_OFFER_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_OFFER_SUCCESS:
			return { ...state, loading: false, loaded: true, errors: null, data: data };
		case adminPanelRequestTypes.GET_OFFER_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		//========== UPDATE OFFERS REDUCER START ==========//

		//===== REQUESTS START =====//
		case adminPanelRequestTypes.UPDATE_OFFER_INFO_REQUEST:
		case adminPanelRequestTypes.ADD_OFFER_GEO_REQUEST:
		case adminPanelRequestTypes.DELETE_OFFER_GEO_REQUEST:
		case adminPanelRequestTypes.UPDATE_OFFER_GEO_REQUEST:
		case adminPanelRequestTypes.ADD_OFFER_LANDING_REQUEST:
		case adminPanelRequestTypes.DELETE_OFFER_LANDING_REQUEST:
		case adminPanelRequestTypes.ADD_OFFER_PRELANDING_REQUEST:
		case adminPanelRequestTypes.DELETE_OFFER_PRELANDING_REQUEST:
		case adminPanelRequestTypes.UPDATE_OFFER_ACCESS_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		//===== REQUESTS END =====//

		//===== SUCCESS START =====//
		case adminPanelRequestTypes.UPDATE_OFFER_INFO_SUCCESS:
			notify.success(MESSAGE.success);
			if (_.isFunction(callback)) callback();
			const newData = _.isObject(data) ? data : {};
			return {
				...state,
				loading: false,
				loaded: true,
				errors: null,
				data: {
					...state.data,
					...newData,
				},
			};
		case adminPanelRequestTypes.UPDATE_OFFER_GEO_SUCCESS:
			notify.success(MESSAGE.success);
			let geos = state.data.geos.slice();
			index = geos.findIndex((t) => t._id === data._id);
			if (index !== -1) {
				geos[index] = { ...geos[index], ...data };
				state.data.geos = geos;
			}
			return { ...state, loading: false, loaded: true, errors: null };
		case adminPanelRequestTypes.ADD_OFFER_GEO_SUCCESS:
			notify.success(MESSAGE.success);
			state.data.geos = state.data.geos.slice();
			state.data.geos.push(data);
			return { ...state, loading: false, loaded: true, errors: null };
		case adminPanelRequestTypes.DELETE_OFFER_GEO_SUCCESS:
			notify.success(MESSAGE.success);
			index = state.data.geos.findIndex((t) => t._id === geoId);
			if (index !== -1) {
				state.data.geos.splice(index, 1);
				state.data.geos = state.data.geos.slice();
			}
			return { ...state, loading: false, loaded: true, errors: null };
		case adminPanelRequestTypes.ADD_OFFER_LANDING_SUCCESS:
			notify.success(MESSAGE.success);
			state.data.landings = state.data.landings.slice();
			state.data.landings.push(data);
			return { ...state, loading: false, loaded: true, errors: null };
		case adminPanelRequestTypes.DELETE_OFFER_LANDING_SUCCESS:
			notify.success(MESSAGE.success);
			index = state.data.landings.findIndex((t) => t._id === landingId);
			if (index !== -1) {
				state.data.landings.splice(index, 1);
				state.data.landings = state.data.landings.slice();
			}
			return { ...state, loading: false, loaded: true, errors: null };
		case adminPanelRequestTypes.ADD_OFFER_PRELANDING_SUCCESS:
			notify.success(MESSAGE.success);
			state.data.middlePages = state.data.middlePages.slice();
			state.data.middlePages.push(data);
			return { ...state, loading: false, loaded: true, errors: null };
		case adminPanelRequestTypes.DELETE_OFFER_PRELANDING_SUCCESS:
			notify.success(MESSAGE.success);
			index = state.data.middlePages.findIndex((t) => t._id === preLandingId);
			if (index !== -1) {
				state.data.middlePages.splice(index, 1);
				state.data.middlePages = state.data.middlePages.slice();
			}
			return { ...state, loading: false, loaded: true, errors: null };
		case adminPanelRequestTypes.UPDATE_OFFER_ACCESS_SUCCESS:
			notify.success(MESSAGE.success);
			const updatedAccess = state.data.access || {};
			updatedAccess[access.field] = data;
			return {
				loading: false,
				loaded: true,
				errors: null,
				data: {
					...state.data,
					access: updatedAccess,
				},
			};
		//===== SUCCESS END =====//

		//===== FAIL START =====//
		case adminPanelRequestTypes.UPDATE_OFFER_INFO_FAIL:
		case adminPanelRequestTypes.ADD_OFFER_GEO_FAIL:
		case adminPanelRequestTypes.DELETE_OFFER_GEO_FAIL:
		case adminPanelRequestTypes.UPDATE_OFFER_GEO_FAIL:
		case adminPanelRequestTypes.ADD_OFFER_LANDING_FAIL:
		case adminPanelRequestTypes.DELETE_OFFER_LANDING_FAIL:
		case adminPanelRequestTypes.ADD_OFFER_PRELANDING_FAIL:
		case adminPanelRequestTypes.DELETE_OFFER_PRELANDING_FAIL:
		case adminPanelRequestTypes.UPDATE_OFFER_ACCESS_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		//===== FAIL END =====//
		//========== UPDATE OFFERS REDUCER END ==========//
		default:
			return state;
	}
}

function createdOffer(state = initialCreateOffer, { type, data, errors, asyncDispatch, callback }) {
	switch (type) {
		case adminPanelRequestTypes.CREATE_OFFER_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.CREATE_OFFER_SUCCESS:
			if (_.isFunction(callback)) callback();
			asyncDispatch(push('/campaigns/' + data));
			return { ...state, loading: false, loaded: true, errors: null, data: data };
		case adminPanelRequestTypes.CREATE_OFFER_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		default:
			return state;
	}
}

function categories(state = initialCategories, { type, data, errors }) {
	switch (type) {
		case adminPanelRequestTypes.GET_OFFER_CATEGORY_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_OFFER_CATEGORY_SUCCESS:
			return { ...state, loading: false, loaded: true, errors: null, data: data };
		case adminPanelRequestTypes.GET_OFFER_CATEGORY_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		default:
			return state;
	}
}

function offerAccess(
	state = initialCategories,
	{ type, data, errors, decided, asyncDispatch, offerAccessId },
) {
	switch (type) {
		case adminPanelRequestTypes.GET_OFFER_ACCESS_REQUEST:
		case adminPanelRequestTypes.CHANGE_OFFER_ACCESS_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_OFFER_ACCESS_SUCCESS:
			return { ...state, loading: false, loaded: true, errors: null, data: data };
		case adminPanelRequestTypes.GET_ONE_OFFER_ACCESS_SUCCESS:
			state.data.push(data);
			return { ...state, data: state.data.slice() };
		case adminPanelRequestTypes.CHANGE_OFFER_ACCESS_SUCCESS:
			notify.success(MESSAGE.success);
			const index = state.data.findIndex((t) => t._id === data);
			if (index !== -1) state.data[index] = { ...state.data[index], decided, closed: true };
			return {
				...state,
				loading: false,
				loaded: true,
				errors: null,
				data: state.data.slice(),
			};
		case socketTypes.ADD_OFFER_ACCESS:
			const newData = state.data.slice();
			if (typeof data === 'object' && data) {
				newData.push(data);
			} else if (typeof data === 'string') {
				asyncDispatch(getOneOfferAccess(data));
			}
			return { ...state, data: newData };
		case adminPanelRequestTypes.HIDE_OFFER_ACCESS:
			let offerAccessNewData = state.data.slice();
			const hideIndex = offerAccessNewData.findIndex((t) => t._id === offerAccessId);
			if (hideIndex !== -1) {
				offerAccessNewData[hideIndex].read = true;
			}
			return { ...state, data: offerAccessNewData };
		case adminPanelRequestTypes.GET_OFFER_ACCESS_FAIL:
		case adminPanelRequestTypes.CHANGE_OFFER_ACCESS_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		default:
			return state;
	}
}

function offersName(state = initialCategories, { type, data, errors, callback }) {
	switch (type) {
		case adminPanelRequestTypes.GET_OFFERS_NAME_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_OFFERS_NAME_SUCCESS:
			callback && callback();
			return { ...state, loading: false, loaded: true, errors: null, data: data };
		case adminPanelRequestTypes.GET_OFFERS_NAME_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		default:
			return state;
	}
}

const offers = {
	allOffers,
	offer,
	createdOffer,
	categories,
	offerAccess,
	offersName,
};

export default combineReducers(offers);
