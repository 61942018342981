import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translate } from 'react-i18next';

import { signUp } from 'store/actions/apiRequestActions/auth';
import { alertShow } from 'store/actions/alertActions';

import { Panel, Button, Form, FormGroup, FormControl } from 'react-bootstrap';
import { uniqueId } from 'underscore';

class AddMerchant extends React.Component {
	state = {
		loading: false,
		username: '',
		password: '',
		repassword: '',
	};

	handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			this.handleOnSubmit();
		}
	};

	handleOnSubmit = () => {
		if (this.state.password !== this.state.repassword) {
			this.props.alertShow({
				message: 'Пароли не совпадают',
				type: 'danger',
			});
		} else {
			this.setState({
				loading: true,
			});
			this.props.signUp(
				{
					username: this.state.username,
					password: this.state.password,
					isAffiliate: false,
				},
				(err) => {
					this.setState({
						loading: false,
					});
				},
			);
		}
	};

	render() {
		return (
			<div className="content-wrapper">
				{this.state.loading ? (
					<div className="pageOverlay">
						<i className="fa fa-spinner css--animate" />
					</div>
				) : null}
				<section className="content">
					<Panel>
						<Panel.Body>
							<h2>Регистрация рекламодателя</h2>
							<Form onKeyPress={this.handleKeyPress} autoComplete="new-password">
								<FormGroup>
									<FormControl
										required
										autoComplete="new-password"
										name={uniqueId()}
										type="text"
										placeholder="Имя пользователя"
										autoFocus
										value={this.state.username}
										onChange={(e) =>
											this.setState({ username: e.target.value })
										}
									/>
								</FormGroup>
								<FormGroup>
									<FormControl
										required
										autoComplete="new-password"
										name={uniqueId()}
										type="password"
										placeholder="Пароль"
										value={this.state.password}
										onChange={(e) =>
											this.setState({ password: e.target.value })
										}
									/>
								</FormGroup>
								<FormGroup>
									<FormControl
										required
										autoComplete="off"
										name={uniqueId()}
										type="password"
										placeholder="Повторите пароль"
										value={this.state.repassword}
										onChange={(e) =>
											this.setState({ repassword: e.target.value })
										}
									/>
								</FormGroup>
								<br />
								<Button block bsStyle={'primary'} onClick={this.handleOnSubmit}>
									Зарегистрировать
								</Button>
							</Form>
						</Panel.Body>
					</Panel>
				</section>
			</div>
		);
	}
}

//Получаем modalStore из stora и записываем в props компонента
const mapStateToProps = () => ({});

//Пробрасываем actions в props компонента
const mapActionCreators = {
	signUp,
	alertShow,
};

AddMerchant = translate()(AddMerchant);
export default withRouter(connect(mapStateToProps, mapActionCreators)(AddMerchant));
