import React from 'react';
import querystring from 'querystring';
import { Link } from 'react-router-dom';

import { Col, Button, Thumbnail } from 'react-bootstrap';

export default class LandingItem extends React.PureComponent {
	onClickButton = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (this.props.data.copy._id) {
			if (this.props.deleteLanding) {
				this.props.deleteLanding(this.props.offerId, { id: this.props.data.copy._id });
			} else if (this.props.hideLanding) {
				this.props.hideLanding({
					username: this.props.data.affiliate,
					offerId: this.props.offerId,
					landingId: this.props.data.copy._id,
					update: {
						disabled: !this.props.data.disabled,
					},
				});
			}
		}
	};

	render() {
		const { t, data, hidden } = this.props;
		const { copy, original } = data;
		const temp = {
			copy: copy,
			original: original,
		};
		const params = querystring.stringify({
			landing: JSON.stringify(temp),
		});
		if (hidden) return null;
		else
			return (
				<Col xs={6} md={4} lg={3} className="user-landing-item">
					<div>
						<Link to={'/stats/landings/?' + params}>
							<div className={'user-landing-item-detail'}>
								<Thumbnail src={copy.screenshot} alt="" />
								<div className="overlay-block" />
								<h1>
									<b>{t('Подробнее')}</b>
								</h1>
							</div>
						</Link>

						<div className="landings-stats">
							<Col xs={12}>
								<h3>{copy.name}</h3>
							</Col>
							<Col xs={6} className="text-center">
								<div className="hr-text hr-text-center m-t-1">
									<strong>{t('EPC')}</strong>
								</div>
								{copy.stats.epc}
							</Col>

							<Col xs={6} className="text-center">
								<div className="hr-text hr-text-center m-t-1">
									<strong>{t('CR')}</strong>
								</div>
								{copy.stats.cr}
							</Col>
						</div>
						{this.props.edited && (
							<Button
								block
								bsStyle={this.props.data.disabled ? 'success' : 'danger'}
								onClick={this.onClickButton}
							>
								{this.props.deleteLanding
									? t('Удалить лендинг')
									: this.props.data.disabled
									? t('Включить лендинг')
									: t('Отключить лендинг')}
							</Button>
						)}
					</div>
				</Col>
			);
	}
}
