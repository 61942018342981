import React from 'react';
import _ from 'underscore';
import memoize from 'memoize-one';
//import Switch from 'rc-switch';
import { Row, Col, Form, Button } from 'react-bootstrap';

import InfoForm from './InfoForm';
import LogoForm from './LogoForm';
import SelectForm from './SelectForm';

export default class SettingsMerchant extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			logoPreviewUrl: '',
			user: {
				name: null,
				lastName: null,
				username: null,
				skype: null,
				password: null,
				oldPassword: null,
				phone: null,
				avatar: null,
				crm: null,
			},
		};
	}

	getReactSelectOptionsFromCRMS = memoize((crms) => {
		return crms.map((crm) => ({
			value: crm._id,
			label: crm.name,
		}));
	});

	// changeNotifications(name, value) {
	//     var settings = this.props.settings;
	//     settings.notifications.actions[name] = value;
	//     this.props.userUpdate(settings);
	// };

	changeInfo = (event, fieldName) => {
		var user = { ...this.state.user };
		user[fieldName] = event ? (event.target ? event.target.value : event.value) : null;
		this.setState({ user });
	};

	changeAvatar = (e) => {
		e.preventDefault();
		e.stopPropagation();
		let user = { ...this.state.user };
		if (e.target.files[0]) {
			let reader = new FileReader();
			user.avatar = e.target.files[0];
			reader.onloadend = () => {
				this.setState({
					user: user,
					logoPreviewUrl: reader.result,
				});
			};
			reader.readAsDataURL(user.avatar);
		} else {
			user.avatar = '';
			this.setState({
				user: user,
				logoPreviewUrl: '',
			});
		}
	};

	submitChanges = (event) => {
		event.preventDefault();
		event.stopPropagation();
		const user = this.state.user;

		let data = new FormData();
		for (let prop in user) {
			if (!_.isNull(user[prop])) data.append(prop, user[prop]);
		}
		this.props.userUpdate(data);
	};

	onResetClick = () => {
		this.setState({
			logoPreviewUrl: '',
			user: {
				name: null,
				lastName: null,
				username: null,
				skype: null,
				password: null,
				oldPassword: null,
				phone: null,
				avatar: null,
				crm: null,
			},
		});
	};

	render() {
		const { t } = this.props;
		let crms = this.getReactSelectOptionsFromCRMS(this.props.crms);
		return (
			<section className="content">
				<Row>
					<Col md={12} className="wBlock">
						<Col md={12}>
							<Form onSubmit={this.submitChanges}>
								<InfoForm
									value={this.state.user.name}
									defaultValue={this.props.name}
									label={t('Имя')}
									placeholder={t('Имя')}
									changeInfo={this.changeInfo}
									name={'name'}
								/>
								<InfoForm
									value={this.state.user.lastName}
									defaultValue={this.props.lastName}
									label={t('Фамилия')}
									placeholder={t('Фамилия')}
									changeInfo={this.changeInfo}
									name={'lastName'}
								/>
								<InfoForm
									value={this.state.user.username}
									defaultValue={this.props.username}
									label={t('Email')}
									placeholder={t('Email')}
									changeInfo={this.changeInfo}
									name={'username'}
								/>
								<InfoForm
									value={this.state.user.skype}
									defaultValue={this.props.skype}
									label={t('Skype')}
									placeholder={t('Skype')}
									changeInfo={this.changeInfo}
									name={'skype'}
								/>
								<InfoForm
									value={this.state.user.phone}
									defaultValue={this.props.phone}
									label={t('Телефон')}
									placeholder={t('Телефон')}
									changeInfo={this.changeInfo}
									name={'phone'}
								/>
								<SelectForm
									value={this.state.user.crm}
									defaultValue={this.props.crm}
									label={t('CRM')}
									changeInfo={this.changeInfo}
									name={'crm'}
									options={crms}
								/>
								<InfoForm
									value={this.state.user.oldPassword}
									defaultValue={this.state.oldPassword}
									label={t('Старый пароль')}
									placeholder={t('Старый пароль')}
									changeInfo={this.changeInfo}
									name={'oldPassword'}
									type="password"
								/>
								<InfoForm
									value={this.state.user.password}
									defaultValue={this.state.password}
									label={t('Новый пароль')}
									placeholder={t('Новый пароль')}
									changeInfo={this.changeInfo}
									name={'password'}
									type="password"
								/>
								<LogoForm
									logo={this.state.logoPreviewUrl}
									defaultLogo={this.props.avatar}
									label={t('Аватар')}
									placeholder={t('Аватар')}
									changeInfo={this.changeAvatar}
								/>
								<Button bsStyle="info" type="submit">
									{t('Сохранить')}
								</Button>{' '}
								<Button bsStyle="danger" onClick={this.onResetClick}>
									{t('Сбросить')}
								</Button>
							</Form>
							{
								//<h4>{t('Оповещения')}</h4>
								// <Switch
								//     checked={this.props.settings.notifications.actions.onCreate}
								//     onChange={this.changeNotifications.bind(this, "onCreate")}
								// />
								// <span>{t('При появлении заказа')}</span>
								// <Switch
								//     checked={this.props.settings.notifications.actions.onChange}
								//     onChange={this.changeNotifications.bind(this, "onChange")}
								// />
								//<span>{t('При обновлении заказа')}</span>
							}
						</Col>
					</Col>
				</Row>
			</section>
		);
	}
}
