const domain = window.location.hostname.split('.').slice(-2).join('.');
let apiBase = `${window.location.protocol}//partner.${domain}`;
if (process.env.NODE_ENV === 'development') {
	// apiBase = '//localhost:8000';
	apiBase = 'https://partner.dzha.top';
}
//export const API_BASE = '//localhost:3000'
export const API_BASE = apiBase;

// ========= API URL BEGIN =========
export const API_URL = API_BASE + '/api';
// ========== API URL END ==========

// ========= API URL AUTCHENTICATE BEGIN =========
export const API_URL_LOGIN = API_URL + '/authorization';
export const API_URL_LOGOUT = API_URL + '/logout';
export const API_URL_SIGNUP = API_URL + '/signup';
export const API_URL_CHECK_USERNAME = API_URL + '/checkusername';

// ========== API URL AUTCHENTICATE END ==========
