import React from 'react';
import memoize from 'memoize-one';
import _ from 'underscore';
import uuidv4 from 'uuid/v4';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { TableHeaderColumn, BootstrapTable } from 'react-bootstrap-table';
import Chat from 'components/service/Chat';
import { Colors } from 'consts';
import MainFunctions from 'mainLibs/MainFunctions';

export default class TableInfo extends React.PureComponent {
	constructor(props) {
		super(props);
		this.tdStyle = { whiteSpace: 'normal', wordWrap: 'break-word', cursor: 'pointer' };
		this.thStyle = { whiteSpace: 'normal' };
		this.numTickets = 30;
	}

	formatStatus = (cell) => {
		const { newMessages, newTicket } = cell;
		const isNewMessages = newMessages !== 0;
		return (
			<React.Fragment>
				{newTicket ? (
					<OverlayTrigger
						placement="top"
						overlay={
							<Tooltip id="tooltipName" className="nameBreak">
								{this.props.t('Новый тикет')}
							</Tooltip>
						}
					>
						<i
							className={'fa fa-lg fa-comment'}
							style={{ color: Colors.brandPrimary }}
						/>
					</OverlayTrigger>
				) : (
					<OverlayTrigger
						placement="top"
						overlay={
							<Tooltip id="tooltipName" className="nameBreak">
								{this.props.t('В процессе')}
							</Tooltip>
						}
					>
						<i className={'fa fa-lg fa-gear'} style={{ color: Colors.brandWarning }} />
					</OverlayTrigger>
				)}
				{newTicket && isNewMessages && <br />}
				{isNewMessages && (
					<OverlayTrigger
						placement="top"
						overlay={
							<Tooltip id="tooltipName" className="nameBreak">
								{newMessages + ' ' + this.props.t('Новых сообщений')}
							</Tooltip>
						}
					>
						<i className={'fa fa-lg fa-envelope'} style={{ color: Colors.brandInfo }} />
					</OverlayTrigger>
				)}
			</React.Fragment>
		);
	};

	getLastMessage = (cell) => {
		const message = _.last(Array.isArray(cell) ? cell : []) || {};
		let text = _.isString(message.message) ? message.message : '';
		if (text) {
			return (
				<div className="clip">
					<strong>{MainFunctions.dateFormat(message.date)} </strong>
					<br />
					{text}
				</div>
			);
		} else {
			return this.props.t('Сообщений не было');
		}
	};

	formatUser = (cell) => {
		const user = _.isObject(cell) ? cell : {};
		const to = user.isAffiliate ? 'affiliates/' : 'merchants/';
		return <Link to={`/users/${to + user._id}`}>{`${user.username} (${user._id})`}</Link>;
	};

	closeTicket = (id) => {
		if (id) {
			this.props.closeTicket(id);
		}
	};

	deletedComponent = (cell) => {
		return (
			<OverlayTrigger
				ref="trigger"
				placement="top"
				overlay={
					<Tooltip id={`closed-${cell || uuidv4()}`} className="nameBreak">
						{this.props.t('Закрыть тикет')}
					</Tooltip>
				}
			>
				<button className="btn btn-table-tool" onClick={() => this.closeTicket(cell)}>
					<i className="fa fa-2x fa-remove" />
				</button>
			</OverlayTrigger>
		);
	};

	isExpandableRow(row) {
		return true;
	}

	expandComponent(row) {
		if (!_.isObject(row)) row = {};
		const { user, _id } = row;
		return (
			<Chat
				header={false}
				column={!row.closed}
				footer={!row.closed}
				className="no-padding-td expanded-block"
				openTicket={row || {}}
				ticketId={_id}
				userInfo={user || {}}
				userId={user ? user._id : null}
			/>
		);
	}

	getOptions = memoize((numTickets) => {
		return {
			//expandRowBgColor: 'rgb(255, 50, 50)',
			expandBy: 'column',
			sizePerPage: numTickets,
		};
	});

	render() {
		const { t, data, active } = this.props;
		const pagination = data.length > this.numTickets;
		const options = this.getOptions(this.numTickets);

		return (
			<BootstrapTable
				data={data}
				striped={true}
				options={options}
				hover={true}
				bordered={false}
				pagination={pagination}
				expandableRow={this.isExpandableRow}
				expandComponent={this.expandComponent}
			>
				{active && (
					<TableHeaderColumn
						dataField="status"
						width="40"
						dataAlign="center"
						tdStyle={this.tdStyle}
						thStyle={this.thStyle}
						dataFormat={this.formatStatus}
					/>
				)}

				<TableHeaderColumn
					dataField="_id"
					tdStyle={this.tdStyle}
					thStyle={this.thStyle}
					dataAlign="center"
					isKey={true}
				>
					{t('ID Тикета')}
				</TableHeaderColumn>

				<TableHeaderColumn
					dataField="created"
					tdStyle={this.tdStyle}
					thStyle={this.thStyle}
					dataAlign="center"
					dataFormat={MainFunctions.dateFormat}
				>
					{t('Создан')}
				</TableHeaderColumn>

				<TableHeaderColumn
					dataField="user"
					tdStyle={this.tdStyle}
					thStyle={this.thStyle}
					dataFormat={this.formatUser}
					dataAlign="center"
				>
					{t('Пользователь')}
				</TableHeaderColumn>

				<TableHeaderColumn
					dataField="category"
					tdStyle={this.tdStyle}
					thStyle={this.thStyle}
					dataAlign="center"
				>
					{t('Категория')}
				</TableHeaderColumn>

				<TableHeaderColumn
					dataField="topic"
					tdStyle={this.tdStyle}
					thStyle={this.thStyle}
					dataAlign="center"
				>
					{t('Тема')}
				</TableHeaderColumn>

				<TableHeaderColumn
					dataField="messages"
					tdStyle={this.tdStyle}
					thStyle={this.thStyle}
					dataFormat={this.getLastMessage}
					dataAlign="left"
				>
					{t('Последнее сообщение')}
				</TableHeaderColumn>

				{active && (
					<TableHeaderColumn
						dataField="_id"
						expandable={false}
						dataAlign="center"
						dataFormat={this.deletedComponent}
						width={'40px'}
					/>
				)}
			</BootstrapTable>
		);
	}
}
