import React from 'react';
import _ from 'underscore';
import memoize from 'memoize-one';
import { connect } from 'react-redux';
import CampaignsStatsTable from './CampaignsStatsTable';
import MainFunctions from 'mainLibs/MainFunctions';

const parseCampaigns = memoize((stats) => {
	if (!Object.keys(stats).length) return [];
	let campaigns = [],
		visitsGrouped = _.groupBy(stats.visits, (visit) => {
			return visit.trackLink.toString();
		}),
		actionsGrouped = _.groupBy(stats.actions, (action) => {
			return action.trackLink.toString();
		});

	let campaignsIds = _.uniq(Object.keys(actionsGrouped).concat(Object.keys(visitsGrouped)));

	for (var i = 0; i < campaignsIds.length; i++) {
		let currentTrackLinkId = campaignsIds[i];
		let actionsParsed = _.groupBy(actionsGrouped[currentTrackLinkId] || [], (action) => {
			let actionStatus = _.last(action.statusesFlow).status.name;
			if (actionStatus === 'Approved') return 'A';
			else if (actionStatus === 'Declined') return 'D';
			else if (actionStatus === 'Trash') return 'T';
			else if (actionStatus === 'Pending') return 'P';
		});

		let validApprove =
			(((actionsParsed.A || []).length || 0) /
				((actionsParsed.A || []).length +
					(actionsParsed.D || []).length +
					(actionsParsed.P || []).length)) *
			100;
		// let statusApprove = (((actionsParsed.A || []).length || 0) / ((actionsParsed.D || []).length || 0)) * 100;
		let statusApprove =
			(((actionsParsed.A || []).length || 0) /
				((actionsParsed.A || []).length +
					(actionsParsed.D || []).length +
					(actionsParsed.P || []).length +
					(actionsParsed.T || []).length)) *
			100;
		//получаем уникальные клики (от одного посетителя)
		let uniqClicks = _.groupBy(visitsGrouped[currentTrackLinkId] || [], (visit) => {
			return visit.techInfo.ip;
		});
		let CR =
			(((actionsParsed.A || []).length +
				(actionsParsed.D || []).length +
				(actionsParsed.P || []).length) /
				((Object.keys(uniqClicks) || []).length || 0)) *
			100;
		let epc =
			_.reduce(actionsParsed.A || [], (memo, action) => memo + action.fixedPayout, 0) /
			((Object.keys(uniqClicks) || []).length || 0);

		let approvedMoney = _.reduce(
			actionsParsed.A || [],
			(memo, action) => memo + action.fixedPayout,
			0,
		);
		let holdMoney = _.reduce(
			actionsParsed.P || [],
			(memo, action) => memo + action.fixedPayout,
			0,
		);
		let declinedMoney = _.reduce(
			actionsParsed.D || [],
			(memo, action) => memo + action.fixedPayout,
			0,
		);

		let allMoney = approvedMoney + holdMoney + declinedMoney;

		campaigns.push({
			name: _.find(stats.trackLinks, (trackLink) => {
				return trackLink._id.toString() === currentTrackLinkId;
			}).name,
			id: currentTrackLinkId,
			uniqClicks: MainFunctions.formatNumber(Object.keys(uniqClicks).length, 0),
			allClicks: MainFunctions.formatNumber(
				(visitsGrouped[currentTrackLinkId] || []).length || 0,
				0,
			),
			CR: MainFunctions.formatNumber(isNaN(CR) || !isFinite(CR) ? 0 : CR, 2) + ' %',
			epc: MainFunctions.formatNumber(isNaN(epc) ? 0 : epc, 2) + ' р.',

			allActions: MainFunctions.formatNumber(
				(actionsParsed.A || []).length +
					(actionsParsed.P || []).length +
					(actionsParsed.D || []).length,
				0,
			),
			approvedActions: MainFunctions.formatNumber((actionsParsed.A || []).length, 0),
			holdActions: MainFunctions.formatNumber((actionsParsed.P || []).length, 0),
			declinedActions: MainFunctions.formatNumber((actionsParsed.D || []).length, 0),
			trashActions: MainFunctions.formatNumber((actionsParsed.T || []).length, 0),

			allMoney: MainFunctions.formatNumber(allMoney, 0) + ' р',
			approvedMoney: MainFunctions.formatNumber(approvedMoney, 0) + ' р',
			holdMoney: MainFunctions.formatNumber(holdMoney, 0) + ' р',
			declinedMoney: MainFunctions.formatNumber(declinedMoney, 0) + ' р',
			validApprove:
				MainFunctions.formatNumber((actionsParsed.A || []).length, 2) +
				' (' +
				MainFunctions.formatNumber(isNaN(validApprove) ? 0 : validApprove, 2) +
				' %)',
			statusApprove:
				MainFunctions.formatNumber((actionsParsed.A || []).length, 2) +
				' (' +
				MainFunctions.formatNumber(isNaN(statusApprove) ? 0 : statusApprove, 2) +
				' %)',
		});
	}
	return campaigns;
});

class CampaignsStats extends React.Component {
	getNoDataText = memoize((loading, text) => {
		if (loading) {
			return (
				<div className="pageOverlay">
					<i className="fa fa-spinner css--animate" />
				</div>
			);
		} else return text;
	});
	render() {
		let campaigns = parseCampaigns(this.props.campaigns.data);
		const noDataText = this.getNoDataText(
			this.props.campaigns.loading,
			this.props.t('Нет данных'),
		);
		return (
			<React.Fragment>
				{this.props.campaigns.loading && (
					<div className="pageOverlay">
						<i className="fa fa-spinner css--animate" />
					</div>
				)}
				<CampaignsStatsTable
					t={this.props.t}
					noDataText={noDataText}
					campaigns={campaigns}
				/>
			</React.Fragment>
		);
	}
}

const mapStateToProps = ({ apiRequestStore }) => ({
	campaigns: apiRequestStore.affiliateStats.campaigns,
});

export default connect(mapStateToProps, null)(CampaignsStats);
