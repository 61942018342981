import React from 'react';
import memoize from 'memoize-one';
import _ from 'underscore';
import StatsBlock from './StatsBlock';
import ChartBlock from './ChartBlock';
import SearchBlock from './SearchBlock';

export default class Content extends React.PureComponent {
	constructor(props) {
		super(props);

		this.categories = {
			day: [
				'00 часов',
				'01 час',
				'02 часа',
				'03 часа',
				'04 часа',
				'05 часов',
				'06 часов',
				'07 часов',
				'08 часов',
				'09 часов',
				'10 часов',
				'11 часов',
				'12 часов',
				'13 часов',
				'14 часов',
				'15 часов',
				'16 часов',
				'17 часов',
				'18 часов',
				'19 часов',
				'20 часов',
				'21 час',
				'22 часа',
				'23 часа',
			],
			week: [
				'Понедельник',
				'Вторник',
				'Среда',
				'Четверг',
				'Пятница',
				'Суббота',
				'Воскресенье',
			],
			monthDays: this.getMonthNames(new Date()),
			month: this.getMonth(new Date()),
			year: this.getYear(),
		};

		this.overallStatPeriod = 'day';
		this.newOverallStatPeriod = this.overallStatPeriod;
		this.state = {
			chartConfig: {
				chart: {
					renderTo: 'container',
					backgroundColor: 'transparent',
					type: 'line',
				},
				xAxis: {
					gridLineWidth: 1,
					categories: this.getCategories(),
				},
				yAxis: {
					gridLineWidth: 1,
				},
				legend: {
					enabled: true,
				},
				series: [],
			},
		};
	}

	getYear = () => {
		const startMonth = new Date(this.props.filter.start).getFullYear();
		const endMonth = new Date(this.props.filter.end).getFullYear();
		const result = [];
		for (let i = startMonth; i <= endMonth; i++) {
			result.push(i);
		}
		return result;
	};

	getMonth = () => {
		const month = [
			'январь',
			'февраль',
			'март',
			'апрель',
			'май',
			'июнь',
			'июль',
			'август',
			'сентябрь',
			'октябрь',
			'ноябрь',
			'декабрь',
		];
		let result = [];
		const startMonth = new Date(this.props.filter.start).getMonth();
		const endMonth = new Date(this.props.filter.end).getMonth();
		if (endMonth > startMonth) {
			result = month.slice(startMonth, endMonth + 1);
		} else {
			result = month.slice(startMonth, 12);
			result = result.concat(month.slice(0, endMonth + 1));
		}
		return result;
	};

	getMonthNames = (fromDate) => {
		var monthNames = [
			'января',
			'февраля',
			'марта',
			'апреля',
			'мая',
			'июня',
			'июля',
			'августа',
			'сентября',
			'октября',
			'ноября',
			'декабря',
		];
		var monthArray = [];
		const d = new Date(fromDate);
		const date = new Date(d);
		date.setDate(0);
		const numberDate = date.getDate();

		let monthName = monthNames.slice(d.getMonth() - 1)[0];
		for (let day = d.getDate() + 1; day <= numberDate; day++) {
			monthArray.push(day + ' ' + monthName);
		}

		monthName = monthNames[d.getMonth()];
		for (let day = 1; day <= d.getDate(); day++) {
			monthArray.push(day + ' ' + monthName);
		}

		return monthArray;
	};

	getCategories = () => {
		switch (this.props.filter.selectedPeriod) {
			case 'yesterday':
			case 'today':
				return this.categories.day;
			case 'last week':
			case 'current week':
			case 'week':
				return this.categories.week;
			case 'current month':
			case 'last month':
			case 'month':
				return this.getMonthNames(this.props.filter.end);
			case 'period':
			default:
				const startDate = new Date(this.props.filter.start);
				startDate.setHours(0, 0, 0, 0);
				const endDate = new Date(this.props.filter.end);
				endDate.setHours(23, 59, 59, 999);
				const diffTime = endDate.getTime() - startDate.getTime();
				if (diffTime <= 3600000 * 24) {
					return this.categories.day;
				} else if (diffTime <= 3600000 * 24 * 7) {
					return this.categories.week;
				} else if (diffTime <= 3600000 * 24 * 31) {
					return this.getMonthNames(this.props.filter.end);
				} else if (diffTime <= 3600000 * 24 * 365) {
					return this.getMonth();
				} else {
					return this.getYear();
				}
		}
	};

	getTimeRange = (date) => {
		function getPeriodMonth(date, fromDate) {
			const currentDate = new Date(fromDate);
			let temp = new Date(currentDate);
			temp.setDate(0);
			const numberDate = temp.getDate();
			let timeRange = date.getDate() - 1; //т.к. у getDate возвращвет числа от 1 до 31, а не от 0 до 30
			timeRange -= currentDate.getDate();
			if (timeRange < 0) timeRange += numberDate;
			return timeRange;
		}

		switch (this.props.filter.selectedPeriod) {
			case 'yesterday':
			case 'today':
				return date.getHours();
			case 'last week':
			case 'current week':
			case 'week':
				return date.getDay() - 1;
			case 'current month':
			case 'last month':
			case 'month':
				return getPeriodMonth(date, new Date(this.props.filter.end));
			case 'period':
			default:
				const startDate = new Date(this.props.filter.start);
				startDate.setHours(0, 0, 0, 0);
				const endDate = new Date(this.props.filter.end);
				endDate.setHours(23, 59, 59, 999);
				const diffTime = endDate.getTime() - startDate.getTime();
				if (diffTime <= 3600000 * 24) {
					return date.getHours();
				} else if (diffTime <= 3600000 * 24 * 7) {
					return date.getDay() - 1;
				} else if (diffTime <= 3600000 * 24 * 31) {
					return getPeriodMonth(date, new Date(this.props.filter.end));
				} else if (diffTime <= 3600000 * 24 * 365) {
					let result = date.getMonth() - new Date(this.props.filter.start).getMonth();
					if (result < 0) result += 12;
					return result;
				} else {
					return date.getFullYear() - startDate.getFullYear();
				}
		}
	};

	recalculateChart = memoize((chartPrepare) => {
		let transactionParsed = [];

		const statuses = this.props.statuses.data.length
			? this.props.statuses.data
			: [{ name: '', description: '' }];

		const groupedByStatus = _.groupBy(
			chartPrepare,
			(action) => action.statusesFlow[action.statusesFlow.length - 1].status.name,
		);
		const chartData = _.mapObject(groupedByStatus, (actions) =>
			_.countBy(actions, (action) => this.getTimeRange(new Date(action.created))),
		);
		const categories = this.getCategories();

		for (let key = 0; key < statuses.length; key++) {
			const name = statuses[key].name;
			const description = statuses[key].description;

			let data = new Array(categories.length);
			for (let i = 0; i < data.length; i++) {
				data[i] = chartData[name] ? (chartData[name][i] ? chartData[name][i] : 0) : 0;
			}
			console.log(chartData);
			transactionParsed.push({
				name: description,
				data: data,
				type: 'area',
				fillColor: {
					linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
					stops: [
						[0, 'rgba(59, 189, 168, .2)'],
						[1, 'rgba(59, 189, 168, 0)'],
					],
				},
				lineWidth: '1',
				marker: {
					symbol: 'circle',
				},
			});
		}
		const chartConfig = {
			chart: {
				renderTo: 'container',
				backgroundColor: 'transparent',
				type: 'line',
			},
			xAxis: {
				gridLineWidth: 1,
				categories: categories,
			},
			yAxis: {
				gridLineWidth: 1,
			},
			legend: {
				enabled: true,
			},
			series: transactionParsed,
		};
		return chartConfig;
	});

	componentDidMount() {
		this.props.getStatuses();
	}

	render() {
		const { t, statuses, stats, access, offers, affiliates, geos } = this.props;
		const loading = statuses.loading || affiliates.loading || geos.loading || stats.loading;
		const chartConfig = this.recalculateChart(this.props.stats.data);
		return (
			<section className="content">
				{loading ? (
					<div className="pageOverlay">
						<i className="fa fa-spinner css--animate" />
					</div>
				) : null}

				<SearchBlock
					access={access}
					t={this.props.t}
					offers={offers.data}
					affiliates={affiliates.data}
					geos={geos.data}
					getAllOffers={this.props.getAllOffers}
					getAffiliatesUsed={this.props.getAffiliatesUsed}
					getAllGeos={this.props.getAllGeos}
					getStatsOverall={this.props.getStatsOverall}
					filter={this.props.filter}
					setFilter={this.props.setFilter}
					clearFilter={this.props.clearFilter}
				/>

				<StatsBlock t={t} stats={this.props.stats.data} />

				<ChartBlock t={t} chartConfig={chartConfig} />
			</section>
		);
	}
}
