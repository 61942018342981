import React from 'react';
import _ from 'underscore';
import memoize from 'memoize-one';
import { TableHeaderColumn, BootstrapTable } from 'react-bootstrap-table';

import MainFunctions from 'mainLibs/MainFunctions';

const parseLandings = memoize((data) => {
	var clicks = data.clicks || [],
		sales = data.sales || [];
	var preLandings = [];
	if (data.preLandings)
		data.preLandings.forEach((landing) => {
			var temp = {
				id: landing.id,
				landing: landing.url,
				clicks: parseInt((_.findWhere(clicks, { landingid: landing.id }) || {}).cnt || 0),
				commissions: _.reduce(
					_.where(sales, { landingid: landing.id }) || [],
					(memo, elem) => memo + parseInt(elem.cnt),
					0,
				),
				A: (_.findWhere(sales, { landingid: landing.id, rstatus: 'A' }) || {}).cnt || 0,
				P: (_.findWhere(sales, { landingid: landing.id, rstatus: 'P' }) || {}).cnt || 0,
				D: (_.findWhere(sales, { landingid: landing.id, rstatus: 'D' }) || {}).cnt || 0,
				T: (_.findWhere(sales, { landingid: landing.id, rstatus: 'T' }) || {}).cnt || 0,
				commA:
					(_.findWhere(sales, { landingid: landing.id, rstatus: 'A' }) || {}).comm || 0,
				commP:
					(_.findWhere(sales, { landingid: landing.id, rstatus: 'P' }) || {}).comm || 0,
				commD:
					(_.findWhere(sales, { landingid: landing.id, rstatus: 'D' }) || {}).comm || 0,
				commT:
					(_.findWhere(sales, { landingid: landing.id, rstatus: 'T' }) || {}).comm || 0,
			};
			temp.conversion = temp.commissions / temp.clicks;
			temp.approve = temp.A / temp.commissions;
			temp.trash = temp.T / temp.commissions;
			temp.epc = temp.commA / temp.clicks;
			preLandings.push(temp);
		});
	return preLandings;
});

export default class FilterBlock extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			boxCollapse: false,
		};
	}

	toggleBox = (e) => {
		e.preventDefault();
		this.setState((prevState) => {
			return {
				boxCollapse: !prevState.boxCollapse,
			};
		});
	};

	getNoDataText = memoize((loading, text) => {
		if (loading) {
			return (
				<div className="pageOverlay">
					<i className="fa fa-spinner css--animate" />
				</div>
			);
		} else return text;
	});

	moneyFormat(cell, row) {
		return MainFunctions.formatNumber(cell, 2) + " <i class='fa fa-usd'/>";
	}

	numberFormat(cell, row) {
		return MainFunctions.formatNumber(cell, 2);
	}

	percentageFormat(cell, row) {
		return MainFunctions.formatNumber(cell * 100, 2) + '%';
	}

	render() {
		const { t } = this.props;
		const { loading, data } = this.props.preLandings;
		const preLandings = parseLandings(data);
		const noDataText = this.getNoDataText(loading, t('Нет данных'));
		return (
			<div className={'box box-primary' + (this.state.boxCollapse ? ' collapsed-box' : '')}>
				<div className="box-header with-border">
					<h3 className="box-title">{t('Статистика по выбранным фильтрам')}</h3>
					<div className="box-tools pull-right">
						<button className="btn btn-box-tool" onClick={this.toggleBox}>
							<i
								className={
									'fa ' + (this.state.boxCollapse ? 'fa-plus' : 'fa-minus')
								}
							/>
						</button>
					</div>
				</div>

				<div className="box-body">
					<BootstrapTable
						data={preLandings}
						striped={true}
						hover={true}
						condensed={true}
						options={{ noDataText }}
					>
						<TableHeaderColumn dataField="id" isKey={true} dataAlign="center">
							{t('Id')}
						</TableHeaderColumn>

						<TableHeaderColumn dataField="landing" dataAlign="center">
							{t('Лендинг')}
						</TableHeaderColumn>

						<TableHeaderColumn
							dataField="clicks"
							dataAlign="center"
							dataFormat={this.numberFormat.bind(this)}
						>
							{t('Клики')}
						</TableHeaderColumn>

						<TableHeaderColumn
							dataField="commissions"
							dataAlign="center"
							dataFormat={this.numberFormat.bind(this)}
						>
							{t('Комиссии')}
						</TableHeaderColumn>

						<TableHeaderColumn
							dataField="conversion"
							dataAlign="center"
							dataFormat={this.percentageFormat.bind(this)}
						>
							{t('Конверсия')}
						</TableHeaderColumn>

						<TableHeaderColumn
							dataField="approve"
							dataAlign="center"
							dataFormat={this.percentageFormat.bind(this)}
						>
							{t('Апрув')}
						</TableHeaderColumn>

						<TableHeaderColumn
							dataField="trash"
							dataAlign="center"
							dataFormat={this.percentageFormat.bind(this)}
						>
							{t('Треш')}
						</TableHeaderColumn>

						<TableHeaderColumn
							dataField="epc"
							dataAlign="center"
							dataFormat={this.moneyFormat.bind(this)}
						>
							{t('EPC')}
						</TableHeaderColumn>

						<TableHeaderColumn
							dataField="commA"
							dataAlign="center"
							dataFormat={this.moneyFormat.bind(this)}
						>
							{t('Одобрено')}
						</TableHeaderColumn>

						<TableHeaderColumn
							dataField="commP"
							dataAlign="center"
							dataFormat={this.moneyFormat.bind(this)}
						>
							{t('Холд')}
						</TableHeaderColumn>

						<TableHeaderColumn
							dataField="commD"
							dataAlign="center"
							dataFormat={this.moneyFormat.bind(this)}
						>
							{t('Отклонено')}
						</TableHeaderColumn>

						<TableHeaderColumn
							dataField="commT"
							dataAlign="center"
							dataFormat={this.moneyFormat.bind(this)}
						>
							{t('Треш')}
						</TableHeaderColumn>
					</BootstrapTable>
				</div>
			</div>
		);
	}
}
