const colors = {
	grayBase: '#000',
	grayDarker: '#262626',
	grayDark: '#2d2d2d',
	gray: '#383838',
	grayLight: '#444444',
	grayLighter: '#5c5c5c',

	brandWhite: 'white',

	brandPrimary: '#2c97de',
	brandSuccess: '#84b547',
	brandInfo: '#2dbda8',
	brandWarning: '#e76d3b',
	brandDanger: '#cc3e4a',

	brandCerulean: '#00A3E3',
	brandCuriousBlue: '#2986c7',
	brandEndaveour: '#0055a3',
	brandMinsk: '#362d88',
	brandEminence: '#742787',
	brandVioletEggplant: '#aa1985',
	brandMintGreen: '#72ff96',
	brandAquamarine: '#62ffe0',
	brandMalibu: '#6abfff',
	brandDodgerBlue: '#5e6cff',
	brandHeliotrope: '#a26bff',
	brandPerfume: '#f57ffe',

	brandSwamp: '#222D33',
	brandCaper: '#AECA89',
	brandChelseaCucumber: '#88B152',
	brandGreenDarker: '#2b2f26',
};

export default colors;
