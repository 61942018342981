import React from 'react';
import { Button } from 'react-bootstrap';
import { TableHeaderColumn, BootstrapTable } from 'react-bootstrap-table';

export default class ContentOffers extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			offset: 0,
			more: true,
			boxCollapse: false,
		};
	}

	loadPartners() {
		this.props.getPartners();

		// Не найден роутинг на сервере
		// var self = this;
		// ServerConnection.sendRequest('partners/min/', 'POST', null, [], (answer)=> {
		//     if (!answer.status) {
		//         AlertActions.show(answer.message, "danger");
		//     } else {
		//         var affiliates = answer.data.affiliates, payout = answer.data.payout;
		//         self.setState({
		//             partners: _.map(affiliates, (affiliate)=>_.extend(affiliate, _.findWhere(payout, {userid: affiliate.id}))),
		//             more: false
		//         });
		//     }
		// })
	}

	componentDidMount() {
		this.loadPartners();
	}

	toggleBox = (e) => {
		e.preventDefault();
		this.setState((prevState) => {
			return {
				boxCollapse: !prevState.boxCollapse,
			};
		});
	};

	loadMore() {
		this.setState({ offset: this.state.offset + 10 }, this.loadPartners);
	}

	payAffiliate(id) {
		// Не найден роутинг на сервере
		// ServerConnection.sendRequest('partners/pay/', 'POST', JSON.stringify({id}), [], (answer)=> {
		//     if (!answer.status) {
		//         AlertActions.show(answer.message, "danger");
		//     } else {
		//         AlertActions.show("Успешно оплачено!", "success");
		//     }
		// })
	}

	enterPanel(id) {
		document.cookie = 'uid=' + id + ';path=/;';
		this.props.history.push('/panel');
	}

	formatActions(cell, row) {
		return (
			<p>
				<p>
					<Button onClick={this.payAffiliate.bind(this, row.id)}>
						{this.props.t('Оплатить')}
					</Button>
				</p>
				<p>
					<Button onClick={this.enterPanel.bind(this, row.id)}>
						{this.props.t('Войти в кабинет')}
					</Button>
				</p>
			</p>
		);
	}

	render() {
		const { data } = this.props.partners;
		const { t } = this.props;
		return (
			<section className="content offers">
				<div
					className={'box box-primary' + (this.state.boxCollapse ? ' collapsed-box' : '')}
				>
					<div className="box-header with-border">
						<h3 className="box-title">{t('Партнеры')}</h3>
						<div className="box-tools pull-right">
							<button className="btn btn-box-tool" onClick={this.toggleBox}>
								<i
									className={
										'fa ' + (this.state.boxCollapse ? 'fa-plus' : 'fa-minus')
									}
								/>
							</button>
						</div>
					</div>

					<div className="box-body">
						<BootstrapTable
							data={data}
							striped={true}
							hover={true}
							condensed={true}
							pagination={true}
						>
							<TableHeaderColumn dataField="id" isKey={true} dataAlign="center">
								{t('ID партнера')}
							</TableHeaderColumn>

							<TableHeaderColumn
								dataField="firstname"
								dataAlign="center"
								dataSort={true}
							>
								{t('Имя')}
							</TableHeaderColumn>

							<TableHeaderColumn
								dataField="lastname"
								dataAlign="center"
								dataSort={true}
							>
								{t('Фамилия')}
							</TableHeaderColumn>

							<TableHeaderColumn
								dataField="email"
								dataAlign="center"
								dataSort={true}
								filter={{
									type: 'TextFilter',
									placeholder: 'Введите email',
								}}
							>
								{t('Email')}
							</TableHeaderColumn>

							<TableHeaderColumn dataField="phone" dataAlign="center" dataSort={true}>
								{t('Телефон')}
							</TableHeaderColumn>

							<TableHeaderColumn dataField="registered" dataAlign="center">
								{t('Дата регистрации')}
							</TableHeaderColumn>

							<TableHeaderColumn dataField="summ" dataAlign="center" dataSort={true}>
								{t('Сумма к выплате')}
							</TableHeaderColumn>

							<TableHeaderColumn
								dataField="status"
								dataAlign="center"
								dataFormat={this.formatActions.bind(this)}
							>
								{t('Действия')}
							</TableHeaderColumn>
						</BootstrapTable>
						{this.state.more ? (
							<Button onClick={this.loadMore.bind(this)}>
								{t('Загрузить еще 10')}
							</Button>
						) : null}
					</div>
				</div>
			</section>
		);
	}
}
