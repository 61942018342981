import React from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';

import SelectForm from './SelectForm';
import DateForm from 'components/service/DateForm';

export default class BoxBody extends React.PureComponent {
	setFilter = (data) => {
		if (this.props.pre) {
			this.props.setFilter('prePayments', data);
		} else {
			this.props.setFilter('payments', data);
		}
	};

	clearFilter = () => {
		if (this.props.pre) {
			this.props.clearFilter('prePayments');
		} else {
			this.props.clearFilter('payments');
		}
	};

	componentDidMount() {
		this.loadPayments();
	}

	loadPayments = () => {
		const { collapsed, selectedPeriod, ...otherFilter } = this.props.filter;
		if (this.props.pre) {
			this.props.getAllPayments(otherFilter, true);
		} else {
			this.props.getAllPayments(otherFilter, false);
		}
	};

	changeFilter = (val, name) => {
		var filter = { ...this.props.filter };
		//filter[name] = val.target ? val.target.value : val;
		filter[name] = val;
		this.setFilter(filter);
	};

	changeFilterPeriod = ({ start, end, selectedPeriod }) => {
		var filter = { ...this.props.filter };
		filter.start = start;
		filter.end = end;
		filter.selectedPeriod = selectedPeriod;
		this.setFilter(filter);
	};

	search = (e) => {
		e.preventDefault();
		e.stopPropagation();
		this.loadPayments();
	};

	// getReactSelectOptions = memoize(
	//     allSelectOptions => {
	//         const emailsSelectOptions = []

	//         const emails = Array.isArray(allSelectOptions) ? allSelectOptions : [];

	//         for (let i = 0; i < emails.length; i++) {
	//             emailsSelectOptions.push({
	//                 value: emails[i],
	//                 label: emails[i]
	//             })
	//         }

	//         return emailsSelectOptions
	//     }
	// );

	render() {
		const { t, emails } = this.props;
		//const emailsSelectOptions = this.getReactSelectOptions(emails);
		return (
			<div className="box-body">
				<Form onSubmit={this.search} className="semiInline">
					<Row>
						<Col xs={12} sm={6} md={8} lg={6}>
							<DateForm
								label={t('Дата')}
								startValue={this.props.filter.start}
								endValue={this.props.filter.end}
								selectedPeriod={this.props.filter.selectedPeriod}
								changeInfo={this.changeFilterPeriod}
							/>
						</Col>

						<Col xs={12} sm={6} md={4} lg={6}>
							<SelectForm
								label={t('Пользователи')}
								value={this.props.filter.email}
								defaultValue={t('Все пользователи')}
								changeInfo={this.changeFilter}
								name={'email'}
								options={emails}
							/>
						</Col>
					</Row>
					<Button type="submit" bsStyle="info">
						{t('Отобразить')}
					</Button>{' '}
					<Button bsStyle="danger" onClick={this.clearFilter}>
						{t('Сбросить')}
					</Button>
				</Form>
			</div>
		);
	}
}
