import React from 'react';
import memoize from 'memoize-one';
import _ from 'underscore';
import SelectForm from './SelectForm';
import SelectMerchantId from '../SelectMerchantId';
import MultiSelectForm from './MultiSelectForm';

import { Col, Button, ControlLabel, FormGroup, Form, Checkbox } from 'react-bootstrap';

class CheckedForm extends React.PureComponent {
	render() {
		const { t, changeFilterStatus, active, noActive, whiteActive, whiteNoActive } = this.props;
		return (
			<FormGroup>
				<Col componentClass={ControlLabel} sm={2}></Col>
				<Col sm={10}>
					<Checkbox
						inline
						checked={active}
						onChange={(e) => changeFilterStatus(e, 'active')}
					>
						{t('Трафик')}
					</Checkbox>
					<Checkbox
						inline
						checked={noActive}
						onChange={(e) => changeFilterStatus(e, 'noActive')}
					>
						{t('Трафик (отключенные)')}
					</Checkbox>
					<Checkbox
						inline
						checked={whiteActive}
						onChange={(e) => changeFilterStatus(e, 'whiteActive')}
					>
						{t('White (активные)')}
					</Checkbox>
					<Checkbox
						inline
						checked={whiteNoActive}
						onChange={(e) => changeFilterStatus(e, 'whiteNoActive')}
					>
						{t('White (отключенные)')}
					</Checkbox>
				</Col>
			</FormGroup>
		);
	}
}

export default class SearchBlock extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			filter: { ...props.filter },
			offersName: [],
			isLoading: false,
			inputName: '',
		};
	}

	componentDidMount() {
		this.loadRequests();
		this.props.getCategories();
		this.props.getOffersName();
	}

	componentDidUpdate(prevProps, prevState) {
		// if(prevProps.offersName !== this.props.offersName)
		//     this.getOffersName(this.props.offersName);
	}

	setFilter = (data) => this.props.setFilter('offers', data);
	clearFilter = () => this.props.clearFilter('offers');

	changeFilter = (event, fieldName) => {
		var filter = { ...this.props.filter };
		filter[fieldName] = event ? (event.target ? event.target.value : event) : null;
		this.setState({ offersName: [] }, () => this.setFilter(filter));
	};

	changeMerchantId = (value) => {
		var filter = { ...this.props.filter };
		filter.merchantId = value;
		this.setState({ offersName: [] }, () => this.setFilter(filter));
	};

	// changeName = (value) => {
	//     if(this.state.inputName.length < 3)
	//         if(value.length === 3) {
	//             const { name, categories, ...otherfilter } = this.props.filter;
	//             const data = {
	//                 name: value,
	//                 ...otherfilter
	//             }
	//             this.setState(
	//                 prevState => {
	//                     return {
	//                         isLoading: true
	//                     }
	//                 },
	//                 ()=> this.props.getOffersName(
	//                     data,
	//                     () => this.setState( prevState => {
	//                         return {
	//                             isLoading: false
	//                         }
	//                     })
	//                 )
	//             );
	//         }
	//     if(value.length < 3)
	//         this.clearOffersName();
	//     this.setState({inputName: value});
	// };

	clearOffersName = () => {
		if (this.state.offersName.length) {
			this.setState({ offersName: [] });
		}
	};

	changeFilterStatus = (event, fieldName) => {
		var filter = { ...this.props.filter };
		filter[fieldName] = !filter[fieldName];
		this.setFilter(filter);
	};

	loadRequests = (e) => {
		if (e) {
			e.stopPropagation();
			e.preventDefault();
		}
		const data = this.getFilter();
		this.props.getAllOffers(data);
	};
	getFilter = () => {
		const { categories, ...otherfilter } = this.props.filter;
		const data = {
			categories: _.pluck(categories, 'value'),
			...otherfilter,
		};
		return data;
	};

	parseCategory = memoize((categories) => {
		return categories.map((category) => ({
			value: category._id,
			label: category.name,
		}));
	});

	render() {
		const { t, access } = this.props;
		const categories = this.parseCategory(this.props.categories || []);
		//const offersNameSelectOptions = this.getOffersName(offersName);
		return (
			<div className={'box box-body'} style={{ borderTop: '0px' }}>
				<Form horizontal onSubmit={this.loadRequests}>
					<SelectForm
						placeholder={t('Название оффера...')}
						value={this.props.filter.name}
						label={t('Название оффера')}
						changeInfo={this.changeFilter}
						name={'name'}
						options={this.props.offersName}
						clearOptions={this.clearOffersName}
						isLoading={this.state.isLoading}
					/>

					<MultiSelectForm
						value={this.props.filter.categories}
						label={t('Категория товара')}
						placeholder={t('Категории')}
						changeInfo={this.changeFilter}
						name={'categories'}
						options={categories}
					/>

					{access.adminOnly && (
						<FormGroup>
							<Col componentClass={ControlLabel} sm={2}>
								{t('Рекламодатель')}
							</Col>
							<Col sm={10}>
								<SelectMerchantId
									defaultStyle={true}
									merchantId={this.props.filter.merchantId}
									changeInfo={this.changeMerchantId}
								/>
							</Col>
						</FormGroup>
					)}

					{access.adminOnly && (
						<CheckedForm
							t={t}
							changeFilterStatus={this.changeFilterStatus}
							active={this.props.filter.active}
							noActive={this.props.filter.noActive}
							whiteActive={this.props.filter.whiteActive}
							whiteNoActive={this.props.filter.whiteNoActive}
						/>
					)}

					<FormGroup>
						<Col smOffset={2} sm={10}>
							<Button type="submit" bsStyle="danger">
								{t('Отобразить')}
							</Button>{' '}
							<Button bsStyle="danger" onClick={this.clearFilter}>
								{t('Сбросить')}
							</Button>
						</Col>
					</FormGroup>
				</Form>
			</div>
		);
	}
}
