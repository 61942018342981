import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import { getStatsDaily } from 'store/actions/apiRequestActions/stats';
import { getAllOffers } from 'store/actions/apiRequestActions/offers';
import { getAllGeos } from 'store/actions/apiRequestActions/geos';
import { getAffiliatesUsed } from 'store/actions/apiRequestActions/affiliates';

import { setFilter, clearFilter } from 'store/actions/supportActions';
import ContentHeader from './ContentHeader';

import SearchBlock from './SearchBlock';
import TableBlock from './TableBlock';

class DailyStatsMerchant extends React.Component {
	static defaultProps = {
		affiliates: [],
		offers: [],
	};

	render() {
		const { offers, affiliates, geos, access } = this.props;
		return (
			<div className="content-wrapper">
				<ContentHeader t={this.props.t} />

				<section className="content offers">
					{/*loading ? (
                        <div className="pageOverlay">
                            <i className="fa fa-spinner css--animate"/>
                        </div>
                    ) : null
                */}

					<SearchBlock
						access={access}
						t={this.props.t}
						offers={offers.data}
						affiliates={affiliates.data}
						geos={geos.data}
						getAllOffers={this.props.getAllOffers}
						getAffiliatesUsed={this.props.getAffiliatesUsed}
						getAllGeos={this.props.getAllGeos}
						getStatsDaily={this.props.getStatsDaily}
						filter={this.props.filter}
						setFilter={this.props.setFilter}
						clearFilter={this.props.clearFilter}
					/>

					<TableBlock t={this.props.t} daily={this.props.daily} />
				</section>
			</div>
		);
	}
}

//Получаем modalStore из stora и записываем в props компонента
const mapStateToProps = ({ apiRequestStore, supportedStore }) => ({
	access: (apiRequestStore.auth.user || {}).access || {},
	daily: apiRequestStore.stats.daily,
	offers: apiRequestStore.offers.allOffers,
	geos: apiRequestStore.geos,
	affiliates: apiRequestStore.affiliates,
	filter: supportedStore.filters.dailyStats,
});

//Пробрасываем actions в props компонента
const mapActionCreators = {
	getStatsDaily,
	getAllOffers,
	getAffiliatesUsed,
	getAllGeos,
	setFilter,
	clearFilter,
};

DailyStatsMerchant = translate('Daily')(DailyStatsMerchant);
export default connect(mapStateToProps, mapActionCreators)(DailyStatsMerchant);
