const domain = window.location.hostname.split('.').slice(-2).join('.');
let apiBase = `${window.location.protocol}//partner.${domain}`;
let apiBaseLandings = `${window.location.protocol}//land.${domain}`;
if (process.env.NODE_ENV === 'development') {
	apiBase = '//localhost:8000';
	apiBase = 'https://partner.dzha.top';
	apiBaseLandings = '//localhost:9000';
	apiBaseLandings = 'https://land.dzha.top';
}
export const API_BASE = apiBase;
export const API_BASE_LANDINGS = apiBaseLandings;

// ========= API URL BEGIN =========
export const API_URL = API_BASE + '/api/adminPanel';
export const API_URL_V2 = API_BASE + '/api/v2/';
// ========== API URL END ==========

// ========= API URL MERCHANT REQUESTS BEGIN =========
export const API_URL_ACTIONS_TYPES = API_URL + '/actionTypes';
export const API_URL_GEOS = API_URL + '/geos';
export const API_URL_CRMS = API_URL + '/crms';
export const API_URL_PAYMENT = API_URL + '/payment';
export const API_URL_PAYMENTS = API_URL + '/payments';
export const API_URL_PAYMENT_STATUSES = API_URL + '/payments/statuses';
export const API_URL_PAYMENT_SYSTEMS = API_URL + '/payments/systems';

export const API_URL_USERS = API_URL + '/users';
export const API_URL_MERCHANTS = API_URL_USERS + '/merchants';
export const API_URL_AFFILIATES = API_URL_USERS + '/affiliates';
export const API_URL_AFFILIATES_USED = API_URL_USERS + '/affiliates/used';
export const API_URL_EMAILS = API_URL_USERS + '/emails';
export const API_URL_UPDATE_LANDINGS = API_URL_USERS + '/updateLandings';
export const API_URL_SET_ACCESS = API_URL_USERS + '/setAccess';

export const API_URL_CURRENCIES = API_URL + '/currencies';

// ========= API URL AFFILIATE STATS BEGIN =========
export const API_URL_STATS_AFFILIATE_DAILY = API_URL + '/stats/affiliate/daily';
export const API_URL_STATS_AFFILIATE_LANDINGS = API_URL + '/stats/affiliate/landings';
export const API_URL_STATS_AFFILIATE_PRELANDINGS = API_URL + '/stats/affiliate/prelandings';
export const API_URL_STATS_AFFILIATE_CAMPAIGNS = API_URL + '/stats/affiliate/campaigns';
export const API_URL_STATS_AFFILIATE_OFFERS = API_URL + '/stats/affiliate/offers';
export const API_URL_STATS_AFFILIATE_GEOS = API_URL + '/stats/affiliate/geos';
export const API_URL_STATS_AFFILIATE_SOURCES = API_URL + '/stats/affiliate/sources';
export const API_URL_STATS_AFFILIATE_REQUESTS = API_URL + '/stats/affiliate/requests';
export const API_URL_STATS_AFFILIATE_FILTERS = API_URL + '/stats/affiliate/filters';
// ========= API URL AFFILIATE STATS END =========

// ========= API URL OFFERS BEGIN =========
export const API_URL_OFFERS = API_URL + '/offers';
export const API_URL_CREATE_OFFER = API_URL_OFFERS + '/create';
export const API_URL_OFFER_CATEGORY = API_URL_OFFERS + '/category';
export const API_URL_OFFER_STATUS = API_URL + '/offerStatus';
export const API_URL_OFFERS_NAME = API_URL + '/offersName';
export const API_URL_OFFER_ACCESS = API_URL + '/offerAccess';
export const API_URL_OFFER_DUPLICATE = API_URL + '/duplicate';
export const API_URL_PWA_OPTIONS = API_URL_V2 + 'pwaoptions/';
// ========= API URL OFFERS END =========

// ========= API URL REQUEST BEGIN =========
export const API_URL_REQUEST = API_URL + '/request';
export const API_URL_REQUEST_OFFERS = API_URL_REQUEST + '/offers';
export const API_URL_REQUEST_TRACKLINKS = API_URL_REQUEST + '/trackLinks';
export const API_URL_REQUEST_IP = API_URL_REQUEST + '/ip';
export const API_URL_REQUEST_CITY = API_URL_REQUEST + '/citys';
export const API_URL_REQUEST_AFFILIATES = API_URL_REQUEST + '/affiliates';
export const API_URL_REQUEST_MERCHANTS = API_URL_REQUEST + '/merchants';
// ========= API URL REQUEST END =========

// ========= API URL ACTIONS BEGIN =========
export const API_URL_ACTIONS = API_URL + '/actions';
// ========= API URL ACTIONS END =========

// ========= API URL LANDINGS BEGIN =========
export const API_URL_GET_USERS_LANDINGS = API_URL + '/landings/users';
// ========= API URL LANDINGS END =========

// ========= API URL TICKETS BEGIN =========
export const API_URL_TICKET = API_URL + '/ticket';
export const API_URL_TICKETS = API_URL + '/tickets';
export const API_URL_TICKETS_FILTER = API_URL + '/tickets/filter';
// ========= API URL TICKETS END =========

export const API_URL_LANDINGS = API_URL + '/landings';
export const API_URL_STATUSES = API_URL + '/statuses';

export const API_URL_STATS_REQUESTS = API_URL + '/stats/request';
export const API_URL_STATS_OVERALL = API_URL + '/stats/overall';
export const API_URL_STATS_DAILY = API_URL + '/stats/daily';
export const API_URL_STATS_LANDINGS = API_URL + '/stats/landings';
export const API_URL_STATS_PRELANDINGS = API_URL + '/stats/preLandings';

export const API_URL_PARTNERS = API_URL + '/partners/min/';

// ========== API URL MERCHANT REQUESTS END ==========
