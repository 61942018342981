import React from 'react';
import { Route, Switch } from 'react-router-dom';

import App from './components/App';

import Profile from './components/pages/Profile';
import Settings from './components/pages/Settings';
import Campaigns from './components/pages/Campaigns';
import DeepCampaign from './components/pages/DeepCampaign';
import DailyStats from './components/pages/DailyStats';
import PreLandingStats from './components/pages/PreLandingStats';
import RequestsStats from './components/pages/RequestsStats';
import DeepRequest from './components/pages/DeepRequest';
import LandingStats from './components/pages/LandingStats';
import Partners from './components/pages/Partners';
import Affiliates from './components/pages/Affiliates';
import DeepAffiliates from './components/pages/DeepAffiliates';
import Merchants from './components/pages/Merchants';
import DeepMerchants from './components/pages/DeepMerchants';
import Request from './components/pages/Request';
import SupportTickets from './components/pages/SupportTickets';
import SupportRequests from './components/pages/SupportRequests';
import SupportFinance from './components/pages/SupportFinance';
import AddMerchant from './components/pages/AddMerchant';

const routes = (
	<Switch>
		<Route
			exact
			path="/"
			render={() => (
				<App>
					<Profile />
				</App>
			)}
		/>
		<Route
			exact
			path="/campaigns"
			render={() => (
				<App>
					<Campaigns />
				</App>
			)}
		/>
		<Route
			exact
			path="/campaigns/*"
			render={() => (
				<App>
					<DeepCampaign />
				</App>
			)}
		/>
		<Route
			exact
			path="/users/affiliates"
			render={() => (
				<App>
					<Affiliates />
				</App>
			)}
		/>
		<Route
			exact
			path="/users/merchants"
			render={() => (
				<App>
					<Merchants />
				</App>
			)}
		/>
		<Route
			exact
			path="/users/affiliates/:id"
			render={() => (
				<App>
					<DeepAffiliates />
				</App>
			)}
		/>
		<Route
			exact
			path="/users/merchants/:id"
			render={() => (
				<App>
					<DeepMerchants />
				</App>
			)}
		/>
		<Route
			exact
			path="/partners"
			render={() => (
				<App>
					<Partners />
				</App>
			)}
		/>
		<Route
			exact
			path="/stats/landings"
			render={() => (
				<App>
					<LandingStats />
				</App>
			)}
		/>
		<Route
			exact
			path="/stats/preLandings"
			render={() => (
				<App>
					<PreLandingStats />
				</App>
			)}
		/>
		<Route
			exact
			path="/stats/requests"
			render={() => (
				<App>
					<RequestsStats />
				</App>
			)}
		/>
		<Route
			exact
			path="/stats/daily"
			render={() => (
				<App>
					<DailyStats />
				</App>
			)}
		/>
		<Route
			exact
			path="/stats/requests/:id"
			render={() => (
				<App>
					<DeepRequest />
				</App>
			)}
		/>
		<Route
			exact
			path="/support/requests"
			render={() => (
				<App>
					<SupportRequests />
				</App>
			)}
		/>
		<Route
			exact
			path="/support/tickets"
			render={() => (
				<App>
					<SupportTickets />
				</App>
			)}
		/>
		<Route
			exact
			path="/support/finance"
			render={() => (
				<App>
					<SupportFinance />
				</App>
			)}
		/>
		<Route
			exact
			path="/request"
			render={() => (
				<App>
					<Request />
				</App>
			)}
		/>
		<Route
			exact
			path="/settings"
			render={() => (
				<App>
					<Settings />
				</App>
			)}
		/>
		<Route
			exact
			path="/addmerchant"
			render={() => (
				<App>
					<AddMerchant />
				</App>
			)}
		/>
	</Switch>
);

export default routes;
