import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Header from './service/Header.jsx';
import Alert from './service/Alert.jsx';
import Notification from './service/Notification';
import SideBar from './service/SideBar.jsx';
import Login from './pages/Login';
import Notify from 'notifications';
import { updateInfo } from 'store/actions/apiRequestActions/auth';
import { connectToSocket } from 'store/actions/socketActions';
const MountedSystemComponent = Notify.MountedSystemComponent;

class App extends React.Component {
	componentDidMount(prevProps) {
		// if (process.env.NODE_ENV !== 'development') {
		//     const access = this.props.auth.user ? this.props.auth.user.access || {} : {};
		//     if(!(access.adminOnly || access.white || access.support))
		//         window.location.href = '/';
		// }
		const updateInfo = () => {
			if (this.props.auth.authenticated) {
				this.props.updateInfo();
			}
		};
		updateInfo();
		this.updatedUser = setInterval(updateInfo, 60000);
		setTimeout(this.checkAccess, 10);
		this.props.connectToSocket();
	}

	componentDidUpdate(prevProps) {
		if (this.props.notifications === prevProps.notifications)
			document.querySelector('.wrapper').scrollTop = 0;
		this.checkAccess();
		this.props.connectToSocket();
	}

	componentWillUnmount() {
		clearInterval(this.updatedUser);
	}

	currentPage = '';
	currentAccess = null;

	checkAccess = () => {
		this.currentAccess = this.props.auth.user ? this.props.auth.user.access : null;
		if (this.currentAccess) {
			let currentPage = window.location.pathname;
			if (currentPage[currentPage.length - 1] !== '/') currentPage += '/';
			currentPage += '\n';
			if (currentPage !== this.currentPage) {
				this.currentPage = currentPage;
				const config = [
					{
						path: '/',
						access: ['adminOnly', 'white', 'support'],
					},
					{
						path: '/campaigns',
						access: ['adminOnly', 'white', 'support'],
					},
					{
						path: '/campaigns/*',
						access: ['adminOnly', 'white', 'support'],
					},
					{
						path: '/users/affiliates',
						access: ['adminOnly', 'support'],
					},
					{
						path: '/users/merchants',
						access: ['adminOnly', 'support'],
					},
					{
						path: '/users/affiliates/:id',
						access: ['adminOnly', 'support'],
					},
					{
						path: '/users/merchants/:id',
						access: ['adminOnly', 'support'],
					},
					{
						path: '/partners',
						access: ['adminOnly'],
					},
					{
						path: '/stats/landings',
						access: ['adminOnly', 'white'],
					},
					{
						path: '/stats/preLandings',
						access: ['adminOnly', 'white'],
					},
					{
						path: '/stats/requests',
						access: ['adminOnly', 'white'],
					},
					{
						path: '/stats/daily',
						access: ['adminOnly', 'white'],
					},
					{
						path: '/stats/requests/:id',
						access: ['adminOnly', 'white'],
					},
					{
						path: '/support/requests',
						access: ['adminOnly', 'support'],
					},
					{
						path: '/support/tickets',
						access: ['adminOnly', 'support'],
					},
					{
						path: '/support/finance',
						access: ['adminOnly', 'support'],
					},
					{
						path: '/request',
						access: ['adminOnly', 'white'],
					},
					{
						path: '/settings',
						access: ['adminOnly', 'white', 'support'],
					},
					{
						path: '/addmerchant',
						access: ['adminOnly'],
					},
				];
				let isAccess = false;
				for (let i = 0; i < config.length; i++) {
					const paths = config[i].path.split('/');
					let regExpPath = '/';
					for (let k = 0; k < paths.length; k++) {
						if (paths[k]) {
							if (paths[k] === '*' || paths[k] === ':id') {
								regExpPath += '.+';
							} else regExpPath += paths[k];
							if (k !== paths.length - 1) regExpPath += '/';
						}
					}
					regExpPath = new RegExp(regExpPath + '\n');
					if (regExpPath.test(currentPage)) {
						for (let j = 0; j < config[i].access.length; j++) {
							if (this.currentAccess[config[i].access[j]]) {
								isAccess = true;
								break;
							}
						}
						break;
					}
				}

				if (!isAccess) {
					Notify.removeAll();
					Notify.error({ title: this.props.t('Отказано в доступе') });
					this.props.push('/');
				}
			}
		}
	};

	render() {
		const access = (this.props.auth.user ? this.props.auth.user.access : {}) || {};
		return (
			<div className="wrapper">
				<Header />
				<SideBar
					page={window.location.pathname.substr(
						window.location.pathname.lastIndexOf('/') + 1,
					)}
				/>
				<Alert />
				{!access.white && (access.adminOnly || access.support) && <Notification />}
				{this.props.auth.authenticated ? this.props.children : <Login />}
				{<MountedSystemComponent />}
			</div>
		);
	}
}

const mapStateToProps = ({ apiRequestStore }) => ({
	auth: apiRequestStore.auth,
});

const mapActionCreators = {
	updateInfo,
	connectToSocket,
	push,
};

App = translate('App')(App);
export default connect(mapStateToProps, mapActionCreators)(App);
