/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import uuidv4 from 'uuid/v4';
import _ from 'underscore';
import memoize from 'memoize-one';
import { connect } from 'react-redux';
import { TableHeaderColumn, BootstrapTable } from 'react-bootstrap-table';
import { ButtonGroup, Button } from 'react-bootstrap';
import MainFunctions from 'mainLibs/MainFunctions';

// Изспользуем мемоизацию, чтобы не парсить данные при каждом рендеринге
const parseDaily = memoize((stats, period) => {
	if (!Object.keys(stats).length) return [];
	let daily = [],
		visits = _.groupBy(stats.visits, (visit) =>
			MainFunctions.dateFormat(new Date(visit.date), true),
		),
		actions = _.groupBy(stats.actions, (action) =>
			MainFunctions.dateFormat(new Date(action.created), true),
		);

	if (!Object.keys(visits).length) return [];
	if (!Object.keys(actions).length) return [];

	let startTimestamp = new Date(period.start),
		endTimestamp = new Date(period.end);

	for (
		endTimestamp;
		endTimestamp >= startTimestamp;
		endTimestamp.setDate(endTimestamp.getDate() - 1)
	) {
		let currentDate = MainFunctions.dateFormat(endTimestamp, true);
		let currentActions = actions[currentDate] || [];
		let currentVisits = visits[currentDate] || [];
		let currentActionsSorted = _.groupBy(currentActions, (action) => {
			return _.last(action.statusesFlow).status.name;
		});

		const validApprove =
			(((currentActionsSorted.Approved || []).length || 0) /
				((currentActionsSorted.Approved || []).length +
					(currentActionsSorted.Declined || []).length +
					(currentActionsSorted.Pending || []).length)) *
			100;
		const statusApprove =
			(((currentActionsSorted.Approved || []).length || 0) /
				((currentActionsSorted.Approved || []).length +
					(currentActionsSorted.Declined || []).length +
					(currentActionsSorted.Pending || []).length +
					(currentActionsSorted.Trash || []).length)) *
			100;

		let uniqClicks = _.groupBy(currentVisits, (visit) => {
			return visit.techInfo.ip;
		});
		let CR =
			(((currentActionsSorted.Approved || []).length +
				(currentActionsSorted.Declined || []).length +
				(currentActionsSorted.Pending || []).length) /
				((Object.keys(uniqClicks) || []).length || 0)) *
			100;
		let epc =
			_.reduce(
				currentActionsSorted.Approved || [],
				(memo, action) => memo + action.fixedPayout,
				0,
			) / ((Object.keys(uniqClicks) || []).length || 0);

		let approvedMoney = _.reduce(
			currentActionsSorted.Approved || [],
			(memo, action) => memo + action.fixedPayout,
			0,
		);
		let holdMoney = _.reduce(
			currentActionsSorted.Pending || [],
			(memo, action) => memo + action.fixedPayout,
			0,
		);
		let declinedMoney = _.reduce(
			currentActionsSorted.Declined || [],
			(memo, action) => memo + action.fixedPayout,
			0,
		);

		let allMoney = approvedMoney + holdMoney + declinedMoney;

		daily.push({
			date: currentDate,
			uniqClicks: MainFunctions.formatNumber(Object.keys(uniqClicks).length, 0),
			allClicks: MainFunctions.formatNumber(currentVisits.length || 0, 0),
			CR: MainFunctions.formatNumber(isNaN(CR) || !isFinite(CR) ? 0 : CR, 2) + ' %',
			epc: MainFunctions.formatNumber(isNaN(epc) ? 0 : epc, 2) + ' р.',

			// approve: (actionsParsed.A || []).length,
			allActions: MainFunctions.formatNumber(
				(currentActionsSorted.Approved || []).length +
					(currentActionsSorted.Pending || []).length +
					(currentActionsSorted.Declined || []).length,
				0,
			),
			approvedActions: MainFunctions.formatNumber(
				(currentActionsSorted.Approved || []).length,
				0,
			),
			holdActions: MainFunctions.formatNumber((currentActionsSorted.Pending || []).length, 0),
			declinedActions: MainFunctions.formatNumber(
				(currentActionsSorted.Declined || []).length,
				0,
			),
			trashActions: MainFunctions.formatNumber((currentActionsSorted.Trash || []).length, 0),

			allMoney: MainFunctions.formatNumber(allMoney, 0) + ' р',
			approvedMoney: MainFunctions.formatNumber(approvedMoney, 0) + ' р',
			holdMoney: MainFunctions.formatNumber(holdMoney, 0) + ' р',
			declinedMoney: MainFunctions.formatNumber(declinedMoney, 0) + ' р',
			validApprove:
				MainFunctions.formatNumber((currentActionsSorted.Approved || []).length, 2) +
				' (' +
				MainFunctions.formatNumber(isNaN(validApprove) ? 0 : validApprove, 2) +
				' %)',
			statusApprove:
				MainFunctions.formatNumber((currentActionsSorted.Approved || []).length, 2) +
				' (' +
				MainFunctions.formatNumber(isNaN(statusApprove) ? 0 : statusApprove, 2) +
				' %)',
		});
	}
	return daily;
});

class DailyStats extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			tableConfig: [
				{
					label: 'Траффик',
					columns: [
						{
							label: 'Клики',
							dataField: 'allClicks',
						},
						{
							label: 'Уники',
							dataField: 'uniqClicks',
						},
					],
					collapse: false,
				},
				{
					label: 'Коэффициенты',
					columns: [
						{
							label: 'CR',
							dataField: 'CR',
						},
						{
							label: 'EPC',
							dataField: 'epc',
						},
					],
					collapse: false,
				},
				{
					label: 'Конверсии',
					columns: [
						{
							label: 'Апрув',
							label2: '(valid)',
							dataField: 'validApprove',
						},
						{
							label: 'Апрув',
							label2: '(status)',
							dataField: 'statusApprove',
						},
						{
							label: 'Всего',
							dataField: 'allActions',
						},
						{
							label: 'Одобрено',
							dataField: 'approvedActions',
						},
						{
							label: 'Холд',
							dataField: 'holdActions',
						},
						{
							label: 'Отклонено',
							dataField: 'declinedActions',
						},
						{
							label: 'Трэш',
							dataField: 'trashActions',
						},
					],
					collapse: false,
				},
				{
					label: 'Финансы',
					columns: [
						{
							label: 'Всего',
							dataField: 'allMoney',
						},
						{
							label: 'Апрув',
							dataField: 'approvedMoney',
						},
						{
							label: 'Холд',
							dataField: 'holdMoney',
						},
						{
							label: 'Отклонено',
							dataField: 'declinedMoney',
						},
					],
					collapse: false,
				},
			],
		};
		this.tdStyle = { whiteSpace: 'normal' };
	}

	getNoDataText = memoize((loading, text) => {
		if (loading) {
			return (
				<div className="pageOverlay">
					<i className="fa fa-spinner css--animate" />
				</div>
			);
		} else return text;
	});

	collapseField = (field, collapsed) => {
		let currentTableConfig = this.state.tableConfig.slice();
		currentTableConfig[field].collapse = collapsed;
		this.setState({
			tableConfig: currentTableConfig,
		});
	};

	render() {
		const daily = parseDaily(this.props.daily.data, this.props.daily.period);
		const noDataText = this.getNoDataText(this.props.daily.loading, this.props.t('Нет данных'));
		const { t } = this.props;
		return (
			<React.Fragment>
				{this.props.daily.loading && (
					<div className="pageOverlay">
						<i className="fa fa-spinner css--animate" />
					</div>
				)}
				<BootstrapTable
					style={{ position: 'relative' }}
					data={daily}
					options={{ noDataText }}
					pagination
					bordered={false}
				>
					<TableHeaderColumn row="0" colSpan="1">
						<ButtonGroup>
							{this.state.tableConfig.map(
								(config, key) =>
									config.collapse && (
										<Button
											key={uuidv4()}
											bsSize="xsmall"
											onClick={() => this.collapseField(key, false)}
										>
											{config.label} <i className={'fa fa-plus'} />
										</Button>
									),
							)}
						</ButtonGroup>
					</TableHeaderColumn>

					<TableHeaderColumn
						row="1"
						dataAlign="center"
						dataField="date"
						tdStyle={this.tdStyle}
						colSpan="1"
						width={'80px'}
					>
						{t('Дата')}
					</TableHeaderColumn>

					<TableHeaderColumn row="1" dataField="date" isKey hidden>
						{t('Id')}
					</TableHeaderColumn>

					{this.state.tableConfig.map((config, key) => {
						if (!config.collapse) {
							const header = (
								<TableHeaderColumn
									key={'header_daily_stats' + config.label + key}
									row="0"
									dataAlign="center"
									colSpan={config.columns.length}
								>
									<a
										href="javascript:void(0)"
										onClick={() => this.collapseField(key, true)}
									>
										{t(config.label)} <i className={'fa fa-minus'} />
									</a>
								</TableHeaderColumn>
							);
							const children = config.columns.map((column, key) => (
								<TableHeaderColumn
									key={'column_daily_stats' + column.label + key}
									row="1"
									dataAlign="center"
									dataField={column.dataField}
									tdStyle={this.tdStyle}
									colSpan="1"
								>
									{t(column.label)}
									{column.label2 && <br />}
									{column.label2 && t(column.label2)}
								</TableHeaderColumn>
							));
							children.push(header);
							return children;
						}
						return null;
					})}
				</BootstrapTable>
			</React.Fragment>
		);
	}
}

const mapStateToProps = ({ apiRequestStore }) => ({
	daily: apiRequestStore.affiliateStats.daily,
});

export default connect(mapStateToProps, null)(DailyStats);
