/* eslint-disable no-useless-concat */
import { adminPanelRequestTypes, adminPanelApi } from '../../constants';

export function getStatsFiltersData(userId) {
	return {
		type: adminPanelRequestTypes.GET_STATS_MERCHANT_FILTERS,
		request: {
			method: 'get',
			url: adminPanelApi.API_URL_MERCHANTS + `/${userId}` + '/filters',
		},
	};
}

export function getStatsOverall(userId, params) {
	return {
		type: adminPanelRequestTypes.GET_STATS_MERCHANT_OVERALL,
		request: {
			method: 'get',
			url: adminPanelApi.API_URL_MERCHANTS + `/${userId}` + '/overall',
			params: params,
		},
	};
}

export function getStatsDaily(userId, data) {
	return {
		type: adminPanelRequestTypes.GET_STATS_MERCHANT_DAILY,
		request: {
			method: 'post',
			url: adminPanelApi.API_URL_MERCHANTS + `/${userId}` + '/daily',
			data: data,
		},
		period: {
			start: data.start,
			end: data.end,
		},
	};
}

export function getStatsRequests(userId, data) {
	return {
		type: adminPanelRequestTypes.GET_STATS_MERCHANT_REQUESTS,
		request: {
			method: 'post',
			url: adminPanelApi.API_URL_MERCHANTS + `/${userId}` + '/requests',
			data: data,
		},
	};
}
