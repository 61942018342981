import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import Switch from 'rc-switch';
import { Col, ControlLabel, FormGroup } from 'react-bootstrap';

export default class SwitchForm extends React.Component {
	static propTypes = {
		defaultValue: PropTypes.bool,
		value: PropTypes.bool,
		changeInfo: PropTypes.func.isRequired,
		name: PropTypes.string.isRequired,
	};
	shouldComponentUpdate(nextProps, nextState) {
		return (
			this.props.defaultValue !== nextProps.defaultValue ||
			this.props.value !== nextProps.value
		);
	}

	onСhangeOfferInfoClick = (value) => {
		this.props.changeInfo(value, this.props.name);
	};
	render() {
		const { value, label, defaultValue, required } = this.props;
		const currentValue = _.isNull(value) ? defaultValue : value;
		return (
			<FormGroup validationState={required ? 'success' : null}>
				<Col componentClass={ControlLabel} sm={2}>
					{label}
				</Col>
				<Col sm={10}>
					<Switch checked={currentValue} onChange={this.onСhangeOfferInfoClick} />
				</Col>
			</FormGroup>
		);
	}
}
