/* eslint-disable no-useless-concat */
import { adminPanelRequestTypes, adminPanelApi } from '../../constants';

// //github.com/axios/axios
export function getActions(actionId) {
	return {
		//тип определяющий обрабатывающий редюсер
		type: adminPanelRequestTypes.GET_ACTIONS,
		//объект request служит для конфигурации запроса axios
		request: {
			method: 'get',
			url: adminPanelApi.API_URL_ACTIONS + `/${actionId}`,
		},
	};
}

export function updateStatus(actionId, data) {
	return {
		type: adminPanelRequestTypes.GET_ACTIONS,
		request: {
			method: 'post',
			url: adminPanelApi.API_URL_ACTIONS + `/${actionId}` + '/status',
			data: data,
		},
	};
}

export function updateStatusMany(data, callback) {
	return {
		type: adminPanelRequestTypes.UPDATE_ACTIONS,
		request: {
			method: 'put',
			url: adminPanelApi.API_URL_ACTIONS,
			data: data,
		},
		actionsId: (data || {}).requests || [],
		callback,
	};
}

export function getAllActions(params) {
	return {
		type: adminPanelRequestTypes.GET_ALL_ACTIONS,
		request: {
			method: 'get',
			url: adminPanelApi.API_URL_ACTIONS,
			params: params,
		},
	};
}

export function addRequest(data, callback) {
	return {
		type: adminPanelRequestTypes.GREATE_ACTIONS,
		request: {
			method: 'post',
			url: adminPanelApi.API_URL_ACTIONS,
			data: data,
		},
		callback: callback,
	};
}
