import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { TableHeaderColumn, BootstrapTable } from 'react-bootstrap-table';
import _ from 'underscore';
import memoize from 'memoize-one';
import MainFunctions from 'mainLibs/MainFunctions';

export default class InfoBlock extends React.PureComponent {
	constructor(props) {
		super(props);
		this.tdStyle = { whiteSpace: 'normal' };
	}

	parceAction = memoize((parcesAction) => {
		const action = _.isObject(parcesAction) ? parcesAction : {};

		function getOffer(offer) {
			const correctOffer = _.isObject(offer) ? offer : {};
			return {
				name: correctOffer.name || '',
				logo: correctOffer.logo || '',
			};
		}

		function getTechInfo(techInfo) {
			const correctTechInfo = _.isObject(techInfo) ? techInfo : {};
			return {
				created: correctTechInfo.created || '',
				OS: correctTechInfo.OS || '',
				device: correctTechInfo.device || '',
				browser: correctTechInfo.browser || '',
				city: correctTechInfo.city || '',
				ip: correctTechInfo.ip || '',
			};
		}

		function getIcon(status) {
			switch (status) {
				case 'Approved':
					return { className: 'fa fa-3x fa-check-circle', color: '#99c262' };
				case 'Pending':
					return { className: 'fa fa-3x fa-spinner fa-spin', color: '#f8d347' };
				case 'Declined':
					return { className: 'fa fa-3x fa-times-circle', color: '#ff3232' };
				case 'Trash':
					return { className: 'fa fa-3x fa-trash', color: '#ff3232' };
				default:
					return { className: 'fa fa-3x fa-spinner fa-spin', color: '#f8d347' };
			}
		}

		const status = (_.last(action.statusesFlow) || {}).status;

		return {
			offer: getOffer(action.trackLink ? action.trackLink.offer : action.offer),
			techInfo: getTechInfo(action.techInfo),
			geo: action.geo || { name: '' },
			client: { name: '', phone: '', ...action.client },
			statusesFlow: action.statusesFlow || [],
			affiliate: action.affiliate || {},
			merchant: action.merchant || {},
			created: action.created || '',
			status: { name: '', description: '', ...status },
			icon: getIcon(status ? status.name : ''),
		};
	});

	render() {
		const { t, edited } = this.props;
		const action = this.parceAction(this.props.action);
		return (
			<div className="box-body offerItemBlock">
				<div className="offerItemOuter">
					<Row>
						<Col lg={3} md={4} sm={12} xs={12} className="offerItemLeft">
							<div className="offerImage">
								<img src={action.offer.logo} alt="" />
							</div>

							<h3>{action.offer.name}</h3>

							<h5 className="deepRequestIcons">
								<OverlayTrigger
									ref="trigger"
									placement="top"
									overlay={
										<Tooltip id="tooltipName" className="nameBreak">
											{action.status.description +
												' (' +
												action.status.name +
												')'}
										</Tooltip>
									}
								>
									<i
										className={action.icon.className}
										style={{ color: action.icon.color }}
									/>
								</OverlayTrigger>
							</h5>
						</Col>

						<Col lg={9} md={8} sm={12} xs={12}>
							<Col sm={12} xs={12} className="offerDescriptionRow">
								<Col xs={4}>{t('Имя клиента')}</Col>
								<Col xs={8}>{action.client.name}</Col>
							</Col>

							<Col sm={12} xs={12} className="offerDescriptionRow">
								<Col xs={4}>{t('Телефон клиента')}</Col>
								<Col xs={8}>{action.client.phone}</Col>
							</Col>

							<Col sm={12} xs={12} className="offerDescriptionRow">
								<Col xs={4}>{t('Партнер')}</Col>
								<Col xs={8}>
									{edited ? (
										<Link to={'/users/affiliates/' + action.affiliate._id}>
											{action.affiliate.username}
										</Link>
									) : (
										action.affiliate.username
									)}
								</Col>
							</Col>

							<Col sm={12} xs={12} className="offerDescriptionRow">
								<Col xs={4}>{t('Рекламодатель')}</Col>
								<Col xs={8}>
									{edited ? (
										<Link to={'/users/merchants/' + action.merchant._id}>
											{action.merchant.username}
										</Link>
									) : (
										action.merchant.username
									)}
								</Col>
							</Col>

							<Col sm={12} xs={12} className="offerDescriptionRow">
								<Col xs={4}>{t('Время добавления')}</Col>
								<Col xs={8}>{MainFunctions.dateFormat(action.created)}</Col>
							</Col>

							<Col sm={12} xs={12} className="offerDescriptionRow">
								<Col xs={4}>{t('Определенное ГЕО')}</Col>
								<Col xs={8}>{action.geo.name}</Col>
							</Col>

							<Col sm={12} xs={12} className="offerDescriptionRow">
								<Col xs={4}>{t('IP')}</Col>
								<Col xs={8}>{action.techInfo.ip}</Col>
							</Col>

							<Col sm={12} xs={12} className="offerDescriptionRow">
								<Col xs={4}>{t('Город')}</Col>
								<Col xs={8}>{action.techInfo.city}</Col>
							</Col>

							<Col sm={12} xs={12} className="offerDescriptionRow">
								<Col xs={4}>{t('ОС')}</Col>
								<Col xs={8}>{action.techInfo.OS}</Col>
							</Col>

							<Col sm={12} xs={12} className="offerDescriptionRow">
								<Col xs={4}>{t('Устройство')}</Col>
								<Col xs={8}>{action.techInfo.device}</Col>
							</Col>

							<Col sm={12} xs={12} className="offerDescriptionRow">
								<Col xs={4}>{t('Браузер')}</Col>
								<Col xs={8}>{action.techInfo.browser}</Col>
							</Col>

							<Col sm={12} xs={12} className="offerDescriptionRow">
								<Col xs={4}>{t('История статусов')}</Col>
								<Col xs={8}>
									<BootstrapTable
										data={action.statusesFlow}
										striped={true}
										hover={true}
										condensed={true}
										bordered={false}
									>
										<TableHeaderColumn
											dataField="_id"
											isKey={true}
											hidden={true}
										>
											{t('ID изменения')}
										</TableHeaderColumn>

										<TableHeaderColumn
											dataField="changed"
											dataAlign="center"
											dataFormat={(cell) => MainFunctions.dateFormat(cell)}
											tdStyle={this.tdStyle}
											width={'80px'}
										>
											{t('Дата')}
										</TableHeaderColumn>

										<TableHeaderColumn
											dataField="status"
											dataFormat={(cell) =>
												cell.description + ' (' + cell.name + ')'
											}
											dataAlign="center"
											tdStyle={this.tdStyle}
										>
											{t('Статус')}
										</TableHeaderColumn>

										<TableHeaderColumn
											dataField="comment"
											dataAlign="center"
											tdStyle={this.tdStyle}
										>
											{t('Комментарий')}
										</TableHeaderColumn>
									</BootstrapTable>
								</Col>
							</Col>
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}
