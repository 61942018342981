/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React from 'react';
import { Row, Col } from 'react-bootstrap';

import PaymentInfo from './PaymentInfo';

export default class UserDetailInfo extends React.PureComponent {
	componentDidMount() {
		this.props.getAllPaymentSystems();
		this.props.getCurrencies();
	}

	deletePayment = (paymentId) =>
		this.props.deletePaymentSetings(this.props.detailInfo.id, { _id: paymentId });
	updatePayment = (data) => this.props.updatePaymentSetings(this.props.detailInfo.id, data);
	onSelectOffer = (element) => this.props.push('/campaigns/' + element);
	render() {
		const {
			t,
			active,
			height,
			detailInfo,
			deletePaymentSetings,
			updatePaymentSetings,
			...otherProps
		} = this.props;
		if (!active) return <div className="box-body" style={{ height: height }} />;
		else
			return (
				<Row className="box-body" style={{ minHeight: height }}>
					<Col sm={6} xs={12}>
						<div className="hr-text hr-text-center m-t-1">
							<strong>{t('Общая информация')}</strong>
						</div>

						<dl className="dl-horizontal">
							<dt>{t('Имя')}</dt>
							<dd className="text-white">{detailInfo.name}</dd>
							<dt>{t('Фамилия')}</dt>
							<dd className="text-white">{detailInfo.lastName}</dd>
							<dt>{t('Зарегистрирован(а)')}</dt>
							<dd className="text-white">{detailInfo.registered}</dd>
							<dt>{t('Язык')}</dt>
							<dd className="text-white">{detailInfo.language}</dd>
						</dl>
					</Col>

					<Col sm={6} xs={12}>
						<div className="hr-text hr-text-center m-t-1">
							<strong>{t('Контакты')}</strong>
						</div>

						<dl className="dl-horizontal">
							<dt>{t('Телефон')}</dt>
							<dd className="text-white">{detailInfo.phone}</dd>
							<dt>{t('Email')}</dt>
							<dd>
								<a href="javascript:void(0)">{detailInfo.email}</a>
							</dd>
							<dt>{t('Skype')}</dt>
							<dd>
								<a href="javascript:void(0)">{detailInfo.skype}</a>
							</dd>
						</dl>
					</Col>
					<Col xs={12}>
						<div className="hr-text hr-text-center m-t-1">
							<strong>{t('Платежные данные')}</strong>
						</div>

						<PaymentInfo
							t={t}
							userId={detailInfo.id}
							payment={detailInfo.payment}
							deletePayment={this.deletePayment}
							updatePayment={this.updatePayment}
							{...otherProps}
						/>
					</Col>
					{
						//                 <Col xs={12}>
						//                     <div className='hr-text hr-text-center m-t-1'>
						//                         <strong>{t('Выбранные офферы')}</strong>
						//                     </div>
						//                     <ListContainer
						//                         noDataText = {t('Нет данных')}
						//                         sizePerPage = {5}
						//                         data = { offers }
						//                         onSelect = {this.onSelectOffer}
						//                     />
						//                 </Col>
					}
				</Row>
			);
	}
}
