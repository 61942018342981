import React from 'react';
import memoize from 'memoize-one';
import _ from 'underscore';
import { Link } from 'react-router-dom';
import { Button, Col } from 'react-bootstrap';
import { TableHeaderColumn, BootstrapTable } from 'react-bootstrap-table';
import FormatedOfferInfo from 'components/service/FormatedOfferInfo';
import MainFunctions from 'mainLibs/MainFunctions';

export default class TableInfo extends React.PureComponent {
	constructor(props) {
		super(props);
		this.tdStyle = { whiteSpace: 'normal', wordWrap: 'break-word', cursor: 'pointer' };
		this.thStyle = { whiteSpace: 'normal' };
		this.numTickets = 30;
	}

	componentDidMount() {
		this.props.getOfferAccess({ type: 'new' });
	}

	formatUser = (cell) => {
		const user = _.isObject(cell) ? cell : null;
		return (
			user && (
				<Link to={`/users/${(user.isAffiliate ? 'affiliates/' : 'merchants/') + user._id}`}>
					{`${user.username} (${user._id})`}
				</Link>
			)
		);
	};

	closeTicket = (id) => {
		if (id) {
			this.props.closeTicket(id);
		}
	};

	dateFormat = (cell) => MainFunctions.dateFormat(cell);

	formatOffer = (cell, row) => cell && <FormatedOfferInfo {...cell} />;

	isExpandableRow(row) {
		return true;
	}

	expandComponent = (row) => {
		const resolveAccess = () => this.props.changeOfferAccess(row._id, { field: 'enabled' });
		const rejectAccess = () => this.props.changeOfferAccess(row._id, { field: 'disabled' });
		return (
			row && (
				<div className={'offer-access no-padding-td'}>
					<Col xs={12}>
						<p>{row.description}</p>
					</Col>
					<Col xs={12}>
						<Button bsStyle={'success'} onClick={resolveAccess}>
							{this.props.t('Разрешить доступ')}
						</Button>{' '}
						<Button bsStyle={'danger'} onClick={rejectAccess}>
							{this.props.t('Запретить доступ')}
						</Button>
					</Col>
				</div>
			)
		);
	};

	getOptions = memoize((numTickets) => {
		return {
			//expandRowBgColor: 'rgb(255, 50, 50)',
			sizePerPage: numTickets,
		};
	});

	parseOfferAccess = memoize((allOfferAccess) => {
		const offerAccess = Array.isArray(allOfferAccess) ? allOfferAccess : [];
		const result = [];
		for (let i = offerAccess.length - 1; i >= 0; i--) {
			if (!offerAccess[i].closed) {
				if (offerAccess[i].offer) result.push(offerAccess[i]);
			}
		}
		return result;
	});

	render() {
		const { t, offerAccess } = this.props;
		const options = this.getOptions(this.numTickets);
		const data = this.parseOfferAccess(offerAccess);
		const pagination = data.length > this.numTickets;
		return (
			<BootstrapTable
				data={data}
				striped={true}
				options={options}
				hover={true}
				bordered={false}
				pagination={pagination}
				expandableRow={this.isExpandableRow}
				expandComponent={this.expandComponent}
			>
				<TableHeaderColumn dataField="_id" hidden isKey={true} />

				<TableHeaderColumn
					dataField="created"
					tdStyle={this.tdStyle}
					thStyle={this.thStyle}
					dataAlign="center"
					dataFormat={this.dateFormat}
				>
					{t('Создан')}
				</TableHeaderColumn>

				<TableHeaderColumn
					dataField="user"
					tdStyle={this.tdStyle}
					thStyle={this.thStyle}
					dataFormat={this.formatUser}
					dataAlign="center"
				>
					{t('Пользователь')}
				</TableHeaderColumn>

				<TableHeaderColumn
					dataField="offer"
					tdStyle={this.tdStyle}
					thStyle={this.thStyle}
					dataAlign="center"
					dataFormat={this.formatOffer}
				>
					{t('Оффер')}
				</TableHeaderColumn>
			</BootstrapTable>
		);
	}
}
