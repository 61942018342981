import React from 'react';
import _ from 'underscore';
import memoize from 'memoize-one';

import { Col } from 'react-bootstrap';

import MainFunctions from 'mainLibs/MainFunctions';

import UserInfo from './UserInfo';
import UserDetail from './UserDetail';

export default class ContentBody extends React.PureComponent {
	componentDidMount() {
		this.props.getUserInfo(this.props.userId);
		this.props.getAllTickets({ userId: this.props.userId });
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.userId !== this.props.userId) this.props.getUserInfo(this.props.userId);
	}

	parseUserInfo = memoize((userInfo) => {
		function getPayment(data) {
			const settings = _.isObject(data.settings) ? data.settings : {};
			return _.isArray(settings.payment) ? settings.payment : [];
		}
		const user = userInfo || {};
		const info = {
			id: user._id,
			username: user.username || '',
			name: user.name || '',
			lastName: user.lastName || '',
			registered: MainFunctions.dateFormat(user.registered || ''),
			lastActivity: MainFunctions.dateFormat(user.lastActivity || ''),
			avatar: user.avatar || '',
			language: user.language ? user.language.name : '',
			phone: user.phone || '',
			skype: user.skype || '',
			email: user.username || '',
			favoriteOffers: user.favoriteOffers || [],
			stats: { money: { paid: 0, notPaid: 0, onBalance: 0 }, ...user.stats },
			payment: getPayment(user),
			offers: Array.isArray(user.offers) ? user.offers : [],
			access: user.access || {},
			isAffiliate: user.isAffiliate,
			ips: user.ips || [],
		};
		return info;
	});

	parseTickets = memoize((ticketsData) => {
		const tickets = _.isArray(ticketsData) ? ticketsData : [];
		let result = [];
		let resultToUp = [];
		for (let i = 0; i < tickets.length; i++) {
			if (tickets[i].closed)
				result.push({
					id: tickets[i]._id || null,
					topic: tickets[i].topic || '',
					created: tickets[i].created || '',
					closed: tickets[i].closed,
					messages: tickets[i].messages || [],
				});
			else
				resultToUp.push({
					id: tickets[i]._id || null,
					topic: tickets[i].topic || '',
					created: tickets[i].created || '',
					closed: tickets[i].closed,
					messages: tickets[i].messages || [],
				});
		}
		resultToUp = _.sortBy(resultToUp, (ticket) => -Date.parse(ticket.created));
		result = _.sortBy(result, (ticket) => -Date.parse(ticket.created));
		return resultToUp.concat(result);
	});

	parsePayments = memoize((allPayments) => {
		const payments = _.isArray(allPayments) ? allPayments : [];
		const result = {
			prePayments: [],
			payments: [],
		};
		const resultHead = {
			prePayments: [],
			payments: [],
		};
		const resultTail = {
			prePayments: [],
			payments: [],
		};

		function getCurrency(currency) {
			const correctCurrency = _.isObject(currency) ? currency : {};
			return correctCurrency.name + ` (${correctCurrency.code})`;
		}

		function getPaymentSystem(paymentSystem) {
			if (paymentSystem) return paymentSystem.description + ` (${paymentSystem.name})`;
			else return '';
		}

		function getUser(user) {
			if (!_.isObject(user)) user = {};
			const money = (user.stats || {}).money || {};
			return {
				onBalance: money.onBalance,
				paid: money.paid,
				notPaid: money.notPaid,
				...user,
			};
		}

		function parsePayment(payments) {
			const correctPayments = _.isObject(payments) ? payments : {};
			const statusesFlow = correctPayments.statusesFlow || [];
			const lastStatusName = ((_.last(statusesFlow) || {}).status || {}).name;
			let hidden = false;
			if (lastStatusName === 'Paid') hidden = true;
			if (lastStatusName === 'Declined') hidden = true;
			return {
				_id: correctPayments._id || '',
				hidden: hidden,
				user: getUser(correctPayments.user),
				account: correctPayments.paymentSystemAccount,
				date: MainFunctions.dateFormat(correctPayments.date),
				amount: correctPayments.amount || 0,
				currency: getCurrency(correctPayments.currency),
				paymentSystem: getPaymentSystem(correctPayments.paymentSystem),
				statusesFlow: statusesFlow,
			};
		}

		for (let i = 0; i < payments.length; i++) {
			const statusesFlow = payments[i].statusesFlow || [];
			switch (statusesFlow.length) {
				case 1:
					if ((payments[i] || {}).requestType === 'PaymentRequest')
						resultHead.payments.push(parsePayment(payments[i]));
					else resultHead.prePayments.push(parsePayment(payments[i]));
					break;
				case 2:
					if ((payments[i] || {}).requestType === 'PaymentRequest')
						result.payments.push(parsePayment(payments[i]));
					else result.prePayments.push(parsePayment(payments[i]));
					break;
				case 3:
					if ((payments[i] || {}).requestType === 'PaymentRequest')
						resultTail.payments.push(parsePayment(payments[i]));
					else resultTail.prePayments.push(parsePayment(payments[i]));
					break;
				default:
					if ((payments[i] || {}).requestType === 'PaymentRequest')
						result.payments.push(parsePayment(payments[i]));
					else result.prePayments.push(parsePayment(payments[i]));
					break;
			}
		}

		return {
			payments: resultHead.payments.concat(result.payments.concat(resultTail.payments)),
			prePayments: resultHead.prePayments.concat(
				result.prePayments.concat(resultTail.prePayments),
			),
		};
	});

	render() {
		const {
			t,
			userId,
			userInfo,
			ticketsData,
			adminInfo,
			paymentsData,
			paymentStatuses,
			paymentSystems,
			setAccess,
			username,
			access,
			...otherProps
		} = this.props;

		const info = this.parseUserInfo(userInfo);
		const tickets = this.parseTickets(ticketsData);
		const { payments, prePayments } = this.parsePayments(paymentsData);
		return (
			<div className="box box-primary">
				<div className="box-body">
					<Col sm={12}>
						<UserInfo
							t={t}
							username={username}
							overallInfo={info}
							setAccess={setAccess}
							access={access}
						/>
					</Col>
				</div>

				<div className="box-body" style={{ padding: '15px' }}>
					<UserDetail
						t={t}
						userId={userId}
						detailInfo={info}
						adminInfo={adminInfo}
						tickets={tickets}
						payments={payments}
						prePayments={prePayments}
						paymentStatuses={paymentStatuses}
						paymentSystems={paymentSystems}
						{...otherProps}
					/>
				</div>
			</div>
		);
	}
}
