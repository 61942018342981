import React from 'react';
import BoxBody from './BoxBody';

export default class EditBlock extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			boxCollapse: false,
		};
	}

	toggleBox = (e) => {
		e.preventDefault();
		this.setState((prevState) => {
			return {
				boxCollapse: !prevState.boxCollapse,
			};
		});
	};

	render() {
		const { t } = this.props;
		return (
			<React.Fragment>
				<div
					className={'box box-primary' + (this.state.boxCollapse ? ' collapsed-box' : '')}
				>
					<div className="box-header with-border">
						<h3 className="box-title">{t('Редактирование')}</h3>
						<div className="box-tools pull-right">
							<button className="btn btn-box-tool" onClick={this.toggleBox}>
								<i
									className={
										'fa ' + (this.state.boxCollapse ? 'fa-plus' : 'fa-minus')
									}
								/>
							</button>
						</div>
					</div>

					<BoxBody {...this.props} />
				</div>

				{/*Магия DOM чтобы уместить раскрывающийся select в область окна*/}
				<div style={{ minHeight: '100px' }} />
			</React.Fragment>
		);
	}
}
