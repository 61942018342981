import { adminPanelRequestTypes } from '../../constants';

const initialState = {
	deleting: false,
	creating: false,
	loading: false,
	loaded: false,
	errors: null,
	data: [],
};

export default function pwaOptions(state = initialState, { type, data, errors }) {
	switch (type) {
		case adminPanelRequestTypes.GET_PWA_OPTIONS_REQUEST:
			return { ...state, loading: true, errors: null };
		case adminPanelRequestTypes.GET_PWA_OPTIONS_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : null };
		case adminPanelRequestTypes.GET_PWA_OPTIONS_SUCCESS:
			return { ...state, loading: false, loaded: true, errors: null, data: data };

		case adminPanelRequestTypes.ADD_PWA_OPTIONS_REQUEST:
			return { ...state, creating: true, errors: null };
		case adminPanelRequestTypes.ADD_PWA_OPTIONS_FAIL:
			return { ...state, creating: false, errors: errors ? errors : null };
		case adminPanelRequestTypes.ADD_PWA_OPTIONS_SUCCESS:
			const next = state.data.concat(data);
			console.log({ next });
			return { ...state, creating: false, errors: null, data: state.data.concat(data) };

		case adminPanelRequestTypes.DELETE_PWA_OPTIONS_REQUEST:
			return { ...state, deleting: true, errors: null };
		case adminPanelRequestTypes.DELETE_PWA_OPTIONS_FAIL:
			return { ...state, deleting: false, errors: errors ? errors : null };
		case adminPanelRequestTypes.DELETE_PWA_OPTIONS_SUCCESS:
			const newData = state.data.slice();
			const index = newData.findIndex((e) => e._id === data._id);
			if (index !== -1) {
				newData.splice(index, 1);
			}
			return { ...state, deleting: false, errors: null, data: newData };
		default:
			return state;
	}
}
