import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translate } from 'react-i18next';
import { push } from 'connected-react-router';

import {
	getUserInfo,
	updatePaymentSetings,
	deletePaymentSetings,
	updateUserOffers,
	userLocalUpdate,
	setAccess,
} from 'store/actions/apiRequestActions/users';
import { getAllTickets, readMessage, createTicket } from 'store/actions/apiRequestActions/tickets';
import {
	getAllPayments,
	updatePaymentStatus,
	getAllPaymentStatuses,
	getAllPaymentSystems,
} from 'store/actions/apiRequestActions/payments';
import { getCurrencies } from 'store/actions/apiRequestActions/currencies';
import { alertShow } from 'store/actions/alertActions';

import ContentBody from './ContentBody';
import ContentHeader from './ContentHeader';

class DeepAffiliates extends React.Component {
	constructor(props) {
		super(props);
		this.props.userLocalUpdate();
	}

	getUserId = () => {
		const url = window.location.pathname;
		return url.substr(url.lastIndexOf('/') + 1);
	};

	render() {
		const {
			t,
			userInfo,
			tickets,
			payments,
			paymentStatuses,
			paymentSystems,
			currencies,
			...otherProps
		} = this.props;

		const loading =
			paymentSystems.loading ||
			userInfo.loading ||
			tickets.loading ||
			payments.loading ||
			paymentStatuses.loading;

		return (
			<div className="content-wrapper">
				<ContentHeader label={t('Веб-мастер') + ' ' + ((userInfo.data || {}).name || '')} />

				<section className="content offers">
					{loading ? (
						<div className="pageOverlay">
							<i className="fa fa-spinner css--animate" />
						</div>
					) : null}

					<ContentBody
						t={t}
						userId={this.getUserId()}
						userInfo={userInfo.data || {}}
						paymentsData={payments.data}
						ticketsData={tickets.data || []}
						paymentStatuses={paymentStatuses.data || []}
						paymentSystems={paymentSystems.data || []}
						currencies={currencies.data || []}
						{...otherProps}
					/>
				</section>
			</div>
		);
	}
}

//Получаем modalStore из stora и записываем в props компонента
const mapStateToProps = ({ apiRequestStore }) => ({
	access: (apiRequestStore.auth.user || {}).access || {},
	currencies: apiRequestStore.currencies,
	userInfo: apiRequestStore.users.userInfo,
	tickets: apiRequestStore.tickets.allTickets,
	payments: apiRequestStore.payments.userPayments,
	paymentStatuses: apiRequestStore.payments.paymentStatuses,
	paymentSystems: apiRequestStore.payments.paymentSystems,
	username: (apiRequestStore.auth.user || {}).username || '',
});

//Пробрасываем actions в props компонента
const mapActionCreators = {
	getUserInfo,
	getAllTickets,
	createTicket,
	readMessage,
	getAllPayments,
	updatePaymentStatus,
	push,
	getAllPaymentStatuses,
	getAllPaymentSystems,
	getCurrencies,
	updatePaymentSetings,
	deletePaymentSetings,
	updateUserOffers,
	alertShow,
	userLocalUpdate,
	setAccess,
};

DeepAffiliates = translate('DeepAffiliates')(DeepAffiliates);
export default withRouter(connect(mapStateToProps, mapActionCreators)(DeepAffiliates));
