import { adminPanelRequestTypes, adminPanelApi } from '../../constants';

export function hidePayment(paymentId) {
	return {
		type: adminPanelRequestTypes.HIDE_PAYMENT,
		paymentId: paymentId,
	};
}

export function getOnePayment(paymentId) {
	return {
		type: adminPanelRequestTypes.GET_PAYMENT,
		request: {
			method: 'get',
			url: adminPanelApi.API_URL_PAYMENT + `/${paymentId}`,
		},
	};
}

// //github.com/axios/axios
export function getAllPayments(params, pre) {
	return {
		//тип определяющий обрабатывающий редюсер
		type: pre ? adminPanelRequestTypes.GET_PRE_PAYMENTS : adminPanelRequestTypes.GET_PAYMENTS,
		//объект request служит для конфигурации запроса axios
		request: {
			method: 'get',
			url: adminPanelApi.API_URL_PAYMENTS,
			params: params,
		},
		userId: (params || {}).userId,
	};
}

// //github.com/axios/axios
export function updatePaymentStatus(paymentId, params, callback) {
	return {
		//тип определяющий обрабатывающий редюсер
		type: adminPanelRequestTypes.UPDATE_PAYMENTS,
		//объект request служит для конфигурации запроса axios
		request: {
			method: 'put',
			url: adminPanelApi.API_URL_PAYMENTS + `/${paymentId}`,
			params: params,
		},
		callback,
	};
}

export function getAllPaymentStatuses() {
	return {
		type: adminPanelRequestTypes.GET_PAYMENT_STATUSES,
		request: {
			method: 'get',
			url: adminPanelApi.API_URL_PAYMENT_STATUSES,
		},
	};
}

export function getAllPaymentSystems() {
	return {
		type: adminPanelRequestTypes.GET_PAYMENT_SYSTEMS,
		request: {
			method: 'get',
			url: adminPanelApi.API_URL_PAYMENT_SYSTEMS,
		},
	};
}

export function addPayment(data) {
	return {
		type: adminPanelRequestTypes.PAYMENTS_ADD,
		request: {
			method: 'post',
			url: adminPanelApi.API_URL_PAYMENTS,
			data: data,
		},
	};
}
