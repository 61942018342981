import React from 'react';
import { Link } from 'react-router-dom';
import querystring from 'querystring';

import { Row, Col, Thumbnail, Button } from 'react-bootstrap';

export default class InfoBlock extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			boxCollapse: false,
		};
	}

	toggleBox = (e) => {
		e.preventDefault();
		this.setState((prevState) => {
			return {
				boxCollapse: !prevState.boxCollapse,
			};
		});
	};

	render() {
		const { t, data } = this.props;
		const temp = {
			copy: this.props.data.original,
		};
		const params = querystring.stringify({
			landing: JSON.stringify(temp),
		});
		return (
			<div className={'box box-primary box-solid'}>
				<div className="box-header with-border">
					<h3 className="box-title">{t('Лендинг')}</h3>
				</div>

				<div className="box-body">
					<Row>
						<Col xs={12} sm={3}>
							<Thumbnail src={data.copy.screenshot} />
							{data.original && (
								<Link to={'/stats/landings/?' + params}>
									<Button bsStyle="danger" block>
										{t('К оригиналу')}
									</Button>
								</Link>
							)}
						</Col>

						<Col xs={12} sm={9}>
							{data.original && (
								<Row className="equal custom-list">
									<Col xs={2} />
									<Col xs={5}>
										<div className="hr-text hr-text-center m-t-1">
											<strong>{t('Копия')}</strong>
										</div>
									</Col>
									<Col xs={5}>
										<div className="hr-text hr-text-center m-t-1">
											<strong>{t('Оригинал')}</strong>
										</div>
									</Col>
								</Row>
							)}
							<Row className="equal custom-list">
								<Col xs={2}>
									<strong>{t('EPC')}</strong>
								</Col>
								<Col xs={data.original ? 5 : 10}>{data.copy.stats.epc}</Col>
								{data.original && <Col xs={5}>{data.original.stats.epc}</Col>}
							</Row>
							<Row className="equal custom-list">
								<Col xs={2}>
									<strong>{t('CR')}</strong>
								</Col>
								<Col xs={data.original ? 5 : 10}>{data.copy.stats.cr}</Col>
								{data.original && <Col xs={5}>{data.original.stats.cr}</Col>}
							</Row>
							<Row className="equal custom-list">
								<Col xs={2}>
									<strong>{t('Название')}</strong>
								</Col>
								<Col xs={data.original ? 5 : 10}>{data.copy.name}</Col>
								{data.original && <Col xs={5}>{data.original.name}</Col>}
							</Row>
							<Row className="equal custom-list">
								<Col xs={2}>
									<strong>{t('URL')}</strong>
								</Col>
								<Col xs={data.original ? 5 : 10}>
									<a
										href={data.copy.url}
										target="_blank"
										className="site-link"
										rel="noreferrer"
									>
										{data.copy.url}
									</a>
								</Col>
								{data.original && (
									<Col xs={5}>
										<a
											href={data.original.url}
											target="_blank"
											className="site-link"
											rel="noreferrer"
										>
											{data.original.url}
										</a>
									</Col>
								)}
							</Row>
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}
