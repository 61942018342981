import React from 'react';
import memoize from 'memoize-one';
import { Panel } from 'react-bootstrap';

import classes from './CardList.module.css';

export default class CardList extends React.PureComponent {
	renderHeading = memoize((title) => {
		if (title) {
			return (
				<Panel.Heading className={classes['list-header']}>
					<Panel.Title>{title}</Panel.Title>
				</Panel.Heading>
			);
		} else {
			return null;
		}
	});

	render() {
		const { title, bsStyle, footer, children, style, className } = this.props;
		return (
			<div style={style} className={`${className} ${classes['list-wrapper']}`}>
				<Panel bsStyle={bsStyle} className={classes['list-content']}>
					{this.renderHeading(title)}

					<Panel.Body className={'custom-scroll ' + classes['list-cards']}>
						{children}
					</Panel.Body>

					{footer && <Panel.Footer>{footer}</Panel.Footer>}
				</Panel>
			</div>
		);
	}
}
