import React from 'react';
import uuidv4 from 'uuid/v4';
import memoize from 'memoize-one';

import { Row, Col, ControlLabel, FormControl, FormGroup, Glyphicon } from 'react-bootstrap';

import Paginator from 'components/service/Paginator';
import OfferItem from './OfferItem';

export default class Offers extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			search: '',
			numPage: 1,
			numItems: 0,
			config: [],
		};
		this.sizePerPage = 12;
	}

	componentDidMount() {
		this.parseOffers(this.props.offers);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.offers !== this.props.offers) {
			this.parseOffers(this.props.offers);
		}
	}

	updateUserOffers = (data, callback) =>
		this.props.updateUserOffers(this.props.userId, data, callback);

	renderOfferItem = (data) => (
		<OfferItem
			key={data.key}
			t={this.props.t}
			hidden={data.hidden}
			data={data}
			updateUserOffers={this.updateUserOffers}
		/>
	);

	parseOffers = memoize((allOffers) => {
		function getPayouts(payouts, geos) {
			payouts = Array.isArray(payouts) ? payouts : [];
			geos = Array.isArray(geos) ? geos : [];
			const result = [];

			for (let i = 0; i < geos.length; i++) {
				const currentGeo = geos[i] || {};
				let payout = null;
				for (let j = 0; j < payouts.length; j++) {
					if ((payouts[j].geo || {})._id === (currentGeo.geoRef || {})._id) {
						payout = {
							geo: payouts[j].geo || {},
							value: payouts[j].value,
							defaultValue: (currentGeo.payout || {}).value,
						};
						break;
					}
				}
				if (!payout) {
					payout = {
						geo: currentGeo.geoRef || {},
						value: (currentGeo.payout || {}).value,
						defaultValue: (currentGeo.payout || {}).value,
					};
				}
				result.push(payout);
			}
			return result;
		}

		const offers = Array.isArray(allOffers) ? allOffers : [];
		const result = [];

		for (var i = 0; i < offers.length; i++) {
			const offer = offers[i].offer || {};
			result.push({
				key: (offer._id || uuidv4()) + i,
				hidden: false,
				offer: offer,
				isUser: offers[i].isUser,
				shave: offers[i].offerShave,
				hold: offers[i].offerHold,
				payouts: getPayouts(offers[i].offerPayouts, offer.geos),
				offerPayouts: offers[i].offerPayouts,
				actions: offers[i].actions,
				landings: offers[i].landings || [],
			});
		}

		let numPage = this.state.numPage;
		while ((numPage - 1) * this.sizePerPage >= result.length) {
			numPage--;
		}
		if (numPage < 1) numPage = 1;
		this.setState({ config: result, numPage: numPage, numItems: result.length });
		return result;
	});

	renderPage = (numPage) => {
		let start = (numPage - 1) * this.sizePerPage;
		start = start < 0 ? 0 : start;
		let end = start + this.sizePerPage;
		end = end > this.state.config.length ? this.state.config.length : end;
		let result = [];
		let i = start;
		while (i < end) {
			result.push(this.renderOfferItem(this.state.config[i], i));
			i++;
		}
		if (!result.length) return null;
		return result;
	};

	setPage = (numPage) =>
		this.setState((prevState) => {
			return { numPage };
		});

	onChangeSearch = (event) => {
		this.search(this.state.config, event.target.value || '');
	};

	search = memoize((data, search) => {
		let numItems = 0;
		for (let i = 0; i < data.length; i++) {
			const name = (data[i].offer.name || '').toLowerCase();
			if (name.search(search.toLowerCase()) === -1) data[i].hidden = true;
			else {
				numItems++;
				data[i].hidden = false;
			}
		}
		this.setState({ config: data, numItems });
		return data;
	});

	render() {
		const { t, active, height } = this.props;
		const pagination = this.state.numItems > this.sizePerPage;
		const numberPage = Math.ceil(this.state.numItems / this.sizePerPage);
		if (!active) return <div className="box-body" style={{ height: height }} />;
		else
			return (
				<Row className="equal" style={{ marginTop: '20px' }}>
					<Col xs={12} sm={6} smOffset={6} md={6} mdOffset={6} lg={4} lgOffset={8}>
						<FormGroup style={{ width: '100%' }}>
							<ControlLabel>{t('Название оффера')}</ControlLabel>

							<FormControl type="text" onChange={this.onChangeSearch} />

							<FormControl.Feedback>
								<Glyphicon glyph="search" />
							</FormControl.Feedback>
						</FormGroup>
					</Col>

					{this.renderPage(this.state.numPage)}
					{pagination && (
						<Col xs={12} style={{ justifyContent: 'flex-end' }}>
							<Paginator
								numberPage={numberPage}
								numPage={this.state.numPage}
								setPage={this.setPage}
							/>
						</Col>
					)}
				</Row>
			);
	}
}
