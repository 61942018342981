import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'underscore';
import memoize from 'memoize-one';
import uuidv4 from 'uuid/v4';

import {
	Row,
	Col,
	Tooltip,
	OverlayTrigger,
	ListGroup,
	ListGroupItem,
	Well,
	Modal,
} from 'react-bootstrap';

import OfferAccessContent from './OfferAccessContent';
import { Colors } from 'consts';
import classes from './Notification.module.css';

class ModalOfferAccess extends React.PureComponent {
	resolveAccess = () => {
		if (this.props.offerAccess._id)
			this.props.changeOfferAccess(this.props.offerAccess._id, { field: 'enabled' });
	};

	rejectAccess = () => {
		if (this.props.offerAccess._id)
			this.props.changeOfferAccess(this.props.offerAccess._id, { field: 'disabled' });
	};

	render() {
		const { t, offerAccess, handleClose, show } = this.props;
		const offer = {
			...offerAccess.offer,
		};
		if (!Array.isArray(offer.geos)) offer.geos = [];
		const user = {
			...offerAccess.user,
		};
		user.language = user.language || {};
		const description = offerAccess.description || '';
		return (
			<Modal
				bsSize="large"
				aria-labelledby="contained-modal-title-lg"
				show={show}
				onHide={handleClose}
			>
				<Modal.Header closeButton>
					<Modal.Title>{t('Запрос на доступ')}</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<OfferAccessContent
						offer={offer}
						user={user}
						description={description}
						resolveAccess={this.resolveAccess}
						rejectAccess={this.rejectAccess}
						t={t}
					/>
				</Modal.Body>
			</Modal>
		);
	}
}

export default class OfferAccessContainer extends React.PureComponent {
	constructor(props, context) {
		super(props, context);
		this.state = {
			openOfferAccess: {},
			show: false,
		};
	}

	handleClose = () => this.setState(() => ({ show: false }));

	expandOfferAccess = (openOfferAccess) => {
		openOfferAccess = openOfferAccess || {};
		this.setState({ openOfferAccess, show: true });
	};

	collapseOfferAccess = () => {
		this.setState({ openOfferAccess: {}, show: false });
	};

	getCurrentOfferAccess = memoize((allOfferAccess, offerAccessId) => {
		let offerAccess = this.state.openOfferAccess;
		const index = allOfferAccess.findIndex((t) => t._id === offerAccessId);
		if (index !== -1) offerAccess = allOfferAccess[index];
		return offerAccess;
	});

	renderNotifyIcon(icon, text) {
		return (
			<OverlayTrigger
				ref="trigger"
				placement="left"
				overlay={
					<Tooltip id={uuidv4()} className="nameBreak">
						{text}
					</Tooltip>
				}
			>
				{icon}
			</OverlayTrigger>
		);
	}

	renderClosedButton = (offerAccessId) => {
		const icon = <i className={'fa fa-lg fa-remove'} style={{ color: Colors.brandDanger }} />;
		const text = this.props.t('Убрать уведомление');
		return (
			<button
				className={classes['close-button']}
				onClick={() => this.closeOfferAccess(offerAccessId)}
			>
				{this.renderNotifyIcon(icon, text)}
			</button>
		);
	};

	closeOfferAccess = (offerAccessId) => {
		if (offerAccessId) this.props.closeOfferAccess(offerAccessId);
	};

	formatUser = (user) => {
		user = _.isObject(user) ? user : {};
		const to = user.isAffiliate ? 'affiliates/' : 'merchants/';
		return (
			<Link to={`/users/${to + user._id}`}>{this.props.t('От') + ': ' + user.username}</Link>
		);
	};

	renderOfferAccessDescription = (offerAccess) => {
		return (
			<Col xs={12}>
				<div className={classes['hr-text']}>{offerAccess.offer.name}</div>
				{this.formatUser(offerAccess.user)}
			</Col>
		);
	};

	renderOfferAccess = (offerAccess, index) => {
		return (
			<ListGroupItem key={offerAccess._id + index}>
				<Row>
					<Col
						xs={10}
						onClick={() => this.expandOfferAccess(offerAccess)}
						style={{ padding: '10px 0px 10px 15px' }}
					>
						{this.renderOfferAccessDescription(offerAccess)}
					</Col>
					<Col xs={2} style={{ padding: '10px 15px 10px 0px' }}>
						{this.renderClosedButton(offerAccess._id)}
					</Col>
				</Row>
			</ListGroupItem>
		);
	};

	render() {
		const { t, offerAccess, allOfferAccess, changeOfferAccess } = this.props;
		const containerHeight = window.innerHeight * 0.6;
		const openOfferAccess = this.getCurrentOfferAccess(
			allOfferAccess,
			this.state.openOfferAccess._id,
		);

		if (offerAccess.length) {
			return (
				<React.Fragment>
					<ListGroup
						className={classes['list-container']}
						style={{ maxHeight: containerHeight }}
					>
						{offerAccess.map(this.renderOfferAccess)}
					</ListGroup>
					<ModalOfferAccess
						t={t}
						show={this.state.show}
						handleClose={this.handleClose}
						offerAccess={openOfferAccess}
						changeOfferAccess={changeOfferAccess}
					/>
				</React.Fragment>
			);
		} else {
			return (
				<Well bsSize="small" style={{ marginBottom: '0px' }}>
					{this.props.t('Новых запросов нет')}
				</Well>
			);
		}
	}
}
