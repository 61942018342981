import React from 'react';
import memoize from 'memoize-one';
import _ from 'underscore';
import { Col, Form, FormGroup, Button } from 'react-bootstrap';

import SelectForm from './SelectForm';
import TextForm from './TextForm';

export default class BoxBody extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			newStatus: {
				status: null,
				comment: null,
			},
		};
	}

	changeNewStatus = (event, fieldName) => {
		var newStatus = { ...this.state.newStatus };
		newStatus[fieldName] = event ? (event.target ? event.target.value : event.value) : null;
		this.setState({ newStatus });
	};

	updateRequest = (event) => {
		event.preventDefault();
		event.stopPropagation();
		this.props.updateStatus(this.props.requestId, this.state.newStatus);
		this.setState({ newStatus: { status: null, comment: '' } });
	};

	getReactSelectOptionsFromStatuses = memoize((statuses) => {
		let result = statuses.map((status) => ({
			value: status._id,
			label: status.description + ' (' + status.name + ')',
		}));
		return result;
	}, _.isEqual);

	render() {
		const { t, statuses, statusId } = this.props;
		const statusSelectOptions = this.getReactSelectOptionsFromStatuses(statuses || []);
		return (
			<div className="box-body">
				<Form horizontal onSubmit={this.updateRequest}>
					<SelectForm
						label={t('Статус')}
						value={this.state.newStatus.status}
						defaultValue={statusId}
						changeInfo={this.changeNewStatus}
						name={'status'}
						options={statusSelectOptions}
					/>

					<TextForm
						label={t('Комментарий')}
						placeholder={t('Комментарий')}
						value={this.state.newStatus.comment}
						changeInfo={this.changeNewStatus}
						name={'comment'}
					/>

					<FormGroup>
						<Col smOffset={2} sm={10}>
							<Button type="submit" bsStyle="danger">
								{t('Сохранить')}
							</Button>
						</Col>
					</FormGroup>
				</Form>
			</div>
		);
	}
}
