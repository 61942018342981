import React from 'react';
import { connect } from 'react-redux';
import memoize from 'memoize-one';
import _ from 'underscore';
import ReactSelect from 'react-select';
import { setMerchantId } from 'store/actions/supportActions';

import { getMerchants } from 'store/actions/apiRequestActions/users';

import ClassNames from './SelectMerchantId.module.css';

class SelectMerchantId extends React.PureComponent {
	componentDidMount() {
		this.props.getMerchants();
		this.props.setMerchantId('all');
	}

	componentWillUnmount() {
		this.props.setMerchantId('all');
	}

	changeMerchantId = (val) => {
		const id = val ? val.value : val;
		if (_.isFunction(this.props.changeInfo)) {
			this.props.changeInfo(id);
		} else {
			this.props.setMerchantId(id);
		}
	};

	getReactSelectOptionsFromMerchants = memoize((merchants) => {
		const result = merchants.map((merchant) => ({
			value: merchant._id,
			label: merchant.username + ' (' + merchant._id + ')',
		}));
		result.unshift({ value: 'all', label: 'Все рекламодатели' });
		return result;
	});

	render() {
		const { defaultStyle } = this.props;
		const merchantId = this.props.merchantId || this.props.defaultMerchantId;
		const merchantsOptions = this.getReactSelectOptionsFromMerchants(
			this.props.merchants.data || [],
		);
		return (
			<ReactSelect
				className={defaultStyle ? '' : ClassNames.merchantId}
				value={merchantId}
				options={merchantsOptions}
				autoBlur={true}
				clearable={false}
				onChange={this.changeMerchantId}
			/>
		);
	}
}

const mapStateToProps = ({ apiRequestStore, supportedStore }) => ({
	merchants: apiRequestStore.users.merchants,
	defaultMerchantId: supportedStore.merchantId.value,
});

const mapActionCreators = {
	setMerchantId,
	getMerchants,
};

export default connect(mapStateToProps, mapActionCreators)(SelectMerchantId);
