import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Button } from 'react-bootstrap';

export default class ContentHeader extends React.PureComponent {
	render() {
		const { t, access } = this.props;
		return (
			<section className="content-header" style={{ height: '50px' }}>
				<Col xs={6} sm={4}>
					<h1>
						<i className="fa fa-fire" />
						{t('Офферы')}
					</h1>
				</Col>

				{access.adminOnly && (
					<Col xs={6} sm={2} smPush={6}>
						<Link to="/campaigns/new">
							<Button bsStyle="info">{t('Добавить оффер')}</Button>
						</Link>
					</Col>
				)}
			</section>
		);
	}
}
