import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import { Col, ControlLabel, FormGroup } from 'react-bootstrap';

export default class SelectForm extends React.PureComponent {
	static propTypes = {
		value: PropTypes.string,
		changeInfo: PropTypes.func.isRequired,
		name: PropTypes.string.isRequired,
		options: PropTypes.array,
	};

	checkValue(prevProps = {}) {
		if (!this.props.value || prevProps.options !== this.props.options) {
			if (this.props.options.length)
				this.props.changeInfo(this.props.options[0], this.props.name);
		}
	}
	componentDidMount() {
		this.checkValue();
	}
	componentDidUpdate(prevProps) {
		this.checkValue(prevProps);
	}
	onСhangeInfoClick = (e) => {
		if (e) {
			if (e.value !== this.props.value) this.props.changeInfo(e, this.props.name);
		}
	};

	render() {
		const { value, required, options, placeholder, label, disabled } = this.props;
		return (
			<FormGroup validationState={required ? (value ? 'success' : 'error') : null}>
				<Col componentClass={ControlLabel} sm={2}>
					{label}
				</Col>
				<Col sm={10}>
					<ReactSelect
						value={value}
						options={options}
						autoBlur={true}
						clearable={false}
						placeholder={placeholder}
						onChange={this.onСhangeInfoClick}
						disabled={disabled}
					/>
				</Col>
			</FormGroup>
		);
	}
}
