import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import _ from 'underscore';
import memoize from 'memoize-one';
import uuidv4 from 'uuid/v4';
import MainFunctions from 'mainLibs/MainFunctions';

import {
	Row,
	Col,
	Tooltip,
	OverlayTrigger,
	ListGroup,
	ListGroupItem,
	Well,
	Tab,
	NavItem,
	Nav,
} from 'react-bootstrap';

import ModalPayment from './ModalPayment';

import { Colors } from 'consts';
import classes from './Notification.module.css';

export default class FinanceContainer extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			paymentId: null,
			show: false,
		};
		this.inputNewStatus = null;
	}

	setInputNewStatus = (ref) => (this.inputNewStatus = ref);

	handleClose = () => {
		this.setState({ show: false });
	};

	saveNewStatus = () => {
		const newStatus = ReactDOM.findDOMNode(this.inputNewStatus).value;
		const paymentId = this.state.paymentId;
		if (paymentId) this.props.updatePaymentStatus(paymentId, { statusId: newStatus });
	};

	getPaymentInfo = (data) => {
		const getStatuses = (pre) => {
			if (!pre) {
				return this.props.paymentStatuses.filter((status) => {
					return status.name === 'Paid' || status.name === 'Returned to balance';
				});
			} else {
				return this.props.paymentStatuses.filter((status) => {
					return (
						status.name === 'Transferred to balance' ||
						status.name === 'Declined' ||
						status.name === 'Verified'
					);
				});
			}
		};

		const paymentInfo = {
			_id: data._id,
			user: data.user,
			account: data.account,
			amount: data.amount,
			currency: data.currency,
			paymentSystem: data.paymentSystem,
			paid: data.paid,
			notPaid: data.notPaid,
			onBalance: data.onBalance,
			date: data.date,
			hidden: data.hidden,
			statuses: [],
			allStatuses: getStatuses(!data.requestType),
		};

		const statusesFlow = data.statusesFlow || [];
		for (var i = 0; i < statusesFlow.length; i++) {
			const status = statusesFlow[i].status || {};
			const statusName = status.description + ` (${status.name})`;
			const statusDate = MainFunctions.dateFormat(statusesFlow[i].changed);
			paymentInfo.statuses.push({
				date: statusDate,
				name: statusName,
			});
			const index = paymentInfo.allStatuses.findIndex((t) => t._id === status._id);
			if (index !== -1) {
				paymentInfo.allStatuses.splice(index, 1);
			}
		}

		return paymentInfo;
	};

	expandPayment = (paymentId) => {
		this.setState({ paymentId, show: true });
	};

	collapsePayment = () => {
		this.setState({ paymentId: null, show: false });
	};

	getCurrentPayment = memoize((allPayment, paymentId) => {
		let payment = {};
		const index = allPayment.findIndex((t) => t._id === paymentId);
		if (index !== -1) payment = allPayment[index];
		return payment;
	});

	renderNotifyIcon(icon, text) {
		return (
			<OverlayTrigger
				ref="trigger"
				placement="left"
				overlay={
					<Tooltip id={uuidv4()} className="nameBreak">
						{text}
					</Tooltip>
				}
			>
				{icon}
			</OverlayTrigger>
		);
	}

	renderClosedButton = (paymentId) => {
		const icon = <i className={'fa fa-lg fa-remove'} style={{ color: Colors.brandDanger }} />;
		const text = this.props.t('Убрать уведомление');
		return (
			<button
				className={classes['close-button']}
				onClick={() => this.closePayment(paymentId)}
			>
				{this.renderNotifyIcon(icon, text)}
			</button>
		);
	};

	closePayment = (paymentId) => {
		if (paymentId) this.props.closePayment(paymentId);
	};

	formatUser = (user) => {
		user = _.isObject(user) ? user : {};
		const to = user.isAffiliate ? 'affiliates/' : 'merchants/';
		return (
			<Link to={`/users/${to + user._id}`}>{this.props.t('От') + ': ' + user.username}</Link>
		);
	};

	renderPaymentDescription = (payment) => {
		return (
			<Col xs={12}>
				{this.formatUser(payment.user)}
				<br />
				<small> {payment.date} </small>
			</Col>
		);
	};

	renderPayment = (payment, index) => {
		return (
			<ListGroupItem key={payment._id + index}>
				<Row>
					<Col
						xs={10}
						onClick={() => this.expandPayment(payment._id)}
						style={{ padding: '10px 0px 10px 15px' }}
					>
						{this.renderPaymentDescription(payment)}
					</Col>
					<Col xs={2} style={{ padding: '10px 15px 10px 0px' }}>
						{this.renderClosedButton(payment._id)}
					</Col>
				</Row>
			</ListGroupItem>
		);
	};

	render() {
		const { t, allPayments, paymentsData, prePaymentsData } = this.props;
		const containerHeight = window.innerHeight * 0.6;
		const currentPayment = this.getCurrentPayment(allPayments, this.state.paymentId);
		const openPayment = this.getPaymentInfo(currentPayment);
		return (
			<React.Fragment>
				<Tab.Container id="tabs-finance-notify" defaultActiveKey="prePayments">
					<Row className="clearfix">
						<Col sm={12}>
							<Nav bsStyle="pills" justified>
								<NavItem eventKey="prePayments">
									<div
										className={
											prePaymentsData.length
												? classes['tab-title-animated']
												: ''
										}
									>
										{t('Досрочные')}
									</div>
								</NavItem>

								<NavItem eventKey="payments">
									<div
										className={
											paymentsData.length ? classes['tab-title-animated'] : ''
										}
									>
										{t('На счет')}
									</div>
								</NavItem>
							</Nav>
						</Col>

						<Col sm={12}>
							<Tab.Content animation>
								<Tab.Pane eventKey="prePayments">
									{prePaymentsData.length ? (
										<ListGroup
											className={classes['list-container']}
											style={{ maxHeight: containerHeight }}
										>
											{prePaymentsData.map(this.renderPayment)}
										</ListGroup>
									) : (
										<Well bsSize="small" style={{ marginBottom: '0px' }}>
											{this.props.t('Новых запросов нет')}
										</Well>
									)}
								</Tab.Pane>

								<Tab.Pane eventKey="payments">
									{paymentsData.length ? (
										<ListGroup
											className={classes['list-container']}
											style={{ maxHeight: containerHeight }}
										>
											{paymentsData.map(this.renderPayment)}
										</ListGroup>
									) : (
										<Well bsSize="small" style={{ marginBottom: '0px' }}>
											{this.props.t('Новых запросов нет')}
										</Well>
									)}
								</Tab.Pane>
							</Tab.Content>
						</Col>
					</Row>
				</Tab.Container>

				<ModalPayment
					t={t}
					show={this.state.show}
					openPayment={openPayment}
					handleClose={this.handleClose}
					saveNewStatus={this.saveNewStatus}
					setInputNewStatus={this.setInputNewStatus}
				/>
			</React.Fragment>
		);
	}
}
