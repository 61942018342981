import React from 'react';
import { connect } from 'react-redux';

import { Button } from 'react-bootstrap';

import {
	getStatsPrelandings,
	getStatsLandings,
	getStatsOffers,
	getStatsGeos,
	getStatsCampaigns,
	getStatsDaily,
	getStatsSources,
	getStatsRequests,
	getStatsFiltersData,
} from 'store/actions/apiRequestActions/affiliateStats';

import { setFilter, clearFilter } from 'store/actions/supportActions';

import BoxBody from './BoxBody';

class SearchBlock extends React.Component {
	toggleBox = (e) => {
		const filter = { ...this.props.filter, collapsed: !this.props.filter.collapsed };
		this.props.setFilter('affiliateStats', filter);
	};

	expandSearch = () => {
		const filter = { ...this.props.filter, expanded: !this.props.filter.expanded };
		this.props.setFilter('affiliateStats', filter);
	};

	getBoxBodyRef = (ref) => (this.boxBody = ref);

	updateStats = () => {
		if (this.boxBody) {
			this.boxBody.loadStats();
		}
	};

	render() {
		const {
			t,
			statsFiltersData,
			getStatsFiltersData,
			getStatsPrelandings,
			getStatsLandings,
			getStatsOffers,
			getStatsGeos,
			getStatsCampaigns,
			getStatsDaily,
			getStatsSources,
			getStatsRequests,
			statsName,
		} = this.props;
		const loading = statsFiltersData.loading;
		return (
			<React.Fragment>
				{loading ? (
					<div className="pageOverlay">
						<i className="fa fa-spinner css--animate" />
					</div>
				) : null}
				<div
					className={
						'box box-danger box-solid color-palette-box ' +
						(this.props.filter.collapsed ? 'collapsed-box' : '')
					}
				>
					<div className="box-header with-border">
						<h3 className="box-title">
							<span>{t('Фильтр')}</span>
							<Button className="btn-box-tool-update" onClick={this.updateStats}>
								{t('Обновить')}
							</Button>
						</h3>

						<div className="box-tools pull-right">
							<Button className="btn-box-tool" onClick={this.expandSearch}>
								{this.props.filter.expanded
									? t('Обычный поиск')
									: t('Расширенный поиск')}{' '}
								<i
									className={
										'fa ' +
										(this.props.filter.expanded
											? 'fa-angle-up '
											: 'fa-angle-down')
									}
								/>
							</Button>

							<Button className="btn-box-tool" onClick={this.toggleBox}>
								<i
									className={
										'fa ' +
										(this.props.filter.collapsed ? 'fa-plus' : 'fa-minus')
									}
								/>
							</Button>
						</div>
					</div>

					<BoxBody
						ref={this.getBoxBodyRef}
						t={t}
						advansedSearch={this.props.filter.expanded}
						statsFiltersData={statsFiltersData.data}
						getStatsFiltersData={getStatsFiltersData}
						getStatsPrelandings={getStatsPrelandings}
						getStatsLandings={getStatsLandings}
						getStatsOffers={getStatsOffers}
						getStatsGeos={getStatsGeos}
						getStatsCampaigns={getStatsCampaigns}
						getStatsDaily={getStatsDaily}
						getStatsSources={getStatsSources}
						getStatsRequests={getStatsRequests}
						statsName={statsName}
						filter={this.props.filter}
						setFilter={this.props.setFilter}
						clearFilter={this.props.clearFilter}
					/>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = ({ apiRequestStore, supportedStore }) => ({
	statsFiltersData: apiRequestStore.affiliateStats.statsFiltersData,
	filter: supportedStore.filters.affiliateStats,
});

const mapActionCreators = {
	getStatsFiltersData,
	getStatsPrelandings,
	getStatsLandings,
	getStatsOffers,
	getStatsGeos,
	getStatsCampaigns,
	getStatsDaily,
	getStatsSources,
	getStatsRequests,
	setFilter,
	clearFilter,
};

export default connect(mapStateToProps, mapActionCreators)(SearchBlock);
