import { adminPanelRequestTypes } from '../../constants';
import { combineReducers } from 'redux';

const initialDaily = {
	loading: false,
	loaded: false,
	errors: null,
	period: {
		start: null,
		end: null,
	},
	data: {},
};

const initialRequests = {
	loading: false,
	loaded: false,
	errors: null,
	data: [],
};

const initialLandings = {
	loading: false,
	loaded: false,
	errors: null,
	data: [],
};

const initialPreLanding = {
	loading: false,
	loaded: false,
	errors: null,
	data: [],
};

const initialOverall = {
	loading: false,
	loaded: false,
	errors: null,
	data: [],
};

function daily(state = initialDaily, { type, period, data, errors }) {
	switch (type) {
		case adminPanelRequestTypes.GET_STATS_DAILY_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_STATS_DAILY_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				errors: null,
				data: data,
				period: period,
			};
		case adminPanelRequestTypes.GET_STATS_DAILY_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		default:
			return state;
	}
}

function requests(state = initialRequests, { type, period, data, errors, callback }) {
	switch (type) {
		case adminPanelRequestTypes.GET_STATS_REQUESTS_REQUEST:
		case adminPanelRequestTypes.UPDATE_ACTIONS_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_STATS_REQUESTS_SUCCESS:
			return { ...state, loading: false, loaded: true, errors: null, data: data };
		case adminPanelRequestTypes.UPDATE_ACTIONS_SUCCESS:
			callback && callback();
			const updatedActions = state.data.slice();

			if (!Array.isArray(data.success)) data.success = [];

			for (let i = 0; i < data.success.length; i++) {
				data.success[i] = data.success[i] || {};
				const index = updatedActions.findIndex((a) => a._id === data.success[i]._id);
				updatedActions[index].statusesFlow = data.success[i].statusesFlow;
			}

			return { ...state, loading: false, loaded: true, errors: null, data: updatedActions };
		case adminPanelRequestTypes.UPDATE_ACTIONS_FAIL:
		case adminPanelRequestTypes.GET_STATS_REQUESTS_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		default:
			return state;
	}
}

function landings(state = initialLandings, { type, period, data, errors }) {
	switch (type) {
		case adminPanelRequestTypes.GET_STATS_LANDINGS_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_STATS_LANDINGS_SUCCESS:
			return { ...state, loading: false, loaded: true, errors: null, data: data };
		case adminPanelRequestTypes.GET_STATS_LANDINGS_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		default:
			return state;
	}
}

function preLandings(state = initialPreLanding, { type, period, data, errors }) {
	switch (type) {
		case adminPanelRequestTypes.GET_STATS_PRELANDINGS_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_STATS_PRELANDINGS_SUCCESS:
			return { ...state, loading: false, loaded: true, errors: null, data: data };
		case adminPanelRequestTypes.GET_STATS_PRELANDINGS_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		default:
			return state;
	}
}

function overall(state = initialOverall, { type, period, data, errors }) {
	switch (type) {
		case adminPanelRequestTypes.GET_STATS_OVERALL_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_STATS_OVERALL_SUCCESS:
			return { ...state, loading: false, loaded: true, errors: null, data: data };
		case adminPanelRequestTypes.GET_STATS_OVERALL_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		default:
			return state;
	}
}

const stats = {
	daily,
	landings,
	preLandings,
	overall,
	requests,
};

export default combineReducers(stats);
