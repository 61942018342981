import React from 'react';
import TableInfo from './TableInfo';

export default class ContentBlock extends React.PureComponent {
	render() {
		return (
			<div
				className="box"
				style={{ borderTop: 'none', minHeight: `${window.innerHeight - 45}px` }}
			>
				<TableInfo {...this.props} />
			</div>
		);
	}
}
