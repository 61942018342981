const highchartsTheme = {
	colors: [
		'#2D99DC',
		'#35BDA8',
		'#86B34D',
		'#7798BF',
		'#E66C40',
		'#CB3E4B',
		'#5C5C5C',
		'#55BF3B',
		'#DF5353',
		'#7798BF',
		'#aaeeee',
	],
	chart: {
		backgroundColor: {
			linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
			stops: [[0, '#212121']],
		},
		style: {
			fontFamily: "-apple-system, 'Unica One', sans-serif",
		},
		plotBorderColor: 'yellow',
	},
	title: {
		style: {
			color: '#525252',
			textTransform: 'uppercase',
			fontSize: '11px',
		},
	},
	subtitle: {
		style: {
			color: '#404040',
			textTransform: 'none',
		},
	},
	xAxis: {
		gridLineColor: '#d5d5d5',
		labels: {
			style: {
				color: '#b5b5b5',
			},
		},
		lineColor: '#c5c5c5',
		minorGridLineColor: 'pink',
		tickColor: '#d6d6d6',
		title: {
			style: {
				color: '#484848',
			},
		},
	},
	yAxis: {
		gridLineColor: '#d5d5d5',
		labels: {
			style: {
				color: '#b5b5b5',
			},
		},
		lineColor: '#c5c5c5',
		minorGridLineColor: 'yellow',
		tickColor: '#d6d6d6',
		tickWidth: 1,
		title: {
			style: {
				color: '#A0A0A3',
			},
		},
	},

	tooltip: {
		pointFormat: '{series.name}: <b>{point.y:,.0f}</b>',
	},
	plotOptions: {
		series: {
			dataLabels: {
				color: '#B0B0B3',
			},
			marker: {
				lineColor: '#333',
			},
			states: {
				hover: {
					lineWidth: 3,
				},
			},
		},
		boxplot: {
			fillColor: '#505053',
		},
		candlestick: {
			lineColor: 'white',
		},
		errorbar: {
			color: 'white',
		},
	},
	credits: {
		enabled: false,
	},
	labels: {
		style: {
			color: '#707073',
		},
	},

	drilldown: {
		activeAxisLabelStyle: {
			color: '#F0F0F3',
		},
		activeDataLabelStyle: {
			color: '#F0F0F3',
		},
	},

	navigation: {
		buttonOptions: {
			symbolStroke: '#DDDDDD',
			theme: {
				fill: '#505053',
			},
		},
	},

	// scroll charts
	rangeSelector: {
		buttonTheme: {
			fill: '#2D2D2D',
			stroke: 'pink',
			style: {
				color: '#5C5C5C',
			},
			states: {
				hover: {
					fill: '#444444',
					stroke: '#000000',
					style: {
						color: 'white',
					},
				},
				select: {
					fill: '#000003',
					stroke: '#000000',
					style: {
						color: 'white',
					},
				},
			},
		},
		inputBoxBorderColor: '#2D2D2D',
		inputStyle: {
			backgroundColor: '#333',
			color: '#5C5C5C',
		},
		labelStyle: {
			color: 'white',
		},
	},

	navigator: {
		handles: {
			backgroundColor: '#262626',
			borderColor: '#5C5C5C',
		},
		outlineColor: '#383838',
		maskFill: 'rgba(255,255,255,0.1)',
		series: {
			color: 'white',
			lineColor: 'none',
		},
		xAxis: {
			gridLineColor: '#383838',
		},
	},

	scrollbar: {
		barBackgroundColor: '#808083',
		barBorderColor: '#808083',
		buttonArrowColor: '#CCC',
		buttonBackgroundColor: '#606063',
		buttonBorderColor: '#606063',
		rifleColor: '#FFF',
		trackBackgroundColor: '#404043',
		trackBorderColor: '#404043',
	},

	// special colors for some of the
	legendBackgroundColor: 'rgba(0, 0, 0, 0.5)',
	background2: '#505053',
	dataLabelsColor: '#B0B0B3',
	textColor: 'blue',
	contrastTextColor: '#F0F0F3',
	maskColor: 'rgba(255,255,255,0.3)',
};

export default highchartsTheme;
