import { combineReducers } from 'redux';
import { routerReducer as router } from 'react-router-redux/lib';
//import { reducer as notifications } from 'mainLibs/Notifications';

import alertStore from './alertStore';
import genericStore from './genericStore';
import modalStore from './modalStore';
import userInfoStore from './userInfoStore';
import apiRequestStore from './apiRequestStore';
import supportedStore from './supportedStore';

export const makeRootReducer = (asyncReducers) => {
	return combineReducers({
		// Add sync reducers her
		apiRequestStore,
		alertStore,
		genericStore,
		modalStore,
		userInfoStore,
		router,
		//notifications,
		supportedStore,
		...asyncReducers,
	});
};

export const injectReducer = (store, { key, reducer }) => {
	store.asyncReducers[key] = reducer;
	store.replaceReducer(makeRootReducer(store.asyncReducers));
};

export default makeRootReducer;
