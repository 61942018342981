import React from 'react';

function getStyle(oElm, strCssRule) {
	var strValue = '';
	if (document.defaultView && document.defaultView.getComputedStyle) {
		strValue = document.defaultView.getComputedStyle(oElm, '').getPropertyValue(strCssRule);
	} else if (oElm.currentStyle) {
		// eslint-disable-next-line no-useless-escape
		strCssRule = strCssRule.replace(/\-(\w)/g, function (strMatch, p1) {
			return p1.toUpperCase();
		});
		strValue = oElm.currentStyle[strCssRule];
	}

	return strValue;
}

function cssStrToNumber(cssStr, size) {
	if (cssStr[cssStr.length - 1] === '%') return (size * cssStr.slice(0, -1)) / 100;
	else return Number(cssStr.slice(0, -2));
}

export function DimensionsSize(node) {
	const wight = node.clientWidth;
	const height = node.clientHeight;

	const padding = {
		top: cssStrToNumber(getStyle(node, 'padding-top'), height),
		right: cssStrToNumber(getStyle(node, 'padding-right'), wight),
		bottom: cssStrToNumber(getStyle(node, 'padding-bottom'), wight),
		left: cssStrToNumber(getStyle(node, 'padding-left'), height),
	};

	const margin = {
		top: cssStrToNumber(getStyle(node, 'margin-top'), height),
		right: cssStrToNumber(getStyle(node, 'margin-right'), wight),
		bottom: cssStrToNumber(getStyle(node, 'margin-bottom'), wight),
		left: cssStrToNumber(getStyle(node, 'margin-left'), height),
	};

	return {
		width: wight,
		height: height,
		padding: padding,
		margin: margin,
		contentWidth: wight - padding.left - padding.right,
		contentHeight: height - padding.top - padding.bottom,
	};
}

export default function Dimensions(ComposedComponent) {
	return class DimensionsComponent extends React.Component {
		constructor(props, context) {
			super(props, context);
			this.state = {
				width: null,
				height: null,
				contentWidth: null,
				contentHeight: null,
				padding: {
					top: null,
					right: null,
					bottom: null,
					left: null,
				},
				margin: {
					top: null,
					right: null,
					bottom: null,
					left: null,
				},
			};
		}

		updateDimensions = () => {
			let dm = DimensionsSize(this._parent);

			if (
				this._parent.clientWidth !== this.state.width ||
				this._parent.clientHeight !== this.state.height
			) {
				this.setState(dm);
			}
		};

		onResize = () => {
			if (this.rqf) return;
			this.rqf = this.getWindow().requestAnimationFrame(() => {
				this.rqf = null;
				this.updateDimensions();
			});
		};

		getWindow = () => {
			return this.refs.container
				? this.refs.container.ownerDocument.defaultView || window
				: window;
		};

		componentDidMount() {
			if (!this.refs.wrapper) {
				console.log('Не удается найти оболочку div');
				throw new Error('Не удается найти оболочку div');
			}

			this._parent = this.refs.wrapper.parentNode;

			this.getWindow().addEventListener('DOMContentLoaded', this.onResize, false);

			this.getWindow().addEventListener('resize', this.onResize, false);
		}

		componentWillUnmount() {
			this.getWindow().removeEventListener('DOMContentLoaded', this.onResize);
			this.getWindow().removeEventListener('resize', this.onResize);
		}

		render() {
			const { width, height } = this.state;
			if (this._parent && !width && !height) {
				console.warn(
					'Оболочка Wrapper не имеет высоты или ширины, попробуйте переопределить стиль с помощью опции `containerStyle`',
				);
			}

			const wrapperStyle = {
				overflow: 'visible',
				height: 'auto',
				width: 'auto',
			};

			return (
				<div style={wrapperStyle} ref="wrapper">
					{(width || height) && <ComposedComponent {...this.state} {...this.props} />}
				</div>
			);
		}
	};
}
