import Stats from './Stats';
import CampaignsStats from './CampaignsStats';
import DailyStats from './DailyStats';
import GeosStats from './GeosStats';
import OffersStats from './OffersStats';
import PreLandingsStats from './PreLandingsStats';
import LandingsStats from './LandingsStats';
import RequestsStats from './RequestsStats';
import SourcesStats from './SourcesStats';

const allStats = {
	Stats,
	CampaignsStats,
	DailyStats,
	GeosStats,
	OffersStats,
	PreLandingsStats,
	LandingsStats,
	RequestsStats,
	SourcesStats,
};

export {
	Stats,
	CampaignsStats,
	DailyStats,
	GeosStats,
	OffersStats,
	PreLandingsStats,
	LandingsStats,
	RequestsStats,
	SourcesStats,
};

export default allStats;
