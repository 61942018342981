import React from 'react';
import _ from 'underscore';
import memoize from 'memoize-one';

import { Form } from 'react-bootstrap';

import {
	InfoForm,
	LogoForm,
	SwitchForm,
	SelectForm,
	InfoFormSubmit,
	MultiSelectForm,
} from '../supportedForm';

export default class EditBlockBodyBox extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			logoPreviewUrl: '',
			campaign: {
				name: null,
				description: null,
				hold: null,
				logo: null,
				callCenter: null,
				traffic: null,
				auditory: null,
				nncpaCallCenter: null,
				publicOffer: null,
				white: null,
				split: null,
				actionType: null,
				category: null,
			},
		};
	}

	componentDidMount() {
		this.props.getActionTypes();
		this.props.getCategories();
	}

	updateOffer(event) {
		event.preventDefault();
		event.stopPropagation();

		function convertObjectToValueArray(object) {
			let container = {};
			for (let prop in object) {
				const value = object[prop];
				if (_.isArray(value)) {
					container[prop] = _.pluck(value, 'value');
				} else if (_.isObject(value) && prop !== 'logo') {
					container[prop] = value.value;
				} else {
					container[prop] = value;
				}
			}
			return container;
		}
		const campaign = convertObjectToValueArray(this.state.campaign);

		if (this.props.offerId === 'new') {
			if (_.isNull(campaign.nncpaCallCenter)) campaign.nncpaCallCenter = false;
			if (_.isNull(campaign.publicOffer)) campaign.publicOffer = false;
			if (_.isNull(campaign.white)) campaign.white = false;
			if (_.isNull(campaign.split)) campaign.split = false;
			let checkData = true;
			if (_.isNull(campaign.name)) checkData = false;
			if (_.isNull(campaign.logo)) checkData = false;
			if (_.isNull(campaign.category)) checkData = false;
			if (_.isNull(campaign.actionType)) checkData = false;
			if (checkData) {
				let data = new FormData();
				data.append('name', campaign.name);
				data.append('description', campaign.description || '');
				data.append('hold', campaign.hold || '');
				data.append('logo', campaign.logo);
				data.append('callCenter', campaign.callCenter || '');
				data.append('traffic', campaign.traffic || '');
				data.append('auditory', campaign.auditory || '');
				data.append('nncpaCallCenter', campaign.nncpaCallCenter);
				data.append('publicOffer', campaign.publicOffer);
				data.append('white', campaign.white);
				data.append('split', campaign.split);
				data.append('actionType', campaign.actionType);
				data.append('category', campaign.category);
				this.props.createOffer(data, this.onResetClick);
			} else {
				this.props.alertShow({
					message: 'Необходимо заполнить выделенные поля',
					type: 'danger',
				});
			}
		} else {
			let data = new FormData();
			for (let prop in campaign) {
				if (!_.isNull(campaign[prop])) data.append(prop, campaign[prop]);
			}
			this.props.updateOffer(this.props.offerId, data, this.onResetClick);
		}
	}

	changeOfferInfo = (event, fieldName) => {
		var campaign = { ...this.state.campaign };
		campaign[fieldName] = event ? (event.target ? event.target.value : event) : null;
		this.setState({ campaign });
	};

	changeOfferInfoSwitchType = (value, fieldName) => {
		var campaign = { ...this.state.campaign };
		campaign[fieldName] = _.isBoolean(value) ? value : false;
		this.setState({ campaign });
	};

	changeOfferLogo = (e) => {
		e.preventDefault();
		e.stopPropagation();
		let campaign = { ...this.state.campaign };
		if (e.target.files[0]) {
			let reader = new FileReader();
			campaign.logo = e.target.files[0];
			reader.onloadend = () => {
				this.setState({
					logoPreviewUrl: reader.result,
				});
			};
			this.setState({
				campaign: campaign,
			});
			reader.readAsDataURL(campaign.logo);
		} else {
			campaign.logo = '';
			this.setState({
				campaign: campaign,
				logoPreviewUrl: '',
			});
		}
	};

	onResetClick = () => {
		this.setState(() => ({
			campaign: {
				name: null,
				description: null,
				hold: null,
				logo: null,
				callCenter: null,
				traffic: null,
				auditory: null,
				nncpaCallCenter: null,
				publicOffer: null,
				white: null,
				actionType: null,
				category: null,
				split: null,
			},
			logoPreviewUrl: '',
		}));
	};

	getReactSelectOptionsFromActionTypes = memoize((actionTypes) =>
		actionTypes.map((actionType) => ({
			value: actionType._id,
			label: actionType.name + ' (' + actionType.description + ')',
		})),
	);

	parseCategory(categories) {
		return categories.map((category) => ({
			value: category._id,
			label: category.name,
		}));
	}
	getReactSelectOptionsFromCategories = memoize(this.parseCategory);
	getMemoizeCategory = memoize(this.parseCategory);

	render() {
		const { t, offerId } = this.props;
		const actionTypes = this.getReactSelectOptionsFromActionTypes(this.props.actionTypes || []);
		const categories = this.getReactSelectOptionsFromCategories(this.props.categories || []);
		const actionType = this.props.actionType ? this.props.actionType._id : '';
		const category = this.getMemoizeCategory(this.props.category || []);
		return (
			<div className="box-body">
				<Form horizontal onSubmit={this.updateOffer.bind(this)}>
					<InfoForm
						value={this.state.campaign.name}
						defaultValue={this.props.name}
						label={t('Название оффера')}
						placeholder={t('Название оффера')}
						changeInfo={this.changeOfferInfo}
						name={'name'}
						required={offerId === 'new'}
					/>

					<InfoForm
						value={this.state.campaign.description}
						defaultValue={this.props.description}
						label={t('Описание товара')}
						placeholder={t('Описание товара')}
						changeInfo={this.changeOfferInfo}
						name={'description'}
						type={'textarea'}
					/>

					<InfoForm
						value={this.state.campaign.hold}
						defaultValue={this.props.hold}
						label={t('Холд (дней)')}
						placeholder={t('Холд (дней)')}
						changeInfo={this.changeOfferInfo}
						name={'hold'}
						type={'number'}
					/>

					<InfoForm
						value={this.state.campaign.auditory}
						defaultValue={this.props.auditory}
						label={t('Целевая аудитория')}
						placeholder={t('Целевая аудитория')}
						changeInfo={this.changeOfferInfo}
						name={'auditory'}
					/>

					<InfoForm
						value={this.state.campaign.traffic}
						defaultValue={this.props.traffic}
						label={t('Траффик')}
						placeholder={t('Траффик')}
						changeInfo={this.changeOfferInfo}
						name={'traffic'}
					/>

					<SelectForm
						value={this.state.campaign.actionType}
						defaultValue={actionType || ''}
						label={t('Тип действия')}
						changeInfo={this.changeOfferInfo}
						name={'actionType'}
						options={actionTypes}
						required={offerId === 'new'}
					/>

					<MultiSelectForm
						value={this.state.campaign.category}
						label={t('Категория товара')}
						placeholder={t('Категории')}
						defaultValue={category}
						changeInfo={this.changeOfferInfo}
						name={'category'}
						options={categories}
						required={offerId === 'new'}
					/>

					<InfoForm
						value={this.state.campaign.callCenter}
						defaultValue={this.props.callCenter}
						label={t('Колл-центр')}
						placeholder={t('Колл-центр')}
						changeInfo={this.changeOfferInfo}
						name={'callCenter'}
					/>

					<SwitchForm
						value={this.state.campaign.nncpaCallCenter}
						defaultValue={this.props.nncpaCallCenter}
						label={t('NNCPA колл-центр')}
						changeInfo={this.changeOfferInfoSwitchType}
						name={'nncpaCallCenter'}
					/>

					<SwitchForm
						value={this.state.campaign.publicOffer}
						defaultValue={this.props.publicOffer}
						label={t('Публичный оффер')}
						changeInfo={this.changeOfferInfoSwitchType}
						name={'publicOffer'}
						required={offerId === 'new'}
					/>

					<SwitchForm
						value={this.state.campaign.white}
						defaultValue={this.props.white}
						label={t('White-оффер')}
						changeInfo={this.changeOfferInfoSwitchType}
						name={'white'}
						required={offerId === 'new'}
					/>

					<SwitchForm
						value={this.state.campaign.split}
						defaultValue={this.props.split}
						label={t('Split-оффер')}
						changeInfo={this.changeOfferInfoSwitchType}
						name={'split'}
						required={offerId === 'new'}
					/>

					<LogoForm
						logo={this.state.logoPreviewUrl}
						label={t('Фото товара')}
						file={this.state.campaign.logo}
						changeInfo={this.changeOfferLogo}
						required={offerId === 'new'}
					/>

					<InfoFormSubmit
						labelSubmit={t('Сохранить')}
						labelReset={t('Сбросить')}
						onResetClick={this.onResetClick}
					/>
				</Form>
			</div>
		);
	}
}
