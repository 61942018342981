import React from 'react';
import _ from 'underscore';
import memoize from 'memoize-one';
import { Form } from 'react-bootstrap';

import { InfoForm, InfoFormSubmit, SelectForm } from '../supportedForm';

export default class EditBlock extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			pwaOptions: {
				name: null,
				landing: null,
				middlePage: null,
			},
		};
	}

	addPwaOptions = (e) => {
		e.preventDefault();
		e.stopPropagation();
		const pwaOptions = { ...this.state.pwaOptions };
		if (!_.findKey(pwaOptions, (elem) => _.isNull(elem))) {
			this.props.addPwaOptions({
				name: this.state.pwaOptions.name,
				landing: this.state.pwaOptions.landing,
				middlePage: this.state.pwaOptions.middlePage,
				offer: this.props.offerId,
			});
			this.onResetClick();
		} else {
			this.props.alertShow({ message: 'Необходимо заполнить все поля', type: 'danger' });
		}
	};

	changePwaOptions = (e, fieldName) => {
		e.preventDefault && e.preventDefault();
		e.stopPropagation && e.stopPropagation();
		const value = e.target ? e.target.value : e.value;
		this.setState((prevState) => {
			const pwaOptions = { ...prevState.pwaOptions };
			pwaOptions[fieldName] = value;
			return {
				...prevState,
				pwaOptions,
			};
		});
	};

	onResetClick = () => {
		this.setState({
			pwaOptions: {
				name: null,
				landing: null,
				middlePage: null,
			},
		});
	};

	checkProperty = () => {
		return !!_.findKey(this.state.pwaOptions, (elem) => _.isNull(elem));
	};

	getlandingsOptions = memoize((landings) => {
		return landings.map((landing) => ({
			label: landing.name,
			value: landing._id,
			data: landing,
		}));
	});

	getMiddlePageOptions = memoize((middlePages) => {
		return middlePages.map((middlePage) => ({
			label: middlePage.name,
			value: middlePage._id,
			data: middlePage,
		}));
	});

	getOptionValue = (options, value) => {
		return options.find((o) => o.value === value) || null;
	};

	render() {
		const { t } = this.props;
		const disabled = this.checkProperty();

		const landingsOptions = this.getlandingsOptions(this.props.landings);
		const middlePageOptions = this.getMiddlePageOptions(this.props.middlePages);

		return (
			<div className={'box box-danger'}>
				<div className="box-header with-border">
					<h3 className="box-title">{t('Добавить PWA-опцию')}</h3>
				</div>

				<div className="box-body">
					<Form horizontal onSubmit={this.addPwaOptions}>
						<InfoForm
							value={this.state.pwaOptions.name}
							label={t('Название')}
							placeholder={t('Название')}
							changeInfo={this.changePwaOptions}
							name={'name'}
						/>

						<SelectForm
							value={this.getOptionValue(
								landingsOptions,
								this.state.pwaOptions.landing,
							)}
							options={landingsOptions}
							label={'Выберите лендинг'}
							name="landing"
							changeInfo={this.changePwaOptions}
						/>

						<SelectForm
							value={this.getOptionValue(
								middlePageOptions,
								this.state.pwaOptions.middlePage,
							)}
							options={middlePageOptions}
							label={'Выберите прелендинг'}
							name="middlePage"
							changeInfo={this.changePwaOptions}
						/>

						<InfoFormSubmit
							disabled={disabled}
							labelSubmit={t('Добавить PWA опцию')}
							labelReset={t('Сбросить')}
							onResetClick={this.onResetClick}
						/>
					</Form>
				</div>
			</div>
		);
	}
}
