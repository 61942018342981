import React from 'react';
import MainFunctions from 'mainLibs/MainFunctions';

import { Media, Image } from 'react-bootstrap';

import OpenImage from '../OpenImage';

import classes from './Chat.module.css';

export default class ChatMessage extends React.PureComponent {
	state = {
		image: null,
	};

	selectImage = (image) => {
		this.setState({
			image,
		});
	};

	render() {
		const { message, userInfo, adminInfo, small } = this.props;
		const leftAligned = message.sentByUser;
		const images = Array.isArray(message.images) ? message.images : [];
		const numImages = 5;

		let countImages = images.length;
		if (countImages > numImages) countImages = numImages;
		return (
			<Media
				className={`${classes.chatMessage} ${leftAligned && classes.leftAligned} ${
					!!parseInt(message.Active, 10) && classes.active
				}`}
			>
				{leftAligned && !small && (
					<Media.Left>
						<Image style={{ width: '40px' }} src={userInfo.avatar} circle />
					</Media.Left>
				)}
				<Media.Body>
					{leftAligned ? (
						<div>
							<div
								className={`${classes.chatMessageContent} pull-left`}
								style={{
									width: small ? '85%' : '75%',
								}}
							>
								{message.message}
								<div className={classes.chatMessageFooter + ' text-black'}>
									<small>{MainFunctions.dateFormat(message.date)}</small>
									<small>
										{(userInfo.lastName || '') + ' ' + (userInfo.name || '')}
									</small>
								</div>
								<div>
									{images.map((image, index) => {
										if (index % numImages === 0) {
											countImages = images.length - index;
											if (countImages > numImages) countImages = numImages;
										}
										const widthImg = 100 / countImages;
										return (
											<OpenImage
												style={{
													maxHeight: '200px',
													maxWidth: `${widthImg}%`,
												}}
												alt=""
												src={image}
											/>
										);
									})}
								</div>
							</div>
						</div>
					) : (
						<div>
							<div
								className={`${classes.chatMessageContent} pull-right`}
								style={{
									width: small ? '85%' : '75%',
								}}
							>
								{message.message}
								<div className={classes.chatMessageFooter + ' text-black'}>
									<small>{MainFunctions.dateFormat(message.date)}</small>
									<small>
										{(adminInfo.lastName || '') + ' ' + (adminInfo.name || '')}
									</small>
								</div>

								<div>
									{images.map((image, index) => {
										if (index % numImages === 0) {
											countImages = images.length - index;
											if (countImages > numImages) countImages = numImages;
										}
										const widthImg = 100 / countImages;
										return (
											<OpenImage
												style={{
													maxHeight: '200px',
													maxWidth: `${widthImg}%`,
												}}
												alt=""
												src={image}
											/>
										);
									})}
								</div>
							</div>
						</div>
					)}
				</Media.Body>
				{!leftAligned && !small && (
					<Media.Right>
						<Image style={{ width: '40px' }} src={adminInfo.avatar} circle />
					</Media.Right>
				)}
			</Media>
		);
	}
}
