import React from 'react';
import ReactDOM from 'react-dom';
import { FormGroup, InputGroup, FormControl, Button } from 'react-bootstrap';
import TableInfo from './TableInfo';

export default class AprovedAccess extends React.PureComponent {
	addUser = () => {
		const data = {
			field: this.props.type,
			type: 'add',
			user: ReactDOM.findDOMNode(this.select).value,
		};
		this.props.updateOfferAccess(this.props.offerId, data);
	};

	deleteUser = (userId) => {
		const data = {
			field: this.props.type,
			type: 'delete',
			user: userId,
		};
		this.props.updateOfferAccess(this.props.offerId, data);
	};

	render() {
		const { t, users, affiliates } = this.props;
		return (
			<div className="box box-primary">
				<FormGroup style={{ marginTop: '20px' }}>
					<InputGroup>
						<FormControl
							inputRef={(ref) => (this.select = ref)}
							componentClass="select"
						>
							{affiliates.map((option) => (
								<option key={option._id} value={option._id}>
									{`${option.username} (${option._id})`}
								</option>
							))}
						</FormControl>
						<InputGroup.Button>
							<Button onClick={this.addUser} bsStyle="danger">
								{t('Добавить')}
							</Button>
						</InputGroup.Button>
					</InputGroup>
				</FormGroup>

				<TableInfo t={t} data={users} deleteUser={this.deleteUser} />
			</div>
		);
	}
}
