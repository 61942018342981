import React from 'react';
import _ from 'underscore';
import memoize from 'memoize-one';
import { FormGroup, Button } from 'react-bootstrap';

import GeoDetail from './GeoDetail';
import OwnersDetail from './OwnersDetail';

export default class Detail extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			changedGeo: {
				geoRef: null,
				currency: null,
				landingPrice: null,
				oldLandingPrice: null,
				payout: null,
			},
			owners: null,
			changed: false,
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.geo !== this.props.geo) this.reset();
	}

	changeGeoInfo = (event, fieldName) => {
		let changedGeo = { ...this.state.changedGeo };
		let value = null;
		let selectedValue = {};
		if (event)
			if (event.target) value = event.target.value;
			else {
				value = event.value;
				selectedValue = event;
			}
		changedGeo[fieldName] = value;
		if (fieldName === 'geoRef' && selectedValue.currency)
			changedGeo.currency = selectedValue.currency;
		this.setState({ changedGeo, changed: true });
	};

	addOwner = (owner) => {
		let owners = this.state.owners ? this.state.owners.slice() : this.props.geo.owners.slice();
		const ownerData = {
			...owner,
		};
		for (const key in ownerData) {
			if (Object.hasOwnProperty.call(ownerData, key)) {
				if (ownerData[key] === null) delete ownerData[key];
			}
		}
		owners.push(ownerData);
		this.setState(() => ({ owners, changed: true }));
	};

	deleteOwner = (id) => {
		let owners = this.state.owners ? this.state.owners.slice() : this.props.geo.owners.slice();
		owners = _.reject(owners, (owner) => owner.ownerId === id);
		this.setState(() => ({ owners, changed: true }));
	};

	changeOwnerInfo = (owner) => {
		let owners = this.state.owners ? this.state.owners.slice() : this.props.geo.owners.slice();
		const index = owners.findIndex((t) => t.ownerId === owner.ownerId);
		owners[index] = owner;
		this.setState(() => ({ owners, changed: true }));
	};

	clearChangedOwners = () => this.setState({ owners: null });

	getParseData = () => {
		const geo = { ...this.state.changedGeo };
		const data = {
			geo: {},
		};
		if (!this.props.newGeo) data.id = this.props.geo._id;
		for (let prop in geo) {
			if (!_.isNull(geo[prop])) data.geo[prop] = geo[prop];
		}
		if (data.geo.payout) {
			data.geo.payout = {
				value: data.geo.payout,
				ranking: 0,
			};
		}
		if (this.state.owners) data.geo.owners = this.state.owners.slice();
		return data;
	};

	addGeo = (event) => {
		event.preventDefault();
		event.stopPropagation();
		const data = this.getParseData();
		this.props.addOfferGeo(this.props.offerId, data);
	};

	updateGeo = (event) => {
		event.preventDefault();
		event.stopPropagation();
		const data = this.getParseData();
		this.props.updateOfferGeo(this.props.offerId, data);
	};

	deleteGeo = (event) => {
		event.preventDefault();
		event.stopPropagation();
		this.props.deleteOfferGeo(this.props.offerId, { id: this.props.geo._id });
	};

	reset = (callback) => {
		this.setState(
			{
				changedGeo: {
					geoRef: null,
					currency: null,
					landingPrice: null,
					oldLandingPrice: null,
					payout: null,
				},
				owners: null,
				changed: false,
			},
			callback,
		);
	};

	onResetClick = (event) => {
		event.preventDefault();
		event.stopPropagation();
		this.reset();
	};

	getMemoizeGeos = memoize((geos) => geos || [], _.isEqual);

	render() {
		const { t, newGeo, geo, currencies, merchants, edited } = this.props;
		const geos = this.getMemoizeGeos(this.props.geos);
		const owners = this.state.owners ? this.state.owners : geo.owners;
		return (
			<div style={{ paddingTop: '10px' }}>
				<GeoDetail
					edited={edited}
					newGeo={newGeo}
					t={t}
					geos={geos}
					currencies={currencies}
					changedGeo={this.state.changedGeo}
					changeGeoInfo={this.changeGeoInfo}
					{...geo}
				/>

				<OwnersDetail
					t={t}
					split={this.props.split}
					edited={edited}
					owners={owners}
					merchants={merchants}
					addOwner={this.addOwner}
					deleteOwner={this.deleteOwner}
					changeOwnerInfo={this.changeOwnerInfo}
					alertShow={this.props.alertShow}
					changed={this.state.owners}
					clearChangedOwners={this.clearChangedOwners}
				/>
				{edited && (
					<FormGroup>
						<Button
							onClick={newGeo ? this.addGeo : this.updateGeo}
							bsStyle="danger"
							disabled={!this.state.changed}
						>
							{newGeo ? t('Добавить гео') : t('Сохранить')}
						</Button>
						<Button
							onClick={this.onResetClick}
							style={{ marginLeft: '10px' }}
							bsStyle="danger"
						>
							{t('Сбросить')}
						</Button>
						{!newGeo && (
							<Button
								onClick={this.deleteGeo}
								style={{ marginLeft: '10px' }}
								bsStyle="danger"
							>
								{t('Удалить')}
							</Button>
						)}
					</FormGroup>
				)}
			</div>
		);
	}
}
