/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import querystring from 'querystring';
import { Link } from 'react-router-dom';

import { Row, Col, Thumbnail } from 'react-bootstrap';

export default class LandingItem extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			showLandings: false,
		};
	}

	hideModal = () => this.setState({ show: false, warning: [], callbackWarning: null });

	showLandings = () => this.setState({ showLandings: true });

	closeLandings = () => this.setState({ showLandings: false });

	render() {
		const { t, data } = this.props;
		const temp = {
			copy: this.props.data.copy,
			original: this.props.data.original,
		};
		const params = querystring.stringify({
			landing: JSON.stringify(temp),
		});
		if (data.hidden) return null;
		else
			return (
				<Col xs={12} md={6} className="user-landing-item">
					<div className={'user-offer-item'}>
						<Link to={'/stats/landings/?' + params}>
							<div className={'user-landing-item-detail'}>
								<Thumbnail src={data.copy.screenshot} />
								<div className="overlay-block" />
								<h1>
									<b>{t('Подробнее')}</b>
								</h1>
							</div>
						</Link>

						<Row style={{ marginBottom: '10px' }}>
							<Col xs={6} className="text-center">
								<div className="hr-text hr-text-center m-t-1">
									<strong>{t('EPC')}</strong>
								</div>
								{t('Копия') + `: ${data.copy.stats.epc}`}
								<br />
								{t('Оригинал') + `: ${data.original.stats.epc}`}
							</Col>

							<Col xs={6} className="text-center">
								<div className="hr-text hr-text-center m-t-1">
									<strong>{t('CR')}</strong>
								</div>
								{t('Копия') + `: ${data.copy.stats.cr}`}
								<br />
								{t('Оригинал') + `: ${data.original.stats.cr}`}
							</Col>
						</Row>

						<dl className="dl-horizontal">
							<dt>{t('Название')}</dt>
							<dd>{data.copy.name}</dd>

							<dt>{t('Url (копия)')}</dt>
							<dd>
								<a href={data.copy.url} target="_blank" className="site-link">
									{data.copy.url}
								</a>
							</dd>

							<dt>{t('Url (оригинал)')}</dt>
							<dd>
								<a href={data.original.url} target="_blank" className="site-link">
									{data.original.url}
								</a>
							</dd>
						</dl>
					</div>
				</Col>
			);
	}
}
