import React from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import LazyLoad from 'react-lazy-load';
import LocalOffer from './LocalOffer';

export default class InfoBlock extends React.PureComponent {
	constructor(props) {
		super(props);
		this.disabled = false;
		this.state = {
			show: false,
		};
	}

	handleClose = () => {
		this.setState({ show: false });
		this.disabled = this.props.disabled;
	};

	handleShow = () => {
		this.setState({ show: true });
	};

	sendChange = () => {
		let data = new FormData();
		data.append('disabled', this.disabled);
		this.props.updateOffer(this.props.offerId, data, this.handleClose);
	};

	onСhangeOfferDisabled = (value) => {
		this.disabled = !this.props.disabled;
		this.handleShow();
	};

	render() {
		const { expand, t } = this.props;
		return (
			<Row className="offerItemBlock">
				<div className="offerItemOuter">
					<Col xs={12}>
						<Col lg={3} md={4} sm={4} xs={12} className="offerItemLeft">
							<div className="offerImage">
								<img src={this.props.logo} alt="" />
							</div>
							<h3>{this.props.name}</h3>
						</Col>

						<Col lg={9} md={8} sm={8} xs={12}>
							<Col sm={12} xs={12} className="offerDescriptionRow">
								<Col xs={4}>{t('Колл-центр')}:</Col>
								<Col xs={8}>{this.props.callCenter}</Col>
							</Col>

							<Col sm={12} xs={12} className="offerDescriptionRow">
								<Col xs={4}>{t('ЦА')}:</Col>
								<Col xs={8}>{this.props.auditory}</Col>
							</Col>

							<Col sm={12} xs={12} className="offerDescriptionRow">
								<Col xs={4}>{t('Траффик')}:</Col>
								<Col xs={8}>{this.props.traffic}</Col>
							</Col>

							{!expand && (
								<Col sm={12} xs={12} className="offerDescriptionRow">
									<Col xs={4}>
										{' '}
										<Button
											bsStyle={this.props.disabled ? 'success' : 'danger'}
											onClick={this.onСhangeOfferDisabled}
										>
											{this.props.disabled ? t('Включить') : t('Отключить')}
										</Button>
									</Col>
								</Col>
							)}

							{expand && (
								<Col sm={12} xs={12} className="offerDescriptionRow">
									<Col xs={4}>{t('Описание товара')}:</Col>
									<Col xs={8}>{this.props.description}</Col>
								</Col>
							)}

							{expand && (
								<Col sm={12} xs={12} className="offerDescriptionRow">
									<Col xs={4}>{t('Тип действия')}:</Col>
									<Col xs={8}>
										{this.props.actionType
											? this.props.actionType.name +
											  ' (' +
											  this.props.actionType.description +
											  ')'
											: ''}
									</Col>
								</Col>
							)}

							{expand && (
								<Col sm={12} xs={12} className="offerDescriptionRow">
									<Col xs={4}>{t('Категории товара')}:</Col>
									<Col xs={8} style={{ lineHeight: '2' }}>
										{Array.isArray(this.props.category)
											? this.props.category.map(
													(categoty, index) =>
														categoty && (
															<div key={categoty._id + index}>
																{' '}
																{categoty.name}{' '}
															</div>
														),
											  )
											: this.props.category.name}
									</Col>
								</Col>
							)}

							{expand && (
								<Col sm={12} xs={12} className="offerDescriptionRow">
									<Col xs={4}>{t('NNCPA колл-центр')}:</Col>
									<Col xs={8}>
										{this.props.nncpaCallCenter ? (
											<i
												className={'fa fa-lg fa-check-circle'}
												style={{ color: '#99c262' }}
											/>
										) : (
											<i
												className={'fa fa-lg fa-times-circle'}
												style={{ color: '#ff3232' }}
											/>
										)}
									</Col>
								</Col>
							)}

							{expand && (
								<Col sm={12} xs={12} className="offerDescriptionRow">
									<Col xs={4}>{t('Публичный оффер')}:</Col>
									<Col xs={8}>
										{this.props.publicOffer ? (
											<i
												className={'fa fa-lg fa-check-circle'}
												style={{ color: '#99c262' }}
											/>
										) : (
											<i
												className={'fa fa-lg fa-times-circle'}
												style={{ color: '#ff3232' }}
											/>
										)}
									</Col>
								</Col>
							)}

							{expand && (
								<Col sm={12} xs={12} className="offerDescriptionRow">
									<Col xs={4}>{t('White-оффер')}:</Col>
									<Col xs={8}>
										{this.props.white ? (
											<i
												className={'fa fa-lg fa-check-circle'}
												style={{ color: '#99c262' }}
											/>
										) : (
											<i
												className={'fa fa-lg fa-times-circle'}
												style={{ color: '#ff3232' }}
											/>
										)}
									</Col>
								</Col>
							)}

							{this.props.locals
								? this.props.locals.map(function (local, lKey) {
										return (
											<LazyLoad
												overflow={true}
												once={true}
												height={60}
												key={lKey}
											>
												<LocalOffer local={local} />
											</LazyLoad>
										);
								  })
								: null}
						</Col>

						<Modal show={this.state.show} onHide={this.handleClose}>
							<Modal.Header closeButton>
								<Modal.Title>{t('Подтвердите действие')}</Modal.Title>
							</Modal.Header>

							<Modal.Footer>
								<Button
									onClick={this.sendChange}
									bsStyle={'success'}
									className="pull-left"
								>
									{t('Сохранить изменения')}
								</Button>
								<Button
									onClick={this.handleClose}
									bsStyle={'danger'}
									className="pull-right"
								>
									{t('Отмена')}
								</Button>
							</Modal.Footer>
						</Modal>
					</Col>
				</div>
			</Row>
		);
	}
}
