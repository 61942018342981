import React from 'react';
import _ from 'underscore';
import memoize from 'memoize-one';
import { connect } from 'react-redux';
import GeosStatsTable from './GeosStatsTable';
import MainFunctions from 'mainLibs/MainFunctions';

// Изспользуем мемоизацию, чтобы не парсить данные при каждом рендеринге
const parseGeos = memoize((stats) => {
	if (!Object.keys(stats).length) return [];
	let geos = [],
		visitsGrouped = _.groupBy(stats.visits, (visit) => {
			return visit.geo && visit.geo._id.toString();
		}),
		actionsGrouped = _.groupBy(stats.actions, (action) => {
			return action.geo && action.geo.toString();
		});

	if (!Object.keys(visitsGrouped).length && !Object.keys(actionsGrouped).length) {
		return [];
	}

	let geoArray = _.chain(stats.visits)
		.pluck('geo')
		.flatten()
		.unique(false, function (geo) {
			if (geo) return geo._id;
		})
		.value();

	geoArray = _.filter(geoArray, (geo) => {
		return geo !== undefined;
	});

	_.each(Object.keys(visitsGrouped), (key) => {
		if (key === 'undefined') {
			delete visitsGrouped[key];
		}
	});
	let geosIds = _.uniq(Object.keys(visitsGrouped).concat(Object.keys(actionsGrouped)));

	for (var i = 0; i < geosIds.length; i++) {
		let currentGeoId = geosIds[i];
		let actionsParsed = _.groupBy(actionsGrouped[currentGeoId] || [], (action) => {
			let actionStatus = _.last(action.statusesFlow).status.name;
			if (actionStatus === 'Approved') return 'A';
			else if (actionStatus === 'Declined') return 'D';
			else if (actionStatus === 'Trash') return 'T';
			else if (actionStatus === 'Pending') return 'P';
		});

		let validApprove =
			(((actionsParsed.A || []).length || 0) /
				((actionsParsed.A || []).length +
					(actionsParsed.D || []).length +
					(actionsParsed.P || []).length)) *
			100;
		// let statusApprove = (((actionsParsed.A || []).length || 0) / ((actionsParsed.D || []).length || 0)) * 100;
		let statusApprove =
			(((actionsParsed.A || []).length || 0) /
				((actionsParsed.A || []).length +
					(actionsParsed.D || []).length +
					(actionsParsed.P || []).length +
					(actionsParsed.T || []).length)) *
			100;
		//получаем уникальные клики (от одного посетителя)
		let uniqClicks = _.groupBy(visitsGrouped[currentGeoId] || [], (visit) => {
			return visit.techInfo.ip;
		});
		let CR =
			(((actionsParsed.A || []).length +
				(actionsParsed.D || []).length +
				(actionsParsed.P || []).length) /
				((Object.keys(uniqClicks) || []).length || 0)) *
			100;
		let epc =
			_.reduce(actionsParsed.A || [], (memo, action) => memo + action.fixedPayout, 0) /
			((Object.keys(uniqClicks) || []).length || 0);

		let approvedMoney = _.reduce(
			actionsParsed.A || [],
			(memo, action) => memo + action.fixedPayout,
			0,
		);
		let holdMoney = _.reduce(
			actionsParsed.P || [],
			(memo, action) => memo + action.fixedPayout,
			0,
		);
		let declinedMoney = _.reduce(
			actionsParsed.D || [],
			(memo, action) => memo + action.fixedPayout,
			0,
		);

		let allMoney = approvedMoney + holdMoney + declinedMoney;

		geos.push({
			geo: _.find(geoArray, (geo) => {
				return geo._id.toString() === currentGeoId;
			}).name,
			id: currentGeoId,
			uniqClicks: MainFunctions.formatNumber(Object.keys(uniqClicks).length, 0),
			allClicks: MainFunctions.formatNumber(
				(visitsGrouped[currentGeoId] || []).length || 0,
				0,
			),
			CR: MainFunctions.formatNumber(isNaN(CR) || !isFinite(CR) ? 0 : CR, 2) + ' %',
			epc: MainFunctions.formatNumber(isNaN(epc) ? 0 : epc, 2) + ' р.',

			allActions: MainFunctions.formatNumber(
				(actionsParsed.A || []).length +
					(actionsParsed.P || []).length +
					(actionsParsed.D || []).length,
				0,
			),
			approvedActions: MainFunctions.formatNumber((actionsParsed.A || []).length, 0),
			holdActions: MainFunctions.formatNumber((actionsParsed.P || []).length, 0),
			declinedActions: MainFunctions.formatNumber((actionsParsed.D || []).length, 0),
			trashActions: MainFunctions.formatNumber((actionsParsed.T || []).length, 0),

			allMoney: MainFunctions.formatNumber(allMoney, 0) + ' р',
			approvedMoney: MainFunctions.formatNumber(approvedMoney, 0) + ' р',
			holdMoney: MainFunctions.formatNumber(holdMoney, 0) + ' р',
			declinedMoney: MainFunctions.formatNumber(declinedMoney, 0) + ' р',
			validApprove:
				MainFunctions.formatNumber((actionsParsed.A || []).length, 2) +
				' (' +
				MainFunctions.formatNumber(isNaN(validApprove) ? 0 : validApprove, 2) +
				' %)',
			statusApprove:
				MainFunctions.formatNumber((actionsParsed.A || []).length, 2) +
				' (' +
				MainFunctions.formatNumber(isNaN(statusApprove) ? 0 : statusApprove, 2) +
				' %)',
		});
	}
	return geos;
});

class GeosStats extends React.Component {
	getNoDataText = memoize((loading, text) => {
		if (loading) {
			return (
				<div className="pageOverlay">
					<i className="fa fa-spinner css--animate" />
				</div>
			);
		} else return text;
	});

	render() {
		let geos = parseGeos(this.props.geos.data);
		const noDataText = this.getNoDataText(this.props.geos.loading, this.props.t('Нет данных'));
		return (
			<React.Fragment>
				{this.props.geos.loading && (
					<div className="pageOverlay">
						<i className="fa fa-spinner css--animate" />
					</div>
				)}
				<GeosStatsTable t={this.props.t} geos={geos} noDataText={noDataText} />
			</React.Fragment>
		);
	}
}

const mapStateToProps = ({ apiRequestStore }) => ({
	geos: apiRequestStore.affiliateStats.geos,
});

export default connect(mapStateToProps, null)(GeosStats);
