export const error = {
	title: 'Ошибка!',
	message: 'Произошла непредвиденная ошибка!',
	type: 'danger',
};

export const error_response = {
	title: 'Во время загрузки данных произошла ошибка!',
	message: 'Отображаемые данные могут быть не актуальны!',
	type: 'danger',
};

export const error_request = {
	title: 'Не удалось отправить запрос!',
	message: 'Проверьте подключение к интернету!',
	type: 'danger',
};

export const error_400 = {
	title: 'Некорректный запрос, отображаемые данные могут быть неверные!',
	message: 'Очистите кэш браузера и куки и обновите страницу.',
	type: 'danger',
};

export const error_401 = {
	title: 'Доступ запрещен!',
	message: 'Чтобы получить доступ к данным авторизуйтесь.',
	type: 'danger',
};

export const error_403 = {
	title: 'Отказано в доступе!',
	message: 'У вас нет прав на получение данных',
	type: 'danger',
};

export const error_404 = {
	title: 'Запрашиваемый ресурс не найден!',
	message: 'Авторизуйтесь и повторите запрос',
	type: 'danger',
};

export const error_408 = {
	title: 'Request Timeout!',
	message: 'Истекло время ожидания запроса',
	type: 'danger',
};

export const error_410 = {
	title: 'Удалено!',
	message: 'Запрашиваемый ресурс удален',
	type: 'danger',
};

export const error_500 = {
	title: 'Ошибка на сервере!',
	message: 'Повторите запрос позже',
	type: 'danger',
};

export const error_502 = {
	title: 'BadGateway!',
	message: 'Сервис временно недоступен',
	type: 'danger',
};

export const error_503 = {
	title: 'Сервер временно не доступен!',
	message: 'Ведется техническое обслуживание, повторите запрос позже',
	type: 'danger',
};

export const error_504 = {
	title: 'GatewayTimeout!',
	message: 'Возможно плохое сетевое соединение',
	type: 'danger',
};
