import React from 'react';

import EditBlock from './EditBlock';
import PwaOptionsItem from './PwaOptionsItem';
import { Row } from 'react-bootstrap';

export default class PwaOptionsBlock extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			boxCollapse: false,
		};
	}

	componentDidMount() {
		this.props.getPwaOptions({ offer: this.props.offerId });
	}

	toggleBox = (e) => {
		e.preventDefault();
		this.setState((prevState) => {
			return {
				boxCollapse: !prevState.boxCollapse,
			};
		});
	};

	renderPwaOptionsItem = (data, i) => {
		const landing = this.props.landings.find((landing) => landing._id === data.landing) || {
			_id: data.landing,
			name: 'Удален',
		};
		const middlePage = this.props.middlePages.find(
			(middlePage) => middlePage._id === data.middlePage,
		) || {
			_id: data.middlePage,
			name: 'Удален',
		};
		return (
			<PwaOptionsItem
				key={data._id}
				t={this.props.t}
				i={i}
				data={data}
				hidden={data.hidden}
				offerId={this.props.offerId}
				landing={landing}
				middlePage={middlePage}
				edited={this.props.edited}
				deletePwaOptions={this.props.deletePwaOptions}
				deleting={this.props.pwaOptionsState.deleting}
			/>
		);
	};

	render() {
		const {
			t,
			edited,
			pwaOptionsState,
			offerId,
			addLanding,
			alertShow,
			landings,
			middlePages,
		} = this.props;

		return (
			<div className={'box box-primary' + (this.state.boxCollapse ? ' collapsed-box' : '')}>
				<div className="box-header with-border">
					<h3 className="box-title">{t('PWA опции')}</h3>
					<div className="box-tools pull-right">
						<button className="btn btn-box-tool" onClick={this.toggleBox}>
							<i
								className={
									'fa ' + (this.state.boxCollapse ? 'fa-plus' : 'fa-minus')
								}
							/>
						</button>
					</div>
				</div>

				<Row className="box-body equal landing ">
					{pwaOptionsState.data.map(this.renderPwaOptionsItem)}
				</Row>

				{edited && (
					<EditBlock
						alertShow={alertShow}
						t={t}
						offerId={offerId}
						addLanding={addLanding}
						landings={landings}
						middlePages={middlePages}
						addPwaOptions={this.props.addPwaOptions}
					/>
				)}
			</div>
		);
	}
}
