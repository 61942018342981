import React from 'react';
import _ from 'underscore';
import { TableHeaderColumn, BootstrapTable } from 'react-bootstrap-table';

import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';

import InfoFormSubmit from './InfoFormSubmit';
import SelectForm from './SelectForm';
import TextForm from './TextForm';

class FormContent extends React.PureComponent {
	formatId = (value) => value._id;
	formatPaymentSystems = (payment) => `${payment.name} (${payment.description})`;
	formatCurrencies = (currency) => `${currency.name} (${currency.code})`;

	onSubmit = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (this.props.newPayment) this.props.addPayment();
		else this.props.updatePayment();
	};

	render() {
		const {
			t,
			changePaymentInfo,
			currencies,
			paymentSystems,
			selectedPayment,
			onResetClick,
			newPayment,
		} = this.props;
		return (
			<Form horizontal onSubmit={this.onSubmit}>
				<SelectForm
					label={t('Платежная система')}
					name={'system'}
					options={paymentSystems}
					value={selectedPayment.system}
					getValue={this.formatId}
					getLabel={this.formatPaymentSystems}
					changeInfo={changePaymentInfo}
				/>

				<SelectForm
					label={t('Валюта')}
					name={'currency'}
					value={selectedPayment.currency}
					options={currencies}
					getValue={this.formatId}
					getLabel={this.formatCurrencies}
					changeInfo={changePaymentInfo}
				/>

				<TextForm
					label={t('Аккаунт')}
					value={selectedPayment.account}
					placeholder={t('Аккаунт')}
					changeInfo={changePaymentInfo}
					name={'account'}
				/>

				<InfoFormSubmit
					labelSubmit={newPayment ? t('Добавить') : t('Изменить')}
					labelReset={newPayment ? t('Сбросить') : t('Отмена')}
					onResetClick={onResetClick}
				/>
			</Form>
		);
	}
}

class TableContent extends React.PureComponent {
	constructor(props) {
		super(props);
		this.tdStyle = { whiteSpace: 'normal' };
	}

	renderCurrencyIcon = (nameIcon) => {
		let classIcon;
		switch (nameIcon) {
			case 'uah':
				classIcon = 'fa-hryvnia';
				break;
			case 'rub':
				classIcon = 'fa-usd';
				break;
			case 'kzt':
				classIcon = 'fa-tenge';
				break;
			default:
				classIcon = 'fa-money';
		}
		return <i className={`fa fa-lg ${classIcon}`} />;
	};

	formatPaymentSystem = (cell) => {
		const tempCell = cell ? cell : {};
		const paymentSystem = tempCell.name;
		let image = '';
		if (_.isString(paymentSystem)) image = paymentSystem.trim().toLowerCase();
		return (
			<OverlayTrigger
				ref="trigger"
				placement="top"
				overlay={
					<Tooltip id={`paymentSystem${tempCell._id}`}>
						{this.props.t(paymentSystem)}
					</Tooltip>
				}
			>
				<div style={{ width: '100%' }}>
					<img
						alt={paymentSystem}
						src={`/images/elements/${image}.png`}
						className="payment-system"
					/>
				</div>
			</OverlayTrigger>
		);
	};

	formatCurrency = (cell) => {
		const tempCell = cell ? cell : {};
		return (
			<OverlayTrigger
				ref="trigger"
				placement="top"
				overlay={
					<Tooltip id={`currency_${tempCell._id}`}>
						{this.props.t(tempCell.name) + ` (${tempCell.code})`}
					</Tooltip>
				}
			>
				<h4>
					{this.renderCurrencyIcon(tempCell.code)}
					{` (${this.props.t(tempCell.textValue)})`}
				</h4>
			</OverlayTrigger>
		);
	};

	formatAccount = (cell) => <h4> {cell} </h4>;

	formatAction = (cell) => {
		const onDeleteClick = () => this.props.deletePayment(cell);
		return (
			<OverlayTrigger
				ref="trigger"
				placement="top"
				overlay={
					<Tooltip id={`payment_${cell}`}>
						{this.props.t('Удалить платежную систему!')}
					</Tooltip>
				}
			>
				<button className="btn btn-table-tool" onClick={onDeleteClick}>
					<i className="fa fa-2x fa-remove" />
				</button>
			</OverlayTrigger>
		);
	};

	onClickCell = () => {
		//setTimeout делает код ассинхронным
		setTimeout(() => {
			//Ждем когда запишется this.row
			while (true) {
				if (this.row) {
					this.props.onPaymentClick(this.row);
					this.row = null;
					break;
				}
			}
		}, 0);
	};

	options = {
		onRowClick: (row) => (this.row = row),
	};

	render() {
		const { t, data } = this.props;
		return (
			<React.Fragment>
				<BootstrapTable
					data={data}
					striped={true}
					hover={true}
					bordered={false}
					options={this.options}
				>
					<TableHeaderColumn
						dataField="system"
						tdStyle={this.tdStyle}
						dataAlign="center"
						thStyle={{ whiteSpace: 'normal' }}
						dataFormat={this.formatPaymentSystem}
						columnClassName="clickableTable"
						tdAttr={{ onClick: this.onClickCell }}
					>
						{t('Платежная система')}
					</TableHeaderColumn>

					<TableHeaderColumn
						dataField="currency"
						tdStyle={this.tdStyle}
						dataAlign="center"
						dataFormat={this.formatCurrency}
						columnClassName="clickableTable"
						tdAttr={{ onClick: this.onClickCell }}
					>
						{t('Валюта')}
					</TableHeaderColumn>

					<TableHeaderColumn
						dataField="account"
						tdStyle={this.tdStyle}
						dataAlign="center"
						dataFormat={this.formatAccount}
						columnClassName="clickableTable"
						tdAttr={{ onClick: this.onClickCell }}
					>
						{t('Аккаунт')}
					</TableHeaderColumn>

					<TableHeaderColumn
						dataField="_id"
						isKey={true}
						tdStyle={this.tdStyle}
						dataAlign="center"
						dataFormat={this.formatAction}
						width={'40px'}
					/>
				</BootstrapTable>
			</React.Fragment>
		);
	}
}

export default class PaymentInfo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			paymentId: null,
			payment: {
				system: null,
				account: null,
				currency: null,
			},
		};
	}

	changePaymentInfo = (e, fieldName) => {
		const value = e.target ? e.target.value : e.value;
		this.setState((prevState) => {
			let payment = { ...prevState.payment };
			payment[fieldName] = value;
			return {
				payment,
			};
		});
	};

	checkProperties(data) {
		for (let prop in data) {
			if (!data[prop]) return false;
		}
		return true;
	}

	addPayment = () => {
		const data = { ...this.state.payment };
		if (this.checkProperties(data)) {
			this.props.updatePayment(data);
			this.reset();
		} else this.props.alertShow({ message: 'Необходимо заполнить все поля!', type: 'danger' });
	};

	updatePayment = () => {
		const data = { ...this.state.payment, _id: this.state.paymentId };
		this.props.updatePayment(data);
		this.reset();
	};

	reset = () =>
		this.setState({
			paymentId: null,
			payment: {
				system: null,
				account: null,
				currency: null,
			},
		});

	onPaymentClick = (payment) => {
		this.setState({
			paymentId: payment._id,
			payment: {
				system: payment.system._id,
				account: payment.account,
				currency: payment.currency._id,
			},
		});
	};

	render() {
		const { t, payment, currencies, paymentSystems, deletePayment } = this.props;
		return (
			<React.Fragment>
				<TableContent
					t={t}
					data={payment}
					onPaymentClick={this.onPaymentClick}
					deletePayment={deletePayment}
				/>
				<FormContent
					t={t}
					newPayment={!this.state.paymentId}
					selectedPayment={this.state.payment}
					currencies={currencies}
					paymentSystems={paymentSystems}
					addPayment={this.addPayment}
					updatePayment={this.updatePayment}
					changePaymentInfo={this.changePaymentInfo}
					onResetClick={this.reset}
				/>
			</React.Fragment>
		);
	}
}
