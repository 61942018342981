import React from 'react';
import { Col, Row, FormGroup, ControlLabel, Button, FormControl, Form } from 'react-bootstrap';
import ReactSelect from 'react-select';
import MainFunctions from 'mainLibs/MainFunctions';

export default class ContentHeader extends React.PureComponent {
	constructor(props) {
		super(props);
		var today = new Date(),
			start = new Date(today.setDate(1)),
			end = new Date(new Date(today.setMonth(today.getMonth() + 1)).setDate(0));
		this.state = {
			boxCollapse: false,
			preLandingsSelect: [],
			filter: {
				affiliate: 'all',
				preLandings: 'all',
				offers: 'all',
				start: MainFunctions.formatDateAsMySQL(start),
				end: MainFunctions.formatDateAsMySQL(end),
			},
		};
	}

	loadStats() {
		this.props.getStatsPreLandings(this.state.filter);
	}

	componentDidMount() {
		this.loadStats();
	}

	changeFilter(filterName, val) {
		var filter = this.state.filter;
		if (Array.isArray(val)) {
			filter[filterName] = val;
		} else {
			filter[filterName] = val.target ? val.target.value : val.value;
		}
		this.setState({ filter: filter });
	}

	search = (e) => {
		e.preventDefault();
		e.stopPropagation();
		this.loadStats();
	};

	toggleBox = (e) => {
		e.preventDefault();
		this.setState((prevState) => {
			return {
				boxCollapse: !prevState.boxCollapse,
			};
		});
	};

	render() {
		const { t } = this.props;
		return (
			<Row>
				<Col md={12}>
					<div
						className={
							'box box-danger box-solid color-palette-box ' +
							(this.state.boxCollapse ? 'collapsed-box' : '')
						}
					>
						<div className="box-header with-border">
							<h3 className="box-title">
								<span>{t('Фильтр')}</span>
							</h3>
							<div className="box-tools pull-right">
								<Button className="btn-box-tool" onClick={this.toggleBox}>
									<i
										className={
											'fa ' +
											(this.state.boxCollapse ? 'fa-plus' : 'fa-minus')
										}
									/>
								</Button>
							</div>
						</div>

						<div className="box-body">
							<Form onSubmit={this.search} className="semiInline">
								<FormGroup>
									<ControlLabel>{t('Прелендинги')}</ControlLabel>
									<ReactSelect
										value={this.state.filter.preLandings}
										options={this.state.preLandingsSelect}
										autoBlur={true}
										clearable={false}
										searchable={true}
										multi={true}
										onChange={this.changeFilter.bind(this, 'preLandings')}
									/>
								</FormGroup>

								<FormGroup>
									<ControlLabel>{t('Партнеры')}</ControlLabel>
									<ReactSelect
										value={this.state.filter.affiliate}
										options={this.state.affiliates}
										autoBlur={true}
										clearable={false}
										onChange={this.changeFilter.bind(this, 'affiliate')}
									/>
								</FormGroup>

								<FormGroup>
									<ControlLabel>{t('Кампания')}</ControlLabel>
									<ReactSelect
										value={this.state.filter.offers}
										options={this.state.offers}
										autoBlur={true}
										clearable={false}
										onChange={this.changeFilter.bind(this, 'offers')}
									/>
								</FormGroup>

								<FormGroup>
									<ControlLabel>{t('Начальная дата')}</ControlLabel>
									<FormControl
										type="date"
										value={this.state.filter.start}
										onChange={this.changeFilter.bind(this, 'start')}
									/>
								</FormGroup>

								<FormGroup>
									<ControlLabel>{t('Конечная дата')}</ControlLabel>
									<FormControl
										type="date"
										value={this.state.filter.end}
										onChange={this.changeFilter.bind(this, 'end')}
									/>
								</FormGroup>

								<Button type="submit" bsStyle="info">
									{t('Отобразить')}
								</Button>
							</Form>
						</div>
					</div>
				</Col>
			</Row>
		);
	}
}
