import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import { Col, ControlLabel, FormGroup } from 'react-bootstrap';

export default class SelectForm extends React.Component {
	static propTypes = {
		defaultValue: PropTypes.string,
		changeInfo: PropTypes.func.isRequired,
		name: PropTypes.string.isRequired,
		options: PropTypes.array,
	};

	shouldComponentUpdate(nextProps, nextState) {
		return (
			this.props.value !== nextProps.value ||
			this.props.defaultValue !== nextProps.defaultValue ||
			this.props.options !== nextProps.options ||
			this.props.disabled !== nextProps.disabled
		);
	}
	checkValue() {
		const currentValue = this.props.value ? this.props.value : this.props.defaultValue;
		if (this.props.defaultValue !== 'undefined' && !this.props.value) {
			const option = this.props.options.find((o) => o.value === this.props.defaultValue);
			if (option) {
				this.props.changeInfo(option, this.props.name);
			}
		} else if (!currentValue && this.props.options.length) {
			this.props.changeInfo(this.props.options[0], this.props.name);
		}
	}
	componentDidMount() {
		this.checkValue();
	}
	componentDidUpdate() {
		this.checkValue();
	}
	onСhangeOfferInfoClick = (event) => {
		this.props.changeInfo(event, this.props.name);
	};
	render() {
		const { value, options, label, defaultValue, required, disabled } = this.props;
		const currentValue = value ? value : defaultValue || '';
		return (
			<FormGroup validationState={required ? (value ? 'success' : 'error') : null}>
				<Col componentClass={ControlLabel} sm={2}>
					{label}
				</Col>
				<Col sm={10}>
					<ReactSelect
						value={currentValue}
						options={options}
						autoBlur={true}
						clearable={false}
						onChange={this.onСhangeOfferInfoClick}
						disabled={disabled}
					/>
				</Col>
			</FormGroup>
		);
	}
}
