import React from 'react';
import { Link } from 'react-router-dom';
import memoize from 'memoize-one';
import _ from 'underscore';
import { TableHeaderColumn, BootstrapTable } from 'react-bootstrap-table';
import { Col, Form, FormGroup, ControlLabel } from 'react-bootstrap';

import { InfoForm, SelectForm, InfoFormSubmit } from '../supportedForm';

import { Colors } from 'consts';

class FormContent extends React.PureComponent {
	renderCrmForm = () => {
		const configCrm = this.props.configCrm || {};
		const { t } = this.props;
		switch (this.props.crm) {
			case 'Rekl.pro':
				return (
					<InfoForm
						key="Rekl_pro"
						value={configCrm.Rekl_pro.api_key}
						label={t('Rekl.pro api_key')}
						placeholder={t('Rekl.pro  api_key')}
						changeInfo={this.props.changeConfigCrmRekl_pro}
						name={'api_key'}
						required
					/>
				);
			case 'Ad1':
				return (
					<InfoForm
						key="Ad1"
						value={configCrm.Ad1.site_key}
						label={t('Ad1 site_key')}
						placeholder={t('Ad1  site_key')}
						changeInfo={this.props.changeConfigCrmAd1}
						name={'site_key'}
						required
					/>
				);
			case 'MonsterLeads':
				return (
					<InfoForm
						key="MonsterLeads"
						value={configCrm.MonsterLeads.api_key}
						label={t('MonsterLeads api_key')}
						placeholder={t('MonsterLeads  api_key')}
						changeInfo={this.props.changeConfigCrmMonsterLeads}
						name={'api_key'}
						required
					/>
				);
			case 'Leadvertex':
				return (
					<React.Fragment>
						<InfoForm
							key="Leadvertex_webmasterId"
							value={configCrm.Leadvertex.webmasterId}
							label={t('Leadvertex webmasterId')}
							placeholder={t('Leadvertex  webmasterId')}
							changeInfo={this.props.changeConfigCrmLeadvertex}
							name={'webmasterId'}
							required
						/>
						<InfoForm
							key="Leadvertex_webmasterToken"
							value={configCrm.Leadvertex.webmasterToken}
							label={t('Leadvertex webmasterToken')}
							placeholder={t('Leadvertex  webmasterToken')}
							changeInfo={this.props.changeConfigCrmLeadvertex}
							name={'webmasterToken'}
							required
						/>
					</React.Fragment>
				);
			case 'KMA.biz':
				return (
					<React.Fragment>
						<InfoForm
							key="KMA_biz_username"
							value={configCrm.KMA_biz.username}
							label={t('KMA.biz username')}
							placeholder={t('KMA.biz  username')}
							changeInfo={this.props.changeConfigCrmKMA_biz}
							name={'username'}
							required
						/>
						<InfoForm
							key="KMA_biz_password"
							value={configCrm.KMA_biz.password}
							label={t('KMA.biz password')}
							placeholder={t('KMA.biz  password')}
							changeInfo={this.props.changeConfigCrmKMA_biz}
							name={'password'}
							required
						/>
					</React.Fragment>
				);
			case 'M1-shop':
				return (
					<React.Fragment>
						<InfoForm
							key="M1_shop_ref"
							value={configCrm.M1_shop.ref}
							label={t('M1-shop ref')}
							placeholder={t('M1-shop  ref')}
							changeInfo={this.props.changeConfigCrmM1_shop}
							name={'ref'}
							required
						/>
						<InfoForm
							key="M1_shop_api_key"
							value={configCrm.M1_shop.api_key}
							label={t('M1-shop api_key')}
							placeholder={t('M1-shop  api_key')}
							changeInfo={this.props.changeConfigCrmM1_shop}
							name={'api_key'}
							required
						/>
					</React.Fragment>
				);
			case 'LeadBit':
				return (
					<React.Fragment>
						<InfoForm
							key="LeadBit_token"
							value={configCrm.LeadBit.token}
							label={t('LeadBit token')}
							placeholder={t('LeadBit token')}
							changeInfo={this.props.changeConfigCrmLeadBit}
							name={'token'}
							required
						/>
						<InfoForm
							key="LeadBit_flow_hash"
							value={configCrm.LeadBit.flow_hash}
							label={t('LeadBit flow_hash')}
							placeholder={t('LeadBit flow_hash')}
							changeInfo={this.props.changeConfigCrmLeadBit}
							name={'flow_hash'}
							required
						/>
					</React.Fragment>
				);
			default:
				return null;
		}
	};

	render() {
		const {
			t,
			newOwner,
			currentOwner,
			cancelOwnerEdit,
			onAddOwnerClick,
			changeNewOwnerInfo,
			otherMerchants,
			onResetClick,
			...owner
		} = this.props;
		return (
			<Form horizontal onSubmit={onAddOwnerClick}>
				{newOwner ? (
					<SelectForm
						value={owner.ownerId}
						label={t('Рекламодатель')}
						changeInfo={changeNewOwnerInfo}
						name={'ownerId'}
						options={otherMerchants}
						required
					/>
				) : (
					<FormGroup>
						<Col componentClass={ControlLabel} sm={2}>
							{t('Рекламодатель')}
						</Col>
						<Col sm={10}>
							<Link to={'/users/merchants/' + currentOwner.ownerId}>
								<h4>{currentOwner.username + ' (' + currentOwner.ownerId + ')'}</h4>
							</Link>
						</Col>
					</FormGroup>
				)}

				{!this.props.split && (
					<InfoForm
						value={owner.percentage}
						label={t('Процент траффика')}
						placeholder={t('Процент траффика')}
						changeInfo={changeNewOwnerInfo}
						name={'percentage'}
						type="number"
						required
					/>
				)}

				{this.props.split && (
					<InfoForm
						value={owner.name}
						label={t('Название')}
						placeholder={t('Название')}
						changeInfo={changeNewOwnerInfo}
						name={'name'}
						required
					/>
				)}

				{this.props.split && (
					<InfoForm
						value={owner.payout}
						label={t('Выплата')}
						placeholder={t('Выплата')}
						changeInfo={changeNewOwnerInfo}
						name={'payout'}
						required
					/>
				)}

				<InfoForm
					value={owner.cpaAdvance}
					label={t('Отчисления ПП')}
					placeholder={t('Отчисления ПП')}
					changeInfo={changeNewOwnerInfo}
					name={'cpaAdvance'}
					type="number"
					required
				/>

				<InfoForm
					value={owner.ownerOfferId}
					label={t('ID оффера в CRM рекламодателя')}
					placeholder={t('ID оффера в CRM рекламодателя')}
					changeInfo={changeNewOwnerInfo}
					name={'ownerOfferId'}
				/>

				{this.renderCrmForm()}

				<InfoForm
					value={owner.ownerOfferName}
					label={t('Сокр.название оффера в CRM рекламодателя')}
					placeholder={t('Сокр.название оффера в CRM рекламодателя')}
					changeInfo={changeNewOwnerInfo}
					name={'ownerOfferName'}
				/>

				<InfoForm
					value={owner.ownerOfferNameFull}
					label={t('Полное название оффера в CRM рекламодателя')}
					placeholder={t('Полное название оффера в CRM рекламодателя')}
					changeInfo={changeNewOwnerInfo}
					name={'ownerOfferNameFull'}
				/>

				{this.props.crm === 'Leadvertex' && (
					<InfoForm
						value={owner.oneOfferName}
						label={t('Название оффера (1 имя)')}
						placeholder={t('Название оффера (1 имя)')}
						changeInfo={changeNewOwnerInfo}
						name={'oneOfferName'}
					/>
				)}

				<InfoFormSubmit
					labelSubmit={newOwner ? t('Добавить рекламодателя') : t('Изменить')}
					labelReset={newOwner ? t('Сбросить') : t('Отмена')}
					onResetClick={newOwner ? onResetClick : cancelOwnerEdit}
				/>
			</Form>
		);
	}
}

class TableContent extends React.PureComponent {
	deletedComponent = (cell) => (
		<button className="btn btn-table-tool" onClick={() => this.props.deleteOwner(cell)}>
			<i className="fa fa-2x fa-remove" />
		</button>
	);

	formatLink = (cell, row) => {
		if (this.props.edited)
			return (
				<Link to={'/users/merchants/' + cell}>
					<div>{`${row.username} (${cell})`}</div>
				</Link>
			);
		else return `${row.username} (${cell})`;
	};

	formatCrmConfig = (cell, row) => {
		cell = cell || {};
		const data = [];
		if (typeof cell === 'object') {
			for (const props in cell) {
				const config = cell[props] || {};
				for (const params in config) {
					data.push(`${params}: ${config[params]}`);
				}
			}
		}
		return (
			<React.Fragment>
				{data.map((d) => (
					<div>{d}</div>
				))}
			</React.Fragment>
		);
	};

	onClickCell = () => {
		//setTimeout делает код ассинхронным
		setTimeout(() => {
			//Ждем когда запишется this.row
			while (true) {
				if (this.row) {
					this.props.onOwnerClick(this.row);
					this.row = null;
					break;
				}
			}
		}, 0);
	};

	options = {
		onRowClick: (row) => (this.row = row),
	};

	render() {
		const { t, owners, edited } = this.props;
		return (
			<div style={{ marginBottom: '20px' }}>
				<BootstrapTable
					data={owners}
					striped={true}
					hover={true}
					condensed={true}
					bordered={false}
					options={this.options}
				>
					<TableHeaderColumn
						dataField="ownerId"
						isKey={true}
						dataAlign="center"
						dataFormat={this.formatLink}
						width={'190px'}
						tdStyle={{ whiteSpace: 'normal' }}
					>
						{t('Рекламодатель')}
					</TableHeaderColumn>

					{!this.props.split && (
						<TableHeaderColumn
							dataField="percentage"
							dataAlign="center"
							thStyle={{ whiteSpace: 'normal' }}
							columnClassName={edited ? 'clickableTable' : undefined}
							tdAttr={{ onClick: this.onClickCell }}
						>
							{t('Процент траффика')}
						</TableHeaderColumn>
					)}

					{this.props.split && (
						<TableHeaderColumn
							dataField="name"
							dataAlign="center"
							thStyle={{ whiteSpace: 'normal' }}
							columnClassName={edited ? 'clickableTable' : undefined}
							tdAttr={{ onClick: this.onClickCell }}
						>
							{t('Название')}
						</TableHeaderColumn>
					)}

					{this.props.split && (
						<TableHeaderColumn
							dataField="percentage"
							dataAlign="center"
							thStyle={{ whiteSpace: 'normal' }}
							columnClassName={edited ? 'clickableTable' : undefined}
							tdAttr={{ onClick: this.onClickCell }}
						>
							{t('Выплата')}
						</TableHeaderColumn>
					)}

					<TableHeaderColumn
						dataField="cpaAdvance"
						dataAlign="center"
						thStyle={{ whiteSpace: 'normal' }}
						columnClassName={edited ? 'clickableTable' : undefined}
						tdAttr={{ onClick: this.onClickCell }}
					>
						{t('Отчисления ПП')}
					</TableHeaderColumn>

					<TableHeaderColumn
						dataField="ownerOfferId"
						dataAlign="center"
						thStyle={{ whiteSpace: 'normal' }}
						columnClassName={edited ? 'clickableTable' : undefined}
						tdAttr={{ onClick: this.onClickCell }}
					>
						{t('ID оффера в CRM')}
					</TableHeaderColumn>

					<TableHeaderColumn
						dataField="configCrm"
						dataAlign="center"
						dataFormat={this.formatCrmConfig}
						thStyle={{ whiteSpace: 'normal' }}
						columnClassName={edited ? 'clickableTable' : undefined}
						tdAttr={{ onClick: this.onClickCell }}
					>
						{t('Данные CRM')}
					</TableHeaderColumn>

					<TableHeaderColumn
						dataField="ownerOfferName"
						dataAlign="center"
						thStyle={{ whiteSpace: 'normal' }}
						columnClassName={edited ? 'clickableTable' : undefined}
						tdAttr={{ onClick: this.onClickCell }}
					>
						{t('Сокр.название оффера в CRM')}
					</TableHeaderColumn>

					<TableHeaderColumn
						dataField="ownerOfferNameFull"
						dataAlign="center"
						thStyle={{ whiteSpace: 'normal' }}
						columnClassName={edited ? 'clickableTable' : undefined}
						tdAttr={{ onClick: this.onClickCell }}
					>
						{t('Полное название оффера в CRM ')}
					</TableHeaderColumn>

					{edited && (
						<TableHeaderColumn
							dataField="ownerId"
							dataAlign="center"
							dataFormat={this.deletedComponent}
							width={'40px'}
						/>
					)}
				</BootstrapTable>
			</div>
		);
	}
}

export default class OwnersDetail extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			owner: {
				ownerId: null,
				name: null,
				payout: null,
				percentage: null,
				cpaAdvance: null,
				ownerOfferId: null,
				ownerOfferName: null,
				ownerOfferNameFull: null,
				configCrm: null,
				oneOfferName: null,
			},
			crm: '',
			newOwner: true,
		};
	}

	correctPercantage = (checkedPercantage) => {
		let temp = Number(checkedPercantage);
		const percentage = _.reduce(
			this.props.owners,
			(memo, owner) => {
				if (owner.ownerId === this.state.owner.ownerId) return Number(memo);
				else return Number(memo) + Number(owner.percentage);
			},
			0,
		);
		if (percentage + temp > 100) temp = 100 - percentage;
		if (temp <= 0) temp = '0';
		return temp.toString();
	};

	checkConfigCrm = (configCrm) => {
		configCrm = configCrm || {};
		for (const props in configCrm) {
			const config = configCrm[props];
			if (!config) {
				return false;
			} else {
				for (const params in config) {
					if (!config[params]) return false;
				}
			}
		}
		return true;
	};

	onAddOwnerClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		const newOwner = { ...this.state.owner };
		if (
			newOwner.ownerId &&
			(this.props.split ? true : newOwner.percentage) &&
			newOwner.cpaAdvance &&
			this.checkConfigCrm(newOwner.configCrm)
		) {
			if (this.state.newOwner) {
				if (!newOwner.ownerOfferId) {
					newOwner.ownerOfferId = '';
				}
				this.props.addOwner(newOwner);
				this.clearForm();
			} else {
				this.props.changeOwnerInfo(newOwner);
				this.clearForm();
			}
		} else
			this.props.alertShow({
				message: 'Необходимо заполнить выделенные поля',
				type: 'danger',
			});
	};

	changeNewOwnerInfo = (event, fieldName) => {
		const owner = { ...this.state.owner };
		let crm = this.state.crm;
		owner[fieldName] = event ? (event.target ? event.target.value : event.value) : null;
		if (fieldName === 'percentage') owner[fieldName] = this.correctPercantage(owner[fieldName]);
		if (fieldName === 'ownerId') {
			crm = event.crm;
			switch (crm) {
				case 'Rekl.pro':
					owner.configCrm = {
						Rekl_pro: {
							api_key: '',
						},
					};
					break;
				case 'Ad1':
					owner.configCrm = {
						Ad1: {
							site_key: '',
						},
					};
					break;
				case 'MonsterLeads':
					owner.configCrm = {
						MonsterLeads: {
							api_key: '',
						},
					};
					break;
				case 'Leadvertex':
					owner.configCrm = {
						Leadvertex: {
							webmasterId: '',
							webmasterToken: '',
						},
					};
					break;
				case 'KMA.biz':
					owner.configCrm = {
						KMA_biz: {
							username: '',
							password: '',
						},
					};
					break;
				case 'M1-shop':
					owner.configCrm = {
						M1_shop: {
							api_key: '',
							ref: '',
						},
					};
					break;
				case 'LeadBit':
					owner.configCrm = {
						LeadBit: {
							token: '',
							flow_hash: '',
						},
					};
					break;
				default:
					break;
			}
		}
		this.setState({ owner, crm });
	};

	changeConfigCrm = (event, fieldName, crm) => {
		const owner = { ...this.state.owner };
		const configCrm = { ...owner.configCrm };
		configCrm[crm][fieldName] = event
			? event.target
				? event.target.value
				: event.value
			: null;
		owner.configCrm = configCrm;
		this.setState({ owner });
	};

	changeConfigCrmRekl_pro = (event, fieldName) => {
		this.changeConfigCrm(event, fieldName, 'Rekl_pro');
	};

	changeConfigCrmLeadvertex = (event, fieldName) => {
		this.changeConfigCrm(event, fieldName, 'Leadvertex');
	};

	changeConfigCrmM1_shop = (event, fieldName) => {
		this.changeConfigCrm(event, fieldName, 'M1_shop');
	};

	changeConfigCrmMonsterLeads = (event, fieldName) => {
		this.changeConfigCrm(event, fieldName, 'MonsterLeads');
	};

	changeConfigCrmKMA_biz = (event, fieldName) => {
		this.changeConfigCrm(event, fieldName, 'KMA_biz');
	};

	changeConfigCrmAd1 = (event, fieldName) => {
		this.changeConfigCrm(event, fieldName, 'Ad1');
	};
	changeConfigCrmLeadBit = (event, fieldName) => {
		this.changeConfigCrm(event, fieldName, 'LeadBit');
	};

	onOwnerClick = (owner) => {
		const { crm, ...otherDataOwner } = owner;
		if (!Object.keys(otherDataOwner.configCrm || {})) {
			switch (crm) {
				case 'Rekl.pro':
					otherDataOwner.configCrm = {
						Rekl_pro: {
							api_key: '',
						},
					};
					break;
				case 'Ad1':
					otherDataOwner.configCrm = {
						Ad1: {
							site_key: '',
						},
					};
					break;
				case 'MonsterLeads':
					otherDataOwner.configCrm = {
						MonsterLeads: {
							api_key: '',
						},
					};
					break;
				case 'Leadvertex':
					otherDataOwner.configCrm = {
						Leadvertex: {
							webmasterId: '',
							webmasterToken: '',
						},
					};
					break;
				case 'KMA.biz':
					otherDataOwner.configCrm = {
						KMA_biz: {
							username: '',
							password: '',
						},
					};
					break;
				case 'M1-shop':
					otherDataOwner.configCrm = {
						M1_shop: {
							api_key: '',
							ref: '',
						},
					};
					break;
				case 'LeadBit':
					otherDataOwner.configCrm = {
						LeadBit: {
							token: '',
							flow_hash: '',
						},
					};
					break;
				default:
					break;
			}
		}
		this.setState({ owner: { ...otherDataOwner }, newOwner: false, crm });
	};

	cancelOwnerEdit = () => this.clearForm();

	getReactSelectOptionsFromMerchants = memoize((owners, merchants) => {
		let allOwners = [];
		let otherMerchants = [];

		for (let j = 0; j < merchants.length; j++) {
			let finded = false;
			for (let i = 0; i < owners.length; i++) {
				if (owners[i].ownerId === merchants[j]._id) {
					allOwners.push({
						...owners[i],
						username: merchants[j].username,
						crm: (merchants[j].crm || {}).name,
					});
					finded = true;
				}
			}
			if (!finded) {
				otherMerchants.push({
					value: merchants[j]._id,
					label: merchants[j].username + ' (' + merchants[j]._id + ')',
					crm: (merchants[j].crm || {}).name,
				});
			}
		}
		return { allOwners, otherMerchants };
	});

	clearForm = (callback) => {
		this.setState(
			{
				owner: {
					ownerId: null,
					percentage: null,
					name: null,
					payout: null,
					cpaAdvance: null,
					ownerOfferId: null,
					ownerOfferName: null,
					ownerOfferNameFull: null,
					configCrm: null,
					oneOfferName: null,
				},
				crm: null,
				newOwner: true,
			},
			callback,
		);
	};

	onResetClick = () => this.clearForm(this.props.clearChangedOwners);

	render() {
		const { t, merchants, owners, deleteOwner, changed, edited } = this.props;
		const { allOwners, otherMerchants } = this.getReactSelectOptionsFromMerchants(
			owners,
			merchants,
		);
		return (
			<div className={'box box-danger'}>
				<div className="box-header with-border">
					<h3 className="box-title">{t('Рекламодатели')}</h3>{' '}
					{changed && (
						<span style={{ color: Colors.brandDanger }}>
							<i className=" fa fa-warning" /> {t('Изменения не сохранены')}
						</span>
					)}
				</div>

				<div className="box-body" style={{ padding: '0px' }}>
					<TableContent
						t={t}
						edited={edited}
						owners={allOwners}
						onOwnerClick={this.onOwnerClick}
						deleteOwner={deleteOwner}
						split={this.props.split}
						crm={this.state.crm}
					/>
					{edited && (
						<FormContent
							t={t}
							split={this.props.split}
							newOwner={this.state.newOwner}
							currentOwner={this.state.owner}
							cancelOwnerEdit={this.cancelOwnerEdit}
							otherMerchants={otherMerchants}
							onAddOwnerClick={this.onAddOwnerClick}
							changeNewOwnerInfo={this.changeNewOwnerInfo}
							onResetClick={this.onResetClick}
							onDeleteOwnerClick={this.onDeleteOwnerClick}
							crm={this.state.crm}
							changeConfigCrmRekl_pro={this.changeConfigCrmRekl_pro}
							changeConfigCrmLeadvertex={this.changeConfigCrmLeadvertex}
							changeConfigCrmM1_shop={this.changeConfigCrmM1_shop}
							changeConfigCrmMonsterLeads={this.changeConfigCrmMonsterLeads}
							changeConfigCrmKMA_biz={this.changeConfigCrmKMA_biz}
							changeConfigCrmAd1={this.changeConfigCrmAd1}
							changeConfigCrmLeadBit={this.changeConfigCrmLeadBit}
							{...this.state.owner}
						/>
					)}
				</div>
			</div>
		);
	}
}
