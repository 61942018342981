import React from 'react';
import uuidv4 from 'uuid/v4';
import { Link } from 'react-router-dom';

import { FormControl, FormGroup, Row, Col } from 'react-bootstrap';

export default class ModalBodyContent extends React.PureComponent {
	formatLink = (text) => {
		const user = this.props.openPayment.user || {};
		let to = '/users/';
		if (user.isAffiliate) {
			to += 'affiliates/';
		} else {
			to += 'merchants/';
		}
		if (user._id)
			return (
				<Link target="_blank" to={to + user._id}>
					{this.props.t(text)}
				</Link>
			);
		else return this.props.t('Пользователь');
	};

	render() {
		const { t, openPayment, setInputNewStatus } = this.props;
		openPayment.user = openPayment.user || {};
		return (
			<React.Fragment>
				<Row style={{ marginBottom: '10px' }}>
					<Col xs={12} sm={4} className="text-center">
						<div className="hr-text hr-text-center m-t-1">
							<strong>{t('Холд')}</strong>
						</div>
						{openPayment.user.notPaid}
					</Col>

					<Col xs={12} sm={4} className="text-center">
						<div className="hr-text hr-text-center m-t-1">
							<strong>{t('Баланс')}</strong>
						</div>
						{openPayment.user.onBalance}
					</Col>

					<Col xs={12} sm={4} className="text-center">
						<div className="hr-text hr-text-center m-t-1">
							<strong>{t('Выплачено')}</strong>
						</div>
						{openPayment.user.paid}
					</Col>
				</Row>

				<div className="hr-text hr-text-left m-t-1">{this.formatLink('Пользователь')}</div>

				<dl className="dl-horizontal">
					{openPayment.user.username && (
						<React.Fragment>
							<dt>{t('Email')}</dt>
							<dd>{this.formatLink(openPayment.user.username)}</dd>
						</React.Fragment>
					)}
					{openPayment.user.name && (
						<React.Fragment>
							<dt>{t('Имя')}</dt>
							<dd>{openPayment.user.name}</dd>
						</React.Fragment>
					)}

					{openPayment.user.lastName && (
						<React.Fragment>
							<dt>{t('Фамилия')}</dt>
							<dd>{openPayment.user.lastName}</dd>
						</React.Fragment>
					)}

					{openPayment.user.phone && (
						<React.Fragment>
							<dt>{t('Телефон')}</dt>
							<dd>{openPayment.user.phone}</dd>
						</React.Fragment>
					)}

					{openPayment.user.skype && (
						<React.Fragment>
							<dt>{t('Skype')}</dt>
							<dd>{openPayment.user.skype}</dd>
						</React.Fragment>
					)}
				</dl>

				<div className="hr-text hr-text-left m-t-1">{t('Выплата')}</div>

				<dl className="dl-horizontal">
					<dt>{t('Дата')}</dt>
					<dd>{openPayment.date}</dd>

					<dt>{t('Сумма')}</dt>
					<dd>{openPayment.amount}</dd>

					<dt>{t('Валюта')}</dt>
					<dd>{openPayment.currency}</dd>

					{openPayment.paymentSystem && (
						<React.Fragment>
							<dt>{t('Платежная система')}</dt>
							<dd>{openPayment.paymentSystem}</dd>
						</React.Fragment>
					)}

					{openPayment.account && (
						<React.Fragment>
							<dt>{t('Аккаунт')}</dt>
							<dd>{openPayment.account}</dd>
						</React.Fragment>
					)}
				</dl>

				<div className="hr-text hr-text-left m-t-1">{t('Статусы')}</div>
				{openPayment.statuses.map((status, i) => (
					<React.Fragment key={uuidv4()}>
						<dl className="dl-horizontal" style={{ marginBottom: '0.5em' }}>
							<dt>{t('Статус')}</dt>
							<dd>{status.name}</dd>

							<dt>{t('Дата')}</dt>
							<dd>{status.date}</dd>
						</dl>
						{i < openPayment.statuses.length - 1 && (
							<dl className="dl-horizontal" style={{ marginBottom: '0' }}>
								<dt>
									<div className="hr-text m-t-1" />
								</dt>
								<dd />
							</dl>
						)}
					</React.Fragment>
				))}
				{!openPayment.hidden && (
					<FormGroup>
						<FormControl
							inputRef={setInputNewStatus}
							componentClass={'select'}
							placeholder={t('Статус')}
						>
							{openPayment.allStatuses.map((status) => (
								<option key={status._id} value={status._id}>
									{status.description + `(${status.name})`}
								</option>
							))}
						</FormControl>
					</FormGroup>
				)}
			</React.Fragment>
		);
	}
}
