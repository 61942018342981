import * as auth from './auth';
import * as affiliates from './affiliates';
import * as geos from './geos';
import * as crms from './crms';
import * as currencies from './currencies';
import * as users from './users';
import * as offers from './offers';
import * as stats from './stats';
import * as actionTypes from './actionTypes';
import * as statuses from './statuses';
import * as actions from './actions';
import * as partners from './partners';
import * as tickets from './tickets';
import * as payments from './payments';
import * as merchantStats from './merchantStats';
import * as affiliateStats from './affiliateStats';
import * as request from './request';
import * as landings from './landings';

const requestActions = {
	...actions,
	...actionTypes,
	...affiliates,
	...auth,
	...geos,
	...crms,
	...currencies,
	...users,
	...offers,
	...stats,
	...statuses,
	...partners,
	...tickets,
	...payments,
	...merchantStats,
	...affiliateStats,
	...request,
	...landings,
};

export {
	actions,
	actionTypes,
	affiliates,
	auth,
	geos,
	crms,
	currencies,
	users,
	offers,
	stats,
	statuses,
	partners,
	tickets,
	payments,
	merchantStats,
	affiliateStats,
	request,
	landings,
};

export default requestActions;
