import { authRequestTypes, authApi, adminPanelApi } from 'store/constants';

// //github.com/axios/axios
export function passwordLogin(data) {
	return {
		//тип определяющий обрабатывающий редюсер
		type: authRequestTypes.PASSWORD_LOGIN,
		//объект request служит для конфигурации запроса axios
		request: {
			method: 'post',
			url: authApi.API_URL_LOGIN,
			data: {
				grant_type: 'password',
				...data,
			},
		},
	};
}

export function refreshLogin(refresh_token, lastAction) {
	return {
		type: authRequestTypes.REFRESH_LOGIN,
		request: {
			method: 'post',
			url: authApi.API_URL_LOGIN,
			data: {
				grant_type: 'refresh_token',
				refresh_token: refresh_token,
			},
		},
		lastAction,
	};
}

export function logOut() {
	return {
		type: authRequestTypes.USER_LOGOUT,
		request: {
			method: 'post',
			url: authApi.API_URL_LOGOUT,
		},
	};
}

export function signUp(data, callback) {
	return {
		type: authRequestTypes.SIGNUP_USER,
		callback,
		request: {
			method: 'post',
			url: authApi.API_URL_SIGNUP,
			data: {
				grant_type: 'password',
				...data,
			},
		},
	};
}

export function updateInfo(data) {
	return {
		type: authRequestTypes.UPDATE_USER_INFO,
		request: {
			method: 'get',
			url: adminPanelApi.API_URL_USERS,
		},
	};
}

export function userUpdate(data) {
	return {
		type: authRequestTypes.UPDATE_SETTINGS_USER,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'multipart/form-data',
		},
		request: {
			method: 'put',
			url: adminPanelApi.API_URL_USERS,
			data: data,
		},
	};
}
