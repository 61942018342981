import React from 'react';
import _ from 'underscore';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import ContentHeader from './ContentHeader';

import InfoBlock from './InfoBlock';
import EditBlock from './EditBlock';

import { getStatuses } from 'store/actions/apiRequestActions/statuses';
import { getActions, updateStatus } from 'store/actions/apiRequestActions/actions';

class DeepRequestMerchant extends React.Component {
	constructor(props) {
		super(props);
		const url = window.location.pathname;
		this.requestId = url.substr(url.lastIndexOf('/') + 1);
	}

	componentDidMount() {
		this.props.getActions(this.requestId);
		this.props.getStatuses();
	}

	getCurrentStatusId() {
		const status = _.last(this.props.action.data.statusesFlow || []) || {};
		return status.status ? status.status._id : null;
	}

	render() {
		return (
			<div className="content-wrapper">
				<ContentHeader requestId={this.requestId} t={this.props.t} />

				<section className="content offers">
					<InfoBlock
						edited={this.props.access.adminOnly}
						t={this.props.t}
						action={this.props.action.data}
					/>

					<EditBlock
						t={this.props.t}
						requestId={this.requestId}
						statuses={this.props.statuses.data}
						updateStatus={this.props.updateStatus}
						statusId={this.getCurrentStatusId()}
					/>
				</section>
			</div>
		);
	}
}

//Получаем modalStore из stora и записываем в props компонента
const mapStateToProps = ({ apiRequestStore }) => ({
	access: (apiRequestStore.auth.user || {}).access || {},
	action: apiRequestStore.actions.action,
	statuses: apiRequestStore.statuses,
});

//Пробрасываем actions в props компонента
const mapActionCreators = {
	getActions: getActions,
	getStatuses: getStatuses,
	updateStatus: updateStatus,
};

DeepRequestMerchant = translate('DeepRequest')(DeepRequestMerchant);
export default connect(mapStateToProps, mapActionCreators)(DeepRequestMerchant);
