import { modalTypes } from '../constants';

const initialState = {
	open: false,
	type: null,
	data: null,
};

export default function alert(state = initialState, action) {
	switch (action.type) {
		case modalTypes.MODAL_HIDE:
			return { ...state, type: null, open: false, data: null };
		case modalTypes.MODAL_LOGIN:
		case modalTypes.MODAL_SIGNUP:
		case modalTypes.MODAL_ACCESS:
			return { ...state, type: action.type, open: true, data: action.data || null };
		default:
			return state;
	}
}
