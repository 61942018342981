import { userInfoTypes } from '../constants';

const initialState = {
	user: [],
};

export default function alert(state = initialState, action) {
	switch (action.type) {
		case userInfoTypes.LOCAL_USER_UPDATE:
			return { ...state, user: action.payload.user };
		default:
			return state;
	}
}
