import React from 'react';
import { TableHeaderColumn, BootstrapTable } from 'react-bootstrap-table';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default class TableInfo extends React.PureComponent {
	constructor(props) {
		super(props);
		this.tdStyle = { whiteSpace: 'normal' };
	}

	formatAction = (cell) => {
		const onDeleteClick = () => this.props.deleteUser(cell);
		return (
			<OverlayTrigger
				ref="trigger"
				placement="top"
				overlay={
					<Tooltip id={`payment_${cell}`}>
						{this.props.t('Удалить пользователя из списка!')}
					</Tooltip>
				}
			>
				<button className="btn btn-table-tool" onClick={onDeleteClick}>
					<i className="fa fa-2x fa-remove" />
				</button>
			</OverlayTrigger>
		);
	};

	render() {
		const { t, data } = this.props;
		return (
			<BootstrapTable
				data={data}
				striped={true}
				hover={true}
				bordered={false}
				condensed={true}
			>
				<TableHeaderColumn
					dataField="_id"
					isKey={true}
					dataAlign="center"
					tdStyle={this.tdStyle}
				>
					{t('ID веб-мастера')}
				</TableHeaderColumn>

				<TableHeaderColumn dataField="name" dataAlign="center" tdStyle={this.tdStyle}>
					{t('Имя')}
				</TableHeaderColumn>

				<TableHeaderColumn dataField="lastName" dataAlign="center" tdStyle={this.tdStyle}>
					{t('Фамилия')}
				</TableHeaderColumn>

				<TableHeaderColumn dataField="username" dataAlign="center" tdStyle={this.tdStyle}>
					{t('Email')}
				</TableHeaderColumn>

				<TableHeaderColumn dataField="phone" dataAlign="center" tdStyle={this.tdStyle}>
					{t('Телефон')}
				</TableHeaderColumn>

				<TableHeaderColumn
					dataField="_id"
					tdStyle={this.tdStyle}
					dataAlign="center"
					dataFormat={this.formatAction}
					width={'40px'}
				/>
			</BootstrapTable>
		);
	}
}
