import React from 'react';
import PropTypes from 'prop-types';
import { Col, ControlLabel, FormGroup, FormControl } from 'react-bootstrap';

export default class TextForm extends React.Component {
	static propTypes = {
		value: PropTypes.string,
		changeInfo: PropTypes.func.isRequired,
		name: PropTypes.string.isRequired,
	};

	shouldComponentUpdate(nextProps, nextState) {
		return this.props.value !== nextProps.value;
	}

	onChangeText = (val) => {
		this.props.changeInfo(val, this.props.name);
	};

	render() {
		const { value, label, placeholder } = this.props;
		return (
			<FormGroup>
				<Col componentClass={ControlLabel} sm={2}>
					{label}
				</Col>
				<Col sm={10}>
					<FormControl
						type="text"
						placeholder={placeholder}
						value={value || ''}
						onChange={this.onChangeText}
					/>
				</Col>
			</FormGroup>
		);
	}
}
