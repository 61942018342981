import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import ModalBodyContent from './ModalBodyContent';

export default class ModalPayment extends React.PureComponent {
	render() {
		const { t, openPayment, handleClose, saveNewStatus, setInputNewStatus, show } = this.props;
		return (
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>{t('Статус выплаты')}</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<ModalBodyContent
						t={t}
						openPayment={openPayment}
						setInputNewStatus={setInputNewStatus}
					/>
				</Modal.Body>

				<Modal.Footer>
					<Button onClick={handleClose}>{t('Закрыть')}</Button>
					{!openPayment.hidden && (
						<Button onClick={saveNewStatus}>{t('Сохранить')}</Button>
					)}
				</Modal.Footer>
			</Modal>
		);
	}
}
