import React from 'react';

import InfoBlock from './InfoBlock';
import EditBlock from './EditBlock';

export default class PreLandingsInfoBlock extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			boxCollapse: false,
		};
	}

	toggleBox = (e) => {
		e.preventDefault();
		this.setState((prevState) => {
			return {
				boxCollapse: !prevState.boxCollapse,
			};
		});
	};

	render() {
		const { t, edited, preLandings, offerId, deletePreLanding, addPreLanding, alertShow } =
			this.props;
		return (
			<div className={'box box-primary' + (this.state.boxCollapse ? ' collapsed-box' : '')}>
				<div className="box-header with-border">
					<h3 className="box-title">{t('Прелендинги')}</h3>
					<div className="box-tools pull-right">
						<button className="btn btn-box-tool" onClick={this.toggleBox}>
							<i
								className={
									'fa ' + (this.state.boxCollapse ? 'fa-plus' : 'fa-minus')
								}
							/>
						</button>
					</div>
				</div>

				<InfoBlock
					t={t}
					preLandings={preLandings}
					offerId={offerId}
					deletePreLanding={deletePreLanding}
					edited={edited}
				/>

				{edited && (
					<EditBlock
						alertShow={alertShow}
						t={t}
						offerId={offerId}
						addPreLanding={addPreLanding}
					/>
				)}
			</div>
		);
	}
}
