import React from 'react';
import _ from 'underscore';
import { Form } from 'react-bootstrap';

import { InfoForm, InfoFormSubmit, LogoForm } from '../supportedForm';

import zipLogo from 'assets/images/zip.png';

export default class EditBlock extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			preLanding: {
				name: null,
				landing: null,
			},
		};
	}

	addpreLanding = (e) => {
		e.preventDefault();
		e.stopPropagation();
		const preLanding = { ...this.state.preLanding };
		if (!_.findKey(preLanding, (elem) => _.isNull(elem))) {
			let data = new FormData();
			data.append('name', preLanding.name);
			data.append('offer', this.props.offerId);
			data.append('landing', preLanding.landing);
			this.props.addPreLanding(this.props.offerId, data);
			this.onResetClick();
		} else {
			this.props.alertShow({ message: 'Необходимо заполнить все поля', type: 'danger' });
		}
	};

	changePreLandingInfo = (e, fieldName) => {
		e.preventDefault();
		e.stopPropagation();
		let preLanding = { ...this.state.preLanding };
		preLanding[fieldName] = e.target ? e.target.value : e.value;
		this.setState({ preLanding });
	};

	changePreLandingPageType = (e, fieldName) => {
		let preLanding = { ...this.state.preLanding };
		preLanding[fieldName] = e.target ? e.target.value : e.value;
		this.setState({ preLanding });
	};

	changeLanding = (e) => {
		e.preventDefault();
		e.stopPropagation();
		let preLanding = { ...this.state.preLanding };
		if (e.target.files[0]) {
			preLanding.landing = e.target.files[0];
			this.setState({
				preLanding: preLanding,
			});
		} else {
			preLanding.landing = null;
			this.setState({
				preLanding: preLanding,
			});
		}
	};

	onResetClick = () => {
		this.setState({
			preLanding: {
				name: null,
				landing: null,
			},
		});
	};

	checkProperty = () => {
		return !!_.findKey(this.state.preLanding, (elem) => _.isNull(elem));
	};

	render() {
		const { t } = this.props;
		const disabled = this.checkProperty();
		return (
			<div className="box-body">
				<div className="box-header with-border">
					<h3 className="box-title">{t('Добавить прелендинг')}</h3>
				</div>

				<Form horizontal onSubmit={this.addpreLanding}>
					<InfoForm
						value={this.state.preLanding.name}
						label={t('Название')}
						placeholder={t('Название')}
						changeInfo={this.changePreLandingInfo}
						name={'name'}
					/>

					<LogoForm
						logo={zipLogo}
						label={t('Архив с прелендингом')}
						file={this.state.preLanding.landing}
						accept=".zip"
						changeInfo={this.changeLanding}
					/>

					<InfoFormSubmit
						disabled={disabled}
						labelSubmit={t('Добавить прелендинг')}
						labelReset={t('Сбросить')}
						onResetClick={this.onResetClick}
					/>
				</Form>
			</div>
		);
	}
}
