import React from 'react';
import _ from 'underscore';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import FormatedOfferInfo from 'components/service/FormatedOfferInfo';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import MainFunctions from 'mainLibs/MainFunctions';
import memoize from 'memoize-one';

class RequestsStats extends React.Component {
	constructor(props) {
		super(props);
		this.tdStyle = { whiteSpace: 'normal' };
	}

	formatStatusIcon = (cell, row) => {
		let icon = '';
		let color = '';
		let title = '';
		switch (this.props.t(_.last(cell).status.name)) {
			case 'Approved':
				icon = ' fa-check-circle';
				color = '#99c262';
				title = this.props.t('Одобрено');
				break;
			case 'Created':
				icon = ' fa-plus-circle';
				color = '#59c7c8';
				title = this.props.t('Заказ создан');
				break;
			case 'Pending':
				icon = ' fa-spinner fa-spin';
				color = '#f8d347';
				title = this.props.t('Холд');
				break;
			case 'Declined':
				icon = ' fa-times-circle';
				color = '#ff3232';
				title = this.props.t('Отклонено');
				break;
			case 'Trash':
				icon = ' fa-trash';
				color = '#ff3232';
				title = this.props.t('Треш');
				break;
			case 'Error':
				icon = ' fa-warning';
				color = '#ff3232';
				title = this.props.t('Ошибка');
				break;
			default:
				break;
		}
		return (
			<i
				className={'fa fa-lg' + icon}
				style={{ color: color, fontSize: '2em' }}
				data-toggle="tooltip"
				data-placement="top"
				title={title}
				aria-hidden="true"
			/>
		);
	};

	formatLink = (cell) => {
		return <Link to={'/stats/requests/' + cell}>{cell}</Link>;
	};

	formatOffer = (cell, row) => <FormatedOfferInfo {...cell} geo={row.geo} />;

	formatDate = (cell, row) => {
		return MainFunctions.dateFormat(cell);
	};

	formatStatus = (cell, row) => {
		return this.props.t(_.last(cell).status.description);
	};

	formatStatusComment = (cell, row) => {
		return _.last(cell).comment;
	};

	formatClientName = (cell, row) => {
		return cell ? cell.name : '';
	};

	formatClientPhone = (cell, row) => {
		const str = cell ? cell.phone || '' : '';
		let phone = (str.match(/([0-9])+/g) || []).join('');
		let countryCode = '';

		if (phone.length > 10) {
			countryCode = phone.slice(0, phone.length - 10);
			if (countryCode === '8') countryCode = 7;
			countryCode = '+' + countryCode + ' ';
		}

		switch (phone.length) {
			case 13:
				phone =
					countryCode +
					phone.substr(3, 3) +
					' ' +
					phone.substr(6, 3) +
					'-' +
					phone.substr(9, 2) +
					'-' +
					phone.substr(11, 13);
				break;
			case 11:
				phone =
					countryCode +
					phone.substr(1, 3) +
					' ' +
					phone.substr(4, 3) +
					'-' +
					phone.substr(7, 2) +
					'-' +
					phone.substr(9, 11);
				break;
			case 10:
				phone =
					countryCode +
					'(' +
					phone.substr(0, 3) +
					') ' +
					phone.substr(3, 3) +
					'-' +
					phone.substr(6, 2) +
					'-' +
					phone.substr(8, 10);
				break;
			case 7:
				phone =
					countryCode +
					phone.substr(0, 3) +
					'-' +
					phone.substr(3, 2) +
					'-' +
					phone.substr(5, 7);
				break;
			default:
				phone = `'${phone}'`;
		}

		return phone;
	};

	getNoDataText = memoize((loading, text) => {
		if (loading) {
			return (
				<div className="pageOverlay">
					<i className="fa fa-spinner css--animate" />
				</div>
			);
		} else return text;
	});

	sortData = memoize((data) => _.sortBy(data, (value) => -Date.parse(value.created)));

	render() {
		const { t, requestsStore } = this.props;
		//const requestsTable = parseRequests(requestsStore.data.actions, t);
		const requestsTable = this.sortData(requestsStore.data.actions);
		const noDataText = this.getNoDataText(requestsStore.loading, this.props.t('Нет данных'));
		return (
			<React.Fragment>
				{requestsStore.loading && (
					<div className="pageOverlay">
						<i className="fa fa-spinner css--animate" />
					</div>
				)}
				<BootstrapTable
					data={requestsTable}
					options={{ noDataText }}
					pagination
					bordered={false}
				>
					<TableHeaderColumn
						dataField="_id"
						isKey={true}
						dataAlign="center"
						tdStyle={this.tdStyle}
						dataFormat={this.formatLink}
					>
						{t('ID заявки')}
					</TableHeaderColumn>

					<TableHeaderColumn
						dataField="offer"
						dataAlign="center"
						tdStyle={this.tdStyle}
						dataFormat={this.formatOffer}
					>
						{t('Оффер')}
					</TableHeaderColumn>

					<TableHeaderColumn
						dataField="created"
						dataAlign="center"
						tdStyle={this.tdStyle}
						dataFormat={this.formatDate}
					>
						{t('Дата')}
					</TableHeaderColumn>

					<TableHeaderColumn
						dataField="statusesFlow"
						dataAlign="center"
						dataFormat={this.formatStatusIcon}
						width={'80px'}
						tdStyle={this.tdStyle}
					>
						{t('Статус')}
					</TableHeaderColumn>

					<TableHeaderColumn
						dataField="client"
						dataFormat={this.formatClientName}
						tdStyle={this.tdStyle}
						dataAlign="center"
					>
						{t('Имя')}
					</TableHeaderColumn>

					<TableHeaderColumn
						dataField="client"
						dataFormat={this.formatClientPhone}
						tdStyle={this.tdStyle}
						dataAlign="center"
					>
						{t('Телефон')}
					</TableHeaderColumn>

					<TableHeaderColumn
						dataField="statusesFlow"
						dataAlign="center"
						tdStyle={this.tdStyle}
						dataFormat={this.formatStatusComment}
					>
						{t('Комментарий')}
					</TableHeaderColumn>
				</BootstrapTable>
			</React.Fragment>
		);
	}
}

const mapStateToProps = ({ apiRequestStore }) => ({
	requestsStore: apiRequestStore.affiliateStats.requests,
});

export default connect(mapStateToProps, null)(RequestsStats);
