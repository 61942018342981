import { adminPanelRequestTypes } from '../../constants';

import notify from 'notifications';

import { combineReducers } from 'redux';

const initialMerchants = {
	loading: false,
	loaded: false,
	errors: null,
	data: [],
};

const initialAffiliates = {
	loading: false,
	loaded: false,
	errors: null,
	data: [],
};

const initialUserInfo = {
	loading: false,
	loaded: false,
	errors: null,
	data: {},
};

function merchants(state = initialMerchants, { type, data, errors }) {
	switch (type) {
		case adminPanelRequestTypes.GET_MERCHANTS_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_MERCHANTS_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		case adminPanelRequestTypes.GET_MERCHANTS_SUCCESS:
			return { ...state, loading: false, loaded: true, errors: null, data: data };
		default:
			return state;
	}
}

function emails(state = initialMerchants, { type, data, errors }) {
	switch (type) {
		case adminPanelRequestTypes.GET_EMAILS_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_EMAILS_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		case adminPanelRequestTypes.GET_EMAILS_SUCCESS:
			return { ...state, loading: false, loaded: true, errors: null, data: data };
		default:
			return state;
	}
}

function affiliates(state = initialAffiliates, { type, data, errors }) {
	switch (type) {
		case adminPanelRequestTypes.GET_ALL_AFFILIATES_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_ALL_AFFILIATES_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		case adminPanelRequestTypes.GET_ALL_AFFILIATES_SUCCESS:
			return { ...state, loading: false, loaded: true, errors: null, data: data };
		default:
			return state;
	}
}

function userInfo(
	state = initialUserInfo,
	{ type, data, errors, asyncDispath, paymentId, callback, newOffer },
) {
	switch (type) {
		case adminPanelRequestTypes.GET_USER_INFO_REQUEST:
			return { ...initialUserInfo, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.UPDATE_USER_PAYMENT_SETTINGS_REQUEST:
		case adminPanelRequestTypes.UPDATE_USER_OFFERS_REQUEST:
		case adminPanelRequestTypes.DELETE_USER_PAYMENT_SETTINGS_REQUEST:
		case adminPanelRequestTypes.SET_USER_ACCESS_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.UPDATE_USER_PAYMENT_SETTINGS_FAIL:
		case adminPanelRequestTypes.UPDATE_USER_OFFERS_FAIL:
		case adminPanelRequestTypes.DELETE_USER_PAYMENT_SETTINGS_FAIL:
		case adminPanelRequestTypes.GET_USER_INFO_FAIL:
		case adminPanelRequestTypes.SET_USER_ACCESS_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		case adminPanelRequestTypes.SET_USER_ACCESS_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				errors: null,
				data: { ...state.data, ...data },
			};
		case adminPanelRequestTypes.DELETE_USER_PAYMENT_SETTINGS_SUCCESS:
			notify.success();
			const payment = Array.isArray(state.data.settings.payment)
				? state.data.settings.payment
				: [];
			const index = payment.findIndex((t) => t._id === paymentId);
			if (index !== -1) {
				payment.splice(index, 1);
				state.data.settings.payment = payment.slice();
			}
			return {
				...state,
				loading: false,
				loaded: true,
				errors: null,
				data: { ...state.data },
			};
		case adminPanelRequestTypes.GET_USER_INFO_SUCCESS:
			return { ...state, loading: false, loaded: true, errors: null, data: data };
		case adminPanelRequestTypes.UPDATE_USER_PAYMENT_SETTINGS_SUCCESS:
			notify.success();
			const newSettings = { ...state.data.settings, payment: data };
			return {
				...state,
				loading: false,
				loaded: true,
				errors: null,
				data: { ...state.data, settings: newSettings },
			};
		case adminPanelRequestTypes.UPDATE_USER_OFFERS_SUCCESS:
			notify.success();
			callback && callback();
			const newOffers = state.data.offers.slice();
			const offerIndex = newOffers.findIndex((t) => t.offer._id === newOffer.offerId);
			delete newOffer.offerId;
			newOffers[offerIndex] = { ...newOffers[offerIndex], ...newOffer };
			return {
				...state,
				loading: false,
				loaded: true,
				errors: null,
				data: { ...state.data, offers: newOffers },
			};
		case adminPanelRequestTypes.LOCAL_USER_UPDATE:
			const updateData = data ? data : initialUserInfo;
			return { ...state, data: { ...state.data, ...updateData } };
		default:
			return state;
	}
}

function landings(state = initialAffiliates, { type, data, errors }) {
	switch (type) {
		case adminPanelRequestTypes.UPDATE_USERS_LANDINGS_REQUEST:
		case adminPanelRequestTypes.GET_USERS_LANDINGS_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_USERS_LANDINGS_FAIL:
		case adminPanelRequestTypes.UPDATE_USERS_LANDINGS_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		case adminPanelRequestTypes.GET_USERS_LANDINGS_SUCCESS:
			return { ...state, loading: false, loaded: true, errors: null, data: data };
		case adminPanelRequestTypes.UPDATE_USERS_LANDINGS_SUCCESS:
			const newData = state.data.slice();
			for (let i = 0; i < newData.length; i++) {
				data.copy = data.copy || {};
				if (newData[i].copy._id === data.copy._id) {
					newData[i] = data;
					break;
				}
			}
			return { ...state, loading: false, loaded: true, errors: null, data: newData };
		default:
			return state;
	}
}

const users = {
	merchants,
	affiliates,
	userInfo,
	emails,
	landings,
};

export default combineReducers(users);
