import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import querystring from 'querystring';

import { getStasLandings } from 'store/actions/apiRequestActions/stats';

import { setFilter, clearFilter } from 'store/actions/supportActions';
import ContentHeader from './ContentHeader';

import SearchBlock from './SearchBlock';
import TableBlock from './TableBlock';
import InfoBlock from './InfoBlock';

class LandingsStats extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			landingInfo: {
				copy: {
					stats: {},
				},
				original: null,
			},
		};
	}

	setLandingsInfo = () => {
		let landingInfo = querystring.parse(
			this.props.location.search.slice(1, this.props.location.search.length),
		);
		landingInfo = JSON.parse(landingInfo.landing);
		landingInfo.copy = landingInfo.copy || {};
		landingInfo.copy.stats = landingInfo.copy.stats || {};
		if (landingInfo.original) {
			landingInfo.original.stats = landingInfo.original.stats || {};
		}
		this.setState({ landingInfo });
	};

	componentDidMount() {
		this.setLandingsInfo();
	}

	componentDidUpdate(prevprops) {
		if (this.props.location !== prevprops.location) {
			this.setLandingsInfo();
		}
	}

	render() {
		return (
			<div className="content-wrapper">
				<ContentHeader t={this.props.t} />

				<section className="content offers">
					<SearchBlock
						t={this.props.t}
						landingId={this.state.landingInfo.copy._id}
						getStasLandings={this.props.getStasLandings}
						filter={this.props.filter}
						setFilter={this.props.setFilter}
						clearFilter={this.props.clearFilter}
					/>

					<InfoBlock t={this.props.t} data={this.state.landingInfo} />

					<TableBlock t={this.props.t} k daily={this.props.daily} />
				</section>
			</div>
		);
	}
}

//Получаем modalStore из stora и записываем в props компонента
const mapStateToProps = ({ apiRequestStore, supportedStore, router }) => ({
	daily: apiRequestStore.stats.landings,
	filter: supportedStore.filters.landingStats,
	location: router.location,
});

//Пробрасываем actions в props компонента
const mapActionCreators = {
	getStasLandings,
	setFilter,
	clearFilter,
};

LandingsStats = translate('Daily')(LandingsStats);
export default connect(mapStateToProps, mapActionCreators)(LandingsStats);
