import { adminPanelRequestTypes, socketTypes } from '../../constants';
import { combineReducers } from 'redux';

import { sendTicket } from 'store/actions/socketActions';
import { getTicket } from 'store/actions/apiRequestActions/tickets';

import notify from 'notifications';

const initialState = {
	loading: false,
	loaded: false,
	errors: null,
	data: [],
};

const initialStateObj = {
	loading: false,
	loaded: false,
	errors: null,
	data: [],
};

function allTickets(
	state = initialState,
	{ type, data, errors, callback, asyncDispatch, ticketId, userId },
) {
	switch (type) {
		case adminPanelRequestTypes.GET_ALL_TICKETS_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_ALL_TICKETS_SUCCESS:
			return { ...state, loading: false, loaded: true, errors: null, data: data };
		case adminPanelRequestTypes.GET_ALL_TICKETS_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : null };
		case adminPanelRequestTypes.CREATE_TICKET_REQUEST:
			return state;
		case adminPanelRequestTypes.CREATE_TICKET_SUCCESS:
			state.data.push(data);
			return { ...state, data: state.data.slice() };
		case adminPanelRequestTypes.CREATE_TICKET_FAIL:
			return { ...state, errors: errors ? errors : null };
		case adminPanelRequestTypes.POST_MESSAGE_TO_TICKET_REQUEST:
			return state;
		case adminPanelRequestTypes.POST_MESSAGE_TO_TICKET_SUCCESS:
			notify.success();
			callback && callback();
			asyncDispatch(
				sendTicket({
					ticketId: ticketId,
					userId: userId,
					message: data,
				}),
			);
			const index = state.data.findIndex((t) => t._id === ticketId);
			if (index !== -1) {
				for (let i = 0; i < state.data[index].messages.length; i++) {
					state.data[index].messages[i].readByBoth = true;
				}
				state.data[index].messages = state.data[index].messages.slice();
				state.data[index].messages.push(data);
				state.data[index] = { ...state.data[index] };
			}

			return { ...state, data: state.data.slice() };
		case socketTypes.ADD_MESSAGE_TO_TICKET:
			const ticketIndex = state.data.findIndex((t) => t._id === data.ticketId);
			if (ticketIndex !== -1) {
				let newMessages = state.data[ticketIndex].messages.slice();
				newMessages.push(data.message);
				state.data[ticketIndex].messages = newMessages;
				state.data[ticketIndex] = { ...state.data[ticketIndex] };
			}
			return { ...state, data: state.data.slice() };
		case adminPanelRequestTypes.POST_MESSAGE_TO_TICKET_FAIL:
			return state;
		case adminPanelRequestTypes.CLOSE_TICKET_REQUEST:
			return state;
		case adminPanelRequestTypes.CLOSE_TICKET_SUCCESS:
			notify.success();
			if (index !== -1) state.data[index].closed = true;
			return { ...state, data: state.data.slice() };
		case adminPanelRequestTypes.CLOSE_TICKET_FAIL:
			return { ...state, errors: errors ? errors : null };
		case adminPanelRequestTypes.READ_TICKET_MESSAGE_REQUEST:
			return state;
		case adminPanelRequestTypes.READ_TICKET_MESSAGE_SUCCESS:
			if (index !== -1) {
				for (let i = 0; i < state.data[index].messages.length; i++) {
					if (state.data[index].messages[i].sentByUser)
						state.data[index].messages[i].readByBoth = true;
				}
				state.data[index].messages = state.data[index].messages.slice();
				state.data[index] = { ...state.data[index] };
			}
			return { ...state, data: state.data.slice() };
		case adminPanelRequestTypes.READ_TICKET_MESSAGE_FAIL:
			return { ...state, errors: errors ? errors : null };
		default:
			return state;
	}
}

function filterData(state = initialStateObj, { type, data, errors }) {
	switch (type) {
		case adminPanelRequestTypes.GET_TICKETS_FILTERS_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_TICKETS_FILTERS_SUCCESS:
			return { ...state, loading: false, loaded: true, errors: null, data: data };
		case adminPanelRequestTypes.GET_TICKETS_FILTERS_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		default:
			return state;
	}
}

function newTickets(state = initialState, { type, data, errors, ticketId, asyncDispatch }) {
	switch (type) {
		case adminPanelRequestTypes.GET_NEW_TICKETS_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_NEW_TICKETS_SUCCESS:
			return { ...state, loading: false, loaded: true, errors: null, data: data };
		case adminPanelRequestTypes.POST_MESSAGE_TO_TICKET_SUCCESS:
			const index = state.data.findIndex((t) => t._id === ticketId);
			if (index !== -1) {
				state.data[index].read = true;
				for (let i = 0; i < state.data[index].messages.length; i++) {
					state.data[index].messages[i].readByBoth = true;
				}
				state.data[index].messages = state.data[index].messages.slice();
				state.data[index].messages.push(data);
				state.data[index] = { ...state.data[index] };
			}
			return { ...state, data: state.data.slice() };
		case socketTypes.ADD_MESSAGE_TO_TICKET:
			const ticketIndex = state.data.findIndex((t) => t._id === data.ticketId);
			if (ticketIndex !== -1) {
				let newMessages = state.data[ticketIndex].messages.slice();
				newMessages.push(data.message);
				state.data[ticketIndex].read = false;
				state.data[ticketIndex].messages = newMessages;
				state.data[ticketIndex] = { ...state.data[ticketIndex] };
			} else {
				asyncDispatch(getTicket(data.ticketId));
			}
			return { ...state, data: state.data.slice() };
		case adminPanelRequestTypes.GET_TICKET_SUCCESS:
			let ticketsNewData = state.data.slice();
			ticketsNewData.unshift(data);
			return { ...state, data: ticketsNewData };
		case adminPanelRequestTypes.HIDE_TICKET:
			ticketsNewData = state.data.slice();
			const hideIndex = ticketsNewData.findIndex((t) => t._id === ticketId);
			if (hideIndex !== -1) {
				ticketsNewData[hideIndex].read = true;
			}
			return { ...state, data: ticketsNewData };
		case adminPanelRequestTypes.GET_NEW_TICKETS_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		default:
			return state;
	}
}

const tickets = {
	allTickets,
	filterData,
	newTickets,
};

export default combineReducers(tickets);
