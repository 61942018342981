import React from 'react';

import { TableHeaderColumn, BootstrapTable } from 'react-bootstrap-table';

export default class TableInfo extends React.PureComponent {
	constructor(props) {
		super(props);
		this.tdStyle = { whiteSpace: 'normal' };
		this.numPayments = 15;
	}

	render() {
		const { t, data, formatStatusesFlow, pre } = this.props;
		const pagination = data.length > this.numPayments;
		return (
			<BootstrapTable
				data={data}
				striped={true}
				options={{ sizePerPage: this.numPayments }}
				hover={true}
				bordered={false}
				pagination={pagination}
			>
				<TableHeaderColumn dataField="_id" isKey={true} hidden />

				<TableHeaderColumn dataField="date" tdStyle={this.tdStyle} dataAlign="center">
					{t('Дата')}
				</TableHeaderColumn>

				<TableHeaderColumn dataField="amount" tdStyle={this.tdStyle} dataAlign="center">
					{t('Сумма')}
				</TableHeaderColumn>

				<TableHeaderColumn dataField="currency" dataAlign="center" tdStyle={this.tdStyle}>
					{t('Валюта')}
				</TableHeaderColumn>

				{!pre && (
					<TableHeaderColumn
						dataField="paymentSystem"
						dataAlign="center"
						tdStyle={this.tdStyle}
						thStyle={{ whiteSpace: 'normal' }}
					>
						{t('Платежная система')}
					</TableHeaderColumn>
				)}
				{!pre && (
					<TableHeaderColumn
						dataField="account"
						dataAlign="center"
						tdStyle={this.tdStyle}
					>
						{t('Аккаунт')}
					</TableHeaderColumn>
				)}

				<TableHeaderColumn
					dataField="statusesFlow"
					dataAlign="center"
					tdStyle={this.tdStyle}
					dataFormat={formatStatusesFlow}
				>
					{t('Статус')}
				</TableHeaderColumn>
			</BootstrapTable>
		);
	}
}
