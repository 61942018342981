// const initialauth = {
// 	authenticated: false,
// 	access_token: null,
// 	refresh_token: null,
// 	user: null,
// };

export const loadState = () => {
	try {
		const serializedState = localStorage.getItem('partnerStore');
		if (serializedState === null) {
			return {
				apiRequestStore: {
					auth: window.mainStorage,
				},
			};
		}
		return JSON.parse(serializedState);
	} catch (err) {
		return undefined;
	}
};

export const saveState = (state) => {
	try {
		const serializedState = JSON.stringify(state);
		localStorage.setItem('partnerStore', serializedState);
	} catch (err) {
		// Ignore write errors.
	}
};
