import React from 'react';
import memoize from 'memoize-one';
import uuidv4 from 'uuid/v4';

import PaymentItem from './PaymentItem';
import CardList from 'components/service/CardList';

export default class PaymentsContainer extends React.PureComponent {
	getStyle = memoize((pre) => {
		const style = {};
		if (pre) {
			style.width = '25%';
		} else {
			style.width = '33%';
		}
		return style;
	});

	componentDidMount() {
		const update = () => {
			this.forceUpdate();
		};
		window.addEventListener('resize', update);
	}

	renderPaymentItems = (bsStyle) => (data) => {
		if (Array.isArray(data)) {
			return data.map((d) => {
				return (
					<PaymentItem
						key={uuidv4()}
						bsStyle={bsStyle}
						t={this.props.t}
						payment={d}
						changedStatus={this.props.changedStatus}
					/>
				);
			});
		} else {
			return null;
		}
	};

	renderWaitings = memoize(this.renderPaymentItems('warning'));
	renderVerified = memoize(this.renderPaymentItems('info'));
	renderTransferred = memoize(this.renderPaymentItems('success'));
	renderPaid = memoize(this.renderPaymentItems('success'));
	renderReturned = memoize(this.renderPaymentItems('danger'));
	renderDeclined = memoize(this.renderPaymentItems('danger'));

	render() {
		const { t, data, pre } = this.props;
		const style = this.getStyle(pre);
		return (
			<div className="payments-box">
				<div className="payments-box-body custom-scroll">
					<CardList bsStyle="warning" title={t('В ожидании')} style={style}>
						{this.renderWaitings(data.Waiting)}
					</CardList>

					{pre && (
						<CardList bsStyle="info" title={t('На рассмотрении')} style={style}>
							{this.renderVerified(data.Verified)}
						</CardList>
					)}

					{pre && (
						<CardList bsStyle="success" title={t('Переведено на баланс')} style={style}>
							{this.renderTransferred(data['Transferred to balance'])}
						</CardList>
					)}

					{!pre && (
						<CardList bsStyle="success" title={t('Выплачено')} style={style}>
							{this.renderPaid(data.Paid)}
						</CardList>
					)}

					{pre && (
						<CardList bsStyle="danger" title={t('Отклонено')} style={style}>
							{this.renderReturned(data.Declined)}
						</CardList>
					)}

					{!pre && (
						<CardList bsStyle="danger" title={t('Возвращено на баланс')} style={style}>
							{this.renderDeclined(data['Returned to balance'])}
						</CardList>
					)}
				</div>
			</div>
		);
	}
}
