import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Button } from 'react-bootstrap';

export default class ContentHeader extends React.PureComponent {
	render() {
		const { t } = this.props;
		return (
			<section className="content-header" style={{ height: '50px' }}>
				<Col xs={6} sm={4}>
					<h1>
						<i className="fa fa-truck" />
						{t('Заявки')}
					</h1>
				</Col>

				<Col xs={6} sm={2} smPush={6}>
					<Link to="/request">
						<Button bsStyle="info">{t('Добавить заявки')}</Button>
					</Link>
				</Col>
			</section>
		);
	}
}
