// ========== Importing react lib ==========//
import React from 'react';
import ReactDOM from 'react-dom';

// ========== Importing Routing lib ========== //
import { createBrowserHistory } from 'history';
// ========== Importing styles ========== //
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import 'rc-switch/assets/index.css';
import './styles/css/bootstrap.css';
import './styles/css/react-select.min.css';
import './styles/fonts/stylesheet.css';
import './styles/css/font-awesome-4.5.0/css/font-awesome.css';
import './styles/stylesheets/Template/css/AdminLTE.min.css';
import './styles/stylesheets/Template/css/color-palette.css';
import './styles/stylesheets/Template/css/skins/skin-red.min.css';
import './styles/stylesheets/icons.css';
import './styles/plugins/morris/morris.css';
import './styles/css/sweetalert.css';
import './styles/css/merchant.css';
// ========== Importing main app ========== //
import createStore from './store/createStore';
import AppContainer from './containers/AppContainer';
import routes from './routes.jsx';

import { loadState, saveState } from 'mainLibs/localStorage';
// ========================================================
// Browser History Setup
// ========================================================
const history = createBrowserHistory();

// ========================================================
// Store and History Instantiation
// ========================================================
// Create redux store and sync with react-router-redux. We have installed the
// react-router-redux reducer under the routerKey "router" in src/routes/index.js,
// so we need to provide a custom `selectLocationState` to inform
// react-router-redux of its location.

const initialState = loadState();
const store = createStore(initialState, history);

store.subscribe(() => {
	saveState({
		apiRequestStore: {
			auth: store.getState().apiRequestStore.auth,
		},
		supportedStore: {
			filters: store.getState().supportedStore.filters,
		},
	});
});

// ========================================================
// Render Setup
// ========================================================
const MOUNT_NODE = document.getElementById('root');

let render = (routerKey = null) => {
	ReactDOM.render(<AppContainer store={store} routes={routes} history={history} />, MOUNT_NODE);
};

// ========================================================
// Go!
// ========================================================

render();
