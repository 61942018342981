import React from 'react';

import { Button } from 'react-bootstrap';

import BoxBody from './BoxBody';

export default class SearchBlock extends React.Component {
	toggleBox = (e) => {
		const filter = { ...this.props.filter, collapsed: !this.props.filter.collapsed };
		this.props.setFilter('tickets', filter);
	};

	getBoxBodyRef = (ref) => (this.boxBody = ref);

	updateStats = () => {
		if (this.boxBody) {
			this.boxBody.loadTickets();
		}
	};

	render() {
		const { t, ...otherProps } = this.props;
		return (
			<div
				className={
					'box box-danger box-solid color-palette-box ' +
					(this.props.filter.collapsed ? 'collapsed-box' : '')
				}
			>
				<div className="box-header with-border">
					<h3 className="box-title">
						<span>{t('Фильтр')}</span>
						<Button className="btn-box-tool-update" onClick={this.updateStats}>
							{t('Обновить')}
						</Button>
					</h3>

					<div className="box-tools pull-right">
						<Button className="btn-box-tool" onClick={this.toggleBox}>
							<i
								className={
									'fa ' + (this.props.filter.collapsed ? 'fa-plus' : 'fa-minus')
								}
							/>
						</Button>
					</div>
				</div>

				<BoxBody ref={this.getBoxBodyRef} t={t} {...otherProps} />
			</div>
		);
	}
}
