import React from 'react';
import _ from 'underscore';
import memoize from 'memoize-one';
import BoxBody from './BoxBody';

export default class ContentBody extends React.PureComponent {
	componentDidMount() {
		this.props.getMerchants();
	}

	parseMerchants = memoize((merchnats) => {
		const users = _.isArray(merchnats) ? merchnats : [];
		let result = [];

		for (let i = 0; i < users.length; i++) {
			const userInfo = {
				id: users[i]._id || '',
				name: users[i].name || '',
				email: users[i].username || '',
				lastName: users[i].lastName || '',
				phone: users[i].phone,
			};
			result.push(userInfo);
		}

		return result;
	});

	render() {
		const { t, merchants } = this.props;
		const data = this.parseMerchants(merchants);
		return (
			<div className="box box-danger">
				<BoxBody t={t} data={data} />
			</div>
		);
	}
}
