import React from 'react';
import { HighchartBasicArea } from 'components/Charts';

export default class ChartBlock extends React.PureComponent {
	render() {
		const { t } = this.props;
		return (
			<div className="box box-primary">
				<div className="box-header with-border">
					<h3 className="box-title">{t('Заказы')}</h3>
				</div>
				<div className="box-body chart-responsive">
					<HighchartBasicArea config={this.props.chartConfig} />
				</div>
			</div>
		);
	}
}
