import React from 'react';
import ReactSelect from 'react-select';
import { ControlLabel, FormGroup } from 'react-bootstrap';

export default class SelectForm extends React.PureComponent {
	state = {
		options: [],
	};

	onChangeSearch = (inputValue) => {
		let options = [];
		if (inputValue.length >= 3) {
			options = [];
			const searchValue = inputValue.toLowerCase();
			for (let i = 0; i < this.props.options.length; i++) {
				const option = this.props.options[i].toLowerCase();
				if (option[0] === searchValue[0]) {
					if (option.indexOf(searchValue) !== -1) {
						options.push({
							value: this.props.options[i],
							label: this.props.options[i],
						});
					}
				}
			}
		} else if (inputValue.length === 2) {
			options = [];
			const searchValue = inputValue.toLowerCase();
			for (let i = 0; i < this.props.options.length; i++) {
				const option = this.props.options[i].toLowerCase();
				if (option[0] === searchValue[0] && option[1] === searchValue[1]) {
					options.push({
						value: this.props.options[i],
						label: this.props.options[i],
					});
				}
			}
		} else if (inputValue.length === 1) {
			options = [];
			const searchValue = inputValue.toLowerCase();
			for (let i = 0; i < this.props.options.length; i++) {
				const option = this.props.options[i].toLowerCase();
				if (option[0] === searchValue[0]) {
					options.push({
						value: this.props.options[i],
						label: this.props.options[i],
					});
				}
			}
		}
		this.setState({ options });
		return inputValue;
	};

	onСhangeInfoClick = (value) => {
		if (value !== this.props.value) {
			this.props.changeInfo(value, this.props.name);
		}
	};

	render() {
		const { value, placeholder, label, disabled, defaultValue } = this.props;
		const currentOptions = this.state.options.slice();
		const index = currentOptions.findIndex((option) => option.value === value);
		if (index === -1 && value)
			currentOptions.unshift({
				value: value,
				label: value,
			});
		return (
			<FormGroup>
				<ControlLabel>{label}</ControlLabel>
				<ReactSelect
					onInputChange={this.onChangeSearch}
					value={value}
					options={currentOptions}
					autoBlur={true}
					clearable={true}
					onChange={this.onСhangeInfoClick}
					placeholder={placeholder}
					disabled={disabled}
					simpleValue={true}
					defaultValue={defaultValue}
				/>
			</FormGroup>
		);
	}
}

// export default class SelectForm extends React.Component {
//     static propTypes = {
//         defaultValue: PropTypes.string,
//         changeInfo: PropTypes.func.isRequired,
//         name: PropTypes.string.isRequired,
//         options: PropTypes.array
//     };

//     shouldComponentUpdate(nextProps, nextState) {
//         return(
//             this.props.value !== nextProps.value
//             || this.props.defaultValue !== nextProps.defaultValue
//             || this.props.options !== nextProps.options
//             || this.props.disabled !== nextProps.disabled
//         );
//     };
//     onInputChange = (inputValue) => {
//         if(!inputValue) {
//             if(this.props.value) {
//                 this.props.changeInfo(null, this.props.name);
//             }
//         } else {
//             this.props.onInputChange(inputValue);
//         }
//         this.inputValue = inputValue;
//         return inputValue;
//     };
//     onСhangeOfferInfoClick = (event) => {
//         if(event){
//             if(event.value){
//                 this.props.changeInfo(event.value, this.props.name);
//             }
//         }
//     };

//     onBlurClick = ()=> {
//         if(this.inputValue && !this.props.value)
//             this.props.clearOptions();
//     };

//     render() {
//         const { value,
//                 placeholder,
//                 options,
//                 label,
//                 defaultValue,
//                 disabled,
//                 clearOptions,
//                 isLoading } = this.props;
//         const currentValue = value ? value : (defaultValue || '');
//         const currentOptions = options.slice();
//         const index = currentOptions.findIndex(option => option.value === value);
//         if(index === -1 &&value)
//             currentOptions.unshift({
//                 value: value,
//                 label: value
//             })
//         return(
//             <FormGroup >
//                 <Col componentClass={ControlLabel} sm={2}>
//                     {label}
//                 </Col>
//                 <Col sm={10}>
//                     <ReactSelect
//                         onCloseResetsInput = {false}
//                         placeholder = {placeholder}
//                         value={currentValue}
//                         options={currentOptions}
//                         autoBlur={true}
//                         clearable={true}
//                         onChange={this.onСhangeOfferInfoClick}
//                         disabled = {disabled}
//                         onInputChange={this.onInputChange}
//                         onBlur = {this.onBlurClick}
//                         isLoading = {isLoading}
//                     />
//                 </Col>
//             </FormGroup>
//         );
//     }
// }
