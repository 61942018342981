import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Col, ControlLabel, FormGroup } from 'react-bootstrap';

export default class MultiSelectForm extends React.Component {
	static propTypes = {
		changeInfo: PropTypes.func.isRequired,
		name: PropTypes.string.isRequired,
		options: PropTypes.array,
	};

	shouldComponentUpdate(nextProps, nextState) {
		return (
			this.props.value !== nextProps.value ||
			this.props.options !== nextProps.options ||
			this.props.defaultValue !== nextProps.defaultValue ||
			this.props.disabled !== nextProps.disabled
		);
	}

	onСhangeClick = (val) => {
		this.props.changeInfo(val, this.props.name);
	};

	render() {
		const { value, options, label, defaultValue, placeholder, required, disabled } = this.props;
		const currentValue = value ? value : defaultValue || [];
		return (
			<FormGroup
				validationState={required ? (currentValue.length ? 'success' : 'error') : null}
			>
				<Col componentClass={ControlLabel} sm={2}>
					{label}
				</Col>
				<Col sm={10}>
					<Select
						value={currentValue}
						options={options}
						onChange={this.onСhangeClick}
						placeholder={placeholder}
						closeOnSelect={false}
						multi
						removeSelected
						autoBlur={true}
						clearable={true}
						searchable={true}
						disabled={disabled}
						backspaceToRemoveMessage=""
					/>
				</Col>
			</FormGroup>
		);
	}
}
