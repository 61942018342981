import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { ControlLabel, FormGroup } from 'react-bootstrap';

export default class SelectForm extends React.Component {
	static propTypes = {
		changeInfo: PropTypes.func.isRequired,
		name: PropTypes.string.isRequired,
		options: PropTypes.array,
	};

	shouldComponentUpdate(nextProps, nextState) {
		return this.props.value !== nextProps.value || this.props.options !== nextProps.options;
	}

	onСhangeClick = (val) => {
		this.props.changeInfo(val, this.props.name);
	};

	render() {
		const { value, options, label, defaultValue } = this.props;
		return (
			<FormGroup>
				<ControlLabel>{label}</ControlLabel>
				<Select
					value={value}
					options={options}
					onChange={this.onСhangeClick}
					placeholder={defaultValue}
					closeOnSelect={false}
					multi
					removeSelected
					autoBlur={true}
					clearable={true}
					searchable={true}
					backspaceToRemoveMessage=""
				/>
			</FormGroup>
		);
	}
}
