import React from 'react';
import { Col, Row, Button } from 'react-bootstrap';

import BoxBody from './BoxBody';

export default class FilterBlock extends React.PureComponent {
	toggleSearch = () => {
		const filter = { ...this.props.filter, collapsed: !this.props.filter.collapsed };
		this.props.setFilter('requestStats', filter);
	};

	getBoxBodyRef = (ref) => (this.boxBody = ref);

	updateStats = () => {
		if (this.boxBody) {
			this.boxBody.loadRequests();
		}
	};

	render() {
		const { t } = this.props;
		return (
			<Row>
				<Col md={12}>
					<div
						className={
							'box box-danger box-solid color-palette-box ' +
							(this.props.filter.collapsed ? 'collapsed-box' : '')
						}
					>
						<div className="box-header with-border">
							<h3 className="box-title">
								<span>{t('Фильтр')}</span>
								<Button className="btn-box-tool-update" onClick={this.updateStats}>
									{t('Обновить')}
								</Button>
							</h3>
							<div className="box-tools pull-right">
								<Button className="btn-box-tool" onClick={this.toggleSearch}>
									<i
										className={
											'fa ' +
											(this.props.filter.collapsed ? 'fa-plus' : 'fa-minus')
										}
									/>
								</Button>
							</div>
						</div>

						<BoxBody ref={this.getBoxBodyRef} {...this.props} />
					</div>
				</Col>
			</Row>
		);
	}
}
