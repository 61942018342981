import React from 'react';
import memoize from 'memoize-one';
import {
	FormGroup,
	Button,
	FormControl,
	ControlLabel,
	Form,
	Checkbox,
	Row,
	Col,
} from 'react-bootstrap';

import _ from 'underscore';

import SelectMerchantId from '../../SelectMerchantId';
import SelectForm from './SelectForm';
import DateForm from 'components/service/DateForm';

class CheckedForm extends React.PureComponent {
	render() {
		const { t, changeFilterStatus, active, noActive, whiteActive, whiteNoActive } = this.props;
		return (
			<FormGroup>
				<Checkbox inline checked={active} onChange={(e) => changeFilterStatus(e, 'active')}>
					{t('Трафик')}
				</Checkbox>
				<Checkbox
					inline
					checked={noActive}
					onChange={(e) => changeFilterStatus(e, 'noActive')}
				>
					{t('Трафик (отключенные)')}
				</Checkbox>
				<Checkbox
					inline
					checked={whiteActive}
					onChange={(e) => changeFilterStatus(e, 'whiteActive')}
				>
					{t('White (активные)')}
				</Checkbox>
				<Checkbox
					inline
					checked={whiteNoActive}
					onChange={(e) => changeFilterStatus(e, 'whiteNoActive')}
				>
					{t('White (отключенные)')}
				</Checkbox>
			</FormGroup>
		);
	}
}

export default class BoxBody extends React.PureComponent {
	loadRequests = () => {
		const { id, rstatus, affiliates, geos, offers, start, end, ...otherFilter } =
			this.props.filter;
		const data = {
			id: _.compact(id.replace(/(\s+)|(,)/g, ',').split(',')),
			rstatus: _.pluck(rstatus, 'value'),
			affiliates: _.pluck(affiliates, 'value'),
			geos: _.pluck(geos, 'value'),
			offers: _.pluck(offers, 'value'),
			start: new Date(start).setHours(0, 0, 0, 0),
			end:
				Date.now() < new Date(end).setHours(23, 59, 59, 999)
					? Date.now()
					: new Date(end).setHours(23, 59, 59, 999),
			//end: new Date(end).setHours(23,59, 59,999),
			...otherFilter,
		};
		this.props.getStatsRequests(data);
	};

	setFilter = (data) => this.props.setFilter('requestStats', data);
	clearFilter = () => this.props.clearFilter('requestStats');
	getAllOffers = (filter) =>
		this.props.getAllOffers({
			merchantId: filter.merchantId,
			active: filter.active,
			noActive: filter.noActive,
			whiteActive: filter.whiteActive,
			whiteNoActive: filter.whiteNoActive,
		});

	componentDidMount() {
		this.props.getAffiliatesUsed({ merchantId: this.props.filter.merchantId });
		this.props.getAllOffers(this.props.filter);
		this.loadRequests();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.filter.merchantId !== this.props.filter.merchantId)
			this.props.getAffiliatesUsed({ merchantId: this.props.filter.merchantId });
	}

	changeFilter = (val, name) => {
		var filter = { ...this.props.filter };
		filter[name] = val.target ? val.target.value : val;
		this.setFilter(filter);
	};

	changeFilterPeriod = ({ start, end, selectedPeriod }) => {
		var filter = { ...this.props.filter };
		filter.start = start;
		filter.end = end;
		filter.selectedPeriod = selectedPeriod;
		this.setFilter(filter);
	};
	changeMerchantId = (value) => this.changeFilter(value, 'merchantId');

	changeFilterStatus = (event, fieldName) => {
		var filter = { ...this.props.filter };
		filter[fieldName] = !filter[fieldName];
		this.getAllOffers(filter);
		this.setFilter(filter);
	};

	search = (e) => {
		e.preventDefault();
		e.stopPropagation();
		this.loadRequests();
	};

	getReactSelectOptionsFromOffers = memoize((offers) => {
		let result = offers.map((offer) => {
			let name = offer.name + ' (';
			offer.geos = offer.geos || [];
			for (var i = 0; i < offer.geos.length; i++) {
				offer.geos[i] = offer.geos[i] || {};
				offer.geos[i].geoRef = offer.geos[i].geoRef || {};
				if (offer.geos[i].geoRef.code) {
					name += offer.geos[i].geoRef.code;
				}
				if (i !== offer.geos.length) name += ', ';
				else name += ')';
			}
			return { value: offer._id, label: name };
		});
		return result;
	});

	getReactSelectOptionsFromAffiliates = memoize((users) => {
		let result = users.map((user) => ({
			value: user._id,
			label: user.username + ' (' + user._id + ')',
		}));
		return result;
	});

	getReactSelectOptionsFromStatuses = memoize((statuses) => {
		let result = statuses.map((status) => ({
			value: status._id,
			label: status.name + ' (' + status.description + ')',
		}));
		return result;
	});

	getReactSelectOptionsFromGeos = memoize((geos) => {
		let result = geos.map((geo) => ({
			value: geo._id,
			label: geo.name + ' (' + geo.code + ')',
		}));
		return result;
	});

	render() {
		const { t, offers, affiliates, statuses, geos, access } = this.props;
		const offersSelectOptions = this.getReactSelectOptionsFromOffers(offers || []);
		const affiliatesSelectOptions = this.getReactSelectOptionsFromAffiliates(affiliates || []);
		const statusSelectOptions = this.getReactSelectOptionsFromStatuses(statuses || []);
		const geosSelectOptions = this.getReactSelectOptionsFromGeos(geos);
		return (
			<div className="box-body">
				<Form onSubmit={this.search} className="semiInline">
					<Row className="equal">
						<Col xs={12} sm={12} md={8} lg={6}>
							<DateForm
								label={t('Дата')}
								startValue={this.props.filter.start}
								endValue={this.props.filter.end}
								changeInfo={this.changeFilterPeriod}
								selectedPeriod={this.props.filter.selectedPeriod}
							/>
						</Col>

						<Col xs={12} sm={6} md={4} lg={access.adminOnly ? 3 : 6}>
							<FormGroup>
								<ControlLabel>{t('ID заявок')}</ControlLabel>
								<FormControl
									type="text"
									value={this.props.filter.id}
									onChange={this.changeFilter}
								/>
							</FormGroup>
						</Col>

						{access.adminOnly && (
							<Col xs={12} sm={6} md={4} lg={3}>
								<SelectForm
									label={t('Веб-мастеры')}
									value={this.props.filter.affiliates}
									defaultValue={t('Все веб-мастеры')}
									changeInfo={this.changeFilter}
									name={'affiliates'}
									options={affiliatesSelectOptions}
								/>
							</Col>
						)}

						<Col xs={12} sm={6} md={4} lg={6}>
							<SelectForm
								label={t('Оффер')}
								value={this.props.filter.offers}
								defaultValue={t('Все офферы')}
								changeInfo={this.changeFilter}
								name={'offers'}
								options={offersSelectOptions}
							/>
						</Col>

						{access.adminOnly && (
							<Col xs={12} sm={6} md={4} lg={6}>
								<FormGroup>
									<ControlLabel>{t('Рекламодатель')}</ControlLabel>
									<SelectMerchantId
										defaultStyle={true}
										merchantId={this.props.filter.merchantId}
										changeInfo={this.changeMerchantId}
									/>
								</FormGroup>
							</Col>
						)}

						{access.adminOnly && (
							<Col xs={12}>
								<CheckedForm
									t={t}
									changeFilterStatus={this.changeFilterStatus}
									active={this.props.filter.active}
									noActive={this.props.filter.noActive}
									whiteActive={this.props.filter.whiteActive}
									whiteNoActive={this.props.filter.whiteNoActive}
								/>
							</Col>
						)}

						<Col xs={12} sm={6} md={6} lg={access.adminOnly ? 6 : 3}>
							<SelectForm
								label={t('Статус')}
								value={this.props.filter.rstatus}
								defaultValue={t('Все статусы')}
								changeInfo={this.changeFilter}
								name={'rstatus'}
								options={statusSelectOptions}
							/>
						</Col>

						<Col xs={12} sm={6} md={6} lg={access.adminOnly ? 6 : 3}>
							<SelectForm
								label={t('Гео')}
								value={this.props.filter.geos}
								defaultValue={t('Все гео')}
								changeInfo={this.changeFilter}
								name={'geos'}
								options={geosSelectOptions}
							/>
						</Col>
					</Row>
					<Button type="submit" bsStyle="info">
						{t('Отобразить')}
					</Button>{' '}
					<Button bsStyle="danger" onClick={this.clearFilter}>
						{t('Сбросить')}
					</Button>
				</Form>
			</div>
		);
	}
}
