import axios from 'axios';
import _ from 'underscore';
import notify from 'notifications';
import { refreshLogin } from 'store/actions/apiRequestActions/auth';
import { authRequestTypes, notificationMessage } from 'store/constants';

var actionQueue = []; //все action записываются в очередь, пока не придет ответ с новым access_token
var refresh = false; // если пришел ответ token expired нужно отправить запрос с refresh_token

export default function requestMiddleware({ getState, dispatch }) {
	return (next) => (action) => {
		// получаем config axios - request и тип action -  type, success, failure
		const { headers, request, type, success, failure, ...rest } = action;
		if (action.asyncDispatch) dispatch = action.asyncDispatch;

		//если нет объекта request, то это обычный action, пропускаем его дальше, через остальные middleware
		if (!request) return next(action);
		let access_token = null;
		let refresh_token = null;
		let userId = null;
		//получаем из store access_token, если он там есть
		if (getState().apiRequestStore.auth !== undefined) {
			const authStore = getState().apiRequestStore.auth;
			access_token = authStore.access_token;
			refresh_token = authStore.refresh_token;
			userId = authStore.user._id;
		}
		//Расширяем тип action на три типа, когда запрос инициализирован, удачный, пришел с ошибкой
		const SUCCESS = success || type + '_SUCCESS';
		const REQUEST = type + '_REQUEST';
		const FAIL = failure || type + '_FAIL';

		//инициализируем новый action с типом request, чтобы зарегистрировать, что был отправлен запрос
		next({ ...rest, type: REQUEST });

		if (type === authRequestTypes.SIGNUP_USER) {
			access_token = null;
			refresh_token = null;
		}

		// настройка заголовка аутентификации запроса
		let auth = !access_token
			? { auth: { username: 'Web', password: 'Secret-Web' } }
			: { headers: { Authorization: 'Bearer ' + access_token, ...headers } };

		if (type === authRequestTypes.USER_LOGOUT) {
			//dispatch(push('/'));
			request.data = {
				accessToken: access_token,
				refreshToken: refresh_token,
				userId: userId,
			};
		}

		var request_success = false;
		//отправляем запрос к серверу
		axios
			.request({ ...request, ...auth })
			.then((res) => {
				const { data } = res;
				request_success = true;
				//инициализируем новый action с типом success, что запрос прошел удачно
				next({ ...rest, data: data, type: SUCCESS });

				if (type === authRequestTypes.REFRESH_LOGIN) {
					refresh = false;
					_.each(actionQueue, (elem) => dispatch(elem));
					actionQueue = [];
				}
				return true;
			})
			.catch((error) => {
				let errorNotification = {};
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx

					const statusText = _.isObject(error.response.data)
						? JSON.stringify(
								error.response.data.message ||
									error.response.data.error_description,
						  )
						: error.response.data;
					dispatch({ ...rest, ...{ errors: statusText }, type: FAIL });

					switch (error.response.status) {
						case 400:
							if (statusText)
								errorNotification = {
									...notificationMessage.error_400,
									message: statusText,
								};
							else errorNotification = notificationMessage.error_400;
							notify.error(errorNotification);
							break;
						case 401:
							if (!refresh) {
								dispatch({ type: authRequestTypes.ACCESS_TOKEN_INVALID });
								dispatch(refreshLogin(refresh_token));
								refresh = true;
							}

							if (type === authRequestTypes.REFRESH_LOGIN) {
								refresh = false;
								actionQueue = [];
								if (statusText)
									errorNotification = {
										...notificationMessage.error_401,
										message: statusText,
									};
								else errorNotification = notificationMessage.error_401;
								notify.error(errorNotification);
							}

							actionQueue.push(action);
							break;
						case 403:
							if (statusText)
								errorNotification = {
									...notificationMessage.error_403,
									message: statusText,
								};
							else errorNotification = notificationMessage.error_403;
							notify.error(errorNotification);
							break;
						case 404:
							if (statusText)
								errorNotification = {
									...notificationMessage.error_404,
									message: statusText,
								};
							else errorNotification = notificationMessage.error_404;
							notify.error(errorNotification);
							break;
						case 408:
							if (statusText)
								errorNotification = {
									...notificationMessage.error_408,
									message: statusText,
								};
							else errorNotification = notificationMessage.error_408;
							notify.error(errorNotification);
							break;
						case 410:
							if (statusText)
								errorNotification = {
									...notificationMessage.error_410,
									message: statusText,
								};
							else errorNotification = notificationMessage.error_410;
							notify.error(errorNotification);
							break;
						case 500:
							if (statusText)
								errorNotification = {
									...notificationMessage.error_500,
									message: statusText,
								};
							else errorNotification = notificationMessage.error_500;
							notify.error(errorNotification);
							break;
						case 502:
							if (statusText)
								errorNotification = {
									...notificationMessage.error_502,
									message: statusText,
								};
							else errorNotification = notificationMessage.error_502;
							notify.error(errorNotification);
							break;
						case 503:
							if (statusText)
								errorNotification = {
									...notificationMessage.error_503,
									message: statusText,
								};
							else errorNotification = notificationMessage.error_503;
							notify.error(errorNotification);
							break;
						case 504:
							if (statusText)
								errorNotification = {
									...notificationMessage.error_504,
									message: statusText,
								};
							else errorNotification = notificationMessage.error_504;
							notify.error(errorNotification);
							break;
						default:
							if (statusText)
								errorNotification = {
									...notificationMessage.error_response,
									message: statusText,
								};
							else errorNotification = notificationMessage.error_response;
							notify.error(errorNotification);
							break;
					}
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					notify.error(notificationMessage.error_request);
					dispatch({ ...rest, ...{ errors: new Error(error).message }, type: FAIL });
				}
				//вызывается если произошла ошибка в чтении полученных
				else if (!request_success) {
					// Something happened in setting up the request that triggered an Error
					notify.error(notificationMessage.error);
					dispatch({ ...rest, ...{ errors: new Error(error).message }, type: FAIL });
				}
				return false;
			});
	};
}
