import React from 'react';
import ReactSelect from 'react-select';
import { Col, ControlLabel, FormGroup } from 'react-bootstrap';

export default class BigSelectForm extends React.PureComponent {
	state = {
		options: [],
		onBlurFunc: () => {},
	};

	setDefault = null;

	checkValue() {
		if (this.setDefault !== this.props.defaultValue) {
			this.setDefault = this.props.defaultValue;
			this.props.changeInfo(this.props.defaultValue, this.props.name);
		}
	}

	componentDidMount() {
		this.checkValue();
	}

	componentDidUpdate() {
		this.checkValue();
	}

	//clearOptions = ()=> this.setState({options:[]});

	onChangeSearch = (inputValue) => {
		const onBlurFunc = () => {
			if (this.props.value !== inputValue && inputValue)
				this.props.changeInfo(inputValue, this.props.name);
		};
		let options = [];
		if (inputValue.length >= 3) {
			options = [];
			const searchValue = inputValue.toLowerCase();
			for (let i = 0; i < this.props.options.length; i++) {
				const option = this.props.options[i].toLowerCase();
				if (option[0] === searchValue[0]) {
					if (option.indexOf(searchValue) !== -1) {
						options.push({
							value: this.props.options[i],
							label: this.props.options[i],
						});
					}
				}
			}
		} else if (inputValue.length === 2) {
			options = [];
			const searchValue = inputValue.toLowerCase();
			for (let i = 0; i < this.props.options.length; i++) {
				const option = this.props.options[i].toLowerCase();
				if (option[0] === searchValue[0] && option[1] === searchValue[1]) {
					options.push({
						value: this.props.options[i],
						label: this.props.options[i],
					});
				}
			}
		}
		this.setState({ options, onBlurFunc: onBlurFunc });
		return inputValue;
	};

	onСhangeInfoClick = (value) => {
		if (value !== this.props.value) {
			this.props.changeInfo(value, this.props.name);
		}
	};

	render() {
		const { value, placeholder, label, disabled } = this.props;
		const currentOptions = this.state.options;
		const index = currentOptions.findIndex((option) => option.value === value);
		if (index === -1 && value)
			currentOptions.unshift({
				value: value,
				label: value,
			});
		return (
			<FormGroup validationState={value ? 'success' : 'error'}>
				<Col componentClass={ControlLabel} sm={2}>
					{label}
				</Col>
				<Col sm={10}>
					<ReactSelect
						onBlurResetsInput={false}
						onCloseResetsInput={false}
						onSelectResetsInput={false}
						onInputChange={this.onChangeSearch}
						value={value}
						simpleValue={true}
						options={currentOptions}
						autoBlur={true}
						clearable={false}
						onChange={this.onСhangeInfoClick}
						placeholder={placeholder}
						disabled={disabled}
						onBlur={this.state.onBlurFunc}
					/>
				</Col>
			</FormGroup>
		);
	}
}
