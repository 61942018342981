import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translate } from 'react-i18next';
import { getPartners } from 'store/actions/apiRequestActions/partners';

import ContentHeader from './ContentHeader';
import ContentOffers from './ContentOffers';

class PartnersMerchant extends React.Component {
	render() {
		return (
			<div className="content-wrapper">
				<ContentHeader t={this.props.t} />

				<ContentOffers
					t={this.props.t}
					partners={this.props.partners}
					getPartners={this.props.getPartners}
					history={this.props.history}
				/>
			</div>
		);
	}
}

//Получаем modalStore из stora и записываем в props компонента
const mapStateToProps = ({ apiRequestStore }) => ({
	partners: apiRequestStore.partners,
});

//Пробрасываем actions в props компонента
const mapActionCreators = {
	getPartners,
};

PartnersMerchant = translate('Partners')(PartnersMerchant);
export default withRouter(connect(mapStateToProps, mapActionCreators)(PartnersMerchant));
