const domain = window.location.hostname.split('.').slice(-2).join('.');
let apiSocket = `${window.location.protocol}//partner.${domain}`;
if (process.env.NODE_ENV === 'development') {
	// apiSocket = '//localhost:8000';
	apiSocket = 'https://partner.dzha.top';
}
// ========= SOCKETS CONSTANTS BEGIN =========
export const SOCKET_CONNECT = 'SOCKET_CONNECT';
export const SOCKET_SEND_TICKET = 'SOCKET_SEND_TICKET';
export const SOCKET_URL = apiSocket;
export const ADD_MESSAGE_TO_TICKET = 'ADD_MESSAGE_TO_TICKET';
export const ADD_OFFER_ACCESS = 'ADD_OFFER_ACCESS';
export const ADD_PAYMENT = 'ADD_PAYMENT';
// ========= SOCKETS CONSTANTS END =========
export const EVENT_TO_TYPE = {
	message: ADD_MESSAGE_TO_TICKET,
	offerAccess: ADD_OFFER_ACCESS,
	payment: ADD_PAYMENT,
};

/* EVENT_TO_TYPE = {
	eventName: action.type,
}
eventName: event вызываемый со стороны сервера через socket.io
action.type: тип действия, определяющий обрабатывающий reduser

*/
