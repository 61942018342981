import { adminPanelRequestTypes, adminPanelApi } from '../../constants';

// //github.com/axios/axios
export function getAllOffers(params, callback) {
	return {
		//тип определяющий обрабатывающий редюсер
		type: adminPanelRequestTypes.GET_OFFERS,
		//объект request служит для конфигурации запроса axios
		request: {
			method: 'get',
			url: adminPanelApi.API_URL_OFFERS,
			params: params,
		},
		callback,
	};
}

export function getOffer(offerId) {
	return {
		type: adminPanelRequestTypes.GET_OFFER,
		request: {
			method: 'get',
			url: adminPanelApi.API_URL_OFFERS + `/${offerId}`,
		},
	};
}

export function updateOffer(offerId, data, callback) {
	return {
		type: adminPanelRequestTypes.UPDATE_OFFER_INFO,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'multipart/form-data',
		},
		request: {
			method: 'post',
			url: adminPanelApi.API_URL_OFFERS + `/${offerId}`,
			data: data,
		},
		callback,
	};
}

export function createOffer(offer, callback) {
	return {
		type: adminPanelRequestTypes.CREATE_OFFER,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'multipart/form-data',
		},
		request: {
			method: 'post',
			url: adminPanelApi.API_URL_CREATE_OFFER,
			data: offer,
		},
		callback,
	};
}

export function getCategories() {
	return {
		type: adminPanelRequestTypes.GET_OFFER_CATEGORY,
		request: {
			method: 'get',
			url: adminPanelApi.API_URL_OFFER_CATEGORY,
		},
	};
}

export function updateOfferGeo(offerId, data, newData) {
	return {
		type: adminPanelRequestTypes.UPDATE_OFFER_GEO,
		request: {
			method: 'put',
			url: adminPanelApi.API_URL_OFFERS + `/${offerId}/geo`,
			data: data,
		},
	};
}

export function addOfferGeo(offerId, data, newData) {
	return {
		type: adminPanelRequestTypes.ADD_OFFER_GEO,
		request: {
			method: 'post',
			url: adminPanelApi.API_URL_OFFERS + `/${offerId}/geo`,
			data: data,
		},
	};
}

export function deleteOfferGeo(offerId, data) {
	return {
		type: adminPanelRequestTypes.DELETE_OFFER_GEO,
		request: {
			method: 'delete',
			url: adminPanelApi.API_URL_OFFERS + `/${offerId}/geo`,
			data: data,
		},
		geoId: data.id,
	};
}

export function addOfferLanding(offerId, data) {
	return {
		type: adminPanelRequestTypes.ADD_OFFER_LANDING,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'multipart/form-data',
		},
		request: {
			method: 'post',
			// url: adminPanelApi.API_URL_OFFERS + `/${offerId}/landing`,
			url: adminPanelApi.API_BASE_LANDINGS + `/api/landings`,
			data: data,
		},
	};
}

export function deleteOfferLanding(offerId, data) {
	return {
		type: adminPanelRequestTypes.DELETE_OFFER_LANDING,
		request: {
			method: 'delete',
			url: adminPanelApi.API_URL_OFFERS + `/${offerId}/landing`,
			data: data,
		},
		landingId: data.id,
	};
}

export function addOfferPreLanding(offerId, data) {
	return {
		type: adminPanelRequestTypes.ADD_OFFER_PRELANDING,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'multipart/form-data',
		},
		request: {
			method: 'post',
			//url: adminPanelApi.API_URL_OFFERS + `/${offerId}/preLanding`,
			url: adminPanelApi.API_BASE_LANDINGS + `/api/prelandings`,
			data: data,
		},
	};
}

export function deleteOfferPreLanding(offerId, data) {
	return {
		type: adminPanelRequestTypes.DELETE_OFFER_PRELANDING,
		request: {
			method: 'delete',
			url: adminPanelApi.API_URL_OFFERS + `/${offerId}/preLanding`,
			data: data,
		},
		preLandingId: data.id,
	};
}

export function updateOfferAccess(offerId, data) {
	return {
		type: adminPanelRequestTypes.UPDATE_OFFER_ACCESS,
		request: {
			method: 'post',
			url: adminPanelApi.API_URL_OFFERS + `/${offerId}/access`,
			data: data,
		},
		access: data,
	};
}

export function getOfferAccess(params) {
	return {
		type: adminPanelRequestTypes.GET_OFFER_ACCESS,
		request: {
			method: 'get',
			url: adminPanelApi.API_URL_OFFER_ACCESS,
			params: params,
		},
	};
}

export function getOneOfferAccess(accessId) {
	return {
		type: adminPanelRequestTypes.GET_ONE_OFFER_ACCESS,
		request: {
			method: 'get',
			url: adminPanelApi.API_URL_OFFER_ACCESS + `/${accessId}`,
		},
	};
}

export function hideOfferAccess(offerAccessId) {
	return {
		type: adminPanelRequestTypes.HIDE_OFFER_ACCESS,
		offerAccessId: offerAccessId,
	};
}

export function changeOfferAccess(accessId, data) {
	return {
		type: adminPanelRequestTypes.CHANGE_OFFER_ACCESS,
		request: {
			method: 'put',
			url: adminPanelApi.API_URL_OFFER_ACCESS + `/${accessId}`,
			data: data,
		},
		decided: data.field === 'enabled',
	};
}

export function offersStatusUpdate(data, callback) {
	return {
		type: adminPanelRequestTypes.CHANGE_OFFER_STATUS,
		request: {
			method: 'put',
			url: adminPanelApi.API_URL_OFFER_STATUS,
			data: data,
		},
		offersStatus: data,
		callback,
	};
}

export function getOffersName(params, callback) {
	return {
		type: adminPanelRequestTypes.GET_OFFERS_NAME,
		request: {
			method: 'get',
			url: adminPanelApi.API_URL_OFFERS_NAME,
			params: params,
		},
		callback,
	};
}

export function duplicateOffers(data, callback) {
	return {
		type: adminPanelRequestTypes.DUPLICATE_OFFERS,
		request: {
			method: 'post',
			url: adminPanelApi.API_URL_OFFER_DUPLICATE,
			data: data,
		},
		callback,
	};
}

export function getPwaOptions(params, callback) {
	return {
		type: adminPanelRequestTypes.GET_PWA_OPTIONS,
		request: {
			method: 'get',
			url: adminPanelApi.API_URL_PWA_OPTIONS,
			params,
		},
		callback,
	};
}

export function addPwaOptions(data, callback) {
	return {
		type: adminPanelRequestTypes.ADD_PWA_OPTIONS,
		request: {
			method: 'post',
			url: adminPanelApi.API_URL_PWA_OPTIONS,
			data,
		},
		callback,
	};
}

export function deletePwaOptions(data, callback) {
	return {
		type: adminPanelRequestTypes.DELETE_PWA_OPTIONS,
		request: {
			method: 'delete',
			url: adminPanelApi.API_URL_PWA_OPTIONS + `${data.pwaOptionID}`,
			data,
		},
		callback,
	};
}
