import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import {
	getAllPayments,
	updatePaymentStatus,
	getAllPaymentStatuses,
} from 'store/actions/apiRequestActions/payments';

import { getEmails } from 'store/actions/apiRequestActions/users';
import { setFilter, clearFilter } from 'store/actions/supportActions';

import ContentBlock from './ContentBlock';

class SupportFinance extends React.Component {
	render() {
		const { payments, paymentStatuses, emails, prePayments } = this.props;
		const loading =
			payments.loading || paymentStatuses.loading || emails.loading || prePayments.loading;
		return (
			<div className="content-wrapper">
				{
					//   <ContentHeader
					//     access = {this.props.access}
					//    t = {this.props.t}
					///>
				}
				<section className="content finance-content">
					{loading ? (
						<div className="pageOverlay">
							<i className="fa fa-spinner css--animate" />
						</div>
					) : null}

					<ContentBlock
						t={this.props.t}
						emails={emails.data}
						payments={payments.data}
						prePayments={prePayments.data}
						paymentStatuses={paymentStatuses.data}
						getAllPaymentStatuses={this.props.getAllPaymentStatuses}
						getAllPayments={this.props.getAllPayments}
						updatePaymentStatus={this.props.updatePaymentStatus}
						getEmails={this.props.getEmails}
						filter={this.props.filter}
						preFilter={this.props.preFilter}
						setFilter={this.props.setFilter}
						clearFilter={this.props.clearFilter}
					/>
				</section>
			</div>
		);
	}
}

//Получаем modalStore из stora и записываем в props компонента
const mapStateToProps = ({ apiRequestStore, supportedStore }) => ({
	emails: apiRequestStore.users.emails,
	payments: apiRequestStore.payments.allPayments,
	prePayments: apiRequestStore.payments.allPrePayments,
	paymentStatuses: apiRequestStore.payments.paymentStatuses,
	filter: supportedStore.filters.payments,
	preFilter: supportedStore.filters.prePayments,
});

//Пробрасываем actions в props компонента
const mapActionCreators = {
	getAllPayments,
	updatePaymentStatus,
	getAllPaymentStatuses,
	getEmails,
	setFilter,
	clearFilter,
};

SupportFinance = translate('SupportFinance')(SupportFinance);
export default connect(mapStateToProps, mapActionCreators)(SupportFinance);
