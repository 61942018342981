import React from 'react';
import { Row, Col } from 'react-bootstrap';

export default class LocalOffer extends React.Component {
	static defaultProps = {
		stats: {
			approve: null,
			epc: null,
		},
	};
	componentDidMount() {}

	render() {
		var local = this.props.local;
		return (
			<Col sm={12} xs={12} className="offerDescriptionRow geoRow">
				<Col sm={4} xs={12} className="geoName">
					<img
						src={'images/countries/' + local.code.toLowerCase() + '.png'}
						alt={local.country}
						title={local.country}
					/>
					{local.country}
				</Col>

				<Col sm={2} xs={3}>
					<br />
					<span style={{ fontWeight: 'bold' }}>Цена товара</span>
				</Col>

				<Col sm={2} xs={3}>
					{local.leadcurrency === 'RUB' ? (
						<i className="fa fa-usd" />
					) : (
						local.leadcurrency
					)}
					<br />
					<span style={{ fontWeight: 'bold' }}> За заявку</span>
				</Col>

				<Col sm={2} xs={3}>
					{this.props.stats.approve === null ? (
						<i className="fa fa-spinner css--animate" />
					) : (
						[' %', <br />, <span style={{ fontWeight: 'bold' }}>Апрув</span>]
					)}
				</Col>

				<Col sm={2} xs={3}>
					{this.props.stats.approve === null ? (
						<i className="fa fa-spinner css--animate" />
					) : (
						[
							' ',
							<i className="fa fa-usd" />,
							<br />,
							<span style={{ fontWeight: 'bold' }}>EPC</span>,
						]
					)}
				</Col>

				<Row>
					<Col sm={12} xs={12} className="offerDescriptionRow geoRow">
						<Col sm={2} xs={3}>
							{this.props.stats.approve === null ? (
								<i className="fa fa-spinner css--animate" />
							) : (
								[
									' ',
									<i className="fa fa-usd" />,
									<br />,
									<span style={{ fontWeight: 'bold' }}>aEPC/eEPC</span>,
								]
							)}
						</Col>

						<Col sm={2} xs={3}>
							{this.props.stats.approve === null ? (
								<i className="fa fa-spinner css--animate" />
							) : (
								[
									' %',
									<br />,
									<span style={{ fontWeight: 'bold' }}>Конверсия</span>,
								]
							)}
						</Col>

						<Col sm={2} xs={3}>
							{this.props.stats.approve === null ? (
								<i className="fa fa-spinner css--animate" />
							) : (
								[' %', <br />, <span style={{ fontWeight: 'bold' }}>CR</span>]
							)}
						</Col>

						<Col sm={2} xs={3}>
							{this.props.stats.approve === null ? (
								<i className="fa fa-spinner css--animate" />
							) : (
								[' %', <br />, <span style={{ fontWeight: 'bold' }}>Холд</span>]
							)}
						</Col>

						<Col sm={2} xs={3}>
							{this.props.stats.approve === null ? (
								<i className="fa fa-spinner css--animate" />
							) : (
								[
									' %',
									<br />,
									<span style={{ fontWeight: 'bold' }}>Отклонено</span>,
								]
							)}
						</Col>

						<Col sm={2} xs={3}>
							{this.props.stats.approve === null ? (
								<i className="fa fa-spinner css--animate" />
							) : (
								[' %', <br />, <span style={{ fontWeight: 'bold' }}>Треш</span>]
							)}
						</Col>
					</Col>
				</Row>
			</Col>
		);
	}
}
