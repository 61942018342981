import { alertTypes } from '../constants';

const initialState = {
	open: false,
	type: null,
	message: null,
	title: null,
};

export default function alert(state = initialState, action) {
	switch (action.type) {
		case alertTypes.ALERT_HIDE:
			return { ...state, type: null, open: false, message: null, title: null };
		case alertTypes.ALERT_SHOW:
			const message = action.payload.message;
			const title = action.payload.title;
			return {
				...state,
				type: action.payload.type || 'info',
				open: true,
				message: (Array.isArray(message) ? message : [message]) || null,
				title: title,
			};
		default:
			return state;
	}
}
