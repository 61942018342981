import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translate } from 'react-i18next';

import { getAffiliates } from 'store/actions/apiRequestActions/users';

import ContentBody from './ContentBody';
import ContentHeader from './ContentHeader';

class Affiliates extends React.Component {
	render() {
		const { t, affiliates, getAffiliates } = this.props;
		const loading = affiliates.loading;

		return (
			<div className="content-wrapper">
				<ContentHeader label={t('Веб-мастеры')} />

				<section className="content offers">
					{loading ? (
						<div className="pageOverlay">
							<i className="fa fa-spinner css--animate" />
						</div>
					) : null}

					<ContentBody
						affiliates={affiliates.data || []}
						getAffiliates={getAffiliates}
						t={t}
					/>
				</section>
			</div>
		);
	}
}

//Получаем modalStore из stora и записываем в props компонента
const mapStateToProps = ({ apiRequestStore }) => ({
	affiliates: apiRequestStore.users.affiliates,
});

//Пробрасываем actions в props компонента
const mapActionCreators = {
	getAffiliates,
};

Affiliates = translate('Affiliates')(Affiliates);
export default withRouter(connect(mapStateToProps, mapActionCreators)(Affiliates));
