import React from 'react';
import _ from 'underscore';
import memoize from 'memoize-one';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Tabs, Tab } from 'react-bootstrap';
import ContentHeader from './ContentHeader';
import InfoBlock from './InfoBlock';
import EditBlock from './EditBlock';
import GeosInfoBlock from './GeosInfoBlock';
import LandingsInfoBlock from './LandingsInfoBlock';
import PreLandingsInfoBlock from './PreLandingsInfoBlock';
import Access from './Access';

import { alertActions, apiRequestActions } from 'store/actions';
import { isNullOrUndefined } from 'util';
import { PwaOptionsBlock } from './PwaOptionsBlock';

class DeepCampaign extends React.Component {
	getOfferId = () => {
		const url = window.location.pathname;
		return url.substr(url.lastIndexOf('/') + 1);
	};
	constructor(props) {
		super(props);
		this.offerId = this.getOfferId();
	}

	componentDidMount() {
		if (this.offerId !== 'new') {
			this.props.getOffer(this.offerId);
			this.props.getMerchants();
			this.props.getAffiliates();
			this.props.getCurrencies();
			this.props.getAllGeos();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const offerId = this.getOfferId();
		if (this.offerId !== offerId) {
			this.offerId = offerId;
			this.props.getOffer(this.offerId);
			if (!this.props.merchants.loaded) this.props.getMerchants();
			if (!this.props.affiliates.loaded) this.props.getAffiliates();
			if (!this.props.currencies.loaded) this.props.getCurrencies();
			if (!this.props.allGeos.loaded) this.props.getAllGeos();
		}
	}

	parsingOffer = memoize((offerInfo) => {
		function getBooleanType(type) {
			if (_.isString(type)) {
				if (type.toString() === 'false') return false;
				if (type.toString() === 'true') return true;
			} else return type;
		}
		if (!_.isObject(offerInfo)) offerInfo = {};
		if (!Array.isArray(offerInfo.category)) offerInfo.category = [];
		if (!Array.isArray(offerInfo.geos)) offerInfo.geos = [];
		if (!Array.isArray(offerInfo.landings)) offerInfo.landings = [];
		if (!Array.isArray(offerInfo.middlePages)) offerInfo.middlePages = [];
		if (!_.isObject(offerInfo.access)) offerInfo.access = {};
		return {
			name: offerInfo.name || '',
			description: offerInfo.description || '',
			hold: isNullOrUndefined(offerInfo.hold) ? '' : offerInfo.hold,
			logo: offerInfo.logo || '',
			callCenter: offerInfo.callCenter || '',
			traffic: offerInfo.traffic || '',
			auditory: offerInfo.auditory || '',
			nncpaCallCenter: getBooleanType(offerInfo.nncpaCallCenter),
			publicOffer: getBooleanType(offerInfo.access.publicOffer),
			white: getBooleanType(offerInfo.access.white),
			split: getBooleanType(offerInfo.access.split),
			disabled: getBooleanType(offerInfo.disabled),
			enabledAccess: Array.isArray(offerInfo.access.enabled) ? offerInfo.access.enabled : [],
			disabledAccess: Array.isArray(offerInfo.access.disabled)
				? offerInfo.access.disabled
				: [],
			actionType: offerInfo.actionType || '',
			category: offerInfo.category,
		};
	});

	parceAffiliates = memoize((allAffiliates, enabled, disabled) => {
		let affiliates = Array.isArray(allAffiliates) ? allAffiliates : [];
		const result = [];
		for (let i = 0; i < affiliates.length; i++) {
			let finded = false;
			for (let j = 0; j < enabled.length; j++) {
				if (affiliates[i]._id === enabled[j]._id) {
					finded = true;
					break;
				}
			}
			for (let j = 0; j < disabled.length; j++) {
				if (affiliates[i]._id === disabled[j]._id) {
					finded = true;
					break;
				}
			}
			if (!finded) result.push(affiliates[i]);
		}
		return result;
	});

	render() {
		const {
			t,
			campaign,
			currencies,
			merchants,
			affiliates,
			allGeos,
			actionTypes,
			categories,
			access,
		} = this.props;
		const campaignInfo = this.parsingOffer(campaign.data);
		const { geos, landings, middlePages } = campaign.data;
		const loading =
			campaign.loading ||
			currencies.loading ||
			merchants.loading ||
			affiliates.loading ||
			allGeos.loading ||
			actionTypes.loading ||
			categories.loading;
		const isNewOffer = this.offerId === 'new';
		const accessAffiliates = this.parceAffiliates(
			affiliates.data,
			campaignInfo.enabledAccess,
			campaignInfo.disabledAccess,
		);
		return (
			<div className="content-wrapper">
				<ContentHeader name={campaignInfo.name} />

				<section className="content">
					{loading ? (
						<div className="pageOverlay">
							<i className="fa fa-spinner css--animate" />
						</div>
					) : null}

					{!isNewOffer && (
						<InfoBlock
							expand={!access.adminOnly}
							offerId={this.offerId}
							updateOffer={this.props.updateOffer}
							t={this.props.t}
							{...campaignInfo}
						/>
					)}
					{!isNewOffer ? (
						<div
							className="box"
							style={{ borderTop: 'none', minHeight: `${window.innerHeight - 45}px` }}
						>
							<Tabs
								defaultActiveKey={'campaignInfo'}
								id="tab-campaign"
								animation={true}
							>
								<Tab
									eventKey={'campaignInfo'}
									title={<h4>{t('Основная информация')}</h4>}
								>
									{access.adminOnly && (
										<EditBlock
											t={this.props.t}
											offerId={this.offerId}
											categories={categories.data || []}
											actionTypes={actionTypes.data || []}
											updateOffer={this.props.updateOffer}
											createOffer={this.props.createOffer}
											alertShow={this.props.alertShow}
											getActionTypes={this.props.getActionTypes}
											getCategories={this.props.getCategories}
											{...campaignInfo}
										/>
									)}

									<GeosInfoBlock
										edited={access.adminOnly}
										t={this.props.t}
										offerId={this.offerId}
										split={campaignInfo.split}
										allGeos={allGeos.data}
										geos={geos || []}
										currencies={currencies.data}
										merchants={merchants.data}
										alertShow={this.props.alertShow}
										updateOfferGeo={this.props.updateOfferGeo}
										addOfferGeo={this.props.addOfferGeo}
										deleteOfferGeo={this.props.deleteOfferGeo}
									/>

									<LandingsInfoBlock
										edited={access.adminOnly}
										offerId={this.offerId}
										t={this.props.t}
										landings={landings || []}
										deleteLanding={this.props.deleteLanding}
										addLanding={this.props.addLanding}
										alertShow={this.props.alertShow}
										usersLandings={this.props.usersLandings}
										getUsersLandings={this.props.getUsersLandings}
										updateLandings={this.props.updateLandings}
									/>

									<PreLandingsInfoBlock
										edited={access.adminOnly}
										offerId={this.offerId}
										t={this.props.t}
										preLandings={middlePages || []}
										deletePreLanding={this.props.deletePreLanding}
										addPreLanding={this.props.addPreLanding}
										alertShow={this.props.alertShow}
									/>

									<PwaOptionsBlock
										alertShow={this.props.alertShow}
										edited={access.adminOnly}
										offerId={this.offerId}
										t={this.props.t}
										landings={landings || []}
										middlePages={middlePages || []}
										getPwaOptions={this.props.getPwaOptions}
										addPwaOptions={this.props.addPwaOptions}
										deletePwaOptions={this.props.deletePwaOptions}
										pwaOptionsState={this.props.pwaOptions}
									/>
								</Tab>

								{access.adminOnly && (
									<Tab
										eventKey={'aprovedAccess'}
										title={<h4>{t('Доступ одобрен')}</h4>}
									>
										<Access
											t={t}
											type="enabled"
											users={campaignInfo.enabledAccess}
											affiliates={accessAffiliates}
											offerId={this.offerId}
											updateOfferAccess={this.props.updateOfferAccess}
										/>
									</Tab>
								)}

								{access.adminOnly && (
									<Tab
										eventKey={'declinedAccess'}
										title={<h4>{t('Доступ заблокирован')}</h4>}
									>
										<Access
											t={t}
											type="disabled"
											users={campaignInfo.disabledAccess}
											affiliates={accessAffiliates}
											offerId={this.offerId}
											updateOfferAccess={this.props.updateOfferAccess}
										/>
									</Tab>
								)}
							</Tabs>
						</div>
					) : (
						access.adminOnly && (
							<EditBlock
								t={this.props.t}
								offerId={this.offerId}
								categories={categories.data}
								actionTypes={actionTypes.data}
								updateOffer={this.props.updateOffer}
								createOffer={this.props.createOffer}
								alertShow={this.props.alertShow}
								getActionTypes={this.props.getActionTypes}
								getCategories={this.props.getCategories}
							/>
						)
					)}
				</section>
			</div>
		);
	}
}

//Получаем modalStore из stora и записываем в props компонента
const mapStateToProps = ({ apiRequestStore, router }) => ({
	access: (apiRequestStore.auth.user || {}).access || {},
	currencies: apiRequestStore.currencies,
	merchants: apiRequestStore.users.merchants,
	affiliates: apiRequestStore.users.affiliates,
	allGeos: apiRequestStore.geos,
	actionTypes: apiRequestStore.actionTypes,
	categories: apiRequestStore.offers.categories,
	campaign: apiRequestStore.offers.offer,
	usersLandings: apiRequestStore.users.landings,
	pwaOptions: apiRequestStore.pwaOptions,
	router: router,
});

//Пробрасываем actions в props компонента
const mapActionCreators = {
	...alertActions,
	getCurrencies: apiRequestActions.getCurrencies,
	getMerchants: apiRequestActions.getMerchants,
	getAffiliates: apiRequestActions.getAffiliates,
	getAllGeos: apiRequestActions.getAllGeos,
	getActionTypes: apiRequestActions.getActionTypes,
	getOffer: apiRequestActions.getOffer,
	getCategories: apiRequestActions.getCategories,
	updateOffer: apiRequestActions.updateOffer,
	createOffer: apiRequestActions.createOffer,
	updateOfferGeo: apiRequestActions.updateOfferGeo,
	addOfferGeo: apiRequestActions.addOfferGeo,
	deleteOfferGeo: apiRequestActions.deleteOfferGeo,
	deleteLanding: apiRequestActions.deleteOfferLanding,
	addLanding: apiRequestActions.addOfferLanding,
	deletePreLanding: apiRequestActions.deleteOfferPreLanding,
	addPreLanding: apiRequestActions.addOfferPreLanding,
	updateOfferAccess: apiRequestActions.updateOfferAccess,
	updateLandings: apiRequestActions.updateLandings,
	getUsersLandings: apiRequestActions.getUsersLandings,
	getPwaOptions: apiRequestActions.getPwaOptions,
	addPwaOptions: apiRequestActions.addPwaOptions,
	deletePwaOptions: apiRequestActions.deletePwaOptions,
};

DeepCampaign = translate('DeepCampaign')(DeepCampaign);
export default connect(mapStateToProps, mapActionCreators)(DeepCampaign);
