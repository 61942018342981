import React from 'react';
import ReactDOM from 'react-dom';
import memoize from 'memoize-one';
import MainFunctions from 'mainLibs/MainFunctions';

import SearchBlock from './SearchBlock';
import ModalPayment from './ModalPayment';
import PaymentsContainer from './PaymentsContainer';

export default class DetailPayments extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			paymentId: null,
			show: false,
		};
		this.inputNewStatus = null;
	}

	setInputNewStatus = (ref) => (this.inputNewStatus = ref);

	handleClose = () => {
		this.setState({ show: false });
	};

	handleShow = () => {
		this.setState({ show: true });
	};

	changedStatus = (paymentId) => {
		this.setState({ paymentId, show: true });
	};

	saveNewStatus = () => {
		const newStatus = ReactDOM.findDOMNode(this.inputNewStatus).value;
		const paymentId = this.state.paymentId;
		if (paymentId)
			this.props.updatePaymentStatus(paymentId, { statusId: newStatus }, () =>
				this.setState({ show: false }),
			);
	};

	getPaymentInfo = memoize((data) => {
		const paymentStatuses = this.props.paymentStatuses.slice();

		function getStatuses(pre) {
			if (!pre) {
				return paymentStatuses.filter((status) => {
					return status.name === 'Paid' || status.name === 'Returned to balance';
				});
			} else {
				return paymentStatuses.filter((status) => {
					return (
						status.name === 'Transferred to balance' ||
						status.name === 'Declined' ||
						status.name === 'Verified'
					);
				});
			}
		}

		const paymentInfo = {
			_id: data._id,
			user: data.user,
			account: data.account,
			amount: data.amount,
			currency: data.currency,
			paymentSystem: data.paymentSystem,
			paid: data.paid,
			notPaid: data.notPaid,
			onBalance: data.onBalance,
			date: data.date,
			hidden: data.hidden,
			statuses: [],
			allStatuses: getStatuses(!data.requestType),
		};

		const statusesFlow = data.statusesFlow || [];
		for (var i = 0; i < statusesFlow.length; i++) {
			statusesFlow[i] = statusesFlow[i] || {};
			const status = statusesFlow[i].status || {};
			const statusName = status.description + ` (${status.name})`;
			const statusDate = MainFunctions.dateFormat(statusesFlow[i].changed);
			paymentInfo.statuses.push({
				date: statusDate,
				name: statusName,
			});
			if (data.requestType) {
				const index = paymentInfo.allStatuses.findIndex((t) => t._id === status._id);
				if (index !== -1) {
					paymentInfo.allStatuses.splice(index, 1);
				}
			} else if (i === statusesFlow.length - 1) {
				const index = paymentInfo.allStatuses.findIndex((t) => t._id === status._id);
				if (index !== -1) {
					paymentInfo.allStatuses.splice(index, 1);
				}
			}
		}

		return paymentInfo;
	});

	getCurrentPayment = memoize((payments, paymentId) => {
		let payment = {};
		const index = payments.findIndex((t) => t._id === paymentId);
		if (index !== -1) payment = payments[index];
		return payment;
	});

	render() {
		const { t, allData, data, pre, getEmails, emails, getAllPayments, filter } = this.props;
		const currentPayment = this.getCurrentPayment(allData, this.state.paymentId);
		const openPayment = this.getPaymentInfo(currentPayment);
		return (
			<div className="payments-container">
				<SearchBlock
					t={t}
					getEmails={getEmails}
					emails={emails}
					getAllPayments={getAllPayments}
					pre={pre}
					filter={filter}
					setFilter={this.props.setFilter}
					clearFilter={this.props.clearFilter}
				/>

				<PaymentsContainer t={t} pre={pre} data={data} changedStatus={this.changedStatus} />

				<ModalPayment
					t={t}
					show={this.state.show}
					openPayment={openPayment}
					handleClose={this.handleClose}
					saveNewStatus={this.saveNewStatus}
					setInputNewStatus={this.setInputNewStatus}
				/>
			</div>
		);
	}
}
