import { supportTypes } from '../constants';

export function setMerchantId(id) {
	return {
		type: supportTypes.SET_MERCHANT_ID,
		id: id,
	};
}

export function setFilter(type, data) {
	switch (type) {
		case 'offers':
			return {
				type: supportTypes.SET_FILTER + '_OFFERS',
				data: data,
			};
		case 'requestStats':
			return {
				type: supportTypes.SET_FILTER + '_REQUEST_STATS',
				data: data,
			};
		case 'dailyStats':
			return {
				type: supportTypes.SET_FILTER + '_DAILY_STATS',
				data: data,
			};
		case 'affiliateStats':
			return {
				type: supportTypes.SET_FILTER + '_AFFILIATE_STATS',
				data: data,
			};
		case 'merchantStats':
			return {
				type: supportTypes.SET_FILTER + '_MERCHANT_STATS',
				data: data,
			};
		case 'tickets':
			return {
				type: supportTypes.SET_FILTER + '_TICKETS',
				data: data,
			};
		case 'profile':
			return {
				type: supportTypes.SET_FILTER + '_PROFILE',
				data: data,
			};
		case 'payments':
			return {
				type: supportTypes.SET_FILTER + '_PAYMENTS',
				data: data,
			};
		case 'prePayments':
			return {
				type: supportTypes.SET_FILTER + '_PRE_PAYMENTS',
				data: data,
			};
		case 'landingsStats':
			return {
				type: supportTypes.SET_FILTER + '_LANDINGS_STATS',
				data: data,
			};
		default:
			return {
				type: '',
			};
	}
}

export function clearFilter(type) {
	switch (type) {
		case 'offers':
			return {
				type: supportTypes.CLEAR_FILTER + '_OFFERS',
			};
		case 'requestStats':
			return {
				type: supportTypes.CLEAR_FILTER + '_REQUEST_STATS',
			};
		case 'dailyStats':
			return {
				type: supportTypes.CLEAR_FILTER + '_DAILY_STATS',
			};
		case 'affiliateStats':
			return {
				type: supportTypes.CLEAR_FILTER + '_AFFILIATE_STATS',
			};
		case 'merchantStats':
			return {
				type: supportTypes.CLEAR_FILTER + '_MERCHANT_STATS',
			};
		case 'tickets':
			return {
				type: supportTypes.CLEAR_FILTER + '_TICKETS',
			};
		case 'profile':
			return {
				type: supportTypes.CLEAR_FILTER + '_PROFILE',
			};
		case 'payments':
			return {
				type: supportTypes.CLEAR_FILTER + '_PAYMENTS',
			};
		case 'prePayments':
			return {
				type: supportTypes.CLEAR_FILTER + '_PRE_PAYMENTS',
			};
		case 'landingsStats':
			return {
				type: supportTypes.CLEAR_FILTER + '_LANDINGS_STATS',
			};
		default:
			return {
				type: supportTypes.CLEAR_FILTER,
			};
	}
}
