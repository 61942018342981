import React from 'react';
import _ from 'underscore';
import memoize from 'memoize-one';
import { Tabs, Tab } from 'react-bootstrap';

import Detail from './Detail';

export default class GeosInfoBlock extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			boxCollapse: false,
		};
	}

	toggleBox = (e) => {
		e.preventDefault();
		this.setState((prevState) => {
			return {
				boxCollapse: !prevState.boxCollapse,
			};
		});
	};

	getMemoizeOtherGeos = memoize((allGeos, currentGeos) => {
		return _.filter(allGeos, (geo) => {
			for (let i = 0; i < currentGeos.length; i++) {
				if (currentGeos[i].geoRef._id === geo._id) return false;
			}
			return true;
		});
	});

	defaultGeo = {
		geoRef: {},
		currency: {},
		landingPrice: null,
		payout: {},
		owners: [],
	};

	render() {
		const { t, allGeos, geos, edited, ...otherProps } = this.props;
		const otherGeos = this.getMemoizeOtherGeos(allGeos, geos);
		return (
			<div className={'box box-primary' + (this.state.boxCollapse ? ' collapsed-box' : '')}>
				<div className="box-header with-border">
					<h3 className="box-title">{t('Гео')}</h3>
					<div className="box-tools pull-right">
						<button className="btn btn-box-tool" onClick={this.toggleBox}>
							<i
								className={
									'fa ' + (this.state.boxCollapse ? 'fa-plus' : 'fa-minus')
								}
							/>
						</button>
					</div>
				</div>

				<div className="box-body">
					<Tabs defaultActiveKey={0} id="tab-geos" animation={true}>
						{geos.map((geo, i) => {
							return (
								<Tab
									eventKey={i}
									title={geo.geoRef.name}
									key={
										'geo_' + this.props.offerId + '_' + geo.geoRef._id + '_' + i
									}
								>
									<Detail
										newGeo={false}
										t={t}
										geo={geo}
										geos={[]}
										edited={edited}
										split={this.props.split}
										{...otherProps}
									/>
								</Tab>
							);
						})}
						{!!otherGeos.length && edited && (
							<Tab eventKey={geos.length} title={<i className={'fa fa-plus'} />}>
								<Detail
									edited={edited}
									newGeo={true}
									split={this.props.split}
									t={t}
									geo={this.defaultGeo}
									geos={otherGeos}
									{...otherProps}
								/>
							</Tab>
						)}
					</Tabs>
				</div>
			</div>
		);
	}
}
