import React from 'react';

import FilterBlock from './FilterBlock';
import InfoBlock from './InfoBlock';

export default class ContentOffers extends React.PureComponent {
	render() {
		return (
			<section className="content offers">
				<FilterBlock
					t={this.props.t}
					getStatsPreLandings={this.props.getStatsPreLandings}
				/>

				<InfoBlock t={this.props.t} preLandings={this.props.preLandings} />
			</section>
		);
	}
}
