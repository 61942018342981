import { adminPanelRequestTypes } from '../../constants';

const initialState = {
	loading: false,
	loaded: false,
	errors: null,
	data: [],
};

export default function currencies(state = initialState, { type, data, errors }) {
	switch (type) {
		case adminPanelRequestTypes.GET_CURRENCIES_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_CURRENCIES_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		case adminPanelRequestTypes.GET_CURRENCIES_SUCCESS:
			return { ...state, loading: false, loaded: true, errors: null, data: data };
		default:
			return state;
	}
}
