import React from 'react';
import _ from 'underscore';
import { connect } from 'react-redux';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import memoize from 'memoize-one';
import MainFunctions from 'mainLibs/MainFunctions';

const parsePrelandings = memoize((stats) => {
	if (!Object.keys(stats).length) return [];
	let prelandTable = [];
	let actionsGrouped = _.groupBy(stats.actions, (action) => {
		const trackLink = _.find(stats.trackLinks, (trackLink) => {
			return trackLink._id.toString() === action.trackLink.toString();
		});
		return (trackLink.middlePage || {})._id;
	});
	let visitsGrouped = _.groupBy(stats.visits, (visit) => {
		const trackLink = _.find(stats.trackLinks, (trackLink) => {
			return trackLink._id.toString() === visit.trackLink.toString();
		});
		return (trackLink.middlePage || {})._id;
	});
	let prelandingIds = _.uniq(
		_.map(stats.trackLinks, (trackLink) => {
			return (trackLink.middlePage || {})._id;
		}),
	);
	for (var i = 0; i < prelandingIds.length; i++) {
		let currentPreLandingId = prelandingIds[i];
		let actionsParsed = _.groupBy(actionsGrouped[currentPreLandingId] || [], (action) => {
			let actionStatus = _.last(action.statusesFlow).status.name;
			if (actionStatus === 'Approved') return 'A';
			else if (actionStatus === 'Declined') return 'D';
			else if (actionStatus === 'Trash') return 'T';
			else if (actionStatus === 'Pending') return 'P';
		});

		let validApprove =
			(((actionsParsed.A || []).length || 0) /
				((actionsParsed.A || []).length +
					(actionsParsed.D || []).length +
					(actionsParsed.P || []).length)) *
			100;
		// let statusApprove = (((actionsParsed.A || []).length || 0) / ((actionsParsed.D || []).length || 0)) * 100;
		let statusApprove =
			(((actionsParsed.A || []).length || 0) /
				((actionsParsed.A || []).length +
					(actionsParsed.D || []).length +
					(actionsParsed.P || []).length +
					(actionsParsed.T || []).length)) *
			100;

		//получаем уникальные клики (от одного посетителя)
		let uniqClicks = _.groupBy(visitsGrouped[currentPreLandingId] || [], (visit) => {
			return visit.techInfo.ip;
		});
		let CR =
			(((actionsParsed.A || []).length +
				(actionsParsed.D || []).length +
				(actionsParsed.P || []).length) /
				((Object.keys(uniqClicks) || []).length || 0)) *
			100;
		let epc =
			_.reduce(actionsParsed.A || [], (memo, action) => memo + action.fixedPayout, 0) /
			((Object.keys(uniqClicks) || []).length || 0);

		let approvedMoney = _.reduce(
			actionsParsed.A || [],
			(memo, action) => memo + action.fixedPayout,
			0,
		);
		let holdMoney = _.reduce(
			actionsParsed.P || [],
			(memo, action) => memo + action.fixedPayout,
			0,
		);
		let declinedMoney = _.reduce(
			actionsParsed.D || [],
			(memo, action) => memo + action.fixedPayout,
			0,
		);

		let allMoney = approvedMoney + holdMoney + declinedMoney;

		prelandTable.push({
			url: (
				(
					_.find(stats.trackLinks, (trackLink) => {
						return (
							((trackLink.middlePage || {})._id || '').toString() ===
							currentPreLandingId
						);
					}) || {}
				).middlePage || {}
			).url,
			id: currentPreLandingId,
			uniqClicks: MainFunctions.formatNumber(Object.keys(uniqClicks).length, 0),
			allClicks: MainFunctions.formatNumber(
				(visitsGrouped[currentPreLandingId] || []).length || 0,
				0,
			),
			CR: MainFunctions.formatNumber(isNaN(CR) || !isFinite(CR) ? 0 : CR, 2) + ' %',
			epc: MainFunctions.formatNumber(isNaN(epc) ? 0 : epc, 2) + ' р.',

			allActions: MainFunctions.formatNumber(
				(actionsParsed.A || []).length +
					(actionsParsed.P || []).length +
					(actionsParsed.D || []).length,
				0,
			),
			approvedActions: MainFunctions.formatNumber((actionsParsed.A || []).length, 0),
			holdActions: MainFunctions.formatNumber((actionsParsed.P || []).length, 0),
			declinedActions: MainFunctions.formatNumber((actionsParsed.D || []).length, 0),
			trashActions: MainFunctions.formatNumber((actionsParsed.T || []).length, 0),

			allMoney: MainFunctions.formatNumber(allMoney, 0) + ' р',
			approvedMoney: MainFunctions.formatNumber(approvedMoney, 0) + ' р',
			holdMoney: MainFunctions.formatNumber(holdMoney, 0) + ' р',
			declinedMoney: MainFunctions.formatNumber(declinedMoney, 0) + ' р',
			validApprove:
				MainFunctions.formatNumber((actionsParsed.A || []).length, 2) +
				' (' +
				MainFunctions.formatNumber(isNaN(validApprove) ? 0 : validApprove, 2) +
				' %)',
			statusApprove:
				MainFunctions.formatNumber((actionsParsed.A || []).length, 2) +
				' (' +
				MainFunctions.formatNumber(isNaN(statusApprove) ? 0 : statusApprove, 2) +
				' %)',
		});
	}
	return prelandTable;
});

class PrelandingsStats extends React.Component {
	linkFormatter = (cell, row) => {
		return (
			// eslint-disable-next-line react/jsx-no-target-blank
			<a href={row.url} target="_blank" className="site-link">
				<span>{row.url}</span>
				<i className="fa fa-link" aria-hidden="true" />
			</a>
		);
	};

	getNoDataText = memoize((loading, text) => {
		if (loading) {
			return (
				<div className="pageOverlay">
					<i className="fa fa-spinner css--animate" />
				</div>
			);
		} else return text;
	});

	render() {
		const { t, prelandingsStore } = this.props;
		let prelandTable = parsePrelandings(prelandingsStore.data);
		const noDataText = this.getNoDataText(prelandingsStore.loading, this.props.t('Нет данных'));
		return (
			<React.Fragment>
				{prelandingsStore.loading && (
					<div className="pageOverlay">
						<i className="fa fa-spinner css--animate" />
					</div>
				)}
				<BootstrapTable
					data={prelandTable}
					options={{ noDataText }}
					pagination
					bordered={false}
				>
					<TableHeaderColumn row="0" colSpan="1"></TableHeaderColumn>
					<TableHeaderColumn row="0" dataAlign="center" colSpan="2">
						{t('Траффик')}
					</TableHeaderColumn>
					<TableHeaderColumn row="0" dataAlign="center" colSpan="2">
						{t('Коэффициенты')}
					</TableHeaderColumn>
					<TableHeaderColumn row="0" dataAlign="center" colSpan="7">
						{t('Конверсии')}
					</TableHeaderColumn>
					<TableHeaderColumn row="0" dataAlign="center" colSpan="4">
						{t('Финансы')}
					</TableHeaderColumn>
					<TableHeaderColumn
						row="1"
						dataAlign="center"
						dataField="url"
						tdStyle={{ whiteSpace: 'normal' }}
						dataFormat={this.linkFormatter}
						colSpan="1"
					>
						{t('Прелендинг')}
					</TableHeaderColumn>
					<TableHeaderColumn row="1" dataField="id" isKey hidden>
						{t('Id')}
					</TableHeaderColumn>
					<TableHeaderColumn row="1" dataAlign="center" dataField="allClicks" colSpan="1">
						{t('Клики')}
					</TableHeaderColumn>
					<TableHeaderColumn
						row="1"
						dataAlign="center"
						dataField="uniqClicks"
						colSpan="1"
					>
						{t('Уники')}
					</TableHeaderColumn>
					<TableHeaderColumn row="1" dataAlign="center" dataField="CR" colSpan="1">
						{t('CR')}
					</TableHeaderColumn>
					<TableHeaderColumn row="1" dataAlign="center" dataField="epc" colSpan="1">
						{t('EPC')}
					</TableHeaderColumn>
					<TableHeaderColumn
						row="1"
						dataAlign="center"
						dataField="validApprove"
						tdStyle={{ whiteSpace: 'normal' }}
						colSpan="1"
					>
						{t('Апрув (valid)')}
					</TableHeaderColumn>
					<TableHeaderColumn
						row="1"
						dataAlign="center"
						dataField="statusApprove"
						tdStyle={{ whiteSpace: 'normal' }}
						colSpan="1"
					>
						{t('Апрув (status)')}
					</TableHeaderColumn>
					<TableHeaderColumn
						row="1"
						dataAlign="center"
						dataField="allActions"
						colSpan="1"
					>
						{t('Всего')}
					</TableHeaderColumn>
					<TableHeaderColumn
						row="1"
						dataAlign="center"
						dataField="approvedActions"
						colSpan="1"
					>
						{t('Одобрено')}
					</TableHeaderColumn>
					<TableHeaderColumn
						row="1"
						dataAlign="center"
						dataField="holdActions"
						colSpan="1"
					>
						{t('Холд')}
					</TableHeaderColumn>
					<TableHeaderColumn
						row="1"
						dataAlign="center"
						dataField="declinedActions"
						colSpan="1"
					>
						{t('Отклонено')}
					</TableHeaderColumn>
					<TableHeaderColumn
						row="1"
						dataAlign="center"
						dataField="trashActions"
						colSpan="1"
					>
						{t('Трэш')}
					</TableHeaderColumn>
					<TableHeaderColumn
						row="1"
						dataAlign="center"
						dataField="allMoney"
						tdStyle={{ whiteSpace: 'normal' }}
						colSpan="1"
					>
						{t('Всего')}
					</TableHeaderColumn>
					<TableHeaderColumn
						row="1"
						dataAlign="center"
						dataField="approvedMoney"
						tdStyle={{ whiteSpace: 'normal' }}
						colSpan="1"
					>
						{t('Апрув')}
					</TableHeaderColumn>
					<TableHeaderColumn
						row="1"
						dataAlign="center"
						dataField="holdMoney"
						tdStyle={{ whiteSpace: 'normal' }}
						colSpan="1"
					>
						{t('Холд')}
					</TableHeaderColumn>
					<TableHeaderColumn
						row="1"
						dataAlign="center"
						dataField="declinedMoney"
						tdStyle={{ whiteSpace: 'normal' }}
						colSpan="1"
					>
						{t('Отклонено')}
					</TableHeaderColumn>
				</BootstrapTable>
			</React.Fragment>
		);
	}
}

const mapStateToProps = ({ apiRequestStore }) => ({
	prelandingsStore: apiRequestStore.affiliateStats.prelandings,
});

export default connect(mapStateToProps, null)(PrelandingsStats);
