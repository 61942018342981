import React from 'react';
import _ from 'underscore';
import memoize from 'memoize-one';
import { Button, Form, Row, Col } from 'react-bootstrap';

import SelectForm from './SelectForm';
import SelectFormMarkers from './SelectFormMarkers';
import DateForm from 'components/service/DateForm';

export default class BoxBody extends React.PureComponent {
	getUserId = () => {
		const url = window.location.pathname;
		return url.substr(url.lastIndexOf('/') + 1);
	};

	constructor(props) {
		super(props);
		this.userId = this.getUserId();
	}

	componentDidMount() {
		this.props.getStatsFiltersData(this.userId);
		this.loadStats();
	}

	componentDidUpdate(prevProps, prevState) {
		const userId = this.getUserId();
		if (this.userId !== userId) {
			this.userId = userId;
			this.props.getStatsFiltersData(this.userId);
			this.loadStats();
		}
		if (this.props.statsName !== prevProps.statsName) this.loadStats();
	}

	setFilter = (data) => this.props.setFilter('affiliateStats', data);
	clearFilter = () => this.props.clearFilter('affiliateStats');

	changeFilter = (val, name) => {
		var filter = { ...this.props.filter };
		filter[name] = val.target ? val.target.value : val;
		this.setFilter(filter);
	};

	changeFilterPeriod = ({ start, end, selectedPeriod }) => {
		var filter = { ...this.props.filter };
		filter.start = start;
		filter.end = end;
		filter.selectedPeriod = selectedPeriod;
		this.setFilter(filter);
	};

	changeFilterMarkers = (val, name) => {
		const filter = { ...this.props.filter };
		const markers = { ...filter.markers };
		markers[name] = val.target ? val.target.value : val;
		filter.markers = markers;
		this.setFilter(filter);
	};

	resolveSearchAction = (userId, data) => {
		if (userId) {
			switch (this.props.statsName) {
				case 'LandingsStats':
					this.props.getStatsLandings(userId, data);
					break;
				case 'PreLandingsStats':
					this.props.getStatsPrelandings(userId, data);
					break;
				case 'OffersStats':
					this.props.getStatsOffers(userId, data);
					break;
				case 'GeosStats':
					this.props.getStatsGeos(userId, data);
					break;
				case 'CampaignsStats':
					this.props.getStatsCampaigns(userId, data);
					break;
				case 'DailyStats':
					this.props.getStatsDaily(userId, data);
					break;
				case 'SourcesStats':
					this.props.getStatsSources(userId, data);
					break;
				case 'RequestsStats':
					this.props.getStatsRequests(userId, data);
					break;
				default:
					break;
			}
		}
	};

	loadStats = () => {
		function convertObjectToValueArray(object) {
			let container = {};
			for (let prop in object) {
				const value = object[prop];
				if (_.isArray(value)) {
					container[prop] = _.pluck(value, 'value');
				} else if (_.isObject(value)) {
					container[prop] = convertObjectToValueArray(value);
				} else if (!_.isNull(value)) {
					container[prop] = value;
				}
			}
			return container;
		}
		const userId = this.userId;
		const { selectedPeriod, ...otherFilters } = this.props.filter;
		const data = convertObjectToValueArray(otherFilters);
		if (data.start) data.start = new Date(data.start).setHours(0, 0, 0, 0);
		if (data.end) data.end = new Date(data.end).setHours(0, 0, 0, 0);
		this.resolveSearchAction(userId, data);
	};

	search = (e) => {
		e.preventDefault();
		e.stopPropagation();
		this.loadStats();
	};

	getReactSelectOptions = memoize((selectOptions) => {
		function parseDataToSelect(data) {
			if (_.isArray(data))
				return data.map((value) => ({ value: value._id, label: value.name }));
			else return [];
		}
		function parceGeosDataToSelect(data) {
			if (_.isArray(data))
				return data.map((value) => ({
					value: value._id,
					label: value.name + ` ${value.code}`,
				}));
			else return [];
		}
		function parceMarkersDataToSelect(data) {
			let markers = {};
			for (let prop in data) {
				if (_.isArray(data[prop]))
					markers[prop] = data[prop].map((value) => ({
						value: value,
						label: value,
					}));
				else result[prop] = [];
			}
			return markers;
		}
		const result = {
			offers: parseDataToSelect(selectOptions.offers),
			trackLinks: parseDataToSelect(selectOptions.trackLinks),
			landings: parseDataToSelect(selectOptions.landings),
			prelandings: parseDataToSelect(selectOptions.middlePages),
			geos: parceGeosDataToSelect(selectOptions.geos),
			markers: parceMarkersDataToSelect(selectOptions.markers),
		};
		return result;
	});

	render() {
		const { t, advansedSearch } = this.props;
		const selectOptions = this.getReactSelectOptions(this.props.statsFiltersData);
		return (
			<div className="box-body">
				<Form onSubmit={this.search} className="semiInline">
					<Row>
						<Col xs={12} sm={12} md={6}>
							<DateForm
								label={t('Дата')}
								startValue={this.props.filter.start}
								endValue={this.props.filter.end}
								changeInfo={this.changeFilterPeriod}
								selectedPeriod={this.props.filter.selectedPeriod}
							/>
						</Col>

						<Col xs={12} sm={12} md={6}>
							<SelectForm
								label={t('Офферы')}
								value={this.props.filter.offers}
								defaultValue={t('Все офферы')}
								changeInfo={this.changeFilter}
								name={'offers'}
								options={selectOptions.offers}
							/>
						</Col>

						{advansedSearch && (
							<React.Fragment>
								<Col xs={12} sm={6} md={6}>
									<SelectForm
										label={t('Потоки')}
										value={this.props.filter.trackLinks}
										defaultValue={t('Все потоки')}
										changeInfo={this.changeFilter}
										name={'trackLinks'}
										options={selectOptions.trackLinks}
									/>
								</Col>

								<Col xs={12} sm={6} md={6}>
									<SelectForm
										label={t('Лендинги')}
										value={this.props.filter.landings}
										defaultValue={t('Все лендинги')}
										changeInfo={this.changeFilter}
										name={'landings'}
										options={selectOptions.landings}
									/>
								</Col>

								<Col xs={12} sm={6} md={6}>
									<SelectForm
										label={t('Прелендинги')}
										value={this.props.filter.prelandings}
										defaultValue={t('Все прелендинги')}
										changeInfo={this.changeFilter}
										name={'prelandings'}
										options={selectOptions.prelandings}
									/>
								</Col>

								<Col xs={12} sm={6} md={6}>
									<SelectForm
										label={t('Гео')}
										value={this.props.filter.geo}
										defaultValue={t('Все гео')}
										changeInfo={this.changeFilter}
										name={'geo'}
										options={selectOptions.geos}
									/>
								</Col>

								<Col xs={12}>
									<SelectFormMarkers
										label={t('Метка')}
										values={this.props.filter.markers}
										defaultValue={t('Все метки')}
										changeInfo={this.changeFilterMarkers}
										name={'markers'}
										options={selectOptions.markers}
									/>
								</Col>
							</React.Fragment>
						)}
					</Row>
					<Button type="submit" bsStyle="info">
						{t('Отобразить')}
					</Button>{' '}
					<Button bsStyle="danger" onClick={this.clearFilter}>
						{t('Сбросить')}
					</Button>
				</Form>
			</div>
		);
	}
}
