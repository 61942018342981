import { adminPanelRequestTypes } from '../../constants';
import { combineReducers } from 'redux';

const initialStateObj = {
	loading: false,
	loaded: false,
	errors: null,
	data: {},
};

const initialStateArr = {
	loading: false,
	loaded: false,
	errors: null,
	data: [],
};

// const initialTrackLinks = {
// 	loading: false,
// 	loaded: false,
// 	errors: null,
// 	data: [],
// };

function offers(state = initialStateArr, { type, data, errors }) {
	switch (type) {
		case adminPanelRequestTypes.GET_OFFERS_FOR_REQUEST_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_OFFERS_FOR_REQUEST_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		case adminPanelRequestTypes.GET_OFFERS_FOR_REQUEST_SUCCESS:
			return { ...state, loading: false, loaded: true, errors: null, data: data };
		default:
			return state;
	}
}

function merchants(state = initialStateObj, { type, data, offerId, geoId, errors }) {
	switch (type) {
		case adminPanelRequestTypes.GET_MERCHANTS_FOR_REQUEST_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_MERCHANTS_FOR_REQUEST_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		case adminPanelRequestTypes.GET_MERCHANTS_FOR_REQUEST_SUCCESS:
			state.data[geoId] = { ...(state.data[geoId] || {}) };
			state.data[geoId][offerId] = data;
			return {
				...state,
				loading: false,
				loaded: true,
				errors: null,
				data: { ...state.data },
			};
		default:
			return state;
	}
}

function trackLinks(state = initialStateArr, { type, data, errors }) {
	switch (type) {
		case adminPanelRequestTypes.GET_TRACKLINKS_FOR_REQUEST_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_TRACKLINKS_FOR_REQUEST_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		case adminPanelRequestTypes.GET_TRACKLINKS_FOR_REQUEST_SUCCESS:
			return { ...state, loading: false, loaded: true, errors: null, data: data };
		default:
			return state;
	}
}

function citys(state = initialStateObj, { type, data, errors, geoId }) {
	switch (type) {
		case adminPanelRequestTypes.GET_CITYS_FOR_REQUEST_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_CITYS_FOR_REQUEST_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		case adminPanelRequestTypes.GET_CITYS_FOR_REQUEST_SUCCESS:
			state.data[geoId] = data;
			return {
				...state,
				loading: false,
				loaded: true,
				errors: null,
				data: { ...state.data },
			};
		default:
			return state;
	}
}

function ip(state = initialStateObj, { type, data, errors }) {
	switch (type) {
		case adminPanelRequestTypes.GET_IPS_FOR_REQUEST_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_IPS_FOR_REQUEST_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		case adminPanelRequestTypes.GET_IPS_FOR_REQUEST_SUCCESS:
			return { ...state, loading: false, loaded: true, errors: null, data: data };
		default:
			return state;
	}
}

function affiliates(state = initialStateArr, { type, data, errors }) {
	switch (type) {
		case adminPanelRequestTypes.GET_AFFILIATES_FOR_REQUEST_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_AFFILIATES_FOR_REQUEST_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		case adminPanelRequestTypes.GET_AFFILIATES_FOR_REQUEST_SUCCESS:
			return { ...state, loading: false, loaded: true, errors: null, data: data };
		default:
			return state;
	}
}

const actions = {
	offers,
	merchants,
	trackLinks,
	citys,
	ip,
	affiliates,
};

export default combineReducers(actions);
