import React from 'react';
import PreLandingItem from './PreLandingItem';

export default class InfoBlock extends React.PureComponent {
	deletePreLanding = (id) => {
		const data = { id: id };
		this.props.deletePreLanding(this.props.offerId, data);
	};

	renderPreLandingItem = (preLanding, i) => {
		return (
			<PreLandingItem
				key={preLanding._id + i}
				t={this.props.t}
				i={i}
				preLanding={preLanding}
				edited={this.props.edited}
				deletePreLanding={this.deletePreLanding}
			/>
		);
	};

	render() {
		const { preLandings } = this.props;

		return (
			<div className="box-body equal landing ">
				{preLandings.map(this.renderPreLandingItem)}
			</div>
		);
	}
}
