import React from 'react';
import _ from 'underscore';
import ReactDOM from 'react-dom';
import { Row, Col, Button, Glyphicon, ButtonGroup } from 'react-bootstrap';
import uuidv4 from 'uuid/v4';
import memoize from 'memoize-one';
import CampaignItem from './CampaignItem';

import Paginator from 'components/service/Paginator';

export default class ContentOffers extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			selected: {},
			offers: [],
			numPage: 1,
			expand: false,
			wrapper: document.querySelector('.wrapper'),
		};
		this.sizePerPage = 12;
	}

	componentDidMount() {
		if (!this.state.wrapper) {
			this.setState({ wrapper: document.querySelector('.wrapper') });
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.campaigns !== this.props.campaigns) {
			this.parseCampaigns(this.props.campaigns);
		}
		if (!this.state.wrapper) {
			this.setState({ wrapper: document.querySelector('.wrapper') });
		}
	}

	parseCampaigns = memoize((allCampaigns) => {
		const campaigns = Array.isArray(allCampaigns) ? allCampaigns : [];
		let parseCampaigns = [];

		for (let i = 0; i < campaigns.length; i++) {
			if (!campaigns[i]._id) campaigns[i]._id = uuidv4();
			const selectedOffer = this.state.selected[campaigns[i]._id] || {};
			parseCampaigns.push({
				hidden: false,
				active: selectedOffer.active,
				key: campaigns[i]._id,
				offer: campaigns[i],
			});
		}
		let numPage = this.state.numPage;
		while ((numPage - 1) * this.sizePerPage >= parseCampaigns.length) {
			numPage--;
		}
		if (numPage < 1) numPage = 1;
		this.setState({ offers: parseCampaigns, numPage: numPage });
		return parseCampaigns;
	});

	renderCampaign = (data, index) => (
		<CampaignItem
			t={this.props.t}
			history={this.props.history}
			index={index}
			setActive={this.setActive}
			{...data}
		/>
	);

	setActive = (index, offer, active) => {
		const selected = { ...this.state.selected };
		const offers = this.state.offers.slice();
		if (active) {
			selected[offer._id] = offer;
			selected[offer._id].active = active;
			offers[index] = { ...offers[index], active: true };
		} else {
			offers[index] = { ...offers[index], active: false };
			delete selected[offer._id];
		}
		this.setState((prevProps) => ({ selected, offers }));
	};

	renderPage = (numPage) => {
		let start = (numPage - 1) * this.sizePerPage;
		start = start < 0 ? 0 : start;
		let end = start + this.sizePerPage;
		end = end > this.state.offers.length ? this.state.offers.length : end;
		let result = [];
		let i = start;
		while (i < end) {
			if (this.state.offers[i].hidden) {
				const temp = end + 1;
				if (temp < this.state.offers.length) end++;
			} else {
				result.push(this.renderCampaign(this.state.offers[i], i));
			}
			i++;
		}
		if (!result.length) return null;
		return result;
	};

	setPage = (numPage) =>
		this.setState((prevState) => {
			return { numPage };
		});

	clearSelected = () => {
		const selected = {};
		const offers = this.state.offers.slice();
		for (let i = 0; i < offers.length; i++) {
			offers[i] = { ...offers[i], active: false };
		}
		this.setState((prevProps) => ({ selected, offers }));
	};

	renderExpandSettings = () => {
		const offers = Object.keys(this.state.selected);
		const callback = () => {
			const filter = this.props.getFilter();
			this.clearSelected();
			return filter;
		};

		return (
			<div className={'outer-settings ' + (this.state.expand ? 'active' : '')}>
				<ButtonGroup vertical block style={{ marginBottom: '10px' }}>
					<Button
						block
						bsStyle="success"
						onClick={() => this.props.duplicateOffers({ offersId: offers }, callback)}
					>
						{this.props.t('Дублировать')}
					</Button>

					<Button
						block
						bsStyle="primary"
						onClick={() =>
							this.props.offersStatusUpdate(
								{ offers, active: true, white: false },
								callback,
							)
						}
					>
						{this.props.t('В трафик')}
					</Button>

					<Button
						block
						bsStyle="primary"
						onClick={() =>
							this.props.offersStatusUpdate(
								{ offers, active: false, white: false },
								callback,
							)
						}
					>
						{this.props.t('В трафик (отключенные)')}
					</Button>

					<Button
						block
						bsStyle="primary"
						onClick={() =>
							this.props.offersStatusUpdate(
								{ offers, active: true, white: true },
								callback,
							)
						}
					>
						{this.props.t('В white')}
					</Button>

					<Button
						block
						bsStyle="primary"
						onClick={() =>
							this.props.offersStatusUpdate(
								{ offers, active: false, white: true },
								callback,
							)
						}
					>
						{this.props.t('В white (отключенные)')}
					</Button>

					<Button block bsStyle="danger" onClick={this.clearSelected}>
						{this.props.t('Сбросить')}
					</Button>
				</ButtonGroup>
				<strong> {this.props.t('Выбранные офферы')} </strong>

				<div style={{ maxHeight: window.innerHeight / 2, overflow: 'auto' }}>
					{_.map(this.state.selected, (offer, index) => (
						<small className="list-item"> {offer ? offer.name : index} </small>
					))}
				</div>

				<Button
					className={'action-toggle active-toggle'}
					bsStyle="primary"
					onClick={this.expandSettings}
				>
					<Glyphicon glyph={this.state.expand ? 'menu-right' : 'menu-left'} />
				</Button>
			</div>
		);
	};

	expandSettings = () => this.setState((prevState) => ({ expand: !prevState.expand }));

	render() {
		const pagination = this.state.offers.length > this.sizePerPage;
		const numberPage = Math.ceil(this.state.offers.length / this.sizePerPage);
		return (
			<Row className="box-body equal">
				{this.renderPage(this.state.numPage)}
				{pagination && (
					<Col xs={12} style={{ justifyContent: 'flex-end' }}>
						<Paginator
							numberPage={numberPage}
							numPage={this.state.numPage}
							setPage={this.setPage}
						/>
					</Col>
				)}
				{this.state.wrapper &&
					ReactDOM.createPortal(this.renderExpandSettings(), this.state.wrapper)}
			</Row>
		);
	}
}
