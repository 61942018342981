/* eslint-disable */
import moment from 'moment';
import 'moment/locale/ru';
moment.locale('ru');

function decodeURIComponentX(str) {
	var out = '',
		arr,
		i = 0,
		l,
		x;
	arr = str.split(/(%(?:D0|D1)%.{2})/);
	for (l = arr.length; i < l; i++) {
		try {
			x = decodeURIComponent(arr[i]);
		} catch (e) {
			x = arr[i];
		}
		out += x;
	}
	return out;
}

export default class MainFunctions {
	static guid() {
		return (
			'CS_' +
			this.s4() +
			this.s4() +
			'-' +
			this.s4() +
			'-' +
			this.s4() +
			'-' +
			this.s4() +
			'-' +
			this.s4() +
			this.s4() +
			this.s4()
		);
	}

	static s4() {
		return Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1);
	}

	static getCookie(name) {
		var matches = document.cookie.match(
			new RegExp(
				'(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)',
			),
		);
		return matches ? decodeURIComponent(matches[1]) : undefined;
	}

	static getTimePeriod(periodName) {
		var startTimestamp = new Date(),
			endTimestamp = new Date();
		switch (periodName) {
			default:
			case 'day':
				startTimestamp = startTimestamp.setHours(0, 0, 0, 0);
				endTimestamp = endTimestamp.setHours(24, 0, 0, 0);
				break;
			case 'yesterday':
				endTimestamp = endTimestamp.setHours(0, 0, 0, 0);
				startTimestamp = startTimestamp.setDate(startTimestamp.getDate() - 1);
				startTimestamp = new Date(startTimestamp).setHours(0, 0, 0, 0);
				break;
			case 'week':
				var day = startTimestamp.getDay(),
					diff = startTimestamp.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
				startTimestamp = new Date(startTimestamp.setDate(diff));
				startTimestamp = startTimestamp.setHours(0, 0, 0, 0);
				endTimestamp = endTimestamp.setDate(new Date(startTimestamp).getDate() + 7);
				endTimestamp = new Date(endTimestamp).setHours(24, 0, 0, 0);
				break;
			case 'month':
				var date = new Date();
				startTimestamp = new Date(date.getFullYear(), date.getMonth(), 1);
				endTimestamp = new Date(date.getFullYear(), date.getMonth() + 1, 0);
				break;
		}
		return { start: startTimestamp, end: endTimestamp };
	}

	static detectPageType() {
		var UIPrefix = '/affiliate/';
		var route = window.location.pathname.replace(UIPrefix, '');
		return route === '' ? 'index' : route.substr(0, route.lastIndexOf('/')) || route;
	}

	static setCookie(name, value, options) {
		options = options || {};

		var expires = options.expires;

		if (typeof expires === 'number' && expires) {
			var d = new Date();
			d.setTime(d.getTime() + expires * 1000);
			expires = options.expires = d;
		}
		if (expires && expires.toUTCString) {
			options.expires = expires.toUTCString();
		}

		value = encodeURIComponent(value);

		var updatedCookie = name + '=' + value;

		for (var propName in options) {
			updatedCookie += '; ' + propName;
			var propValue = options[propName];
			if (propValue !== true) {
				updatedCookie += '=' + propValue;
			}
		}

		document.cookie = updatedCookie;
	}

	static deleteCookie(name) {
		this.setCookie(name, '', {
			expires: -1,
		});
	}

	static hidePart(str) {
		if (!str || !str.length) return false;
		var length = str.length,
			fp = str.substring(0, 2),
			sp = str.substring(length - 2);
		return length > 4 ? fp + '***' + sp : fp + '***';
	}

	static QueryString(name) {
		var query_string = {};
		var query = window.location.search.substring(1);
		var vars = query.split('&');
		for (var i = 0; i < vars.length; i++) {
			var pair = [vars[i].split(/=(.+)?/)[0], vars[i].split(/=(.+)?/)[1]];
			if (typeof query_string[pair[0]] === 'undefined') {
				query_string[pair[0]] = decodeURIComponent(pair[1]);
			} else if (typeof query_string[pair[0]] === 'string') {
				query_string[pair[0]] = [query_string[pair[0]], decodeURIComponent(pair[1])];
			} else {
				query_string[pair[0]].push(decodeURIComponent(pair[1]));
			}
		}
		if (typeof name !== 'undefined') return query_string[name];
		else return query_string;
	}

	divideQuery() {
		return window.location.pathname.split('/');
	}

	shrinkString(stringToShrink) {
		if (stringToShrink && stringToShrink.length > 40) {
			var str = stringToShrink.slice(0, stringToShrink.indexOf('('));
			return (
				str.slice(0, 40) +
				stringToShrink.slice(
					stringToShrink.indexOf('('),
					stringToShrink.lastIndexOf(')') + 1,
				) +
				'...'
			);
		} else return stringToShrink;
	}

	intersect(a, b) {
		var t,
			self = this;
		if (b.length > a.length) {
			//noinspection CommaExpressionJS
			(t = b), (b = a), (a = t);
		} // indexOf to loop over shorter
		return a.filter(function (e) {
			if (self.findInner(e.name, b)) return true;
			//if (b.indexOf(e) !== -1) return true;
		});
	}

	checkStorage(headers) {
		if (!headers || !headers.length) return false;
		try {
			var json = JSON.parse(headers);
			json = !(!json[0]['value'] || !json[0]['value'].length);
		} catch (e) {
			return false;
		}
		return true;
	}

	ableToString(variable) {
		try {
			if (typeof variable === 'undefined') return false;
			else if (typeof variable.toString !== 'function') return false;
		} catch (e) {
			return false;
		}
		return true;
	}

	searchOverlay(haystack, needle) {
		if (typeof needle === 'string') return haystack.indexOf(needle) != -1;
		else
			try {
				return haystack.search(needle) != -1;
			} catch (e) {
				return false;
			}
	}

	static dateFormat(javadate, dateOnly) {
		if (javadate) {
			const momentDate = moment(javadate);
			//return moment(javadate).format('DD.MM');
			var months = [
				'Янв',
				'Фев',
				'Мар',
				'Апр',
				'Май',
				'Июн',
				'Июл',
				'Авг',
				'Сен',
				'Окт',
				'Ноя',
				'Дек',
			];
			var today = new Date();
			var date = new Date(javadate);
			var timeDiff = Math.abs(today.getTime() - date.getTime());
			var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
			if (dateOnly) {
				if (date.getFullYear() === today.getFullYear()) {
					return momentDate.format('DD.MM');
					//return ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2);
				}
				return moment(javadate).format('DD.MM.YYYY');
				//return ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear();
			}
			if (date.toDateString() == today.toDateString()) {
				return momentDate.format('[Сегодня в ] HH:mm');
				//return ('Cегодня в ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2));
			} else if (diffDays < 2) {
				return momentDate.format('[Вчера в ] HH:mm');
				// return ('вчера в ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2));
			} else if (date.getFullYear() === today.getFullYear()) {
				return momentDate.format('DD.MM, HH:mm');
				// return ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
			}
			return momentDate.format('DD.MM.YYYY, HH:mm');
			//return ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear() + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
		}
		return '';
	}

	mergeObjects(obj1, obj2) {
		var obj3 = {};
		for (var attr in obj1) {
			if (obj1.hasOwnProperty(attr)) obj3[attr] = obj1[attr];
		}
		for (var attr in obj2) {
			if (obj2.hasOwnProperty(attr)) obj3[attr] = obj2[attr];
		}
		return obj3;
	}

	mergeLists(list1, list2) {
		var obj3 = { files: [], folders: [] },
			element = null,
			prop = null;
		for (prop in list1) {
			if (list1.hasOwnProperty(prop))
				for (element in list1[prop]) obj3[prop].push(list1[prop][element]);
		}
		for (prop in list2) {
			if (list2.hasOwnProperty(prop))
				for (element in list2[prop]) obj3[prop].push(list2[prop][element]);
		}
		return obj3;
	}

	static formatDateAsMySQL(dateString) {
		var d = new Date(dateString);
		return (
			d.getFullYear() +
			'-' +
			('0' + (d.getMonth() + 1)).slice(-2) +
			'-' +
			('0' + d.getDate()).slice(-2)
		);
	}

	static urldecode(str) {
		var note = '';
		try {
			note = decodeURIComponent((str + '').replace(/\+/g, '%20')) || str;
		} catch (e) {
			console.info(e);
			note = decodeURIComponentX(str);
		}
		return note;
		//return decodeURIComponent((str + '').replace(/\+/g, '%20'));
	}

	objectDiff(obj1, obj2, ignoreProps) {
		if (!ignoreProps) ignoreProps = [];
		var anyDiff = false;
		for (var prop in obj1) {
			if (obj1.hasOwnProperty(prop) && ignoreProps.indexOf(prop) == -1)
				if (!obj2.hasOwnProperty(prop) || obj1[prop] != obj2[prop]) anyDiff = true;
		}
		return anyDiff;
	}

	static formatNumber(num, c) {
		let parseNum = parseFloat(num);
		if (isNaN(parseNum)) num = 0;
		return parseNum
			.toFixed(c !== undefined ? c : 2)
			.toString()
			.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
	}
}
if (process.env.NODE_ENV === 'development') {
	var logData = [];
	(function () {
		var log = console.log,
			error = console.error,
			warn = console.warn,
			info = console.info;

		console.log = function () {
			var args = Array.prototype.slice.call(arguments);
			log.apply(this, args);
			logData.push({ level: 'log', arguments: args });
		};
		console.error = function () {
			var args = Array.prototype.slice.call(arguments);
			error.apply(this, args);
			logData.push({ level: 'error', arguments: args });
		};
		console.warn = function () {
			var args = Array.prototype.slice.call(arguments);
			warn.apply(this, args);
			logData.push({ level: 'warn', arguments: args });
		};
		console.info = function () {
			var args = Array.prototype.slice.call(arguments);
			info.apply(this, args);
			logData.push({ level: 'info', arguments: args });
		};
	})();
	(function (console) {
		console.save = function (filename) {
			if (!logData) {
				console.error('Console.save: No data');
				return;
			}

			if (!filename) filename = 'console.json';

			if (typeof logData === 'object') {
				var saveDate = JSON.stringify(logData, undefined, 4);
			}

			var blob = new Blob([saveDate], { type: 'text/json' }),
				e = document.createEvent('MouseEvents'),
				a = document.createElement('a');

			a.download = filename;
			a.href = window.URL.createObjectURL(blob);
			a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
			e.initMouseEvent(
				'click',
				true,
				false,
				window,
				0,
				0,
				0,
				0,
				0,
				false,
				false,
				false,
				false,
				0,
				null,
			);
			a.dispatchEvent(e);
		};
	})(console);
}
