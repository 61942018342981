/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import MainFunctions from 'mainLibs/MainFunctions';
import _ from 'underscore';
import { Label } from 'react-bootstrap';
import { translate } from 'react-i18next';
import { genericActions } from 'store/actions';
import { logOut } from 'store/actions/apiRequestActions/auth';

class SideBar extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			treeCollapse: {
				stats: false,
			},
			collapse: MainFunctions.getCookie('sidebar-collapse') === '1',
			user: null,
			campaignsRequests: 0,
			currentPage: window.location.pathname.substr(
				window.location.pathname.lastIndexOf('/') + 1,
			),
		};
	}

	handleClick = (path) => {
		if (path) {
			this.setState({
				currentPage: path.slice(1),
			});
			this.props.transitToPage(path.substring(1));
		}
	};

	componentDidUpdate(prevProps, prevState) {
		if (this.state.currentPage !== this.props.page) {
			if (this.state.currentPage !== prevState.currentPage) {
				this.setState({
					currentPage: this.props.page.slice(1),
				});
				this.props.transitToPage(this.props.page.substring(1));
			}
		}
	}

	toggleActiveTree = (item) => {
		var tree = this.state.treeCollapse;
		_.each(tree, (elem, key) => {
			if (key === item) {
				tree[key] = !elem;
			} else {
				tree[key] = false;
			}
		});
		this.setState({ treeCollapse: tree });
	};

	toAffiliate = () => {
		const domain = window.location.hostname.split('.').slice(-2).join('.');
		if (process.env.NODE_ENV === 'development') {
			window.location.href = '/affiliate/';
		} else {
			window.location.href = `${window.location.protocol}//${domain}`;
		}
	};

	logout = () => {
		this.props.logOut();
		window.location.href = '/';
	};

	toggleMenu = () => {
		this.setState({ collapse: !this.state.collapse }, () => {
			var body = document.body;
			if (body.clientWidth > 768) {
				if (body.className.indexOf('sidebar-collapse') === -1) {
					body.className += ' sidebar-collapse';
					MainFunctions.setCookie('sidebar-collapse', '1');
				} else {
					body.className = body.className.replace(' sidebar-collapse', '');
					MainFunctions.setCookie('sidebar-collapse', '0');
				}
			} else {
				if (body.className.indexOf('sidebar-open') === -1) {
					body.className += ' sidebar-open';
					MainFunctions.setCookie('sidebar-collapse', '0');
				} else {
					body.className = body.className.replace(' sidebar-open', '');
					MainFunctions.setCookie('sidebar-collapse', '1');
				}
			}
		});
	};

	render() {
		var currentPage = this.state.currentPage;
		const { t, access, tickets, payments, offerAccess } = this.props;

		let newTickets = 0;
		for (let i = 0; i < tickets.data.length; i++) {
			if (!tickets.data[i].read) newTickets++;
		}

		let newOfferAccess = 0;
		for (let i = 0; i < offerAccess.data.length; i++) {
			if (!offerAccess.data[i].read && !offerAccess.data[i].closed) newOfferAccess++;
		}

		let newPayments = 0;
		for (let i = 0; i < payments.data.length; i++) {
			const statusesFlow = payments.data[i].statusesFlow || [];
			if (statusesFlow.length === 1 && !payments.data[i].read) {
				newPayments++;
			}
		}

		return (
			<aside className="main-sidebar custom-scroll">
				<section className="sidebar">
					<ul className="sidebar-menu">
						<li
							className={
								currentPage === 'profile' || currentPage === '' ? 'active' : ''
							}
						>
							<Link to="/" onClick={(e) => this.handleClick('/')}>
								<i className="fa fa-user" />
								<span>{t('Профиль')}</span>
							</Link>
						</li>
						{(access.adminOnly || access.support) && (
							<li className={currentPage.indexOf('campaigns') === 0 ? 'active' : ''}>
								<Link
									to="/campaigns"
									onClick={(e) => this.handleClick('/campaigns')}
								>
									<i className="fa fa-fire" />
									<span>
										{t('Офферы')}{' '}
										{this.state.campaignsRequests > 0 ? (
											<Label bsStyle="danger">
												{this.state.campaignsRequests}
											</Label>
										) : null}
									</span>
								</Link>
							</li>
						)}
						{(access.adminOnly || access.support) && (
							<li
								className={
									currentPage.indexOf('users') === 0 ||
									currentPage.indexOf('affiliates') === 0 ||
									currentPage.indexOf('merchants') === 0
										? 'active'
										: 'treeview'
								}
							>
								<a href="javascript:void(0)">
									<i className="fa fa-users" />
									<span>{t('Пользователи')}</span>
								</a>
								<ul className="treeview-menu">
									<li>
										<Link
											to="/users/affiliates"
											onClick={(e) => this.handleClick('/users/affiliates')}
										>
											<i className="fa fa-laptop" />
											<span>{t('Веб-мастеры')}</span>
										</Link>
									</li>
									<li>
										<Link
											to="/users/merchants"
											onClick={(e) => this.handleClick('/users/merchants')}
										>
											<i className="fa fa-suitcase" />
											<span>{t('Рекламодатели')}</span>
										</Link>
									</li>
								</ul>
							</li>
						)}
						{(access.adminOnly || access.white) && (
							<li
								className={
									currentPage.indexOf('stats') === 0 ? 'active' : 'treeview'
								}
							>
								<a href="javascript:void(0)">
									<i className="fa fa-pie-chart" />
									<span>{t('Статистика')}</span>
								</a>
								<ul className="treeview-menu">
									<li
										className={
											currentPage.indexOf('requests') === 6 ? 'active' : ''
										}
									>
										<Link
											to="/stats/requests"
											onClick={(e) => this.handleClick('/stats/requests')}
										>
											<i className="fa fa-truck" />
											<span>{t('По заявкам')}</span>
										</Link>
									</li>
									<li
										className={
											currentPage.indexOf('daily') === 6 ? 'active' : ''
										}
									>
										<Link
											to="/stats/daily"
											onClick={(e) => this.handleClick('/stats/daily')}
										>
											<i className="fa fa-truck" />
											<span>{t('Ежедневная')}</span>
										</Link>
									</li>
								</ul>
							</li>
						)}

						{(access.adminOnly || access.support) && (
							<li
								className={
									currentPage.indexOf('support') === 0 ? 'active' : 'treeview'
								}
							>
								<a href="javascript:void(0)">
									<i className="fa fa-gears" />
									<span>{t('Поддержка')}</span>
								</a>
								<ul className="treeview-menu">
									<li
										className={
											currentPage.indexOf('tickets') === 8 ? 'active' : ''
										}
									>
										<Link
											to="/support/tickets"
											onClick={(e) => this.handleClick('/support/tickets')}
										>
											<i className="fa fa-question" />
											<span>{t('Тикеты') + ` (${newTickets})`}</span>
										</Link>
									</li>
									<li
										className={
											currentPage.indexOf('requests') === 8 ? 'active' : ''
										}
									>
										<Link
											to="/support/requests"
											onClick={(e) => this.handleClick('/support/requests')}
										>
											<i className="fa fa-expeditedssl" />
											<span>
												{t('Запросы на доступ') + ` (${newOfferAccess})`}
											</span>
										</Link>
									</li>
									<li
										className={
											currentPage.indexOf('finance') === 8 ? 'active' : ''
										}
									>
										<Link
											to="/support/finance"
											onClick={(e) => this.handleClick('/support/finance')}
										>
											<i className="fa fa-money" />
											<span>{t('Финансы') + ` (${newPayments})`}</span>
										</Link>
									</li>
								</ul>
							</li>
						)}
						{access.adminOnly && (
							<li className={currentPage === 'addmerchant' ? 'active' : ''}>
								<Link
									to="/addmerchant"
									onClick={(e) => this.handleClick('/addmerchant')}
								>
									<i className="fa fa-user-plus" />
									<span>{t('Добавить рекламодателя')}</span>
								</Link>
							</li>
						)}

						<li className={currentPage === 'settings' ? 'active' : ''}>
							<Link to="/settings" onClick={(e) => this.handleClick('/settings')}>
								<i className="fa fa-cog" />
								<span>{t('Настройки')}</span>
							</Link>
						</li>
						{(access.adminOnly || access.white || access.support) && (
							<li>
								<a href="javascript:void(0)" onClick={this.toAffiliate}>
									<i className="fa fa-sign-out" />
									<span>{t('Перейти в affiliate')}</span>
								</a>
							</li>
						)}
						<li>
							<a href="javascript:void(0)" onClick={this.logout}>
								<i className="fa fa-sign-out" />
								<span>{t('Выйти')}</span>
							</a>
						</li>
						<li>
							<a href="javascript:void(0)" onClick={this.toggleMenu}>
								<i
									className={
										'fa ' + (this.state.collapse ? 'fa-expand' : 'fa-compress')
									}
								/>
								<span>{this.state.collapse ? t('Развернуть') : t('Свернуть')}</span>
							</a>
						</li>
					</ul>
				</section>
			</aside>
		);
	}
}

const mapStateToProps = ({ apiRequestStore }) => ({
	access: (apiRequestStore.auth.user || {}).access || {},
	tickets: apiRequestStore.tickets.newTickets,
	offerAccess: apiRequestStore.offers.offerAccess,
	payments: apiRequestStore.payments.allPayments,
});

const mapActionCreators = {
	...genericActions,
	logOut,
};

SideBar = translate('SideBar')(SideBar);
export default connect(mapStateToProps, mapActionCreators)(SideBar);
