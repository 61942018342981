import React from 'react';

export default class ContentHeader extends React.Component {
	shouldComponentUpdate(nextProps) {
		return this.props.label !== nextProps.label;
	}

	render() {
		const { label } = this.props;
		return (
			<section className="content-header" style={{ height: '50px' }}>
				<h1>
					<i className="fa fa-user" />
					{label}
				</h1>
			</section>
		);
	}
}
