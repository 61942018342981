import { adminPanelRequestTypes } from '../../constants';
import { combineReducers } from 'redux';

//intialState для всех одинаковый, так как Redux его не меняет
//А структура у всех "stats" одинаковая

// const initialState = {
// 	loading: false,
// 	loaded: false,
// 	errors: null,
// 	data: {},
// };

// const initialTrackLinks = {
// 	loading: false,
// 	loaded: false,
// 	errors: null,
// 	data: {},
// };

const initialDaily = {
	loading: false,
	loaded: false,
	errors: null,
	data: {
		visits: [],
		actions: [],
	},
	period: {
		start: null,
		end: null,
	},
};

const initialLandings = {
	loading: false,
	loaded: false,
	errors: null,
	data: {
		trackLinks: [],
		visits: [],
		actions: [],
	},
};

const initialPrelandings = {
	loading: false,
	loaded: false,
	errors: null,
	data: {
		trackLinks: [],
		visits: [],
		actions: [],
	},
};

const initialCampaigns = {
	loading: false,
	loaded: false,
	errors: null,
	data: {
		visits: [],
		actions: [],
		trackLinks: [],
	},
};

const initialOffers = {
	loading: false,
	loaded: false,
	errors: null,
	data: {
		visits: [],
		actions: [],
		trackLinks: [],
	},
};

const initialGeos = {
	loading: false,
	loaded: false,
	errors: null,
	data: {
		visits: [],
		actions: [],
		geos: [],
	},
};

const initialSources = {
	loading: false,
	loaded: false,
	errors: null,
	data: {
		visits: [],
		actions: [],
		trackLinks: [],
	},
};

const initialStatsFilters = {
	loading: false,
	loaded: false,
	errors: null,
	data: {
		geos: [],
		trackLinks: [],
		offers: [],
		landings: [],
		middlePages: [],
		markers: {},
	},
};

function statsFiltersData(state = initialStatsFilters, { type, data, errors }) {
	switch (type) {
		case adminPanelRequestTypes.GET_STATS_AFFILIATE_FILTERS_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_STATS_AFFILIATE_FILTERS_SUCCESS:
			return { ...state, loading: false, loaded: true, errors: null, data: data };
		case adminPanelRequestTypes.GET_STATS_AFFILIATE_FILTERS_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		default:
			return state;
	}
}

function campaigns(state = initialCampaigns, { type, data, errors }) {
	switch (type) {
		//...state содержит данные из store на момент выполнения этого этого редюсера
		//поэтому data: null не высталяем, чтобы не обнулить ранее полученные данные
		//если вместо ...state прописать ...initialState то
		//вместо текущих данных из store будут взяты начальные данные,
		//это полезно, когда данные стоит очищать при каждом вызове,
		//например при аутентификации пользователя и других "секретных" данных
		case adminPanelRequestTypes.GET_STATS_AFFILIATE_CAMPAIGNS_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_STATS_AFFILIATE_CAMPAIGNS_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				errors: null,
				data: {
					visits: data.visits,
					actions: data.actions,
					trackLinks: data.trackLinks,
				},
			};
		// При ошибке обнулять data тоже не к чему
		// ошибкой может быть простой обрыв связи и в этом случае
		// лучше отображать ранее полученные данные, чем вовсе их не отображать
		case adminPanelRequestTypes.GET_STATS_AFFILIATE_CAMPAIGNS_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : null };
		default:
			return state;
	}
}

function offers(state = initialOffers, { type, data, errors }) {
	switch (type) {
		//...state содержит данные из store на момент выполнения этого этого редюсера
		//поэтому data: null не высталяем, чтобы не обнулить ранее полученные данные
		//если вместо ...state прописать ...initialState то
		//вместо текущих данных из store будут взяты начальные данные,
		//это полезно, когда данные стоит очищать при каждом вызове,
		//например при аутентификации пользователя и других "секретных" данных
		case adminPanelRequestTypes.GET_STATS_AFFILIATE_OFFERS_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_STATS_AFFILIATE_OFFERS_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				errors: null,
				data: {
					visits: data.visits,
					actions: data.actions,
					trackLinks: data.trackLinks,
				},
			};
		// При ошибке обнулять data тоже не к чему
		// ошибкой может быть простой обрыв связи и в этом случае
		// лучше отображать ранее полученные данные, чем вовсе их не отображать
		case adminPanelRequestTypes.GET_STATS_AFFILIATE_OFFERS_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : null };
		default:
			return state;
	}
}

function geos(state = initialGeos, { type, data, errors }) {
	switch (type) {
		//...state содержит данные из store на момент выполнения этого этого редюсера
		//поэтому data: null не высталяем, чтобы не обнулить ранее полученные данные
		//если вместо ...state прописать ...initialState то
		//вместо текущих данных из store будут взяты начальные данные,
		//это полезно, когда данные стоит очищать при каждом вызове,
		//например при аутентификации пользователя и других "секретных" данных
		case adminPanelRequestTypes.GET_STATS_AFFILIATE_GEOS_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_STATS_AFFILIATE_GEOS_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				errors: null,
				data: {
					visits: data.visits,
					actions: data.actions,
					geos: data.geos,
				},
			};
		// При ошибке обнулять data тоже не к чему
		// ошибкой может быть простой обрыв связи и в этом случае
		// лучше отображать ранее полученные данные, чем вовсе их не отображать
		case adminPanelRequestTypes.GET_STATS_AFFILIATE_GEOS_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : null };
		default:
			return state;
	}
}

function daily(state = initialDaily, { type, data, errors, period }) {
	switch (type) {
		case adminPanelRequestTypes.GET_STATS_AFFILIATE_DAILY_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_STATS_AFFILIATE_DAILY_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				errors: null,
				data: {
					visits: data.visits,
					actions: data.actions,
				},
				period: period,
			};
		case adminPanelRequestTypes.GET_STATS_AFFILIATE_DAILY_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : null };
		default:
			return state;
	}
}

function landings(state = initialLandings, { type, data, errors }) {
	switch (type) {
		case adminPanelRequestTypes.GET_STATS_AFFILIATE_LANDINGS_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_STATS_AFFILIATE_LANDINGS_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				errors: null,
				data: {
					trackLinks: data.trackLinks,
					visits: data.visits,
					actions: data.actions,
				},
			};
		case adminPanelRequestTypes.GET_STATS_AFFILIATE_LANDINGS_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : null };
		default:
			return state;
	}
}

function prelandings(state = initialPrelandings, { type, data, errors }) {
	switch (type) {
		case adminPanelRequestTypes.GET_STATS_AFFILIATE_PRELANDINGS_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_STATS_AFFILIATE_PRELANDINGS_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				errors: null,
				data: {
					trackLinks: data.trackLinks,
					visits: data.visits,
					actions: data.actions,
				},
			};
		case adminPanelRequestTypes.GET_STATS_AFFILIATE_PRELANDINGS_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : null };
		default:
			return state;
	}
}

function sources(state = initialSources, { type, data, errors }) {
	switch (type) {
		case adminPanelRequestTypes.GET_STATS_AFFILIATE_SOURCES_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_STATS_AFFILIATE_SOURCES_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				errors: null,
				data: {
					visits: data.visits,
					actions: data.actions,
					trackLinks: data.trackLinks,
				},
			};
		case adminPanelRequestTypes.GET_STATS_AFFILIATE_SOURCES_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : null };
		default:
			return state;
	}
}

function requests(state = initialSources, { type, data, errors }) {
	switch (type) {
		case adminPanelRequestTypes.GET_STATS_AFFILIATE_REQUESTS_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_STATS_AFFILIATE_REQUESTS_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				errors: null,
				data: {
					actions: data.actions,
				},
			};
		case adminPanelRequestTypes.GET_STATS_AFFILIATE_REQUESTS_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		default:
			return state;
	}
}

const stats = {
	campaigns,
	offers,
	geos,
	daily,
	landings,
	prelandings,
	sources,
	requests,
	statsFiltersData,
};

export default combineReducers(stats);
