import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import memoize from 'memoize-one';
import _ from 'underscore';
import MainFunctions from 'mainLibs/MainFunctions';

import { Button, Overlay, ButtonGroup, Popover } from 'react-bootstrap';

import {
	getOfferAccess,
	changeOfferAccess,
	hideOfferAccess,
} from 'store/actions/apiRequestActions/offers';
import { hideTicket, getNewTickets } from 'store/actions/apiRequestActions/tickets';

import {
	getAllPayments,
	updatePaymentStatus,
	getAllPaymentStatuses,
	hidePayment,
} from 'store/actions/apiRequestActions/payments';

import TicketsContainer from './TicketsContainer';
import OfferAccessContainer from './OfferAccessContainer';
import FinanceContainer from './FinanceContainer';

import classes from './Notification.module.css';

class Notification extends React.PureComponent {
	constructor(props, context) {
		super(props, context);
		this.state = {
			showTicket: false,
			showReqAccess: false,
			showFinance: false,
		};
	}

	componentDidMount() {
		this.props.getNewTickets();
		this.props.getOfferAccess({ type: 'new' });
		this.props.getAllPaymentStatuses();
		this.props.getAllPayments();
	}

	showTicket = (e) => {
		const currentTarget = e.currentTarget;
		this.setState((prevState) => ({
			showTicket: !prevState.showTicket,
			showReqAccess: false,
			showFinance: false,
			targetTicket: currentTarget,
		}));
	};

	showReqAccess = (e) => {
		const currentTarget = e.currentTarget;
		this.setState((prevState) => ({
			showTicket: false,
			showFinance: false,
			showReqAccess: !prevState.showReqAccess,
			targetReqAccess: currentTarget,
		}));
	};

	showFinance = (e) => {
		const currentTarget = e.currentTarget;
		this.setState((prevState) => ({
			showTicket: false,
			showFinance: !prevState.showFinance,
			showReqAccess: false,
			targetFinance: currentTarget,
		}));
	};

	parseTickets = memoize((allTickets) => {
		allTickets = Array.isArray(allTickets) ? allTickets : [];

		const result = _.groupBy(allTickets, (ticket) => {
			ticket = ticket || {};
			ticket.category = ticket.category || {};
			ticket.user = ticket.user || {};
			ticket.messages = Array.isArray(ticket.messages) ? ticket.messages : [];
			for (let i = 0; i < ticket.messages.length; i++) {
				ticket.messages[i] = ticket.messages[i] || {};
			}
			let name = ticket.category.name;
			if (ticket.read) name = 'read';
			switch (name) {
				case 'Общие вопросы':
					return 'overall';
				case 'Финансовые вопросы':
					return 'finance';
				case 'Технические вопросы':
					return 'tech';
				case 'read':
					return 'read';
				default:
					return 'undefined';
			}
		});
		result.overall = result.overall || [];
		result.finance = result.finance || [];
		result.tech = result.tech || [];
		return result;
	});

	parseOfferAccess = memoize((allOfferAccess) => {
		allOfferAccess = Array.isArray(allOfferAccess) ? allOfferAccess : [];

		const result = [];
		for (let i = allOfferAccess.length - 1; i >= 0; i--) {
			if (!allOfferAccess[i].read && !allOfferAccess[i].closed) {
				if (allOfferAccess[i].offer) result.push(allOfferAccess[i]);
			}
		}
		return result;
	});

	parsePayments = memoize((allPayments) => {
		const payments = _.isArray(allPayments) ? allPayments.slice() : [];
		const result = {
			prePayments: [],
			payments: [],
			allPayments: [],
		};

		function getCurrency(currency) {
			const correctCurrency = _.isObject(currency) ? currency : {};
			return correctCurrency.name + ` (${correctCurrency.code})`;
		}

		function getPaymentSystem(paymentSystem) {
			if (paymentSystem) return paymentSystem.description + ` (${paymentSystem.name})`;
			else return '';
		}

		function getUser(user) {
			if (!_.isObject(user)) user = {};
			const money = (user.stats || {}).money || {};
			return {
				onBalance: money.onBalance,
				paid: money.paid,
				notPaid: money.notPaid,
				...user,
			};
		}

		function parsePayment(payments) {
			const correctPayments = _.isObject(payments) ? payments : {};
			const statusesFlow = correctPayments.statusesFlow || [];
			const lastStatusName = ((_.last(statusesFlow) || {}).status || {}).name;
			let hidden = false;
			if (lastStatusName === 'Paid') hidden = true;
			if (lastStatusName === 'Declined') hidden = true;
			return {
				_id: correctPayments._id || '',
				requestType: correctPayments.requestType || '',
				hidden: hidden,
				user: getUser(correctPayments.user),
				account: correctPayments.paymentSystemAccount,
				date: MainFunctions.dateFormat(correctPayments.date),
				amount: correctPayments.amount || 0,
				currency: getCurrency(correctPayments.currency),
				paymentSystem: getPaymentSystem(correctPayments.paymentSystem),
				statusesFlow: statusesFlow,
			};
		}

		for (let i = payments.length - 1; i >= 0; i--) {
			const statusesFlow = payments[i].statusesFlow || [];
			const payment = parsePayment(payments[i]);
			if (statusesFlow.length === 1 && !payments[i].read) {
				if (payment.requestType === 'PaymentRequest') result.payments.push(payment);
				else result.prePayments.push(payment);
			}
			result.allPayments.push(payment);
		}

		return {
			paymentsData: result.payments,
			prePaymentsData: result.prePayments,
			allPayments: result.allPayments,
		};
	});

	render() {
		const { t, tickets, offerAccess, payments, paymentStatuses } = this.props;
		const parseTickets = this.parseTickets(tickets.data);
		const parseOfferAccess = this.parseOfferAccess(offerAccess.data);
		const newTickets =
			parseTickets.overall.length + parseTickets.finance.length + parseTickets.tech.length;
		const newOfferAccess = parseOfferAccess.length;
		const { paymentsData, prePaymentsData, allPayments } = this.parsePayments(payments.data);
		return (
			<div className={classes['container-tools']}>
				<ButtonGroup className={classes.tools}>
					<Button
						onClick={this.showTicket}
						className={classes['item-tool']}
						bsStyle="primary"
					>
						<i className="fa fa-2x fa-question" /> <label>{newTickets}</label>
					</Button>

					<Button
						onClick={this.showFinance}
						className={classes['item-tool']}
						bsStyle="primary"
					>
						<i className="fa fa-2x fa-money" />{' '}
						<label>{paymentsData.length + prePaymentsData.length}</label>
					</Button>

					<Button
						onClick={this.showReqAccess}
						className={classes['item-tool']}
						bsStyle="primary"
					>
						<i className="fa fa-2x fa-expeditedssl" /> <label>{newOfferAccess}</label>
					</Button>

					<Overlay
						show={this.state.showTicket}
						target={this.state.targetTicket}
						placement="bottom"
						container={this}
					>
						<Popover id="tickets-popover" className={classes['item-container']}>
							<TicketsContainer
								tickets={parseTickets}
								allTicket={tickets.data}
								closeTicket={this.props.hideTicket}
								t={t}
							/>
						</Popover>
					</Overlay>

					<Overlay
						show={this.state.showFinance}
						target={this.state.targetFinance}
						placement="bottom"
						container={this}
					>
						<Popover id="tickets-popover" className={classes['item-container']}>
							<FinanceContainer
								allPayments={allPayments}
								paymentStatuses={paymentStatuses.data}
								paymentsData={paymentsData}
								prePaymentsData={prePaymentsData}
								closePayment={this.props.hidePayment}
								updatePaymentStatus={this.props.updatePaymentStatus}
								t={t}
							/>
						</Popover>
					</Overlay>

					<Overlay
						show={this.state.showReqAccess}
						target={this.state.targetReqAccess}
						placement="bottom"
						container={this}
					>
						<Popover
							id="offerAccess-popover"
							title={<strong>{this.props.t('Запросы на доступ')}</strong>}
							className={classes['item-container']}
						>
							<OfferAccessContainer
								t={t}
								offerAccess={parseOfferAccess}
								allOfferAccess={offerAccess.data}
								changeOfferAccess={this.props.changeOfferAccess}
								closeOfferAccess={this.props.hideOfferAccess}
							/>
						</Popover>
					</Overlay>
				</ButtonGroup>
			</div>
		);
	}
}

const mapStateToProps = ({ apiRequestStore }) => ({
	tickets: apiRequestStore.tickets.newTickets,
	offerAccess: apiRequestStore.offers.offerAccess,
	payments: apiRequestStore.payments.allPayments,
	paymentStatuses: apiRequestStore.payments.paymentStatuses,
});

const mapActionCreators = {
	hideTicket,
	getNewTickets,
	getOfferAccess,
	changeOfferAccess,
	hideOfferAccess,
	getAllPayments,
	updatePaymentStatus,
	getAllPaymentStatuses,
	hidePayment,
};

Notification = translate('Notification')(Notification);
export default connect(mapStateToProps, mapActionCreators)(Notification);
