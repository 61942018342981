import React from 'react';
import { Col, FormGroup, Button } from 'react-bootstrap';

export default class InfoFormSubmit extends React.PureComponent {
	onResetClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		this.props.onResetClick();
	};

	render() {
		const { labelSubmit, labelReset, disabled } = this.props;
		return (
			<FormGroup>
				<Col smOffset={2} sm={10}>
					<Button type="submit" bsStyle="danger" disabled={!!disabled}>
						{labelSubmit}
					</Button>

					<Button
						onClick={this.onResetClick}
						style={{ marginLeft: '10px' }}
						bsStyle="danger"
					>
						{labelReset}
					</Button>
				</Col>
			</FormGroup>
		);
	}
}
