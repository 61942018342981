import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';

import { Panel, Button, Form, FormGroup, FormControl } from 'react-bootstrap';

import { passwordLogin } from 'store/actions/apiRequestActions/auth';

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.handleOnSubmit = this.handleOnSubmit.bind(this);
	}

	componentWillMount() {
		let node = ReactDOM.findDOMNode(this.refs.username);
		if (node && node.focus instanceof Function) {
			node.focus();
		}
	}

	handleOnSubmit() {
		const username = ReactDOM.findDOMNode(this.refs.username).value;
		const password = ReactDOM.findDOMNode(this.refs.password).value;

		this.props.passwordLogin({ username, password });
	}
	render() {
		return (
			<div className="content-wrapper">
				<section className="content">
					<Panel>
						<Panel.Body>
							<h2>Вход</h2>
							<Form
								onKeyPress={(e) =>
									e.charCode === 13 ? this.handleOnSubmit() : null
								}
							>
								<FormGroup>
									<FormControl
										type="text"
										ref="username"
										placeholder="Имя пользователя"
										autoFocus
									/>
								</FormGroup>
								<FormGroup>
									<FormControl
										type="password"
										ref="password"
										placeholder="Пароль"
									/>
								</FormGroup>
								<br />
								<Button block bsStyle={'primary'} onClick={this.handleOnSubmit}>
									Войти
								</Button>
							</Form>
						</Panel.Body>
					</Panel>
				</section>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({});

const mapActionCreators = {
	passwordLogin,
};

export default connect(mapStateToProps, mapActionCreators)(Login);
