/* eslint-disable no-useless-concat */
import { adminPanelRequestTypes, adminPanelApi } from '../../constants';

export function getStatsFiltersData(userId) {
	return {
		type: adminPanelRequestTypes.GET_STATS_AFFILIATE_FILTERS,
		request: {
			method: 'get',
			url: adminPanelApi.API_URL_AFFILIATES + `/${userId}` + '/filters',
		},
	};
}

export function getStatsDaily(userId, data) {
	return {
		type: adminPanelRequestTypes.GET_STATS_AFFILIATE_DAILY,
		request: {
			method: 'post',
			url: adminPanelApi.API_URL_AFFILIATES + `/${userId}` + '/daily',
			data: data,
		},
		period: {
			start: data.start,
			end: data.end,
		},
	};
}

export function getStatsLandings(userId, data) {
	return {
		type: adminPanelRequestTypes.GET_STATS_AFFILIATE_LANDINGS,
		request: {
			method: 'post',
			url: adminPanelApi.API_URL_AFFILIATES + `/${userId}` + '/landings',
			data: data,
		},
	};
}

export function getStatsPrelandings(userId, data) {
	return {
		type: adminPanelRequestTypes.GET_STATS_AFFILIATE_PRELANDINGS,
		request: {
			method: 'post',
			url: adminPanelApi.API_URL_AFFILIATES + `/${userId}` + '/prelandings',
			data: data,
		},
	};
}

export function getStatsSources(userId, data) {
	return {
		type: adminPanelRequestTypes.GET_STATS_AFFILIATE_SOURCES,
		request: {
			method: 'post',
			url: adminPanelApi.API_URL_AFFILIATES + `/${userId}` + '/sources',
			data: data,
		},
	};
}

export function getStatsCampaigns(userId, data) {
	return {
		type: adminPanelRequestTypes.GET_STATS_AFFILIATE_CAMPAIGNS,
		request: {
			method: 'post',
			url: adminPanelApi.API_URL_AFFILIATES + `/${userId}` + '/campaigns',
			data: data,
		},
	};
}

export function getStatsOffers(userId, data) {
	return {
		type: adminPanelRequestTypes.GET_STATS_AFFILIATE_OFFERS,
		request: {
			method: 'post',
			url: adminPanelApi.API_URL_AFFILIATES + `/${userId}` + '/offers',
			data: data,
		},
	};
}

export function getStatsGeos(userId, data) {
	return {
		type: adminPanelRequestTypes.GET_STATS_AFFILIATE_GEOS,
		request: {
			method: 'post',
			url: adminPanelApi.API_URL_AFFILIATES + `/${userId}` + '/geos',
			data: data,
		},
	};
}

export function getStatsRequests(userId, data) {
	return {
		type: adminPanelRequestTypes.GET_STATS_AFFILIATE_REQUESTS,
		request: {
			method: 'post',
			url: adminPanelApi.API_URL_AFFILIATES + `/${userId}` + '/requests',
			data: data,
		},
	};
}
