import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import { getOfferAccess, changeOfferAccess } from 'store/actions/apiRequestActions/offers';

import ContentHeader from './ContentHeader';

import ContentBlock from './ContentBlock';

class SupportRequests extends React.Component {
	render() {
		const { offerAccess, ...otherProps } = this.props;
		const loading = offerAccess.loading;
		return (
			<div className="content-wrapper">
				<ContentHeader t={this.props.t} />
				<section className="content">
					{loading ? (
						<div className="pageOverlay">
							<i className="fa fa-spinner css--animate" />
						</div>
					) : null}

					<ContentBlock offerAccess={offerAccess.data} {...otherProps} />
				</section>
			</div>
		);
	}
}

//Получаем modalStore из stora и записываем в props компонента
const mapStateToProps = ({ apiRequestStore, supportedStore }) => ({
	offerAccess: apiRequestStore.offers.offerAccess,
});

//Пробрасываем actions в props компонента
const mapActionCreators = {
	getOfferAccess,
	changeOfferAccess,
};

SupportRequests = translate('SupportRequests')(SupportRequests);
export default connect(mapStateToProps, mapActionCreators)(SupportRequests);
