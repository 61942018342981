import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

export default class GeosStatsTable extends React.PureComponent {
	render() {
		const { t, noDataText } = this.props;
		return (
			<BootstrapTable data={this.props.geos} options={{ noDataText }} bordered={false}>
				<TableHeaderColumn row="0" colSpan="1"></TableHeaderColumn>
				<TableHeaderColumn row="0" dataAlign="center" colSpan="2">
					{t('Траффик')}
				</TableHeaderColumn>
				<TableHeaderColumn row="0" dataAlign="center" colSpan="2">
					{t('Коэффициенты')}
				</TableHeaderColumn>
				<TableHeaderColumn row="0" dataAlign="center" colSpan="7">
					{t('Конверсии')}
				</TableHeaderColumn>
				<TableHeaderColumn row="0" dataAlign="center" colSpan="4">
					{t('Финансы')}
				</TableHeaderColumn>
				<TableHeaderColumn
					row="1"
					dataAlign="center"
					dataField="geo"
					tdStyle={{ whiteSpace: 'normal' }}
					colSpan="1"
				>
					{t('Гео')}
				</TableHeaderColumn>
				<TableHeaderColumn row="1" dataField="id" isKey hidden>
					{t('Id')}
				</TableHeaderColumn>
				<TableHeaderColumn row="1" dataAlign="center" dataField="allClicks" colSpan="1">
					{t('Клики')}
				</TableHeaderColumn>
				<TableHeaderColumn row="1" dataAlign="center" dataField="uniqClicks" colSpan="1">
					{t('Уники')}
				</TableHeaderColumn>
				<TableHeaderColumn row="1" dataAlign="center" dataField="CR" colSpan="1">
					{t('CR')}
				</TableHeaderColumn>
				<TableHeaderColumn row="1" dataAlign="center" dataField="epc" colSpan="1">
					{t('EPC')}
				</TableHeaderColumn>
				<TableHeaderColumn
					row="1"
					dataAlign="center"
					dataField="validApprove"
					tdStyle={{ whiteSpace: 'normal' }}
					colSpan="1"
				>
					{t('Апрув (valid)')}
				</TableHeaderColumn>
				<TableHeaderColumn
					row="1"
					dataAlign="center"
					dataField="statusApprove"
					tdStyle={{ whiteSpace: 'normal' }}
					colSpan="1"
				>
					{t('Апрув (status)')}
				</TableHeaderColumn>
				<TableHeaderColumn row="1" dataAlign="center" dataField="allActions" colSpan="1">
					{t('Всего')}
				</TableHeaderColumn>
				<TableHeaderColumn
					row="1"
					dataAlign="center"
					dataField="approvedActions"
					colSpan="1"
				>
					{t('Одобрено')}
				</TableHeaderColumn>
				<TableHeaderColumn row="1" dataAlign="center" dataField="holdActions" colSpan="1">
					{t('Холд')}
				</TableHeaderColumn>
				<TableHeaderColumn
					row="1"
					dataAlign="center"
					dataField="declinedActions"
					colSpan="1"
				>
					{t('Отклонено')}
				</TableHeaderColumn>
				<TableHeaderColumn row="1" dataAlign="center" dataField="trashActions" colSpan="1">
					{t('Трэш')}
				</TableHeaderColumn>
				<TableHeaderColumn
					row="1"
					dataAlign="center"
					dataField="allMoney"
					tdStyle={{ whiteSpace: 'normal' }}
					colSpan="1"
				>
					{t('Всего')}
				</TableHeaderColumn>
				<TableHeaderColumn
					row="1"
					dataAlign="center"
					dataField="approvedMoney"
					tdStyle={{ whiteSpace: 'normal' }}
					colSpan="1"
				>
					{t('Апрув')}
				</TableHeaderColumn>
				<TableHeaderColumn
					row="1"
					dataAlign="center"
					dataField="holdMoney"
					tdStyle={{ whiteSpace: 'normal' }}
					colSpan="1"
				>
					{t('Холд')}
				</TableHeaderColumn>
				<TableHeaderColumn
					row="1"
					dataAlign="center"
					dataField="declinedMoney"
					tdStyle={{ whiteSpace: 'normal' }}
					colSpan="1"
				>
					{t('Отклонено')}
				</TableHeaderColumn>
			</BootstrapTable>
		);
	}
}
