import { socketTypes } from 'store/constants';

// //github.com/axios/axios
export function connectToSocket(actionId) {
	return {
		//тип определяющий обрабатывающий редюсер
		type: socketTypes.SOCKET_CONNECT,
	};
}

export function sendTicket(data) {
	return {
		//тип определяющий обрабатывающий редюсер
		type: socketTypes.SOCKET_SEND_TICKET,
		//объект socket служит для конфигурации socket.io
		socket: {
			emit: 'postMessageToTicket',
			data: data,
		},
	};
}
