import { adminPanelRequestTypes, socketTypes } from '../../constants';

import { combineReducers } from 'redux';

import notify from 'notifications';
import { userLocalUpdate } from 'store/actions/apiRequestActions/users';
import { alertShow } from 'store/actions/alertActions';

const initialPayments = {
	loading: false,
	loaded: false,
	errors: null,
	data: [],
};

const initialPaymentStatuses = {
	loading: false,
	loaded: false,
	errors: null,
	data: [],
};

const initialPaymentSystems = {
	loading: false,
	loaded: false,
	errors: null,
	data: [],
};

function allPrePayments(
	state = initialPayments,
	{ type, data, errors, asyncDispatch, paymentId, userId, callback },
) {
	if (userId) return state;
	switch (type) {
		case adminPanelRequestTypes.GET_PRE_PAYMENTS_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_PRE_PAYMENTS_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		case adminPanelRequestTypes.GET_PRE_PAYMENTS_SUCCESS:
			return { ...state, loading: false, loaded: true, errors: null, data: data };
		case adminPanelRequestTypes.UPDATE_PAYMENTS_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.UPDATE_PAYMENTS_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		case adminPanelRequestTypes.UPDATE_PAYMENTS_SUCCESS:
			const newData = state.data.slice();
			if (data.payment) {
				const index = newData.findIndex((t) => t._id === data.payment._id);
				if (index !== -1) {
					newData[index] = { ...data.payment };
				}
			}
			callback && callback();
			return { ...state, loading: false, loaded: true, errors: null, data: newData };
		case adminPanelRequestTypes.PAYMENTS_ADD_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.PAYMENTS_ADD_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		case adminPanelRequestTypes.PAYMENTS_ADD_SUCCESS:
			let payments = state.data.slice();
			payments.unshift(data);
			return { ...state, loading: false, loaded: true, errors: null, data: payments };
		case socketTypes.ADD_PAYMENT:
			state.data.push(data);
			return { ...state, data: state.data.slice() };
		case adminPanelRequestTypes.HIDE_PAYMENT:
			let paymentsNewData = state.data.slice();
			const hideIndex = paymentsNewData.findIndex((t) => t._id === paymentId);
			if (hideIndex !== -1) {
				paymentsNewData[hideIndex].read = true;
			}
			return { ...state, data: paymentsNewData };
		default:
			return state;
	}
}

function allPayments(
	state = initialPayments,
	{ type, data, errors, asyncDispatch, paymentId, userId, callback },
) {
	if (userId) return state;
	switch (type) {
		case adminPanelRequestTypes.GET_PAYMENTS_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_PAYMENTS_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		case adminPanelRequestTypes.GET_PAYMENTS_SUCCESS:
			return { ...state, loading: false, loaded: true, errors: null, data: data };
		case adminPanelRequestTypes.UPDATE_PAYMENTS_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.UPDATE_PAYMENTS_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		case adminPanelRequestTypes.UPDATE_PAYMENTS_SUCCESS:
			notify.success();
			const newData = state.data.slice();
			if (data.payment) {
				const index = newData.findIndex((t) => t._id === data.payment._id);
				if (index !== -1) {
					newData[index] = { ...data.payment };
				}
			}
			if (data.user) asyncDispatch(userLocalUpdate(data.user));
			if (data.error)
				asyncDispatch(
					alertShow({
						title: 'Ошибка при смене статуса выплаты!',
						message: data.error,
						type: 'danger',
					}),
				);
			callback && callback();
			return { ...state, loading: false, loaded: true, errors: null, data: newData };
		case adminPanelRequestTypes.PAYMENTS_ADD_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.PAYMENTS_ADD_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		case adminPanelRequestTypes.PAYMENTS_ADD_SUCCESS:
			notify.success();
			let payments = state.data.slice();
			payments.unshift(data);
			return { ...state, loading: false, loaded: true, errors: null, data: payments };
		case socketTypes.ADD_PAYMENT:
			state.data.push(data);
			return { ...state, data: state.data.slice() };
		case adminPanelRequestTypes.HIDE_PAYMENT:
			let paymentsNewData = state.data.slice();
			const hideIndex = paymentsNewData.findIndex((t) => t._id === paymentId);
			if (hideIndex !== -1) {
				paymentsNewData[hideIndex].read = true;
			}
			return { ...state, data: paymentsNewData };
		default:
			return state;
	}
}

function userPayments(
	state = initialPayments,
	{ type, data, errors, asyncDispatch, paymentId, userId },
) {
	if (!userId) return state;
	switch (type) {
		case adminPanelRequestTypes.GET_PAYMENTS_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_PAYMENTS_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		case adminPanelRequestTypes.GET_PAYMENTS_SUCCESS:
			return { ...state, loading: false, loaded: true, errors: null, data: data };
		case adminPanelRequestTypes.UPDATE_PAYMENTS_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.UPDATE_PAYMENTS_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		case adminPanelRequestTypes.UPDATE_PAYMENTS_SUCCESS:
			notify.success();
			const newData = state.data.slice();
			if (data.payment) {
				const index = newData.findIndex((t) => t._id === data.payment._id);
				if (index !== -1) {
					newData[index] = { ...data.payment };
				}
			}
			if (data.user) asyncDispatch(userLocalUpdate(data.user));
			if (data.error)
				asyncDispatch(
					alertShow({
						title: 'Ошибка при смене статуса выплаты!',
						message: data.error,
						type: 'danger',
					}),
				);
			return { ...state, loading: false, loaded: true, errors: null, data: newData };
		case adminPanelRequestTypes.PAYMENTS_ADD_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.PAYMENTS_ADD_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		case adminPanelRequestTypes.PAYMENTS_ADD_SUCCESS:
			notify.success();
			let payments = state.data.slice();
			payments.unshift(data);
			return { ...state, loading: false, loaded: true, errors: null, data: payments };
		case socketTypes.ADD_PAYMENT:
			state.data.unshift(data);
			return { ...state, data: state.data.slice() };
		case adminPanelRequestTypes.HIDE_PAYMENT:
			let paymentsNewData = state.data.slice();
			const hideIndex = paymentsNewData.findIndex((t) => t._id === paymentId);
			if (hideIndex !== -1) {
				paymentsNewData[hideIndex].read = true;
			}
			return { ...state, data: paymentsNewData };
		default:
			return state;
	}
}

function paymentStatuses(state = initialPaymentStatuses, { type, data, errors }) {
	switch (type) {
		case adminPanelRequestTypes.GET_PAYMENT_STATUSES_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_PAYMENT_STATUSES_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		case adminPanelRequestTypes.GET_PAYMENT_STATUSES_SUCCESS:
			return { ...state, loading: false, loaded: true, errors: null, data: data };
		default:
			return state;
	}
}

function paymentSystems(state = initialPaymentSystems, { type, data, errors }) {
	switch (type) {
		case adminPanelRequestTypes.GET_PAYMENT_SYSTEMS_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_PAYMENT_SYSTEMS_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		case adminPanelRequestTypes.GET_PAYMENT_SYSTEMS_SUCCESS:
			return { ...state, loading: false, loaded: true, errors: null, data: data };
		default:
			return state;
	}
}

const payments = {
	allPayments,
	paymentStatuses,
	paymentSystems,
	userPayments,
	allPrePayments,
};
export default combineReducers(payments);
