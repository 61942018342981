import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'underscore';
import uuidv4 from 'uuid/v4';
import memoize from 'memoize-one';
import MainFunctions from 'mainLibs/MainFunctions';

import { Row, Col, Button } from 'react-bootstrap';

import TableInfo from './TableInfo';
import ModalPayment from './ModalPayment';

export default class UserDetailPayments extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			paymentId: null,
			show: false,
		};
		this.inputNewStatus = null;
	}

	componentDidMount() {
		this.props.getAllPaymentStatuses();
		if (this.props.userId) this.props.getAllPayments({ userId: this.props.userId });
	}

	componentDidUpdate(prevProps) {
		if (prevProps.userId !== this.props.userId) {
			if (this.props.userId) this.props.getAllPayments({ userId: this.props.userId });
		}
	}

	setInputNewStatus = (ref) => (this.inputNewStatus = ref);

	handleClose = () => {
		this.setState({ show: false });
	};

	handleShow = () => {
		this.setState({ show: true });
	};

	changedStatus = (paymentId) => {
		this.setState({ paymentId }, this.handleShow);
	};

	saveNewStatus = () => {
		const newStatus = ReactDOM.findDOMNode(this.inputNewStatus).value;
		const paymentId = this.state.paymentId;
		if (paymentId) this.props.updatePaymentStatus(paymentId, { statusId: newStatus });
	};

	getPaymentInfo = (data) => {
		const getStatuses = (pre) => {
			if (!pre) {
				return this.props.paymentStatuses.filter((status) => {
					return status.name === 'Paid' || status.name === 'Returned to balance';
				});
			} else {
				return this.props.paymentStatuses.filter((status) => {
					return (
						status.name === 'Transferred to balance' ||
						status.name === 'Declined' ||
						status.name === 'Verified'
					);
				});
			}
		};

		const paymentInfo = {
			_id: data._id,
			user: data.user,
			account: data.account,
			amount: data.amount,
			currency: data.currency,
			paymentSystem: data.paymentSystem,
			paid: data.paid,
			notPaid: data.notPaid,
			onBalance: data.onBalance,
			date: data.date,
			hidden: data.hidden,
			statuses: [],
			allStatuses: getStatuses(!data.requestType),
		};

		const statusesFlow = data.statusesFlow || [];
		for (var i = 0; i < statusesFlow.length; i++) {
			const status = statusesFlow[i].status || {};
			const statusName = status.description + ` (${status.name})`;
			const statusDate = MainFunctions.dateFormat(statusesFlow[i].changed);
			paymentInfo.statuses.push({
				date: statusDate,
				name: statusName,
			});
			const index = paymentInfo.allStatuses.findIndex((t) => t._id === status._id);
			if (index !== -1) {
				paymentInfo.allStatuses.splice(index, 1);
			}
		}

		return paymentInfo;
	};

	formatStatusesFlow = (cell, row) => {
		const currentCell = _.isArray(cell) ? cell : [];
		const lastStatusName = ((_.last(currentCell) || {}).status || {}).name;
		let changedStatus = true;
		if (lastStatusName === 'Paid') changedStatus = false;
		if (lastStatusName === 'Declined') changedStatus = false;
		return (
			<React.Fragment>
				{currentCell.map((value) => {
					const status = value.status || {};
					const statusName = status.description + ` (${status.name})`;
					const statusDate = MainFunctions.dateFormat(value.changed);
					return (
						<React.Fragment key={uuidv4()}>
							<Row>
								<Col xs={12} className="text-left">
									{statusName}
								</Col>
								<Col xs={12} className="text-left">
									{statusDate}
								</Col>
							</Row>
							<div className="hr-text hr-text-left m-t-1" />
						</React.Fragment>
					);
				})}
				{changedStatus && (
					<Button
						bsSize="small"
						bsStyle="danger"
						block
						onClick={() => this.changedStatus(row._id)}
					>
						{this.props.t('Изменить статус')}
					</Button>
				)}
			</React.Fragment>
		);
	};

	getCurrentPayment = memoize((payments, paymentId) => {
		let payment = {};
		const index = payments.findIndex((t) => t._id === paymentId);
		if (index !== -1) payment = payments[index];
		return payment;
	});

	render() {
		const { t, data, pre, active, height } = this.props;
		const currentPayment = this.getCurrentPayment(data, this.state.paymentId);
		const openPayment = this.getPaymentInfo(currentPayment);
		if (!active) return <div className="box-body" style={{ height: height }} />;
		else
			return (
				<Row className="box-body">
					<TableInfo
						t={t}
						pre={pre}
						data={data}
						formatStatusesFlow={this.formatStatusesFlow}
					/>

					<ModalPayment
						t={t}
						show={this.state.show}
						openPayment={openPayment}
						handleClose={this.handleClose}
						saveNewStatus={this.saveNewStatus}
						setInputNewStatus={this.setInputNewStatus}
					/>
				</Row>
			);
	}
}
