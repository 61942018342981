import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormControl, ControlLabel, FormGroup } from 'react-bootstrap';

export default class InfoForm extends React.Component {
	constructor(props) {
		super(props);
		this.params = {};
		if (this.props.type === 'textarea') this.params.componentClass = 'textarea';
		else this.params.type = this.props.type || 'text';
	}

	static propTypes = {
		placeholder: PropTypes.string.isRequired,
		changeInfo: PropTypes.func.isRequired,
		name: PropTypes.string,
		type: PropTypes.string,
	};

	onСhangeOfferInfoClick = (event) => {
		this.props.changeInfo(event, this.props.name);
	};

	shouldComponentUpdate(nextProps, nextState) {
		return (
			this.props.value !== nextProps.value ||
			this.props.defaultValue !== nextProps.defaultValue ||
			this.props.disabled !== nextProps.disabled
		);
	}

	render() {
		const { value, label, placeholder, defaultValue, required, disabled } = this.props;
		const currentValue = value ? value : defaultValue || '';
		return (
			<FormGroup validationState={required ? (value ? 'success' : 'error') : null}>
				<Col componentClass={ControlLabel} sm={2}>
					{label}
				</Col>
				<Col sm={10}>
					<FormControl
						placeholder={placeholder}
						value={currentValue}
						onChange={this.onСhangeOfferInfoClick}
						readOnly={disabled ? 'readonly' : false}
						{...this.params}
					/>
				</Col>
			</FormGroup>
		);
	}
}
