import React from 'react';
import uuidv4 from 'uuid/v4';
import memoize from 'memoize-one';

import { Row, Col } from 'react-bootstrap';

import Paginator from 'components/service/Paginator';

import LandingItem from './LandingItem';

export default class LandingsList extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			search: '',
			numPage: 1,
			numItems: 0,
			config: [],
		};
		this.sizePerPage = 2;
	}

	componentDidMount() {
		this.parseLandings(this.props.data);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.data !== this.props.data) {
			this.parseLandings(this.props.data);
		}
	}

	renderLandingItem = (data) => (
		<LandingItem key={data.key} t={this.props.t} hidden={data.hidden} data={data} />
	);

	parseLandings = memoize((allLandings) => {
		const landings = Array.isArray(allLandings) ? allLandings : [];
		const result = [];

		function parse(landing) {
			return {
				_id: landing._id,
				url: landing.url || '',
				name: landing.name || '',
				screenshot: landing.screenshot || '',
				stats: { epc: 0, cr: 0, ...landing.stats },
			};
		}

		for (let i = 0; i < landings.length; i++) {
			const landingCopy = landings[i].copy || {};
			const landingOrig = landings[i].original || {};
			result.push({
				key: (landings[i]._id || uuidv4()) + i,
				copy: parse(landingCopy),
				original: parse(landingOrig),
			});
		}

		let numPage = this.state.numPage;
		while ((numPage - 1) * this.sizePerPage >= result.length) {
			numPage--;
		}
		if (numPage < 1) numPage = 1;
		this.setState({ config: result, numPage: numPage, numItems: result.length });
		return result;
	});

	renderPage = (numPage) => {
		let start = (numPage - 1) * this.sizePerPage;
		start = start < 0 ? 0 : start;
		let end = start + this.sizePerPage;
		end = end > this.state.config.length ? this.state.config.length : end;
		let result = [];
		let i = start;
		while (i < end) {
			result.push(this.renderLandingItem(this.state.config[i], i));
			i++;
		}
		if (!result.length) return null;
		return result;
	};

	setPage = (numPage) =>
		this.setState((prevState) => {
			return { numPage };
		});

	onChangeSearch = (event) => {
		this.search(this.state.config, event.target.value || '');
	};

	search = memoize((data, search) => {
		let numItems = 0;
		for (let i = 0; i < data.length; i++) {
			const name = (data[i].copy.name || '').toLowerCase();
			if (name.search(search.toLowerCase()) === -1) data[i].hidden = true;
			else {
				numItems++;
				data[i].hidden = false;
			}
		}
		this.setState({ config: data, numItems });
		return data;
	});

	render() {
		const pagination = this.state.numItems > this.sizePerPage;
		const numberPage = Math.ceil(this.state.numItems / this.sizePerPage);
		return (
			<Row className="equal" style={{ marginTop: '20px' }}>
				{this.renderPage(this.state.numPage)}

				{pagination && (
					<Col xs={12} style={{ justifyContent: 'flex-end' }}>
						<Paginator
							numberPage={numberPage}
							numPage={this.state.numPage}
							setPage={this.setPage}
						/>
					</Col>
				)}
			</Row>
		);
	}
}
