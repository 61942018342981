import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import { userUpdate } from 'store/actions/apiRequestActions/auth';
import { getAllCRMs } from 'store/actions/apiRequestActions/crms';

import ContentHeader from './ContentHeader';
import Content from './Content';

class SettingsMerchant extends React.Component {
	componentDidMount() {
		this.props.getAllCRMs();
	}

	render() {
		return (
			<div className="content-wrapper">
				<ContentHeader t={this.props.t} />
				<Content
					t={this.props.t}
					{...this.props.user}
					crms={this.props.crms.data}
					userUpdate={this.props.userUpdate}
				/>
			</div>
		);
	}
}

//Получаем modalStore из stora и записываем в props компонента
const mapStateToProps = ({ apiRequestStore }) => ({
	user: apiRequestStore.auth.user,
	crms: apiRequestStore.crms,
});

//Пробрасываем actions в props компонента
const mapActionCreators = {
	userUpdate,
	getAllCRMs,
};

SettingsMerchant = translate('Settings')(SettingsMerchant);
export default connect(mapStateToProps, mapActionCreators)(SettingsMerchant);
