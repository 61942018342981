import React from 'react';
import _ from 'underscore';
import memoize from 'memoize-one';
import MainFunctions from 'mainLibs/MainFunctions';
import BoxBody from './BoxBody';

export default class ContentBody extends React.PureComponent {
	componentDidMount() {
		this.props.getAffiliates({ isStats: true });
	}

	parseAffiliates = memoize((affiliates) => {
		function getMoney(stats = { money: {} }) {
			return stats.money;
		}

		const users = _.isArray(affiliates) ? affiliates : [];
		let result = [];
		let resultTickets = [];
		let resultPayments = [];

		for (let i = 0; i < users.length; i++) {
			const money = getMoney(users[i].stats);
			const userInfo = {
				id: users[i]._id || '',
				email: users[i].username || '',
				name: users[i].name || '',
				lastName: users[i].lastName || '',
				paid: MainFunctions.formatNumber(isNaN(money.paid) ? 0 : money.paid, 0),
				notPaid: MainFunctions.formatNumber(isNaN(money.notPaid) ? 0 : money.notPaid, 0),
				clicks: MainFunctions.formatNumber(isNaN(users[i].visits) ? 0 : users[i].visits, 0),
				requests: MainFunctions.formatNumber(
					isNaN(users[i].actions) ? 0 : users[i].actions,
					0,
				),
				status: {
					payments: users[i].payments || 0,
					tickets: users[i].tickets || 0,
				},
			};
			if (userInfo.status.payment !== 0 && userInfo.status.tickets !== 0)
				resultTickets.push(userInfo);
			else if (userInfo.status.tickets !== 0) resultTickets.push(userInfo);
			else if (userInfo.status.payment !== 0) resultPayments.push(userInfo);
			else result.push(userInfo);
		}

		return resultTickets.concat(resultPayments.concat(result));
	});

	render() {
		const { t, affiliates } = this.props;
		const data = this.parseAffiliates(affiliates);
		return (
			<div className="box box-danger">
				<BoxBody t={t} data={data} />
			</div>
		);
	}
}
