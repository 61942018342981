import React from 'react';
import SearchBlock from './SearchBlock';
import { Row } from 'react-bootstrap';

export default class Stats extends React.PureComponent {
	render() {
		const { active, height } = this.props;
		if (!active) return <div className="box-body" style={{ height: height }} />;
		else
			return (
				<Row>
					<SearchBlock t={this.props.t} statsName={this.props.statsName} />
					{this.props.children}
				</Row>
			);
	}
}
