import { combineReducers } from 'redux';
import auth from './apiAuthRequestStore';
import affiliates from './apiAffiliatesRequestStore';
import geos from './apiGeosRequestStore';
import crms from './apiCrmsRequestStore';
import users from './apiUsersRequestStore';
import offers from './apiOffersRequestStore';
import stats from './apiStatsRequestStore';
import actionTypes from './apiActionsTypesRequestStore';
import currencies from './apiCurrenciesRequestStore';
import actions from './apiActionsRequestStore';
import statuses from './apiStatusesRequestStore';
import partners from './apiPartnersRequestStore';
import tickets from './apiTicketsRequestStore';
import payments from './apiPaymentsRequestStore';
import affiliateStats from './apiAffiliateStatsRequestStore';
import merchantStats from './apiMerchantStatsRequestStore';
import trackLinks from './apiTrackLinksRequestStore';
import request from './apiRequestRequestStore';
import pwaOptions from './apiPwaOptionsRequestStore';

const apiRequestStore = {
	affiliates,
	request,
	actions,
	auth,
	geos,
	crms,
	offers,
	stats,
	users,
	actionTypes,
	currencies,
	statuses,
	partners,
	tickets,
	payments,
	affiliateStats,
	merchantStats,
	trackLinks,
	pwaOptions,
};

export default combineReducers(apiRequestStore);

/* Немного про store в Redux
store - один и создается при createStore. 
createStore, принимает в качестве параметра один reducer
получается такая структура store:
store = {
    reducer
}
когда делаем getState, Redux возвращает этот reducer
если нужно передать несколько reducers, делаем combaineReducers
combineReducers создает новый reducer, содержащий переданные ему reducers
это похоже на вложенные объекты. То есть combineReducer "разбивает" store
примерно так
reducer = combainReducer({
    reducer_1,
    reducer_2,
    .........,
    reducer_n
})
store = {
    reducer : {
        reducer_1,
        reduser_2,
        .........,
        reducer_3
    }
}
combainReduser возвращает reducer и его можно применят множество раз
Пример:
reducer_2 = combainReducer({
    reducer_2_1,
    reducer_2_2,
    .........,
    reducer_2_n
})
store будет выглядеть так
store = {
    reducer : {
        reducer_1,
        reduser_2 : {
            reducer_2_1,
            reducer_2_2,
            .........,
            reducer_2_n
        }
        .........,
        reducer_3
    }
}
*/
