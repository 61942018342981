import React from 'react';
import _ from 'underscore';
import memoize from 'memoize-one';
import { Col } from 'react-bootstrap';

import MainFunctions from 'mainLibs/MainFunctions';
import StatsComponent from './StatsComponent';

export default class BoxBody extends React.PureComponent {
	// Изспользуем мемоизацию, чтобы не парсить данные при каждом рендеринге
	parseActions = memoize((actions) => {
		const actionsSortedByStatus = _.groupBy(actions, (action) => {
			const status = _.last(action.statusesFlow) || {};
			return status.status ? status.status.name || '' : '';
		});
		const allActions = actions.length;

		function getWebMoney(status) {
			const money = _.reduce(status, (memo, action) => memo + action.fixedPayout, 0) || 0;
			return MainFunctions.formatNumber(money, 0);
		}
		function getPpMoney(status) {
			const money = _.reduce(status, (memo, action) => memo + action.cpaAdvance, 0) || 0;
			return MainFunctions.formatNumber(money, 0);
		}

		function getStats(status) {
			status = Array.isArray(status) ? status : [];
			const count = status.length;
			return {
				count: count,
				webMoney: getWebMoney(status),
				ppMoney: getPpMoney(status),
				percent: MainFunctions.formatNumber((count * 100) / allActions || 0, 2),
			};
		}

		const stats = {
			//Created: getStats(actionsSortedByStatus.Created),
			Approved: getStats(actionsSortedByStatus.Approved),
			//Paid: getStats(actionsSortedByStatus.Paid),
			Pending: getStats(actionsSortedByStatus.Pending),
			Declined: getStats(actionsSortedByStatus.Declined),
			Trash: getStats(actionsSortedByStatus.Trash),
			Error: getStats(actionsSortedByStatus.Error),
		};

		return stats;
	});

	render() {
		const { t, requests } = this.props;
		const stats = this.parseActions(requests);
		return (
			<div className="box-body">
				<Col xs={12}>
					<Col lg={2} lgOffset={1} md={2} mdOffset={1} sm={6} xs={6} className="statIcon">
						<StatsComponent
							icon={'fa fa-lg fa-check-circle'}
							count={stats.Approved.count}
							webMoney={stats.Approved.webMoney}
							ppMoney={stats.Approved.ppMoney}
							percent={stats.Approved.percent}
							label={t('Одобрено')}
							color={'#99c262'}
						/>
					</Col>

					<Col lg={2} md={2} sm={6} xs={6} className="statIcon">
						<StatsComponent
							icon={'fa fa-lg fa-spinner fa-spin'}
							count={stats.Pending.count}
							webMoney={stats.Pending.webMoney}
							ppMoney={stats.Pending.ppMoney}
							percent={stats.Pending.percent}
							label={t('Холд')}
							color={'#f8d347'}
						/>
					</Col>

					<Col lg={2} md={2} sm={6} xs={6} className="statIcon">
						<StatsComponent
							icon={'fa fa-lg fa-times-circle'}
							count={stats.Declined.count}
							webMoney={stats.Declined.webMoney}
							ppMoney={stats.Declined.ppMoney}
							percent={stats.Declined.percent}
							label={t('Отклонено')}
							color={'#ff3232'}
						/>
					</Col>

					<Col lg={2} md={2} sm={6} xs={6} className="statIcon">
						<StatsComponent
							icon={'fa fa-lg fa-trash'}
							count={stats.Trash.count}
							webMoney={stats.Trash.webMoney}
							ppMoney={stats.Trash.ppMoney}
							percent={stats.Trash.percent}
							label={t('Треш')}
							color={'#ff3232'}
						/>
					</Col>

					<Col lg={2} md={2} sm={6} xs={6} className="statIcon">
						<StatsComponent
							icon={'fa fa-lg fa-warning'}
							count={stats.Error.count}
							webMoney={stats.Error.webMoney}
							ppMoney={stats.Error.ppMoney}
							percent={stats.Error.percent}
							label={t('Ошибка')}
							color={'#ff3232'}
						/>
					</Col>
				</Col>
			</div>
		);
	}
}
