import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormControl, ControlLabel, FormGroup, Image, Button } from 'react-bootstrap';

export default class LogoForm extends React.Component {
	static propTypes = {
		logo: PropTypes.string,
		fileName: PropTypes.string,
		changeInfo: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);
		this.input = React.createRef();
		this.state = {
			value: '',
		};
	}

	shouldComponentUpdate(nextProps, nextState) {
		return this.props.logo !== nextProps.logo || this.props.file !== nextProps.file;
	}

	componentDidUpdate(prevProps, prevState) {
		if (!prevProps.file && this.state.value) {
			this.setState({
				value: '',
			});
		}
	}

	onСhangeOfferInfoClick = (event) => {
		this.setState({
			value: event.target.value,
		});
		this.props.changeInfo(event);
	};

	onClickButton = (event) => this.input.current.click();

	render() {
		const { file, logo, label, required, accept } = this.props;
		return (
			<FormGroup validationState={required ? (logo ? 'success' : 'error') : null}>
				<Col componentClass={ControlLabel} sm={2}>
					{label}
				</Col>
				<Col sm={10}>
					<Button bsStyle={file ? 'primary' : 'danger'} onClick={this.onClickButton}>
						Выберите файл
					</Button>
					{file ? ` ${file.name}` : ''}
					<FormControl
						inputRef={(ref) => (this.input.current = ref)}
						type="file"
						accept={accept || '.jpg, .jpeg, .png'}
						style={{ display: 'none' }}
						onChange={this.onСhangeOfferInfoClick}
						value={this.state.value}
					/>
				</Col>
				<Col sm={10} smOffset={2}>
					{file && (
						<div className="offerImage">
							<Image src={logo} thumbnail />
						</div>
					)}
				</Col>
			</FormGroup>
		);
	}
}
