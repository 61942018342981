import { authRequestTypes } from '../../constants';

import { clearFilter } from 'store/actions/supportActions';
import _ from 'underscore';
const initialState = {
	loading: false,
	loaded: true,
	authenticated: false,
	access_token: null,
	refresh_token: null,
	errors: null,
	user: {
		_id: '',
		username: '',
		name: '',
		lastName: '',
		skype: '',
		phone: '',
		crm: '',
		lastActivity: null,
		language: '',
		avatar: '',
		registered: null,
		access: null,
		settings: {
			notifications: {
				actions: {
					onCreate: false,
					onChange: false,
				},
			},
		},
		stats: {
			money: {
				paid: 0,
				notPaid: 0,
			},
		},
	},
};

export default function auth(
	state = initialState,
	{ type, data, callback, errors, asyncDispatch },
) {
	switch (type) {
		case authRequestTypes.UPDATE_USER_INFO_REQUEST:
			return state;
		case authRequestTypes.PASSWORD_LOGIN_REQUEST:
		case authRequestTypes.UPDATE_SETTINGS_USER_REQUEST:
			return { ...initialState, loading: true, errors: null, authenticated: false };
		// case authRequestTypes.SIGNUP_USER_REQUEST:
		case authRequestTypes.USER_LOGOUT_REQUEST:
			asyncDispatch(clearFilter());
			return { ...initialState, loading: true, errors: null, authenticated: false };
		case authRequestTypes.PASSWORD_LOGIN_SUCCESS:
			return {
				...initialState,
				loading: false,
				loaded: true,
				errors: null,
				access_token: data.access_token ? data.access_token : null,
				refresh_token: data.refresh_token ? data.refresh_token : null,
				authenticated: true,
				user: data.user ? data.user : null,
			};
		case authRequestTypes.REFRESH_LOGIN_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				errors: null,
				access_token: data.access_token ? data.access_token : null,
				refresh_token: data.refresh_token ? data.refresh_token : null,
				authenticated: true,
				user: data.user ? data.user : null,
			};
		case authRequestTypes.UPDATE_USER_INFO_SUCCESS:
			if (!_.isEqual(state.user, data)) state.user = data;
			return state;
		case authRequestTypes.UPDATE_SETTINGS_USER_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				update: true,
				errors: null,
				user: data,
			};
		case authRequestTypes.SIGNUP_USER_SUCCESS: {
			callback && callback();
			return state;
		}
		// case authRequestTypes.SIGNUP_USER_SUCCESS:
		//     return { ...state,
		//         loading: false,
		//         loaded: true,
		//         errors: null,
		//         access_token: data.access_token ? data.access_token : null,
		//         refresh_token: data.refresh_token ? data.refresh_token : null,
		//         authenticated: true,
		//         user: data.user ? data.user : null,
		//     }
		case authRequestTypes.PASSWORD_LOGIN_FAIL:
		case authRequestTypes.REFRESH_LOGIN_FAIL:
			return { ...initialState, loading: false, errors: errors ? errors : null };
		case authRequestTypes.SIGNUP_USER_FAIL: {
			callback && callback(errors);
			return state;
		}
		// case authRequestTypes.SIGNUP_USER_FAIL:
		case authRequestTypes.USER_LOGOUT_FAIL:
		case authRequestTypes.USER_LOGOUT_SUCCESS:
			asyncDispatch(clearFilter());
			return { ...initialState, loading: false, errors: errors ? errors : null };
		case authRequestTypes.UPDATE_USER_INFO_FAIL:
			return state;
		case authRequestTypes.UPDATE_SETTINGS_USER_FAIL:
			return { ...state, loading: false, errors: errors ? errors : true };
		case authRequestTypes.ACCESS_TOKEN_INVALID:
			return { ...state, loading: false, errors: errors ? errors : null, access_token: null };
		default:
			return state;
	}
}
