import React from 'react';

export default class StatsComponent extends React.Component {
	shouldComponentUpdate(nextProps) {
		return this.props.count !== nextProps.count || this.props.money !== nextProps.money;
	}

	render() {
		const { label, color, count, percent, webMoney, ppMoney, icon } = this.props;
		return (
			<React.Fragment>
				<div className="divider-center divider">
					<h5>
						<i className={icon} style={{ color: color }} />
						{' ' + label}
					</h5>
				</div>

				<div className="statNumber">
					{count}
					{`/${percent}%`}
					<br />
					{'ПП: ' + ppMoney} <i className="fa fa-usd" />
					<br />
					{'Веб: ' + webMoney} <i className="fa fa-usd" />
				</div>
			</React.Fragment>
		);
	}
}
