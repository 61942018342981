import React from 'react';
import _ from 'underscore';
import { Form } from 'react-bootstrap';

import { InfoForm, InfoFormSubmit, LogoForm } from '../supportedForm';

import zipLogo from 'assets/images/zip.png';

export default class EditBlock extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			landing: {
				name: null,
				landing: null,
			},
		};
	}

	addlanding = (e) => {
		e.preventDefault();
		e.stopPropagation();
		const landing = { ...this.state.landing };
		if (!_.findKey(landing, (elem) => _.isNull(elem))) {
			let data = new FormData();
			data.append('name', landing.name);
			data.append('offer', this.props.offerId);
			data.append('landing', landing.landing);
			this.props.addLanding(this.props.offerId, data);
			this.onResetClick();
		} else {
			this.props.alertShow({ message: 'Необходимо заполнить все поля', type: 'danger' });
		}
	};

	changeLandingInfo = (e, fieldName) => {
		e.preventDefault();
		e.stopPropagation();
		let landing = { ...this.state.landing };
		landing[fieldName] = e.target ? e.target.value : e.value;
		this.setState({ landing });
	};

	changeLandingPageType = (e, fieldName) => {
		let landing = { ...this.state.landing };
		landing[fieldName] = e.target ? e.target.value : e.value;
		this.setState({ landing });
	};

	switchLandingInfo = (fieldName) => {
		let landing = { ...this.state.landing };
		landing[fieldName] = !landing[fieldName];
		this.setState((prevState) => {
			return {
				landing: landing,
			};
		});
	};

	changeLanding = (e) => {
		e.preventDefault();
		e.stopPropagation();
		let landing = { ...this.state.landing };
		if (e.target.files[0]) {
			landing.landing = e.target.files[0];
			this.setState({
				landing: landing,
			});
		} else {
			landing.landing = null;
			this.setState({
				landing: landing,
			});
		}
	};

	onResetClick = () => {
		this.setState({
			landing: {
				name: null,
				landing: null,
			},
		});
	};

	checkProperty = () => {
		return !!_.findKey(this.state.landing, (elem) => _.isNull(elem));
	};

	render() {
		const { t } = this.props;
		const disabled = this.checkProperty();
		return (
			<div className={'box box-danger'}>
				<div className="box-header with-border">
					<h3 className="box-title">{t('Добавить лендинг')}</h3>
				</div>

				<div className="box-body">
					<Form horizontal onSubmit={this.addlanding}>
						<InfoForm
							value={this.state.landing.name}
							label={t('Название')}
							placeholder={t('Название')}
							changeInfo={this.changeLandingInfo}
							name={'name'}
						/>

						<LogoForm
							logo={zipLogo}
							label={t('Архив с лендингом')}
							file={this.state.landing.landing}
							accept=".zip"
							changeInfo={this.changeLanding}
						/>

						<InfoFormSubmit
							disabled={disabled}
							labelSubmit={t('Добавить лендинг')}
							labelReset={t('Сбросить')}
							onResetClick={this.onResetClick}
						/>
					</Form>
				</div>
			</div>
		);
	}
}
