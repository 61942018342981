import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Alert } from 'react-bootstrap';

export default class PaymentItem extends React.PureComponent {
	handleClick = () => {
		const id = (this.props.payment || {})._id;
		if (id) this.props.changedStatus(id);
	};

	formatLink = (text) => {
		const user = this.props.payment.user || {};
		let to = '/users/';
		if (user.isAffiliate) {
			to += 'affiliates/';
		} else {
			to += 'merchants/';
		}
		if (user._id)
			return (
				<Link target="_blank" to={to + user._id}>
					{this.props.t(text)}
				</Link>
			);
		else return this.props.t('Пользователь');
	};

	render() {
		const { t, payment, bsStyle } = this.props;
		const { user, date, amount, disabled } = payment;
		return (
			<Alert bsStyle={bsStyle} className="payment-item">
				<div className="hr-text hr-text-center m-t-1">
					<strong>{date}</strong>
				</div>

				<dl className="dl-horizontal">
					<dt>{t('Email')}</dt>
					<dd>{this.formatLink(user.username)}</dd>

					<dt>{t('Имя')}</dt>
					<dd>{user.name || <br />}</dd>

					<dt>{t('Фамилия')}</dt>
					<dd>{user.lastName || <br />}</dd>

					<dt>{t('Сумма запроса')}</dt>
					<dd>{amount}</dd>
				</dl>

				{!disabled && (
					<Button block bsStyle="danger" onClick={this.handleClick} disabled={disabled}>
						{t('Изменить статус')}
					</Button>
				)}
			</Alert>
		);
	}
}
