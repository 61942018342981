import React from 'react';
import memoize from 'memoize-one';
import _ from 'underscore';
import { Col, Form, FormGroup, Button } from 'react-bootstrap';
import TextForm from './TextForm';
import BigSelectForm from './BigSelectForm';
import SelectForm from './SelectForm';
import RequestBlock from './RequestBlock';

export default class EditBlock extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			client: {
				name: null,
				phone: null,
			},
			geo: null,
			ip: null,
			city: null,
			affiliate: null,
			requests: [
				{
					offer: null,
					trackLink: null,
					merchant: null,
				},
			],
		};
		this.code = null;
		this.affiliateId = null;
		this.geoId = [];
		this.merchantsRequest = {};
		this.defaultRequest = null;
	}

	componentDidMount() {
		if (this.props.isWhite) {
			if (!this.state.affiliate && this.props.userId) {
				this.changeInfo({ value: this.props.userId }, 'affiliate');
			}
		}
		this.props.getAllGeos();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.isWhite) {
			if (!this.state.affiliate && this.props.userId) {
				this.changeInfo({ value: this.props.userId }, 'affiliate');
			}
		}
	}

	changeInfo = (value, fieldName) => {
		const data = {};
		data[fieldName] = value;
		let requestFunc = null;
		if (fieldName === 'geo') {
			data[fieldName] = value.value;
			this.code = value.code;
			data.affiliate = null;
			data.city = null;
			data.ip = null;
			requestFunc = () => this.props.getIpsForRequest({ code: value.code });
			if (this.geoId.findIndex((id) => id === value.value) === -1) {
				requestFunc = () => {
					this.props.getIpsForRequest({ code: value.code });
					this.props.getAffiliatesForRequest({ geo: value.value });
					this.props.getCitysForRequest({ code: value.code }, value.value);
				};

				this.geoId.push(value.value);
				this.defaultRequest = null;
			}
		}
		if (fieldName === 'affiliate') {
			data[fieldName] = value.value;
			const requests = this.state.requests.slice();
			for (let i = 0; i < requests.length; i++) {
				requests[i].offer = null;
				requests[i].merchant = null;
				requests[i].trackLink = null;
			}
			data.requests = requests;
			if (this.affiliateId !== value.value) {
				requestFunc = () => {
					this.props.getTrackLinksForRequest({
						affiliate: value.value,
						geo: this.state.geo,
					});
					this.props.getOffersForRequest({ user: value.value });
				};
				this.defaultRequest = null;
				this.affiliateId = value.value;
			}
		}
		if (fieldName === 'city') {
			if (this.code) {
				if (this.state.ip === this.props.ip.ip) {
					data.ip = null;
					const type = this.props.ip.isMobile ? 'mobile' : 'desktop';
					requestFunc = () =>
						this.props.getIpsForRequest({ code: this.code, type: type, city: value });
				} else {
					data.ip = this.props.ip.ip;
				}
			}
		}

		this.setState(data, requestFunc);
	};

	getIpsForRequest = (e) => {
		e.preventDefault();
		e.stopPropagation();
		const type = this.props.ip.isMobile ? 'mobile' : 'desktop';
		this.props.getIpsForRequest({ code: this.code, type: type });
	};

	validationIp = (ip) => {
		const str = ip || '';
		return !!str.match(
			/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
		);
	};

	changeClientInfo = (value, fieldName) => {
		const client = { ...this.state.client };
		if (value) client[fieldName] = value;
		else client[fieldName] = null;
		this.setState({ client });
	};

	changeRequest = (select, fieldName, index) => {
		const requests = this.state.requests.slice();
		requests[index][fieldName] = select.value;

		if (fieldName === 'offer' && select.value) {
			requests[index].trackLink = select.trackLink;
			requests[index].merchant = null;
		}
		if (fieldName === 'trackLink' && select.value) {
			requests[index].merchant = null;
		}
		if (!this.defaultRequest) this.defaultRequest = { ...requests[index] };

		this.setState({ requests }, () => {
			const geo = this.state.geo || '';
			const offer = requests[index].offer || '';
			let hashRequest = '';
			for (let i = 0; i < offer.length; i++) {
				hashRequest += (parseInt(offer[i], 16) ^ parseInt(geo[i], 16)).toString(16);
			}
			if (!this.merchantsRequest[hashRequest]) {
				this.props.getMerchantsForRequest({ offer: offer, geo: geo });
				this.merchantsRequest[hashRequest] = true;
			}
		});
	};

	removeRequestBlock = (index) => {
		const requests = this.state.requests.slice();
		requests.splice(index, 1);
		this.setState({ requests });
	};

	addRequest = (event) => {
		event.preventDefault();
		event.stopPropagation();
		const data = {
			code: this.code,
			isMobile: this.props.ip.isMobile,
			...this.state,
		};
		if (this.checkRequiredProps(data)) {
			this.props.addRequest(data);
		}
	};

	addNewRequest = () => {
		const requests = this.state.requests.slice();
		requests.push({ ...this.defaultRequest, merchant: null });
		this.setState({ requests });
	};

	reset = () => {
		this.setState({
			affiliate: null,
			ip: null,
			city: null,
			client: {
				name: null,
				phone: null,
			},
			requests: [
				{
					offer: null,
					merchant: null,
					trackLink: null,
				},
			],
		});
	};

	checkRequiredProps = (object) => {
		for (const property in object) {
			if (_.isObject(object[property]))
				if (!this.checkRequiredProps(object[property])) return false;
			if (_.isArray(object[property])) {
				if (!object[property].length) return false;
				for (let i = 0; i < object[property].length; i++) {
					if (!this.checkRequiredProps(object[property][i])) return false;
				}
			}
			if (_.isNull(object[property])) {
				if (property !== 'trackLink') return false;
			}
			if (property === 'ip') if (!this.validationIp(object[property])) return false;
		}
		return true;
	};

	getReactSelectOptionsFromUsers = memoize((allUsers) => {
		const result = [];
		const tail = [];
		const users = Array.isArray(allUsers) ? allUsers : [];
		for (let i = 0; i < users.length; i++) {
			if (users[i]._id === this.props.userId) {
				result.push({
					label: `${users[i].username} (${users[i]._id})`,
					value: users[i]._id || '',
				});
			} else {
				tail.push({
					label: `${users[i].username} (${users[i]._id})`,
					value: users[i]._id || '',
				});
			}
		}
		return result.concat(tail);
	});

	getOffersFromTrackLinks = memoize((allTrackLinks, allOffers) => {
		const trackLinks = Array.isArray(allTrackLinks) ? allTrackLinks : [];
		const offersData = Array.isArray(allOffers) ? allOffers : [];
		const offers = [];

		for (let i = 0; i < offersData.length; i++) {
			let trackLink = null;
			for (let j = 0; j < trackLinks.length; j++) {
				if (trackLinks[j].offer === offersData[i]._id) {
					trackLink = trackLinks[j]._id;
					break;
				}
			}
			offers.push({
				trackLink,
				_id: '',
				name: '',
				geos: [],
				...offersData[i],
			});
		}
		return offers;
	});

	getReactSelectOptionsFromGeos = memoize((allGeos) => {
		const geos = [];
		let firstGeo = null;
		for (let i = 0; i < allGeos.length; i++) {
			if (allGeos[i].code === 'ru') {
				firstGeo = {
					value: allGeos[i]._id,
					code: allGeos[i].code,
					label: allGeos[i].name + ' (' + allGeos[i].code + ')',
				};
			} else {
				geos.push({
					value: allGeos[i]._id,
					code: allGeos[i].code,
					label: allGeos[i].name + ' (' + allGeos[i].code + ')',
				});
			}
		}
		if (firstGeo) geos.unshift(firstGeo);
		return geos;
	});

	render() {
		const { t, merchants, affiliates, trackLinks, geos, citys, offers } = this.props;
		const disabled = !this.checkRequiredProps(this.state);
		const affiliatesOptions = this.getReactSelectOptionsFromUsers(affiliates);
		const offersByTracklink = this.getOffersFromTrackLinks(trackLinks, offers);
		const geosOptions = this.getReactSelectOptionsFromGeos(geos);
		return (
			<React.Fragment>
				<div className={`box box-${disabled ? 'danger' : 'primary'} box-body`}>
					<Form horizontal onSubmit={this.addRequest}>
						<TextForm
							label={t('Имя заказчика')}
							placeholder={t('Имя заказчика')}
							value={this.state.client.name}
							changeInfo={this.changeClientInfo}
							name={'name'}
						/>

						<TextForm
							label={t('Телефон заказчика')}
							placeholder={t('Телефон заказчика')}
							value={this.state.client.phone}
							changeInfo={this.changeClientInfo}
							name={'phone'}
						/>

						<SelectForm
							required
							value={this.state.geo}
							label={t('Гео')}
							changeInfo={this.changeInfo}
							name={'geo'}
							options={geosOptions}
							placeholder={geosOptions.length ? t('Выберите гео') : t('Нет гео')}
						/>

						<BigSelectForm
							value={this.state.city}
							label={
								<React.Fragment>
									{t('Город')}
									<span className="text-button" onClick={this.getIpsForRequest}>
										{' '}
										<i className="fa fa-lg fa-repeat" />
									</span>
								</React.Fragment>
							}
							defaultValue={this.props.ip.city}
							changeInfo={this.changeInfo}
							name={'city'}
							options={citys.data[this.state.geo] || []}
							placeholder={this.state.geo ? t('Выберите город') : t('Выберите гео')}
							disabled={!this.state.geo}
						/>

						<TextForm
							label={t('Ip')}
							placeholder={this.state.geo ? t('Введите ip') : t('Выберите гео')}
							value={this.state.ip}
							defaultValue={this.props.ip.ip}
							changeInfo={this.changeInfo}
							name={'ip'}
							validator={this.validationIp}
							disabled={!this.state.geo}
						/>
						{!this.props.isWhite && (
							<SelectForm
								required
								value={this.state.affiliate}
								label={t('Веб-мастер')}
								changeInfo={this.changeInfo}
								name={'affiliate'}
								options={affiliatesOptions}
								placeholder={
									this.state.geo
										? affiliatesOptions.length
											? t('Выберите веб-мастера')
											: t('Нет подходящих веб-мастеров')
										: t('Выберите гео')
								}
								disabled={!this.state.geo || !affiliatesOptions.length}
							/>
						)}

						{this.state.requests.map((request, index) => (
							<RequestBlock
								t={t}
								key={`request_${index}`}
								affiliate={this.state.affiliate}
								index={index}
								offers={offersByTracklink}
								trackLinks={trackLinks}
								merchants={
									merchants[this.state.geo]
										? merchants[this.state.geo][request.offer] || []
										: []
								}
								changeInfo={this.changeRequest}
								remove={this.removeRequestBlock}
								{...request}
							/>
						))}

						<div className="box-header">
							<Button bsStyle="danger" onClick={this.addNewRequest}>
								{t('Добавить заявку')}
							</Button>
						</div>

						<FormGroup style={{ marginTop: '10px' }}>
							<Col smOffset={2} sm={10}>
								<Button type="submit" bsStyle="danger" disabled={disabled}>
									{t('Отправить')}
								</Button>{' '}
								<Button bsStyle="danger" onClick={this.reset}>
									{t('Сбросить')}
								</Button>
							</Col>
						</FormGroup>
					</Form>
				</div>
				{/*Магия DOM чтобы уместить раскрывающийся select в область окна*/}
				<div style={{ minHeight: '100px' }} />
			</React.Fragment>
		);
	}
}
