import { adminPanelRequestTypes, adminPanelApi } from '../../constants';

// //github.com/axios/axios
export function getMerchants() {
	return {
		//тип определяющий обрабатывающий редюсер
		type: adminPanelRequestTypes.GET_MERCHANTS,
		//объект request служит для конфигурации запроса axios
		request: {
			method: 'get',
			url: adminPanelApi.API_URL_MERCHANTS,
		},
	};
}

export function getMerchantsByTrackLink(id, geo) {
	return {
		//тип определяющий обрабатывающий редюсер
		type: adminPanelRequestTypes.GET_MERCHANTS,
		//объект request служит для конфигурации запроса axios
		request: {
			method: 'get',
			url: adminPanelApi.API_URL_MERCHANTS + `/${id}`,
			params: geo,
		},
	};
}

export function getAffiliates(params) {
	return {
		type: adminPanelRequestTypes.GET_ALL_AFFILIATES,
		request: {
			method: 'get',
			url: adminPanelApi.API_URL_AFFILIATES,
			params,
		},
	};
}

export function getUserInfo(userId) {
	return {
		type: adminPanelRequestTypes.GET_USER_INFO,
		request: {
			method: 'get',
			url: adminPanelApi.API_URL_USERS + `/${userId}`,
		},
	};
}

export function updatePaymentSetings(userId, data) {
	return {
		type: adminPanelRequestTypes.UPDATE_USER_PAYMENT_SETTINGS,
		request: {
			method: 'post',
			url: adminPanelApi.API_URL_AFFILIATES + `/${userId}/paymentSettings`,
			data: data,
		},
	};
}

export function deletePaymentSetings(userId, data) {
	return {
		type: adminPanelRequestTypes.DELETE_USER_PAYMENT_SETTINGS,
		request: {
			method: 'delete',
			url: adminPanelApi.API_URL_AFFILIATES + `/${userId}/paymentSettings`,
			data: data,
		},
		paymentId: data._id,
	};
}

export function updateUserOffers(userId, data, callback) {
	return {
		type: adminPanelRequestTypes.UPDATE_USER_OFFERS,
		request: {
			method: 'post',
			url: adminPanelApi.API_URL_USERS + `/${userId}/updateOffers`,
			data: data,
		},
		newOffer: data,
		callback: callback,
	};
}

export function userLocalUpdate(data) {
	return {
		//тип определяющий обрабатывающий редюсер
		type: adminPanelRequestTypes.LOCAL_USER_UPDATE,
		data: data,
	};
}

export function getEmails() {
	return {
		type: adminPanelRequestTypes.GET_EMAILS,
		request: {
			method: 'get',
			url: adminPanelApi.API_URL_EMAILS,
		},
	};
}

export function updateLandings(data) {
	return {
		type: adminPanelRequestTypes.UPDATE_USERS_LANDINGS,
		request: {
			method: 'post',
			url: adminPanelApi.API_URL_UPDATE_LANDINGS,
			data: data,
		},
	};
}

export function setAccess(data) {
	return {
		type: adminPanelRequestTypes.SET_USER_ACCESS,
		request: {
			method: 'post',
			url: adminPanelApi.API_URL_SET_ACCESS,
			data: data,
		},
	};
}
