import React from 'react';
import _ from 'underscore';
import memoize from 'memoize-one';
import MainFunctions from 'mainLibs/MainFunctions';
import { Tabs, Tab } from 'react-bootstrap';

import DetailPayments from './DetailPayments';

export default class ContentBlock extends React.PureComponent {
	componentDidMount() {
		this.props.getAllPaymentStatuses();
		this.props.getEmails();
	}

	parse(allPayments) {
		const payments = _.isArray(allPayments) ? allPayments.slice() : [];

		const result = {
			prePayments: [],
			payments: [],
		};

		function getCurrency(currency) {
			const correctCurrency = _.isObject(currency) ? currency : {};
			return correctCurrency.name + ` (${correctCurrency.code})`;
		}

		function getPaymentSystem(paymentSystem) {
			if (paymentSystem) return paymentSystem.description + ` (${paymentSystem.name})`;
			else return '';
		}

		function getUser(user) {
			if (!_.isObject(user) || !user) user = {};
			const money = (user.stats || {}).money || {};
			return {
				onBalance: money.onBalance,
				paid: money.paid,
				notPaid: money.notPaid,
				...user,
			};
		}

		function parsePayment(payments) {
			const correctPayments = _.isObject(payments) ? payments || {} : {};
			const statusesFlow = Array.isArray(correctPayments.statusesFlow)
				? correctPayments.statusesFlow
				: [];
			let hidden = false;
			if (
				statusesFlow.find((s) => {
					const name = ((s || {}).status || {}).name || '';
					return (
						name === 'Paid' ||
						name === 'Returned to balance' ||
						name === 'Transferred to balance'
					);
				})
			)
				hidden = true;
			return {
				_id: correctPayments._id || '',
				requestType: correctPayments.requestType || '',
				hidden: hidden,
				disabled: hidden,
				user: getUser(correctPayments.user),
				account: correctPayments.paymentSystemAccount || '',
				date: MainFunctions.dateFormat(correctPayments.date),
				amount: correctPayments.amount || 0,
				currency: getCurrency(correctPayments.currency),
				paymentSystem: getPaymentSystem(correctPayments.paymentSystem),
				statusesFlow: statusesFlow,
			};
		}

		for (let i = 0; i < payments.length; i++) {
			if (!_.isObject(payments[i]) || !payments[i]) payments[i] = {};
			const payment = parsePayment(payments[i]);
			if (payment.requestType === 'PaymentRequest') result.payments.push(payment);
			else result.prePayments.push(payment);
		}

		function sortByStatusAndDate(payments) {
			payments = _.groupBy(payments, (payment) => {
				const status = _.last(payment.statusesFlow) || {};
				return status.status ? status.status.name || '' : '';
			});
			if (!_.isObject(payments) || !payments) payments = {};
			for (let prop in payments) {
				payments[prop] = _.sortBy(payments[prop], (value) => Date.parse(value.date));
			}
			return payments;
		}
		return {
			paymentsData: sortByStatusAndDate(result.payments),
			prePaymentsData: sortByStatusAndDate(result.prePayments),
			allData: result.payments.concat(result.prePayments),
		};
	}

	parsePayments = memoize(this.parse);
	parsePrePayments = memoize(this.parse);

	render() {
		const {
			t,
			filter,
			preFilter,
			emails,
			payments,
			prePayments,
			updatePaymentStatus,
			paymentStatuses,
			getAllPayments,
			getEmails,
		} = this.props;
		const { paymentsData, allData: paymentsAllData } = this.parsePayments(payments);
		const { prePaymentsData, allData: prePaymentsAllData } = this.parsePrePayments(prePayments);
		return (
			<div className="box tabs tabs-primary payments-wrapper" style={{ borderTop: 'none' }}>
				<Tabs defaultActiveKey={'tab-pre-payments'} id="tab-payments" animation={true}>
					<Tab
						className="payments-wrapper"
						eventKey={'tab-pre-payments'}
						title={t('Запросы на досрочную выплату')}
					>
						<DetailPayments
							t={t}
							allData={prePaymentsAllData}
							data={prePaymentsData}
							getAllPayments={getAllPayments}
							updatePaymentStatus={updatePaymentStatus}
							paymentStatuses={paymentStatuses}
							getEmails={getEmails}
							emails={emails}
							filter={preFilter}
							pre={true}
							setFilter={this.props.setFilter}
							clearFilter={this.props.clearFilter}
						/>
					</Tab>
					<Tab
						className="payments-wrapper"
						eventKey={'tab-payments'}
						title={t('Запросы на выплату')}
					>
						<DetailPayments
							t={t}
							allData={paymentsAllData}
							data={paymentsData}
							updatePaymentStatus={updatePaymentStatus}
							getAllPayments={getAllPayments}
							paymentStatuses={paymentStatuses}
							getEmails={getEmails}
							emails={emails}
							filter={filter}
							pre={false}
							setFilter={this.props.setFilter}
							clearFilter={this.props.clearFilter}
						/>
					</Tab>
				</Tabs>
			</div>
		);
	}
}
