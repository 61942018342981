import React from 'react';
import { Col, Thumbnail, Button } from 'react-bootstrap';

export default class PreLandingItem extends React.Component {
	onDeletePreLandingClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		this.props.deletePreLanding(this.props.preLanding._id);
	};

	shouldComponentUpdate(nextProps, nextState) {
		return nextProps.preLanding !== this.props.preLanding || nextProps.i !== this.props.i;
	}

	render() {
		const { t, preLanding, edited } = this.props;
		return (
			<Col xs={4} md={3}>
				<Thumbnail src={preLanding.screenshot} alt={''}>
					<div>
						<h3>{preLanding.name}</h3>
						<p>
							<a href={preLanding.url}> {preLanding.url} </a>{' '}
						</p>
					</div>

					{edited && (
						<Button block onClick={this.onDeletePreLandingClick} bsStyle="danger">
							{t('Удалить')}
						</Button>
					)}
				</Thumbnail>
			</Col>
		);
	}
}
