import React from 'react';
import memoize from 'memoize-one';

import { Row, Col, ControlLabel, FormControl, FormGroup, Glyphicon } from 'react-bootstrap';

import Paginator from 'components/service/Paginator';

import LandingItem from './LandingItem';

export default class LandingsList extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			search: '',
			numPage: 1,
			numItems: 0,
			config: [],
		};
		this.sizePerPage = 12;
	}

	componentDidMount() {
		this.parseLandings(this.props.landings);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.landings !== this.props.landings) {
			this.parseLandings(this.props.landings);
		}
	}

	renderLandingItem = (data, i) => {
		return (
			<LandingItem
				key={data.key}
				t={this.props.t}
				i={i}
				data={data}
				hidden={data.hidden}
				offerId={this.props.offerId}
				edited={this.props.edited}
				deleteLanding={this.props.deleteLanding}
				hideLanding={this.props.hideLanding}
			/>
		);
	};

	parseLandings = memoize((allLandings) => {
		const landings = Array.isArray(allLandings) ? allLandings : [];
		const result = [];

		for (let i = 0; i < landings.length; i++) {
			const landing = this.props.parse(landings[i], i);
			if (landing) {
				result.push(landing);
			}
		}

		let numPage = this.state.numPage;
		while ((numPage - 1) * this.sizePerPage >= result.length) {
			numPage--;
		}
		if (numPage < 1) numPage = 1;
		this.setState({ config: result, numPage: numPage, numItems: result.length });
		return result;
	});

	renderPage = (numPage) => {
		let start = (numPage - 1) * this.sizePerPage;
		start = start < 0 ? 0 : start;
		let end = start + this.sizePerPage;
		end = end > this.state.config.length ? this.state.config.length : end;
		let result = [];
		let i = start;
		while (i < end) {
			result.push(this.renderLandingItem(this.state.config[i], i));
			i++;
		}
		if (!result.length) return null;
		return result;
	};

	setPage = (numPage) =>
		this.setState((prevState) => {
			return { numPage };
		});

	onChangeSearch = (event) => {
		this.search(this.state.config, event.target.value || '');
	};

	search = memoize((data, search) => {
		let numItems = 0;
		for (let i = 0; i < data.length; i++) {
			const name = (data[i][this.props.searchField] || '').toLowerCase();
			if (name.search(search.toLowerCase()) === -1) data[i].hidden = true;
			else {
				numItems++;
				data[i].hidden = false;
			}
		}
		this.setState({ config: data, numItems });
		return data;
	});

	render() {
		const { t } = this.props;
		const pagination = this.state.numItems > this.sizePerPage;
		const numberPage = Math.ceil(this.state.numItems / this.sizePerPage);
		return (
			<div className="box-body">
				<Row className="equal">
					{this.props.searchField && (
						<Col xs={12} sm={6} smOffset={6} md={6} mdOffset={6} lg={4} lgOffset={8}>
							<FormGroup style={{ width: '100%' }}>
								<ControlLabel>{t('Email веб-мастера')}</ControlLabel>

								<FormControl type="text" onChange={this.onChangeSearch} />

								<FormControl.Feedback>
									<Glyphicon glyph="search" />
								</FormControl.Feedback>
							</FormGroup>
						</Col>
					)}
					{this.renderPage(this.state.numPage)}

					{pagination && (
						<Col xs={12} style={{ justifyContent: 'flex-end' }}>
							<Paginator
								numberPage={numberPage}
								numPage={this.state.numPage}
								setPage={this.setPage}
							/>
						</Col>
					)}
				</Row>
			</div>
		);
	}
}
