import { genericTypes } from '../constants';

export function transitToPage(page) {
	return {
		type: genericTypes.GENERIC_PAGE_TRANSIT,
		payload: {
			page: page,
		},
	};
}
