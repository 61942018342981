import React from 'react';
import memoize from 'memoize-one';
import { Button } from 'react-bootstrap';
import SelectForm from './SelectForm';

class BoxBody extends React.PureComponent {
	changeInfo = (value, fieldName) => this.props.changeInfo(value, fieldName, this.props.index);
	getReactSelectOptionsFromTracklinks = memoize((offerId) => {
		const result = [];
		for (let i = 0; i < this.props.trackLinks.length; i++) {
			if (this.props.trackLinks[i].offer === offerId) {
				//const correctGeoRef = (this.props.trackLinks[i].offer.geos[0] ||{}).geoRef ||{};
				result.push({
					value: this.props.trackLinks[i]._id,
					//geo: correctGeoRef._id,
					label: this.props.trackLinks[i].name,
				});
			}
		}
		return result;
	});

	getReactSelectOptionsFromOffers = memoize((data) =>
		data.map((value) => {
			//const correctGeoRef = (value.geos[0] ||{}).geoRef ||{};
			return {
				value: value._id || '',
				trackLink: value.trackLink,
				geos: Array.isArray(value.geos) ? value.geos : [],
				//geo: correctGeoRef._id,
				label: value.name,
			};
		}),
	);

	getReactSelectOptionsFromUsers = memoize((users) =>
		users.map((user) => ({
			label: `${user.username} (${user._id})`,
			value: user._id || '',
		})),
	);

	render() {
		const { t, affiliate, merchants, offers, offer, merchant, trackLink } = this.props;
		const offersOptions = this.getReactSelectOptionsFromOffers(offers);
		const trackLinksOptions = this.getReactSelectOptionsFromTracklinks(offer);
		const merchantsOptions = this.getReactSelectOptionsFromUsers(merchants);
		return (
			<React.Fragment>
				<SelectForm
					required
					value={offer}
					label={t('Оффер')}
					changeInfo={this.changeInfo}
					name={'offer'}
					options={offersOptions}
					disabled={!affiliate || !offersOptions.length}
					placeholder={
						affiliate
							? offersOptions.length
								? t('Выберите оффер')
								: t('Нет подходящих офферов')
							: t('Выберите веб-мастера')
					}
				/>

				<SelectForm
					value={trackLink}
					label={t('Реферальная ссылка')}
					changeInfo={this.changeInfo}
					name={'trackLink'}
					options={trackLinksOptions}
					disabled={!offer || !trackLinksOptions.length}
					placeholder={
						offer
							? trackLinksOptions.length
								? t('Выберите реферальную ссылку')
								: t('Нет подходящих реферальных ссылок')
							: t('Выберите оффер')
					}
				/>

				<SelectForm
					required
					value={merchant}
					label={t('Рекламодатель')}
					changeInfo={this.changeInfo}
					name={'merchant'}
					options={merchantsOptions}
					disabled={!offer || !merchantsOptions.length}
					placeholder={
						offer
							? merchantsOptions.length
								? t('Выберите рекламодателя')
								: t('Нет подходящих рекламодателей')
							: t('Выберите оффер')
					}
				/>
			</React.Fragment>
		);
	}
}

export default class RequestBlock extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			boxCollapse: false,
		};
	}

	toggleBox = (e) => {
		e.preventDefault();
		this.setState((prevState) => {
			return {
				boxCollapse: !prevState.boxCollapse,
			};
		});
	};

	remove = () => this.props.remove(this.props.index);
	render() {
		return (
			<div className={'box box-danger' + (this.state.boxCollapse ? ' collapsed-box' : '')}>
				<div className="box-header with-border">
					<h3 className="box-title">{this.props.t(`Заявка №${this.props.index + 1}`)}</h3>
					<button className="btn btn-box-tool" onClick={this.toggleBox}>
						<i className={'fa ' + (this.state.boxCollapse ? 'fa-plus' : 'fa-minus')} />
					</button>
					<div className="box-tools pull-right">
						<Button bsStyle="danger" onClick={this.remove}>
							{this.props.t('Убрать заявку')}
						</Button>
					</div>
				</div>

				<div className="box-body" style={{ padding: '0px' }}>
					<BoxBody {...this.props} />
				</div>
			</div>
		);
	}
}
