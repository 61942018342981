import React from 'react';

import { Col, Button, Table } from 'react-bootstrap';

export default class PwaOptionsItem extends React.PureComponent {
	onClickButton = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (this.props.data._id) {
			this.props.deletePwaOptions({ pwaOptionID: this.props.data._id });
		}
	};

	render() {
		const { data, landing, middlePage } = this.props;

		return (
			<Col xs={6} md={4} lg={3} className="user-landing-item">
				<div style={{ paddingTop: '10px' }}>
					<Table>
						<tbody>
							<tr>
								<th>Название:</th>
								<td>{data.name}</td>
							</tr>
							<tr>
								<th>Лендинг:</th>
								<td>{landing.name}</td>
							</tr>
							<tr>
								<th>Прелендинг:</th>
								<td>{middlePage.name}</td>
							</tr>
						</tbody>
					</Table>
					{this.props.edited && (
						<Button
							block
							disabled={this.props.deleting}
							bsStyle={this.props.data.disabled ? 'success' : 'danger'}
							onClick={this.onClickButton}
						>
							Удалить
						</Button>
					)}
				</div>
			</Col>
		);
	}
}
