import React from 'react';
import memoize from 'memoize-one';
import MainFunctions from 'mainLibs/MainFunctions';

import { Row, Col } from 'react-bootstrap';

import classes from '../../DeepAffiliates.module.css';

import TicketsContainer from './TicketsContainer';
import Chat from 'components/service/Chat';

const defaultTicket = {
	id: null,
	topic: 'Проблема века',
	created: MainFunctions.dateFormat(Date.now()),
	closed: false,
	messages: [
		{
			_id: '123',
			sentByUser: true,
			date: Date.now(),
			message: 'Памагити!',
			readByBoth: false,
		},
		{
			_id: '124',
			sentByUser: false,
			date: Date.now(),
			message: 'Не перживайте!',
			readByBoth: false,
		},
	],
};

export default class UserDetailChat extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			ticketId: null,
		};
	}

	setTicket = (value) => {
		this.setState((prevState) => ({ ticketId: value.id }));
	};

	getCurrentTicket = memoize((tickets, ticketId) => {
		let ticket = null;
		const index = tickets.findIndex((t) => t.id === ticketId);
		if (index !== -1) ticket = tickets[index];

		return ticket;
	});

	render() {
		const { t, tickets, createTicket, userInfo, active, height } = this.props;
		const userId = this.props.userInfo ? this.props.userInfo.id : null;

		const noTickets = tickets.length === 0;
		const currentTicket = this.getCurrentTicket(tickets, this.state.ticketId);
		const openTicket = currentTicket ? currentTicket : defaultTicket;
		if (!active) return <div className="box-body" style={{ height: height }} />;
		else
			return (
				<Row style={{ marginTop: '20px' }}>
					<Col lg={3} md={4} sm={4} xs={12}>
						<TicketsContainer
							t={t}
							tickets={tickets}
							createTicket={createTicket}
							setTicket={this.setTicket}
							userId={userId}
						/>
					</Col>

					<Col lg={9} md={8} sm={8} xs={12}>
						{noTickets && (
							<div className={classes.overlayBlock}>
								<h2>
									{t('Пока обращений в поддержку не было!')}
									<br />
									{t('Надеемся и не будет') + ':)'}
								</h2>
							</div>
						)}
						{!noTickets && !currentTicket && (
							<div className={classes.overlayBlock}>
								<h2>{t('Выберите обращение')}</h2>
							</div>
						)}
						<Chat
							userInfo={userInfo}
							openTicket={openTicket}
							ticketId={this.state.ticketId}
							userId={userId}
						/>
					</Col>
				</Row>
			);
	}
}
