import { supportTypes } from '../../constants';

const initialState = {
	value: 'all',
};

export default function merchantId(state = initialState, action) {
	switch (action.type) {
		case supportTypes.SET_MERCHANT_ID:
			return { ...state, value: action.id };
		default:
			return state;
	}
}
