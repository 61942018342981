import React from 'react';
import BoxBody from './BoxBody';

export default class StatsBlock extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			boxCollapse: false,
		};
	}

	toggleBox = (e) => {
		e.preventDefault();
		this.setState((prevState) => {
			return { boxCollapse: !prevState.boxCollapse };
		});
	};

	render() {
		const { t } = this.props;
		return (
			<div className={'box box-primary' + (this.state.boxCollapse ? ' collapsed-box' : '')}>
				<div className="box-header with-border">
					<h3 className="box-title">{t('Статистика по выбранным фильтрам')}</h3>
					<div className="box-tools pull-right">
						<button className="btn btn-box-tool" onClick={this.toggleBox}>
							<i
								className={
									'fa ' + (this.state.boxCollapse ? 'fa-plus' : 'fa-minus')
								}
							/>
						</button>
					</div>
				</div>

				<BoxBody {...this.props} />
			</div>
		);
	}
}
