import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import ReactDOM from 'react-dom';
import _ from 'underscore';
import uuidv4 from 'uuid/v4';
import MainFunctions from 'mainLibs/MainFunctions';

import {
	Row,
	Col,
	Panel,
	FormControl,
	Button,
	OverlayTrigger,
	Tooltip,
	InputGroup,
} from 'react-bootstrap';

import {
	postMessageToTicket,
	closeTicket,
	readMessage,
} from 'store/actions/apiRequestActions/tickets';
import { DimensionsSize } from 'components/service/Dimensions';
import OpenImage from '../OpenImage';

import classes from './Chat.module.css';

import ChatMessage from './ChatMessage';

class Chat extends React.PureComponent {
	static defaultProps = {
		header: true,
		footer: true,
	};

	constructor(props) {
		super(props);
		this.inputNewMessage = null;
		this.state = {
			footerSize: {},
			headingSize: {},
			images: [],
		};
	}

	getSize = () => {
		let footerSize = {};
		let headingSize = {};
		function compress(object) {
			for (const key in object) {
				const value = object[key];
				if (!isNaN(value)) {
					object[key] = Math.ceil(value * 0.1) * 10;
				} else if (value && typeof value === 'object') {
					compress(value);
				}
			}
		}
		if (this.footer) footerSize = DimensionsSize(ReactDOM.findDOMNode(this.footer));
		if (this.heading) headingSize = DimensionsSize(ReactDOM.findDOMNode(this.heading));
		compress(footerSize);
		compress(headingSize);
		return {
			footerSize: footerSize,
			headingSize: headingSize,
		};
	};

	scrollToBottom = () => {
		if (this.messagesEnd && this.props.small) {
			setTimeout(() => this.messagesEnd.scrollIntoView({ behavior: 'smooth' }), 500);
		}
	};

	componentDidMount() {
		this.scrollToBottom();
		this.message = ReactDOM.findDOMNode(this.inputNewMessage);
		const size = this.getSize();
		this.setState(size);
	}

	componentDidUpdate(prevProps, prevState) {
		this.resize();
		this.scrollToBottom();
	}

	resize = () => {
		const size = this.getSize();
		if (
			!_.isEqual(this.state.footerSize, size.footerSize) ||
			!_.isEqual(this.state.headingSize, size.headingSize)
		) {
			this.setState(size);
		}
	};

	sendMail = () => {
		if (this.inputNewMessage) {
			if (this.props.ticketId) {
				let data = new FormData();
				data.append('message', this.message.value || '');
				for (let i = 0; i < this.state.images.length; i++) {
					data.append('images[]', this.state.images[i].img);
				}

				this.props.postMessageToTicket(this.props.ticketId, this.props.userId, data, () => {
					if (this.message) {
						this.message.value = '';
						this.setState({ images: [] });
					}
				});
			}
		}
	};

	closeTicket = () => {
		if (this.props.ticketId) {
			this.props.closeTicket(this.props.ticketId);
		}
	};

	getHeadingRef = (heading) => (this.heading = heading);
	getFooterRef = (footer) => (this.footer = footer);

	renderChatMessage = (message, id) => {
		const currentMessage = message || {};
		const userInfo = this.props.userInfo;
		const adminInfo = message.user || this.props.adminInfo;
		return (
			<ChatMessage
				small={this.props.small}
				key={currentMessage._id || uuidv4()}
				message={currentMessage}
				id={id}
				userInfo={userInfo}
				adminInfo={adminInfo}
			/>
		);
	};

	attachImg = (e) => {
		e.preventDefault();
		e.stopPropagation();
		const loaded = [];
		for (let i = 0; i < e.target.files.length; i++) {
			loaded[i] = true;
			if (e.target.files[i]) {
				const img = e.target.files[i];
				let reader = new FileReader();
				loaded[i] = false;
				loaded.push(
					new Promise((resolve, reject) => {
						reader.onloadend = () => {
							const images = this.state.images.slice();
							images.push({
								img: img,
								imgPreviewUrl: reader.result,
							});
							this.setState({ images });
							resolve();
						};
					}),
				);
				reader.readAsDataURL(img);
			}
		}
		Promise.all(loaded).then(() => (this.loadImage.value = null));
	};

	removeImage = (image) => {
		const images = this.state.images.slice();
		const index = images.findIndex((i) => i === image);
		images.splice(index, 1);
		this.setState({ images });
	};

	attachImgClick = (event) => ReactDOM.findDOMNode(this.loadImage).click();

	getLoadImage = (ref) => (this.loadImage = ref);

	render() {
		const { t, openTicket, header, footer, column, small, height, className = '' } = this.props;
		let maxHeightBody = height || window.innerHeight * 0.7;
		const { footerSize, headingSize } = this.state;
		if (column) {
			maxHeightBody = `${maxHeightBody - (headingSize.height || 0)}px`;
		} else {
			maxHeightBody = `${
				maxHeightBody - (footerSize.height || 0) - (headingSize.height || 0)
			}px`;
		}
		const bodyStyle = {
			overflow: 'auto',
			padding: small ? '0px' : '0px 15px',
		};
		if (height) {
			bodyStyle.height = maxHeightBody;
		} else {
			bodyStyle.maxHeight = maxHeightBody;
		}
		return (
			<div
				className={`${classes['chat-container']} ${
					small ? classes['small'] : ''
				} ${className}`}
				style={this.props.style}
			>
				<Panel className={'m-t-2'}>
					{header && (
						<Panel.Heading ref={this.getHeadingRef}>
							{React.isValidElement(header) ? (
								header
							) : (
								<Row>
									<Col xs={6} className="text-left">
										{openTicket.topic}
										<br />
										<small>
											{MainFunctions.dateFormat(openTicket.created || 0)}
										</small>
									</Col>

									<Col xs={6} className="text-right">
										{!openTicket.closed && (
											<OverlayTrigger
												ref="trigger"
												placement="top"
												overlay={
													<Tooltip
														id={`closed-${
															this.props.ticketId || uuidv4()
														}`}
														className="nameBreak"
													>
														{t('Закрыть текущий тикет')}
													</Tooltip>
												}
											>
												<button
													className="btn btn-table-tool"
													onClick={this.closeTicket}
												>
													<i className="fa fa-2x fa-remove" />
												</button>
											</OverlayTrigger>
										)}
									</Col>
								</Row>
							)}
						</Panel.Heading>
					)}

					<Panel.Body style={{ padding: small ? '0px' : '15px 0px' }}>
						<Col xs={12} md={column ? 8 : 12} style={bodyStyle}>
							{Array.isArray(openTicket.messages)
								? openTicket.messages.map(this.renderChatMessage)
								: null}
							<div
								style={{ float: 'left', clear: 'both' }}
								ref={(el) => {
									this.messagesEnd = el;
								}}
							></div>
						</Col>
						{column && (
							<Col
								xs={12}
								md={4}
								style={{ padding: '15px 15px 0px 15px' }}
								ref={this.getFooterRef}
							>
								<FormControl
									componentClass="textarea"
									placeholder={t('Сообщение') + '...'}
									inputRef={(ref) => {
										this.inputNewMessage = ref;
									}}
								/>

								<div style={{ maxHeight: '105px', overflowY: 'auto' }}>
									{this.state.images.map((image, index) => {
										return (
											<OpenImage
												key={`${image.imgPreviewUrl}_${index}`}
												style={{
													maxHeight: '50px',
												}}
												alt=""
												src={image.imgPreviewUrl}
												onLoad={this.resize}
												attach
												remove={this.removeImage}
											/>
										);
									})}
								</div>

								<InputGroup>
									<Button block bsStyle="primary" onClick={this.sendMail}>
										{t('Отправить')}
									</Button>
									<InputGroup.Button>
										<Button bsStyle="primary" onClick={this.attachImgClick}>
											<i className="fa fa-download" />
											<FormControl
												inputRef={this.getLoadImage}
												type="file"
												accept=".jpg, .jpeg, .png"
												style={{ display: 'none' }}
												onChange={this.attachImg}
												multiple
											/>
										</Button>
									</InputGroup.Button>
								</InputGroup>
							</Col>
						)}
					</Panel.Body>

					{footer && !column && (
						<Panel.Footer ref={this.getFooterRef}>
							{openTicket.closed && (
								<div className={classes.overlayBlock}>
									<h2>{t('Текущее обращение закрыто')}</h2>
								</div>
							)}

							<div>
								<FormControl
									componentClass="textarea"
									placeholder={t('Сообщение') + '...'}
									inputRef={(ref) => {
										this.inputNewMessage = ref;
									}}
								/>

								<div style={{ maxHeight: '105px', overflowY: 'auto' }}>
									{this.state.images.map((image, index) => {
										return (
											<OpenImage
												key={`${image.imgPreviewUrl}_${index}`}
												image={image}
												style={{
													maxHeight: '50px',
												}}
												alt=""
												src={image.imgPreviewUrl}
												onLoad={this.resize}
												attach
												remove={this.removeImage}
											/>
										);
									})}
								</div>

								<InputGroup>
									<Button block bsStyle="primary" onClick={this.sendMail}>
										{t('Отправить')}
									</Button>
									<InputGroup.Button>
										<Button bsStyle="primary" onClick={this.attachImgClick}>
											<i className="fa fa-download" />
											<FormControl
												inputRef={this.getLoadImage}
												type="file"
												accept=".jpg, .jpeg, .png"
												style={{ display: 'none' }}
												onChange={this.attachImg}
												multiple
											/>
										</Button>
									</InputGroup.Button>
								</InputGroup>
							</div>
						</Panel.Footer>
					)}
				</Panel>
			</div>
		);
	}
}
const mapStateToProps = ({ apiRequestStore }) => ({
	adminInfo: apiRequestStore.auth.user || {},
});

const mapActionCreators = {
	postMessageToTicket,
	closeTicket,
	readMessage,
};

Chat = translate('Chat')(Chat);
export default connect(mapStateToProps, mapActionCreators)(Chat);
