import makeNotifications from 'mainLibs/Notifications';
const DEFAULT_MESSAGE = {
	success: {
		title: 'Успешно!',
		message: 'Запрос успешно выполнен!',
		position: 'tr',
		autoDismiss: 3,
	},
	error: {
		title: 'Ошибка!',
		message: 'При запросе возникла ошибка!',
		position: 'tr',
		autoDismiss: 3,
	},
	warning: {
		title: 'Предупреждение!',
		message: 'Что-то пошло не так!',
		position: 'tr',
		autoDismiss: 3,
	},
	info: {
		title: 'Уведомление!',
		message: 'Что-то произошло!',
		position: 'tr',
		autoDismiss: 3,
	},
};

const Notifications = makeNotifications('Notifications', DEFAULT_MESSAGE);
const { MountedSystemComponent } = Notifications;

export { MountedSystemComponent };

export default Notifications;
