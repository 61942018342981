import React from 'react';
import { Row, Col, Tab, Nav, NavItem, NavDropdown, MenuItem } from 'react-bootstrap';

import Stats from './StatsPages';

export default class UserDetail extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			activeComponent: null,
			nameComponent: '',
			activeKey: 'UserDetailInfo',
		};
	}

	componentDidMount() {
		//this.switchStats(this.state.activeKey)
		this.setActiveComponent(this.state.activeKey, this.state.activeKey, this.state.activeKey);
	}

	switchStats = (nameComponent) =>
		this.setActiveComponent(
			`StatsPages/${nameComponent}`,
			'merchant-profile-stats',
			nameComponent,
		);

	async setActiveComponent(pathToComponent, activeKey, nameComponent) {
		const { default: component } = await import(`./${pathToComponent}`);

		this.setState({
			activeComponent: component,
			nameComponent: nameComponent,
			activeKey: activeKey,
		});
	}

	switchTab = (nameTab) => {
		this.setActiveComponent(nameTab, nameTab, nameTab);
	};

	render() {
		const {
			t,
			push,
			detailInfo,
			payments,
			getAllPayments,
			getCurrencies,
			currencies,
			getAllPaymentSystems,
			paymentSystems,
			addPayment,
		} = this.props;
		const ActiveComponent = this.state.activeComponent;
		return (
			<Tab.Container
				activeKey={this.state.activeKey}
				id="merchant-profile-tabs"
				onSelect={() => {}}
			>
				<Row className="clearfix">
					<Col sm={12}>
						<Nav bsStyle="tabs">
							<NavItem
								eventKey={'UserDetailInfo'}
								onClick={() => this.switchTab('UserDetailInfo')}
							>
								{t('О пользователе')}
							</NavItem>

							<NavItem
								eventKey={'UserDetailPayments'}
								onClick={() => this.switchTab('UserDetailPayments')}
							>
								{t('Выплаты')}
							</NavItem>

							<NavDropdown
								eventKey={'merchant-profile-stats'}
								title={t('Статистика')}
							>
								<MenuItem
									active={this.state.nameComponent === 'OverallStats'}
									onClick={() => this.switchTab('OverallStats')}
								>
									{t('Общая')}
								</MenuItem>

								<MenuItem
									active={this.state.nameComponent === 'DailyStats'}
									onClick={() => this.switchStats('DailyStats')}
								>
									{t('Ежедневная')}
								</MenuItem>

								<MenuItem
									active={this.state.nameComponent === 'RequestsStats'}
									onClick={() => this.switchStats('RequestsStats')}
								>
									{t('По заявкам')}
								</MenuItem>
							</NavDropdown>
						</Nav>
					</Col>

					<Col sm={12}>
						<Tab.Content animation={false}>
							<Tab.Pane eventKey={'UserDetailInfo'}>
								{this.state.nameComponent === 'UserDetailInfo' ? (
									ActiveComponent ? (
										<ActiveComponent
											t={t}
											push={push}
											detailInfo={detailInfo}
										/>
									) : null
								) : (
									<div className="box-body" style={{ height: '760px' }} />
								)}
							</Tab.Pane>

							<Tab.Pane eventKey={'UserDetailPayments'}>
								{this.state.nameComponent === 'UserDetailPayments' ? (
									ActiveComponent ? (
										<ActiveComponent
											t={t}
											data={payments}
											userId={detailInfo.id}
											addPayment={addPayment}
											getAllPayments={getAllPayments}
											getAllPaymentSystems={getAllPaymentSystems}
											paymentSystems={paymentSystems}
											getCurrencies={getCurrencies}
											currencies={currencies}
										/>
									) : null
								) : (
									<div className="box-body" style={{ height: '760px' }} />
								)}
							</Tab.Pane>

							<Tab.Pane eventKey={'OverallStats'}>
								{this.state.nameComponent === 'OverallStats' ? (
									ActiveComponent ? (
										<ActiveComponent
											t={this.props.t}
											userId={this.props.detailInfo.id}
										/>
									) : null
								) : (
									<div className="box-body" style={{ height: '760px' }} />
								)}
							</Tab.Pane>

							<Tab.Pane eventKey={'merchant-profile-stats'}>
								<Stats
									t={this.props.t}
									userId={this.props.detailInfo.id}
									statsName={this.state.nameComponent}
								>
									{this.state.activeKey === 'merchant-profile-stats' ? (
										ActiveComponent ? (
											<ActiveComponent
												t={this.props.t}
												userId={this.props.detailInfo.id}
											/>
										) : null
									) : (
										<div className="box-body" style={{ height: '760px' }} />
									)}
								</Stats>
							</Tab.Pane>
						</Tab.Content>
					</Col>
				</Row>
			</Tab.Container>
		);
	}
}
