import { adminPanelRequestTypes, adminPanelApi } from '../../constants';

export function getUsersLandings(params) {
	return {
		//тип определяющий обрабатывающий редюсер
		type: adminPanelRequestTypes.GET_USERS_LANDINGS,
		//объект request служит для конфигурации запроса axios
		request: {
			method: 'get',
			url: adminPanelApi.API_URL_GET_USERS_LANDINGS,
			params: params,
		},
	};
}
