import React from 'react';
import { Link } from 'react-router-dom';
import { TableHeaderColumn, BootstrapTable } from 'react-bootstrap-table';

export default class BoxBody extends React.PureComponent {
	constructor(props) {
		super(props);
		this.tdStyle = { whiteSpace: 'normal' };
	}

	formatLink = (cell) => {
		return <Link to={'/users/merchants/' + cell}>{cell}</Link>;
	};

	render() {
		const { t, data } = this.props;
		const numUsers = 15;
		const pagination = data.length > numUsers;
		return (
			<BootstrapTable
				data={data}
				striped={true}
				options={{ sizePerPage: numUsers }}
				hover={true}
				pagination={pagination}
				search
				multiColumnSearch
				searchPlaceholder={t('Поиск рекламодателя')}
			>
				<TableHeaderColumn
					dataField="id"
					isKey={true}
					dataAlign="center"
					tdStyle={this.tdStyle}
					dataFormat={this.formatLink}
				>
					{t('ID')}
					<br />
					{t('Рекламодателя')}
				</TableHeaderColumn>

				<TableHeaderColumn dataField="name" tdStyle={this.tdStyle} dataAlign="center">
					{t('Имя')}
				</TableHeaderColumn>

				<TableHeaderColumn dataField="lastName" tdStyle={this.tdStyle} dataAlign="center">
					{t('Фамилия')}
				</TableHeaderColumn>

				<TableHeaderColumn dataField="phone" tdStyle={this.tdStyle} dataAlign="center">
					{t('Телефон')}
				</TableHeaderColumn>

				<TableHeaderColumn dataField="email" tdStyle={this.tdStyle} dataAlign="center">
					{t('Email')}
				</TableHeaderColumn>
			</BootstrapTable>
		);
	}
}
