import React from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';

import DateForm from 'components/service/DateForm';

export default class BoxBody extends React.PureComponent {
	loadStats = () => {
		if (this.props.landingId) {
			const { start, end, ...otherFilter } = this.props.filter;
			const data = {
				landingId: this.props.landingId,
				start: new Date(start).setHours(0, 0, 0, 0),
				end:
					Date.now() < new Date(end).setHours(23, 59, 59, 999)
						? Date.now()
						: new Date(end).setHours(23, 59, 59, 999),
				...otherFilter,
			};
			this.props.getStasLandings(data);
		}
	};

	setFilter = (data) => this.props.setFilter('landingsStats', data);
	clearFilter = () => this.props.clearFilter('landingsStats');

	componentDidMount() {
		this.loadStats();
	}

	componentDidUpdate(prevprops) {
		if (prevprops.landingId !== this.props.landingId) {
			this.loadStats();
		}
	}

	changeFilterPeriod = ({ start, end, selectedPeriod }) => {
		var filter = { ...this.props.filter };
		filter.start = start;
		filter.end = end;
		filter.selectedPeriod = selectedPeriod;
		this.setFilter(filter);
	};

	search = (e) => {
		e.preventDefault();
		e.stopPropagation();
		this.loadStats();
	};

	render() {
		const { t } = this.props;
		return (
			<div className="box-body">
				<Form onSubmit={this.search} className="semiInline">
					<Row>
						<Col xs={12}>
							<DateForm
								label={t('Дата')}
								startValue={this.props.filter.start}
								endValue={this.props.filter.end}
								changeInfo={this.changeFilterPeriod}
								selectedPeriod={this.props.filter.selectedPeriod}
							/>
						</Col>
					</Row>
					<Button type="submit" bsStyle="info">
						{t('Отобразить')}
					</Button>{' '}
					<Button bsStyle="danger" onClick={this.clearFilter}>
						{t('Сбросить')}
					</Button>
				</Form>
			</div>
		);
	}
}
