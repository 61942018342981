import { genericTypes } from '../constants';

const initialState = {
	name: 'index',
	url: 'index',
	title: 'NNCPA | кабинет рекламодателя',
};

function updatePage(page) {
	let caption = null;
	let pageRoute =
		page.indexOf('/') > -1 && (page.match(/\//g) || []).length > 1
			? page.substr(0, page.lastIndexOf('/'))
			: page;
	switch (pageRoute) {
		default:
		case 'index':
		case 'profile':
			caption = 'кабинет рекламодателя';
			pageRoute = 'profile';
			break;
		case 'campaigns':
			caption = 'офферы';
			break;
		case 'partners':
			caption = 'партнеры';
			break;
		case 'campaigns/deepstats':
			caption = 'подробная статистика по компании';
			break;
		case 'stats/daily':
			caption = 'ежедневная статистика';
			break;
		case 'stats/requests':
			caption = 'статистика по заявкам';
			break;
		case 'stats/request':
			caption = 'информация о заявке';
			break;
		case 'stats/landings':
			caption = 'статистика по лендингам';
			break;
		case 'stats/prelandings':
			caption = 'статистика по прелендингам';
			break;
		case 'settings':
			caption = 'настройки';
			break;
	}
	// window.history.pushState({ page: page }, "NNCPA | " + caption, "?page=" + page);
	window.history.pushState(
		{
			page: page,
		},
		'NNCPA | ' + caption,
	);
	const _page = {
		name: pageRoute,
		url: page,
		title: 'NNCPA | ' + caption,
	};
	return _page;
}

export default function generic(state = initialState, action) {
	switch (action.type) {
		case genericTypes.GENERIC_PAGE_TRANSIT:
			return updatePage(action.payload.page);
		default:
			return state;
	}
}
