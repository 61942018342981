import React from 'react';
import uuidv4 from 'uuid/v4';
import PropTypes from 'prop-types';
import SelectForm from './SelectForm';

import { Row, Col } from 'react-bootstrap';

export default class SelectFormMarkers extends React.Component {
	static propTypes = {
		changeInfo: PropTypes.func.isRequired,
		name: PropTypes.string.isRequired,
		options: PropTypes.object,
	};

	shouldComponentUpdate(nextProps, nextState) {
		return this.props.values !== nextProps.values || this.props.options !== nextProps.options;
	}

	render() {
		const { values, options, label, defaultValue } = this.props;
		let selectsMarcker = [];
		for (let prop in options) {
			selectsMarcker.push(
				<Col
					xs={12}
					md={6}
					lg={3}
					key={uuidv4()}
					style={{ paddingLeft: '5px', paddingRight: '5px' }}
				>
					<SelectForm
						label={label + ` ${prop}`}
						value={values[prop]}
						defaultValue={defaultValue}
						changeInfo={this.props.changeInfo}
						name={prop}
						options={options[prop]}
					/>
				</Col>,
			);
		}

		return <Row style={{ paddingLeft: '10px', paddingRight: '10px' }}>{selectsMarcker}</Row>;
	}
}
