import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { I18nextProvider } from 'react-i18next';
import i18n from '../dictionary/i18n';

class AppContainer extends React.Component {
	static propTypes = {
		store: PropTypes.object.isRequired,
	};

	render() {
		const { store, routes, history } = this.props;
		return (
			<I18nextProvider i18n={i18n}>
				<Provider store={store}>
					<ConnectedRouter history={history}>{routes}</ConnectedRouter>
				</Provider>
			</I18nextProvider>
		);
	}
}

export default AppContainer;
