import React from 'react';
import { Pager } from 'react-bootstrap';

export default class Paginator extends React.PureComponent {
	nextPage = () => {
		let numPage = this.props.numPage;
		if (numPage < this.props.numberPage) numPage++;
		this.props.setPage(numPage);
	};

	prevPage = () => {
		let numPage = this.props.numPage;
		if (numPage > 1) numPage--;
		this.props.setPage(numPage);
	};

	toPage = (numPage) => this.props.setPage(numPage);

	toFirstPage = () => this.toPage(1);

	toLastPage = () => {
		const numPage = this.props.numberPage;
		this.toPage(numPage);
	};

	render() {
		const numberPage = this.props.numberPage;
		let toNumPage = this.props.numPage;
		if (this.props.numPage - 1 > 0) toNumPage = this.props.numPage - 1;
		if (this.props.numPage - 2 > 0) toNumPage = this.props.numPage - 2;
		return (
			<Pager style={{ margin: '0px' }}>
				{this.props.numPage >= 3 && (
					<Pager.Item onClick={this.toFirstPage}>
						<i className={'fa fa-lg fa-angle-double-left'} />
					</Pager.Item>
				)}

				{this.props.numPage > 1 && (
					<Pager.Item onClick={this.prevPage}>
						<i className={'fa fa-lg fa-long-arrow-left'} />
					</Pager.Item>
				)}

				{toNumPage <= numberPage && (
					<Pager.Item
						className={this.props.numPage === toNumPage ? 'active-page' : ''}
						onClick={() => this.toPage(toNumPage)}
					>
						{toNumPage}
					</Pager.Item>
				)}

				{toNumPage + 1 <= numberPage && (
					<Pager.Item
						className={this.props.numPage === toNumPage + 1 ? 'active-page' : ''}
						onClick={() => this.toPage(toNumPage + 1)}
					>
						{toNumPage + 1}
					</Pager.Item>
				)}

				{toNumPage + 2 <= numberPage && (
					<Pager.Item
						className={this.props.numPage === toNumPage + 2 ? 'active-page' : ''}
						onClick={() => this.toPage(toNumPage + 2)}
					>
						{toNumPage + 2}
					</Pager.Item>
				)}

				{toNumPage + 3 <= numberPage && (
					<Pager.Item
						className={this.props.numPage === toNumPage + 3 ? 'active-page' : ''}
						onClick={() => this.toPage(toNumPage + 3)}
					>
						{toNumPage + 3}
					</Pager.Item>
				)}

				{toNumPage + 4 <= numberPage && (
					<Pager.Item
						className={this.props.numPage === toNumPage + 4 ? 'active-page' : ''}
						onClick={() => this.toPage(toNumPage + 4)}
					>
						{toNumPage + 4}
					</Pager.Item>
				)}

				{this.props.numPage < numberPage && (
					<Pager.Item onClick={this.nextPage}>
						<i className={'fa fa-lg fa-long-arrow-right'} />
					</Pager.Item>
				)}

				{this.props.numPage <= numberPage - 2 && (
					<Pager.Item onClick={this.toLastPage}>
						<i className={'fa fa-lg fa-angle-double-right'} />
					</Pager.Item>
				)}
			</Pager>
		);
	}
}
