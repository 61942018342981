import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4';
import { Col, ControlLabel, FormGroup, FormControl } from 'react-bootstrap';

export default class SelectForm extends React.Component {
	static propTypes = {
		changeInfo: PropTypes.func.isRequired,
		name: PropTypes.string.isRequired,
		getLabel: PropTypes.func.isRequired,
		getValue: PropTypes.func.isRequired,
		options: PropTypes.array.isRequired,
	};

	shouldComponentUpdate(nextProps, nextState) {
		return this.props.options !== nextProps.options || this.props.value !== nextProps.value;
	}

	componentDidUpdate(prevProps, prevState) {
		if (!this.props.value) {
			const value = ReactDOM.findDOMNode(this.select);
			if (value) this.props.changeInfo(value, this.props.name);
		}
	}

	onChangeInfo = (e) => this.props.changeInfo(e, this.props.name);

	render() {
		const { label, options, value, getValue, getLabel } = this.props;
		const propsFormControl = {};
		if (value) propsFormControl.value = value;
		return (
			<FormGroup>
				<Col componentClass={ControlLabel} sm={2}>
					{label}
				</Col>
				<Col sm={10}>
					<FormControl
						inputRef={(ref) => (this.select = ref)}
						componentClass="select"
						onChange={this.onChangeInfo}
						{...propsFormControl}
					>
						{options.map((option) => (
							<option key={uuidv4()} value={getValue(option)}>
								{getLabel(option)}
							</option>
						))}
					</FormControl>
				</Col>
			</FormGroup>
		);
	}
}
