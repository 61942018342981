import { adminPanelRequestTypes } from '../../constants';
import { combineReducers } from 'redux';
import { push } from 'connected-react-router';
const initialAction = {
	loading: false,
	loaded: false,
	errors: null,
	data: {},
};

const initialAllActions = {
	loading: false,
	loaded: false,
	errors: null,
	data: [],
};

function action(state = initialAction, { type, data, errors }) {
	switch (type) {
		case adminPanelRequestTypes.GET_ACTIONS_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_ACTIONS_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		case adminPanelRequestTypes.GET_ACTIONS_SUCCESS:
			return { ...state, loading: false, loaded: true, errors: null, data: data };
		default:
			return state;
	}
}

function createdAction(state = initialAction, { type, data, errors, asyncDispatch, callback }) {
	switch (type) {
		case adminPanelRequestTypes.GREATE_ACTIONS_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GREATE_ACTIONS_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		case adminPanelRequestTypes.GREATE_ACTIONS_SUCCESS:
			callback && callback();
			asyncDispatch(push('stats/requests'));
			return { ...state, loading: false, loaded: true, errors: null };
		default:
			return state;
	}
}

function allActions(state = initialAllActions, { type, data, errors }) {
	switch (type) {
		case adminPanelRequestTypes.GET_ALL_ACTIONS_REQUEST:
			return { ...state, loading: true, loaded: false, errors: null };
		case adminPanelRequestTypes.GET_ALL_ACTIONS_FAIL:
			return { ...state, loading: false, loaded: true, errors: errors ? errors : true };
		case adminPanelRequestTypes.GET_ALL_ACTIONS_SUCCESS:
			return { ...state, loading: false, loaded: true, errors: null, data: data };
		default:
			return state;
	}
}

const actions = {
	allActions,
	action,
	createdAction,
};

export default combineReducers(actions);
