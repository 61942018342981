import React from 'react';

import { TableHeaderColumn, BootstrapTable } from 'react-bootstrap-table';

export default class UserDetailSignIn extends React.PureComponent {
	constructor(props) {
		super(props);
		this.tdStyle = { whiteSpace: 'normal' };
		this.numPayments = 15;
	}

	formatDate = (date) => {
		date = new Date(date);
		return date.toLocaleDateString();
	};

	render() {
		const { t, data } = this.props;
		const pagination = data.length > this.numPayments;
		return (
			<BootstrapTable
				data={data}
				striped={true}
				options={{ sizePerPage: this.numPayments }}
				hover={true}
				bordered={false}
				pagination={pagination}
			>
				<TableHeaderColumn dataField="_id" isKey={true} hidden />

				<TableHeaderColumn
					dataField="date"
					tdStyle={this.tdStyle}
					dataAlign="center"
					dataFormat={this.formatDate}
				>
					{t('Дата')}
				</TableHeaderColumn>

				<TableHeaderColumn dataField="value" tdStyle={this.tdStyle} dataAlign="center">
					{t('Ip')}
				</TableHeaderColumn>
			</BootstrapTable>
		);
	}
}
