import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import { getAllTickets, getFiltersData } from 'store/actions/apiRequestActions/tickets';

import { setFilter, clearFilter } from 'store/actions/supportActions';

import { closeTicket } from 'store/actions/apiRequestActions/tickets';
import ContentHeader from './ContentHeader';
import SearchBlock from './SearchBlock';
import ContentBlock from './ContentBlock';

class SupportTickets extends React.Component {
	render() {
		const { tickets, filterData } = this.props;
		const loading = tickets.loading || filterData.loading;
		return (
			<div className="content-wrapper">
				<ContentHeader access={this.props.access} t={this.props.t} />
				<section className="content">
					{loading ? (
						<div className="pageOverlay">
							<i className="fa fa-spinner css--animate" />
						</div>
					) : null}

					<SearchBlock
						t={this.props.t}
						filterData={filterData.data}
						getAllTickets={this.props.getAllTickets}
						getFiltersData={this.props.getFiltersData}
						filter={this.props.filter}
						setFilter={this.props.setFilter}
						clearFilter={this.props.clearFilter}
					/>

					<ContentBlock
						t={this.props.t}
						tickets={tickets.data}
						closeTicket={this.props.closeTicket}
					/>
				</section>
			</div>
		);
	}
}

//Получаем modalStore из stora и записываем в props компонента
const mapStateToProps = ({ apiRequestStore, supportedStore }) => ({
	tickets: apiRequestStore.tickets.allTickets,
	filterData: apiRequestStore.tickets.filterData,
	filter: supportedStore.filters.tickets,
});

//Пробрасываем actions в props компонента
const mapActionCreators = {
	getAllTickets,
	getFiltersData,
	closeTicket,
	setFilter,
	clearFilter,
};

SupportTickets = translate('SupportTickets')(SupportTickets);
export default connect(mapStateToProps, mapActionCreators)(SupportTickets);
