import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'underscore';
import memoize from 'memoize-one';
import {
	ProgressBar,
	FormGroup,
	Checkbox,
	Col,
	Button,
	Glyphicon,
	ButtonGroup,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FormatedOfferInfo from 'components/service/FormatedOfferInfo';
import { TableHeaderColumn, BootstrapTable } from 'react-bootstrap-table';
import MainFunctions from 'mainLibs/MainFunctions';

class Loader extends React.PureComponent {
	state = {
		display: 'none',
	};

	show(callback) {
		this.setState({ display: 'block' }, callback);
	}

	hide(callback) {
		this.setState({ display: 'none' }, callback);
	}

	render() {
		return (
			<div ref="loader" className="pageOverlay" style={this.state}>
				<i className="fa fa-spinner css--animate" />
			</div>
		);
	}
}

class CheckboxForm extends React.PureComponent {
	render() {
		return (
			<Col xs={12}>
				<FormGroup className={'pull-right'}>
					{/* <Checkbox inline onClick={this.props.setExportName}>
						{this.props.t('Имя')}
					</Checkbox>
					<Checkbox inline onClick={this.props.setExportPhone}>
						{this.props.t('Телефон')}
					</Checkbox> */}
					<Checkbox inline onClick={this.props.setExportOffer}>
						{this.props.t('Оффер')}
					</Checkbox>
					<Checkbox inline onClick={this.props.setExportDepostisCount}>
						{this.props.t('Депозиты')}
					</Checkbox>
					<Checkbox inline onClick={this.props.setExportStats}>
						{this.props.t('Ставки')}
					</Checkbox>
					<Checkbox inline onClick={this.props.setExportStatus}>
						{this.props.t('Статус заявки')}
					</Checkbox>
					<Button
						onClick={this.props.exportTable}
						bsStyle={'success'}
						style={{ marginLeft: '15px' }}
					>
						{this.props.t('Сохранить на устройство')}
					</Button>
				</FormGroup>
			</Col>
		);
	}
}

class ExpandSettings extends React.PureComponent {
	state = {
		expand: false,
		wrapper: document.querySelector('.wrapper'),
	};

	setApproved = (e) => this.props.updateStatusMany('Approved', e);
	setPending = (e) => this.props.updateStatusMany('Pending', e);
	setDeclined = (e) => this.props.updateStatusMany('Declined', e);
	setTrash = (e) => this.props.updateStatusMany('Trash', e);
	setError = (e) => this.props.updateStatusMany('Error', e);

	renderExpandSettings = () => {
		return (
			<div className={'text-center outer-settings ' + (this.state.expand ? 'active' : '')}>
				<strong> {this.props.t('Установить статус')} </strong>
				<ButtonGroup vertical block>
					<Button block bsStyle="primary" onClick={this.setPending}>
						{this.props.t('Заказ только что создан')}
					</Button>

					<Button block bsStyle="success" onClick={this.setApproved}>
						{this.props.t('Заказ одобрен')}
					</Button>

					<Button block bsStyle="warning" onClick={this.setDeclined}>
						{this.props.t('Заказ отменен')}
					</Button>

					<Button block bsStyle="danger" onClick={this.setError}>
						{this.props.t('Заказ с ошибкой')}
					</Button>

					<Button block bsStyle="danger" onClick={this.setTrash}>
						{this.props.t('Трэш')}
					</Button>
				</ButtonGroup>

				<Button block bsStyle="danger" onClick={this.props.reset}>
					{this.props.t('Сбросить')}
				</Button>

				<Button
					className={'action-toggle active-toggle'}
					bsStyle="primary"
					onClick={this.expandSettings}
				>
					<Glyphicon glyph={this.state.expand ? 'menu-right' : 'menu-left'} />
				</Button>
			</div>
		);
	};

	expandSettings = () => this.setState((prevState) => ({ expand: !prevState.expand }));

	render() {
		if (this.state.wrapper)
			return ReactDOM.createPortal(this.renderExpandSettings(), this.state.wrapper);
		else return null;
	}
}

export default class BoxBody extends React.PureComponent {
	constructor(props) {
		super(props);
		this.export = {
			name: false,
			phone: false,
			status: false,
			offer: false,
			depositsCount: false,
			stats: false,
		};
		this.state = {
			export: {
				is: false,
				...this.export,
			},
			selected: {},
		};
		this.tdStyle = { whiteSpace: 'normal' };
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.export.is) {
			setTimeout(this.refs.table.handleExportCSV, 1);
			setTimeout(
				() =>
					this.setState(
						{
							export: { ...this.state.export, is: false },
						},
						() => this.refs.loader.hide(),
					),
				501,
			);
		}
	}

	formatStatusIcon = (cell, row) => {
		let icon = '';
		let color = '';
		let title = '';
		switch (this.props.t(_.last(cell).status.name)) {
			case 'Approved':
				icon = ' fa-check-circle';
				color = '#99c262';
				title = this.props.t('Одобрено');
				break;
			case 'Created':
				icon = ' fa-plus-circle';
				color = '#59c7c8';
				title = this.props.t('Заказ создан');
				break;
			case 'Pending':
				icon = ' fa-spinner fa-spin';
				color = '#f8d347';
				title = this.props.t('Холд');
				break;
			case 'Declined':
				icon = ' fa-times-circle';
				color = '#ff3232';
				title = this.props.t('Отклонено');
				break;
			case 'Trash':
				icon = ' fa-trash';
				color = '#ff3232';
				title = this.props.t('Треш');
				break;
			case 'Error':
				icon = ' fa-warning';
				color = '#ff3232';
				title = this.props.t('Ошибка');
				break;
			default:
				break;
		}
		return (
			<i
				className={'fa fa-lg' + icon}
				style={{ color: color, fontSize: '2em' }}
				data-toggle="tooltip"
				data-placement="top"
				title={title}
				aria-hidden="true"
			/>
		);
	};

	formatText = (cell) => cell;

	formatLink = (cell) => {
		return <Link to={'/stats/requests/' + cell}>{cell}</Link>;
	};

	formatOffer = (cell, row) => <FormatedOfferInfo {...cell} geo={row.geo} />;

	formatDate = (cell, row) => {
		return MainFunctions.dateFormat(cell);
	};

	ratingFormat = (cell, row) => {
		return <ProgressBar min={0} max={100} now={cell} label={`${cell}%`} />;
	};

	formatStatus = (cell, row) => {
		return this.props.t(_.last(cell).status.description);
	};

	csvFormatterOffer = (cell, row) => {
		return (cell || {}).name || '';
	};

	formatStatusComment = (cell, row) => {
		return _.last(cell).comment;
	};

	formatClientName = (cell, row) => {
		return cell ? cell.name : '';
	};

	formatBets = (cell, row) => {
		const currencies = cell?.currencies || [];
		return (
			<div>
				{currencies.map((c) => (
					<div key={c.currency}>
						{Number(c.betsCount).toLocaleString()} <b>{c.currency}</b>{' '}
					</div>
				))}
			</div>
		);
	};

	csvFormatBets = (cell, row) => {
		const currencies = cell?.currencies || [];
		return currencies
			.map((c) => `${Number(c.betsCount).toLocaleString()} ${c.currency}`)
			.join('\r\n');
	};

	formatDepositsCount = (cell, row) => {
		return Number(cell?.depositsCount || 0).toLocaleString();
	};

	formatClientPhone = (cell, row) => {
		const str = cell ? cell.phone || '' : '';
		let phone = (str.match(/([0-9])+/g) || []).join('');
		let countryCode = '';

		if (phone.length > 10) {
			countryCode = phone.slice(0, phone.length - 10);
			if (countryCode === '8') countryCode = 7;
			countryCode = '+' + countryCode + ' ';
		}

		switch (phone.length) {
			case 13:
				phone =
					countryCode +
					phone.substr(3, 3) +
					' ' +
					phone.substr(6, 3) +
					'-' +
					phone.substr(9, 2) +
					'-' +
					phone.substr(11, 13);
				break;
			case 11:
				phone =
					countryCode +
					phone.substr(1, 3) +
					' ' +
					phone.substr(4, 3) +
					'-' +
					phone.substr(7, 2) +
					'-' +
					phone.substr(9, 11);
				break;
			case 10:
				phone =
					countryCode +
					'(' +
					phone.substr(0, 3) +
					') ' +
					phone.substr(3, 3) +
					'-' +
					phone.substr(6, 2) +
					'-' +
					phone.substr(8, 10);
				break;
			case 7:
				phone =
					countryCode +
					phone.substr(0, 3) +
					'-' +
					phone.substr(3, 2) +
					'-' +
					phone.substr(5, 7);
				break;
			default:
				phone = `'${phone}'`;
		}
		if (phone.length > 2) {
			return phone;
		} else {
			return str;
		}
	};

	setCheckBox(field) {
		const exportState = { ...this.state.export };
		exportState[field] = !exportState[field];
		this.setState({ export: exportState });
	}

	exportTable = () => {
		this.refs.loader.show(() => this.setState({ export: { ...this.export, is: true } }));
	};

	setExportName = () => (this.export.name = !this.export.name);
	setExportPhone = () => (this.export.phone = !this.export.phone);
	setExportStatus = () => (this.export.status = !this.export.status);
	setExportOffer = () => (this.export.offer = !this.export.offer);
	setExportDepostisCount = () => (this.export.depositsCount = !this.export.depositsCount);
	setExportStats = () => (this.export.stats = !this.export.stats);

	// setExportName = ()=> this.setCheckBox('name');
	// setExportPhone = ()=> this.setCheckBox('phone');
	// setExportStatus = ()=> this.setCheckBox('status');

	sortData = memoize((data) => _.sortBy(data, (value) => -Date.parse(value.created)));

	options = {
		exportCSVSeparator: ';',
	};

	onRowSelect = (row, isSelected, e) => {
		const selected = { ...this.state.selected };
		if (isSelected) {
			if ((row || {})._id) {
				selected[row._id] = row;
			}
		} else {
			if ((row || {})._id) {
				delete selected[row._id];
			}
		}
		this.setState({ selected });
	};

	onSelectAll = (isSelected, rows) => {
		const selected = { ...this.state.selected };
		for (let i = 0; i < rows.length; i++) {
			rows[i] = rows[i] || {};
			if (isSelected) {
				if (rows[i]._id) {
					selected[rows[i]._id] = rows[i];
				}
			} else {
				if (rows[i]._id) {
					delete selected[rows[i]._id];
				}
			}
		}
		this.setState({ selected });
	};

	selectRowProp = {
		mode: 'checkbox',
		clickToSelect: true,
		onSelect: this.onRowSelect,
		onSelectAll: this.onSelectAll,
	};

	cleanSelected = () => {
		const selected = {};
		this.setState({ selected }, () => this.refs.table.cleanSelected());
	};

	updateStatusMany = (statusName, e) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		const requests = Object.keys(this.state.selected);
		if (requests.length) {
			const status = (this.props.statuses.find((s) => s.name === statusName) || {})._id;
			const comment = this.comment || '';
			if (status) {
				this.props.updateStatusMany({ requests, status, comment });
			}
		}
	};

	render() {
		const { t, requests } = this.props;
		const data = this.sortData(requests);
		const period = {
			start: data[0]
				? new Date(data[0].created).toDateString().split(' ').slice(1).join('_')
				: '',
			end: _.last(data)
				? new Date(_.last(data).created).toDateString().split(' ').slice(1).join('_')
				: '',
		};
		return (
			<React.Fragment>
				<ExpandSettings
					t={this.props.t}
					updateStatusMany={this.updateStatusMany}
					reset={this.cleanSelected}
				/>

				<Loader ref="loader" />

				<div className="box-body" style={{ padding: '0px' }}>
					<CheckboxForm
						t={this.props.t}
						setExportName={this.setExportName}
						setExportOffer={this.setExportOffer}
						setExportPhone={this.setExportPhone}
						setExportStatus={this.setExportStatus}
						setExportDepostisCount={this.setExportDepostisCount}
						setExportStats={this.setExportStats}
						exportTable={this.exportTable}
					/>

					<BootstrapTable
						ref="table"
						data={data}
						striped={true}
						hover={true}
						condensed={true}
						pagination={true}
						bordered={false}
						options={this.options}
						csvFileName={`requests${period.start}-${period.end}.csv`}
						selectRow={this.selectRowProp}
					>
						<TableHeaderColumn
							dataField="_id"
							isKey={true}
							dataAlign="center"
							tdStyle={this.tdStyle}
							dataFormat={this.formatLink}
							export={false}
						>
							{t('ID заявки')}
						</TableHeaderColumn>

						<TableHeaderColumn
							dataField="offer"
							dataAlign="center"
							tdStyle={this.tdStyle}
							dataFormat={this.formatOffer}
							export={this.state.export.offer}
							csvHeader={t('Оффер')}
							csvFormat={this.csvFormatterOffer}
						>
							{t('Оффер')}
						</TableHeaderColumn>

						<TableHeaderColumn
							dataField="created"
							dataAlign="center"
							tdStyle={this.tdStyle}
							dataFormat={this.formatDate}
							export={false}
						>
							{t('Дата')}
						</TableHeaderColumn>

						<TableHeaderColumn
							dataField="statusesFlow"
							dataAlign="center"
							dataFormat={this.formatStatusIcon}
							width={'80px'}
							tdStyle={this.tdStyle}
							csvHeader={t('Статус заявки')}
							export={this.state.export.status}
							csvFormat={this.formatStatus}
						>
							{t('Статус')}
						</TableHeaderColumn>

						<TableHeaderColumn
							dataField="stats"
							tdStyle={this.tdStyle}
							dataFormat={this.formatDepositsCount}
							csvFormat={this.formatDepositsCount}
							dataAlign="center"
							csvHeader={t('Депозиты')}
							export={this.state.export.depositsCount}
						>
							{t('Депозиты')}
						</TableHeaderColumn>

						<TableHeaderColumn
							dataField="stats"
							dataFormat={this.formatBets}
							tdStyle={this.tdStyle}
							dataAlign="center"
							csvHeader={t('Ставки (сумма в валюте)')}
							csvFormat={this.csvFormatBets}
							export={this.state.export.stats}
						>
							{t('Ставки (сумма в валюте)')}
						</TableHeaderColumn>

						{/* <TableHeaderColumn
							dataField="client"
							dataFormat={this.formatClientName}
							tdStyle={this.tdStyle}
							dataAlign="center"
							csvHeader={t('Имя')}
							csvFormat={this.formatClientName}
							export={this.state.export.name}
						>
							{t('Имя')}
						</TableHeaderColumn>

						<TableHeaderColumn
							dataField="client"
							dataFormat={this.formatClientPhone}
							tdStyle={this.tdStyle}
							dataAlign="center"
							csvHeader={t('Телефон')}
							csvFormat={this.formatClientPhone}
							export={this.state.export.phone}
						>
							{t('Телефон')}
						</TableHeaderColumn> */}

						{/* <TableHeaderColumn 
                            dataField="suspectRatio" 
                            dataAlign="center"
                            tdStyle={this.tdStyle} 
                            dataFormat={this.ratingFormat}
                            export = {false}
                        >
                            {t('Рейтинг')}
                        </TableHeaderColumn> */}

						<TableHeaderColumn
							dataField="statusesFlow"
							dataAlign="center"
							tdStyle={this.tdStyle}
							dataFormat={this.formatStatusComment}
							export={false}
						>
							{t('Комментарий')}
						</TableHeaderColumn>
					</BootstrapTable>
				</div>
			</React.Fragment>
		);
	}
}
