import React from 'react';

export default class ContentHeader extends React.PureComponent {
	render() {
		const { t } = this.props;
		return (
			<section className="content-header">
				<h1>
					<i className="fa fa-user" />
					{t('Тикеты')}
				</h1>
			</section>
		);
	}
}
