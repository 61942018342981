import { adminPanelRequestTypes, adminPanelApi } from '../../constants';

export function getOffersForRequest(params) {
	return {
		type: adminPanelRequestTypes.GET_OFFERS_FOR_REQUEST,
		request: {
			method: 'get',
			url: adminPanelApi.API_URL_REQUEST_OFFERS,
			params: params,
		},
	};
}

export function getMerchantsForRequest(params) {
	return {
		type: adminPanelRequestTypes.GET_MERCHANTS_FOR_REQUEST,
		request: {
			method: 'get',
			url: adminPanelApi.API_URL_REQUEST_MERCHANTS,
			params: params,
		},
		offerId: params.offer,
		geoId: params.geo,
	};
}

export function getTrackLinksForRequest(params) {
	return {
		type: adminPanelRequestTypes.GET_TRACKLINKS_FOR_REQUEST,
		request: {
			method: 'get',
			url: adminPanelApi.API_URL_REQUEST_TRACKLINKS,
			params: params,
		},
	};
}

const requestedCitys = [];
export function getCitysForRequest(params = {}, geoId) {
	if (requestedCitys.findIndex((id) => id === geoId) === -1) {
		requestedCitys.push(geoId);
		return {
			type: adminPanelRequestTypes.GET_CITYS_FOR_REQUEST,
			request: {
				method: 'get',
				url: adminPanelApi.API_URL_REQUEST_CITY,
				params: params,
			},
			geoId: geoId,
		};
	} else return { type: 'miss' };
}

export function getIpsForRequest(params) {
	return {
		type: adminPanelRequestTypes.GET_IPS_FOR_REQUEST,
		request: {
			method: 'get',
			url: adminPanelApi.API_URL_REQUEST_IP,
			params: params,
		},
	};
}

export function getAffiliatesForRequest(params) {
	return {
		type: adminPanelRequestTypes.GET_AFFILIATES_FOR_REQUEST,
		request: {
			method: 'get',
			url: adminPanelApi.API_URL_REQUEST_AFFILIATES,
			params: params,
		},
	};
}
